import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as Common from '../../component/services/common/CommonService';
import * as Service from '../../component/services/operation/photoGalleryService';

class PhotoGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authToken: Common.getToken(this),
      data: []
    };
  }

  componentDidMount() {
    this.getPhotoGallery();
  }

  // 포토갤러리 렌더링
  getPhotoGallery = () => {
    const { authToken } = this.state;
    Service.getPhotoGallery(authToken).then((response) => {
      this.setState({
        data: response.data.data
      });
    });
  }

  render() {
    const { data } = this.state;
    console.log(this.state.data)
    return (
      <>
        <div className="title"><p className="mb10"><span>홈</span> 〉 <span>콘텐츠</span> 〉 <span>포토갤러리관리</span></p>포토갤러리관리</div>
        <div className="">
          <div className="">

          </div>
          <table className="mt20 photo_table">
            <colgroup>
              <col width="80px" />
              <col width="26%" />
              <col width="" />
              <col width="" />
              <col width="10%" />
              <col width="10%" />
            </colgroup>
            <thead>
              <th className="ccc" colsPan="11"> 총12개</th>
              <tr>
                <th>노출순서</th>
                <th>이미지</th>
                <th>브랜드명</th>
                <th>모델명</th>
                <th>등록일</th>
                <th>관리</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => {
                if(item.image === null){
                  return (
                    <tr key={`pkey${index}`}>
                      <td>{index + 1}</td>
                      <td colsPan="4" style={{height : '120px'}}>
                        비어있음
                      </td>
                      <td>
                        <Link to={`/operation/photoGallery/photoGalleryDetail/${item.position}&0`}>
                          <button type="button" className="btn grayBg ml10">추가</button>
                        </Link>
                      </td>
                    </tr>
                  )
                }else{
                  return (
                    <tr key={`pkey${index}`}>
                      <td>{index + 1}</td>
                      <td>
                        <div className="gall_img">
                          <img src={item.image} alt="이미지" />
                        </div>
                      </td>
                      <td>{item.brand_name}</td>
                      <td>{item.car_model_name}</td>
                      <td>{item.updated_date}</td>
                      <td>
                        <Link to={`/operation/photoGallery/photoGalleryDetail/${item.position}&${item.car_model_id}`}>
                          <button type="button" className="btn grayBg ml10">수정</button>
                        </Link>
                      </td>
                    </tr>
                  )
                }
              })}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

export default PhotoGallery;
