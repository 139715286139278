import React, {useEffect, useState} from 'react';
import PriorityForm from "./PriorityForm";
import '../../UIKitMonkeyPatch.scss'

const menuNameTable = {
    carModel: '자동차 모델',
    content: '컨텐츠',
    video: '동영상',
    carModelImage: '인기 포토'
};

const PriorityManager = ({match}) => {
    const [targetModel, setTargetModel] = useState(match.params.targetModel);

    useEffect(() => {
        setTargetModel(match.params.targetModel);
    }, [match.params.targetModel]);


    return (
        <>
            <div className="title">
                <p className="mb10">
                    <span>홈</span> 〉 <span>운영</span> 〉 <span>우선순위 지정 - {menuNameTable[targetModel]}</span>
                </p>
                우선순위 지정 - {menuNameTable[targetModel]}
            </div>
            <PriorityForm targetModel={targetModel} />
        </>
    );
};

export default PriorityManager;
