import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import DatePicker from 'react-datepicker';
import Pagination from '../../component/Pagination'

import moment from 'moment'
import Select from 'react-select';

import * as Service from '../../component/services/operation/estimateService'
import * as Common from "../../component/services/common/CommonService";
import * as brandService from "../../component/services/brand/BrandService";
import * as modelGroupService from "../../component/services/vehicleDB/ModelGroupService"
import * as modelService from "../../component/services/vehicleDB/ModelService"

class TotalInfo extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      authToken: Common.getToken.call(this),
      isEnabled: "전체",
      per: 8,
      page: 1,
      fetching: false,
      data : [],
      userName : "",
      startAt : new Date(),
      endAt : new Date(),
      mode : "classic",
      pagination : {},
      totalCnt : 0,

      brandId : {label : '전체' , value : 0},
      modelGroupId : {label : '전체' , value : 0},
      modelId : {label: '전체' , value : 0},

      totalInfo: [],
      modelGroup : [{label: '전체' , value : 0}],
      model : [{label: '전체' , value : 0}],
    };
  }

  // Select Functions 
  selectBrand = selectOption => {
    this.setState({
      brandId : selectOption,
      modelGroup : [{label: '전체' , value : 0}],
      model : [{label: '전체' , value : 0}],
      lineUpList : [{label: '전체' , value : 0}],
      modelGroupId : {label : '전체' , value : 0},
      modelId : {label: '전체' , value : 0},
      lineUpId : {label: '전체' , value : 0},
    } , () => {
      const req = modelGroupService.getModelG(this.state.authToken , this.state.brandId.value)
      req.then(response => {
        const data = response.data.data
        let options = []
        options.push({label: '전체' , value : 0})
        for(var x = 0; x<data.length; x++){
          options.push({label: data[x].name , value : data[x].id})
        }
        this.setState({
          modelGroup : options
        })
      })
    });
  };

  selectModelGroup = selectOption => {
    this.setState({
      modelGroupId : selectOption,
      model : [{label: '전체' , value : 0}],
      lineUpList : [{label: '전체' , value : 0}],
      modelId : {label: '전체' , value : 0},
      lineUpId : {label: '전체' , value : 0},
    } , () => {
      const req = modelService.getModelList(
        this.state.brandId.value,
      10000,
      1,
      this.state.authToken,
      this.state.modelGroupId.label,
      "","all","","","","","",""
      )
      req.then(response => {
        const data = response.data.data
        let options = []
        options.push({label: '전체' , value : 0})
        for(var x = 0; x<data.length; x++){
          options.push({label: data[x].name , value : data[x].id})
        }
        this.setState({
          model : options
        })
      })
    });
  };

  selectModel = selectOption => {
    this.setState({
      modelId : selectOption
    })
  }

  infoSearchValue = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  setStartDateOnChange = (date) => {
    this.setState({
      startAt: date
    });
  };

  setEndDateOnChange = (date) => {
    this.setState({
      endAt: date
    });
  };

  onClickPage = (page) => {
    this.setState({page} , () => this.getEstimates())
  };

  onClickSearch = () => {
    this.setState({
      mode : "custom"
    },() => this.getEstimates())
  }
  

  getTotalInfo = async () => {
    const brandList = brandService.getTotalInfo(this.state.authToken);
    await brandList.then(response => {
      const data = response.data.data
      let options = []
      options.push({label: '전체' , value : 0})
      for(var x = 0; x<data.length; x++){
        options.push({label: data[x].name , value : data[x].id})
      }
      this.setState({
        totalInfo: options
      });
    });
  };

  getEstimates = async () => {
    await Service.getEstimates(
      this.state.authToken,
      this.state.page,
      this.state.per,
      this.state.modelId.value,
      this.state.mode === "custom" ? this.state.userName : "",
      this.state.mode === "custom" ? this.state.startAt : "",
      this.state.mode === "custom" ? this.state.endAt : "",
    ).then(res => {
      this.setState({
        data : res.data.data,
        pagination : res.data.pagination,
        totalCnt : res.data.total_entries
      })
    })
  }

  componentDidMount() {
    this.getTotalInfo()
    this.getEstimates()
  }

  render() {
    console.log(this.state)
    return (
      <Fragment>
        {/* <BigText>종합정보 <p className="floatR"><span>홈</span> 〉 <span>브랜드</span> 〉 <span>종합정보</span></p></BigText> */}
        <div className="title"><p className="mb10"><span>홈</span> 〉 <span>운영</span> 〉 <span>견적요청현황관리</span>
          </p>
          견적요청현황관리{" "}

        </div>
        <div className="info_searchwrap mt20">
          <form>
            <table>
              <colgroup>
                <col width="10%"></col>
                <col width="*"></col>
              </colgroup>
              <tbody>
                <tr>
                  <th>모델명</th>
                  <td id="brandSelect">
                    <Select className="reactSelect"
                    options={this.state.totalInfo}
                    value={this.state.brandId}
                    name="brandId"
                    onChange={this.selectBrand}
                    placeholder="전체"
                    isSearchable={true}/>

                    <Select className="reactSelect"
                    options={this.state.modelGroup}
                    value={this.state.modelGroupId}
                    name="brandId"
                    onChange={this.selectModelGroup}
                    placeholder="전체"
                    isSearchable={true}/>

                    <Select className="reactSelect"
                    options={this.state.model}
                    value={this.state.modelId}
                    name="brandId"
                    onChange={this.selectModel}
                    placeholder="전체"
                    isSearchable={true}/>
                  </td>
                </tr>
                <tr>
                  <th>작성자</th>
                  <td>
                      <input type="text" name="userName" value={this.state.userName} onChange={this.infoSearchValue}/>
                  </td>
                </tr>
                <tr>
                  <th>등록일</th>
                    <td>
                      <DatePicker
                        onChange={this.setStartDateOnChange}
                        selected={this.state.startAt}
                        maxDate={this.state.endAt}
                        dateFormat="yyyy-MM-dd"
                      />
                      {' '}
                      -
                      {' '}
                      <DatePicker
                        onChange={this.setEndDateOnChange}
                        selected={this.state.endAt}
                        minDate={this.state.startAt}
                        maxDate={new Date()}
                        dateFormat="yyyy-MM-dd"
                      />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="centerT mt20">
              <button
                type="button"
                className="btnLine navyBg medium"
                onClick={this.onClickSearch}
              >
                검색
              </button>
            </div>
          </form>
        </div>
        {/* 종합정보 검색영역 끝 */}
        <div className="info_resultwrap">
          <div className="info_tabletop">
          </div>
          <table>
            <colgroup>
              <col width="10%"></col>
              <col width="15%"></col>
              <col width="30%"></col>
              <col width="20%"></col>
              <col width="25%"></col>
            </colgroup>
            <thead>
              <tr>
                <th className="ccc" colsPan="11"> 총{this.state.totalCnt}개</th>
              </tr>
              <tr>
                <th>번호</th>
                <th>
                  접수번호
                </th>
                <th>
                  견적요청 모델명
                </th>
                <th>작성자</th>
                <th>등록일</th>
              </tr>
            </thead>
            <tbody>
              {this.state.data.map((item, key) => {
                return (
                  <tr key={key}>
                    <td>{key+1+((this.state.page-1)*8)}</td>
                    <td>
                      <Link to={`/operation/Estimate_detail/${item.id}`} className="">{item.id}</Link>
                    </td>
                    <td style={{textAlign : 'left'}}>
                      {item.brand.name} 〉{item.car_model.name}
                    </td>
                    <td>{item.user_name}</td>
                    <td>
                      {moment(item.created_at).format('YYYY-MM-DD hh:mm:ss')}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="pagination">
            <Pagination
              onClickPage={this.onClickPage}
              paginationOption={this.state.pagination}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default TotalInfo;
