import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import * as Common from './services/common/CommonService';
import { completeLogin, completeLogout } from '../store/modules/login';


import LogoBox from './Logo';
import MenuBox from './MenuBox';
import MenuFooter from './MenuFooter';
import SideMenu from './SideMenu';
import '../style/common.scss';
import '../style/style.scss';

class Header extends Component {

  changeLoginState = () => {
    const { completeLogout } = this.props;
    if (window.localStorage.getItem('token')) {
      Common.logout(window.localStorage.getItem('token'));
      window.localStorage.removeItem('token');
      window.localStorage.removeItem('username');
      alert('로그아웃 되었습니다.');
      completeLogout();
    }
  }

  render() {
    const { loginState } = this.props;
    return (
      <>
        {loginState
          ? (
            <>
              <div className="header">
                <NavLink to="#" className="mainIcon"><LogoBox /></NavLink>
                <MenuBox />
                <MenuFooter changeLoginState={this.changeLoginState} loginState={loginState} />
              </div>
              <div className="s-header">
                <SideMenu />
              </div>
            </>
          ) : ''}
      </>
    );
  }
}

/* -- 예제 파일
const Header = () => {
return(
    <Fragment>
      <div className="header">
        <NavLink to="/" className="item" activeClassName="active">홈</NavLink>
        <NavLink to="/about" className="item" activeClassName="active">소개</NavLink>
        <NavLink to="/posts" className="item" activeClassName="active">포스트</NavLink>
        <NavLink to="/login" className="item" activeClassName="active">로그인</NavLink>
        <NavLink to="/mypage" className="item" activeClassName="active">마이페이지</NavLink>
        <NavLink to="/search" className="item" activeClassName="active">검색</NavLink>
      </div>
    </Fragment>
    )
}
*/
const mapStateToProps = (state) => ({
  loginState: state.login.loginState
});

const mapDispatchToProps = (dispatch) => ({
  completeLogin: () => dispatch(completeLogin()),
  completeLogout: () => dispatch(completeLogout())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
