import React, { Component, Fragment } from 'react';
import {Link} from 'react-router-dom';
import * as Common from "../../component/services/common/CommonService";
import * as Service from "../../component/services/vehicleDB/TrimService";
import * as BrandService from "../../component/services/brand/BrandService";
import * as modelGroupService from "../../component/services/vehicleDB/ModelGroupService"
import * as modelService from "../../component/services/vehicleDB/ModelService"
import * as LineUpService from '../../component/services/vehicleDB/LineUpService'
import * as PriceOptionService from '../../component/services/brand/PriceOptionService';
import styles from '../../style/vehicleDB/trim.module.scss';
import Select from 'react-select';
import classNames from 'classnames/bind';

let id = 0;
class TrimUpdate extends Component{
    constructor(props) {
      super(props);
      this.state = {
        page_history: this.props.location.search.replace(/\?page=/g, '') ? this.props.location.search.replace(/\?page=/g, '') : 1,
        authToken: Common.getToken.call(this),
        isEdit : false,
        editId : 0,
        brandId : {label : '전체' , value : 0},
        modelGroupId : {label : '전체' , value : 0},
        modelId : {label: '전체' , value : 0},
        totalInfo : [],
        modelGroup : [{label: '전체' , value : 0}],
        model : [{label: '전체' , value : 0}],
        lineUpList : [{label: '전체' , value : 0}],
        priceOptionList : [{label: '가격옵션' , value : 0}],
        optionList : [{label : '변속기' , value : null} ,{label : 'A/T' , value : "A/T"} ,{label : 'M/T' , value : "M/T"} ],
        forms : [],
        popActiveResource:false,            // 팝업창 상태값
        popActiveOption:false,
        popActivePriceOption: false
      };
      this.exposureResource = this.exposureResource.bind(this)
      this.exposureOption = this.exposureOption.bind(this)
    }
    infoSearchValue = e => {
      this.setState({
        [e.target.name]: e.target.value
      });
    };
  
    exposureResource = (e,index) =>{
      const data = this.state.forms
      data[index].popActiveResource = true
      this.setState({forms : data});
    }
    exposureOption = (e,index) =>{
      const data = this.state.forms
      data[index].popActiveOption = true
      this.setState({forms : data});
    }
    exposurePriceOption = (e,index) =>{
      const data = this.state.forms
      data[index].popActivePriceOption = true
      this.setState({forms : data});
    }

  closePop = (index, popUpType) => {
    const data = this.state.forms;

    if (popUpType && popUpType === 'priceOption') {
      let validationFlag = true;
      let emptyFlag = false;
      let savePriceOptions = [];

      data[index].price_options.forEach((item) => {
        if (!item.name || !item.price) {
          validationFlag = false;
          if (data[index].price_options.length === 1 && (!item.name && !item.price)) {
            emptyFlag = true;
          }
          return false;
        } else {
          savePriceOptions.push({
            id: item.id,
            name: item.name,
            price: item.price,
            description: item.description
          });
        }
      });

      if (!validationFlag && !emptyFlag) {
        alert('옵션명 또는 가격 입력이 올바르지 않습니다.');
        return false;
      } else if (!validationFlag && emptyFlag) {
        data[index].save_price_options = [];
      } else {
        data[index].save_price_options = savePriceOptions;
      }
    };

    data[index].popActiveOption = false;
    data[index].popActiveResource = false;
    data[index].popActivePriceOption = false;
    this.setState({forms : data});
  }
  
    componentDidMount() {
      const id = this.props.location.pathname.split('/')
        if(id[id.length-1].indexOf('bulk') === -1){
          this.setState({
            isEdit : true,
            editId : id[id.length-1]
          }, () => {
            this.getTrimDetail(id[id.length-1])
          })
        }else {
            this.getTrimListByModel(id[id.length-1].replace('bulk',''))
        }
    }

    getTrimListByModel = (id) => {
      const req = Service.getTrimListByModel(this.state.authToken , id)
      req.then(res => {
        const data = res.data.data[0]
        this.setState({
          brandId : {label : data.brand_name , value : data.brand_id},
          modelGroupId : {label : data.car_model_group_name , value : data.car_model_group_id},
          modelId : {label: data.car_model_name , value : data.car_model_id},
          totalInfo : [{label : data.brand_name , value : data.brand_id}],
          modelGroup : [{label : data.car_model_group_name , value : data.car_model_group_id}],
          model : [{label: data.car_model_name , value : data.car_model_id}],
        },async () => {
          await this.getPriceOptionList();
          await this.getLineUpList();
          const datas = res.data.data
          for(let x = 0; x<datas.length; x++){
            const { data: { data: trims } } = await Service.getSimpleTrims(this.state.authToken, datas[x].lineup_id);
            const req = Service.getTrimDetail(this.state.authToken , datas[x].id);
            await req.then(res => {
              const data = res.data.data
              const pushdata = {
                id : data.id,
                type : 'edit',
                lineUpId : {label: data.lineup_name , value : data.lineup_id},
                priceOptionId : {label: '가격옵션' , value : 0},
                optionId : {label : data.option !== null ? data.option : '변속기', value : data.option},
                name : data.name,
                subname : data.subname,
                price : data.price,
                is_active : data.is_active,
                price_options: (data.price_options && data.price_options.length !== 0) ? [ ...data.price_options.map((item) => ({
                  name: item.name,
                  price: item.price,
                  description: item.description
                }))] : [{
                  name: null,
                  price: null,
                  description: null
                }],
                save_price_options: data.price_options,
                popActiveResource : false,
                popActiveOption : false,
                trimSpec : data.trim_spec ? data.trim_spec :{
                  engine_type : "",
                  fuel: "",
                  displacement: "",
                  engine_max_out: "",
                  engine_max_torque: "",
                  idle_stop_go: "",
                  eco_friendly: "",
                  battery_type: "",
                  battery_voltage: "",
                  battery_capacity: "",
                  charging_capacity: "",
                  motor_max_out: "",
                  motor_max_torque: "",
                  charging_type: "",
                  charging_hour: "",
                  quick_charging_type: "",
                  quick_charging_min: "",
                  wheel_drive_type: "",
                  gearbox: "",
                  suspension_front: "",
                  suspension_rear: "",
                  break_front: "",
                  break_rear: "",
                  tire_front: "",
                  tire_rear: "",
                  wheel_front: "",
                  weel_rear: "",
                  efficiency: "",
                  efficiency_city: "",
                  efficiency_high: "",
                  co2_emission: "",
                  energy_consumption: "",
                  efficiency_e: "",
                  efficiency_e_city: "",
                  efficiency_e_high: "",
                  mileage: "",
                  full_length: "",
                  full_width: "",
                  full_height: "",
                  wheel_base: "",
                  wheel_track_front: "",
                  wheel_track_rear: "",
                  overhang_front: "",
                  overhang_rear: "",
                  quota: "",
                  empty_weight: "",
                  tank_volume: "",
                  cargo_width: "",
                  cargo_area: "",
                  cargo_height: "",
                  zero_to_max: "",
                  max_velocity: "",
                },
                trimItem : data.trim_item ? data.trim_item : {
                  ext_fog_lamp_front:"",
                  ext_fog_lamp:"",
                  ext_rear_lamp:"",
                  ext_roof:"",
                  ext_door_light:"",
                  ext_out_mirror:"",
                  ext_wiper:"",
                  ext_uv_glass:"",
                  ext_no_water_glass:"",
                  ext_no_sound_glass:"",
                  ext_muffler_tip:"",
                  int_dashboard:"",
                  int_power_window:"",
                  int_safty_power_window:"",
                  int_steering_wheel:"",
                  int_gear_knob:"",
                  int_room_mirror:"",
                  int_door_light:"",
                  int_rear_side_curtain:"",
                  int_rear_rear_curtaion:"",
                  sft_airbag:"",
                  sft_drive:"",
                  sft_belt:"",
                  sft_pedestrian:"",
                  sft_reduce_rear_impact:"",
                  fac_change_mode:"",
                  fac_fix_speed:"",
                  fac_break:"",
                  fac_engine_start:"",
                  fac_trunk:"",
                  fac_power_outlet:"",
                  fac_parking:"",
                  sht_sequence:"",
                  sht_type:"",
                  sht_driver:"",
                  sht_passenger:"",
                  sht_row2:"",
                  sht_row3:"",
                  acn_aircon:"",
                  acn_temp_range:"",
                  acn_rear:"",
                  acn_air_clean:"",
                  mul_monitor_size:"",
                  mul_functions:"",
                  mul_extra_functions:"",
                  mul_interface:"",
                  mul_speaker:"",
                  mul_sound_system:""
                },
                trims: trims.map((item) => ({
                  label: item.name,
                  value: item.id
                })),
                trimSelector: {
                  price_options: null,
                  spec: null,
                  item: null,
                }
              }
              this.setState({
                forms : this.state.forms.concat(pushdata)
              });
            })
          }
        })
      })
    }

    getSimpleTrims = (lineup_id) => {
      const { authToken } = this.state;

      return Service.getSimpleTrims(authToken, lineup_id);
    };

    getTrimDetail = (id) => {
      const req = Service.getTrimDetail(this.state.authToken , id)
      req.then(res => {
        const data = res.data.data
        const pushdata = {
          id : data.id,
          type : 'edit',
          lineUpId : {label: data.lineup_name , value : data.lineup_id},
          priceOptionId : {label: '가격옵션' , value : 0},
          optionId : {label : data.option !== null ? data.option : '변속기', value : data.option},
          name : data.name,
          subname : data.subname,
          price : data.price,
          is_active : data.is_active,
          price_options: (data.price_options && data.price_options.length !== 0) ? [ ...data.price_options.map((item) => ({
            name: item.name,
            price: item.price,
            description: item.description
          }))] : [{
            name: null,
            price: null,
            description: null
          }],
          save_price_options: data.price_options,
          popActiveResource : false,
          popActiveOption : false,
          popActivePriceOption: false,
          trimSpec : data.trim_spec ? data.trim_spec :{
            engine_type : "",
            fuel: "",
            displacement: "",
            engine_max_out: "",
            engine_max_torque: "",
            idle_stop_go: "",
            eco_friendly: "",
            battery_type: "",
            battery_voltage: "",
            battery_capacity: "",
            charging_capacity: "",
            motor_max_out: "",
            motor_max_torque: "",
            charging_type: "",
            charging_hour: "",
            quick_charging_type: "",
            quick_charging_min: "",
            wheel_drive_type: "",
            gearbox: "",
            suspension_front: "",
            suspension_rear: "",
            break_front: "",
            break_rear: "",
            tire_front: "",
            tire_rear: "",
            wheel_front: "",
            weel_rear: "",
            efficiency: "",
            efficiency_city: "",
            efficiency_high: "",
            co2_emission: "",
            energy_consumption: "",
            efficiency_e: "",
            efficiency_e_city: "",
            efficiency_e_high: "",
            mileage: "",
            full_length: "",
            full_width: "",
            full_height: "",
            wheel_base: "",
            wheel_track_front: "",
            wheel_track_rear: "",
            overhang_front: "",
            overhang_rear: "",
            quota: "",
            empty_weight: "",
            tank_volume: "",
            cargo_width: "",
            cargo_area: "",
            cargo_height: "",
            zero_to_max: "",
            max_velocity: "",
          },
          trimItem : data.trim_item ? data.trim_item : {
            ext_fog_lamp_front:"",
            ext_fog_lamp:"",
            ext_rear_lamp:"",
            ext_roof:"",
            ext_door_light:"",
            ext_out_mirror:"",
            ext_wiper:"",
            ext_uv_glass:"",
            ext_no_water_glass:"",
            ext_no_sound_glass:"",
            ext_muffler_tip:"",
            int_dashboard:"",
            int_power_window:"",
            int_safty_power_window:"",
            int_steering_wheel:"",
            int_gear_knob:"",
            int_room_mirror:"",
            int_door_light:"",
            int_rear_side_curtain:"",
            int_rear_rear_curtaion:"",
            sft_airbag:"",
            sft_drive:"",
            sft_belt:"",
            sft_pedestrian:"",
            sft_reduce_rear_impact:"",
            fac_change_mode:"",
            fac_fix_speed:"",
            fac_break:"",
            fac_engine_start:"",
            fac_trunk:"",
            fac_power_outlet:"",
            fac_parking:"",
            sht_sequence:"",
            sht_type:"",
            sht_driver:"",
            sht_passenger:"",
            sht_row2:"",
            sht_row3:"",
            acn_aircon:"",
            acn_temp_range:"",
            acn_rear:"",
            acn_air_clean:"",
            mul_monitor_size:"",
            mul_functions:"",
            mul_extra_functions:"",
            mul_interface:"",
            mul_speaker:"",
            mul_sound_system:""
          },
          trims: [],
          trimSelector: {
            price_options: null,
            spec: null,
            item: null,
          }
        }
        this.setState({
          forms : this.state.forms.concat(pushdata),
          brandId : {label : data.brand_name , value : data.brand_id},
          modelGroupId : {label : data.car_model_group_name , value : data.car_model_group_id},
          modelId : {label: data.car_model_name , value : data.car_model_id},
          totalInfo : [{label : data.brand_name , value : data.brand_id}],
          modelGroup : [{label : data.car_model_group_name , value : data.car_model_group_id}],
          model : [{label: data.car_model_name , value : data.car_model_id}],
        }, () => {
          const { authToken, forms: nextForms } = this.state;
          this.getPriceOptionList();
          this.getLineUpList();

          Service.getSimpleTrims(authToken, data.lineup_id).then((response) => {
            this.setState({
                forms: nextForms.map((item, itemIndex) => {
                  if (itemIndex === 0) {
                    return {
                      ...item,
                      trims: response.data.data.map((item) => ({
                        label: item.name,
                        value: item.id
                      }))
                    };
                  } else {
                    return item;
                  }
                })
            })
          });
        })
      })
    }

    getLineUpList = () => {
      const req = LineUpService.getLineUpList(
        this.state.authToken,10000,1,"","","","","","","",this.state.brandId.value,this.state.modelGroupId.value,this.state.modelId.value,0,'all'
      )
      req.then(response => {
        const data = response.data.data
        let options = []
        options.push({label: '전체' , value : 0})
        for(var x = 0; x<data.length; x++){
          options.push({label: data[x].name , value : data[x].id})
        }
        this.setState({
          lineUpList : options
        })
      })
    }
    
    getPriceOptionList = () => {
      const req = PriceOptionService.getPriceOptionListByTrim(this.state.authToken , this.state.brandId.value)
      req.then(res => {
        const data = res.data.data
        let options = []
        options.push({label: '가격옵션' , value : 0})
        for(var x = 0; x<data.length; x++){
          options.push({label: data[x].name , value : data[x].id})
        }
        this.setState({
          priceOptionList : options,
        })
      })
    }
    selectLineUp = (selectOption , index) => {
      const data = this.state.forms
      data[index].lineUpId = selectOption
      this.setState({
        forms : data
      }, () => {
        const { authToken, forms } = this.state;
        Service.getSimpleTrims(authToken, selectOption.value).then((response) => {
          this.setState({
            forms: forms.map((item, itemIndex) => {
              if (index === itemIndex) {
                return {
                  ...item,
                  trims: response.data.data.map((item) => ({
                    label: item.name,
                    value: item.id
                  }))
                };
              } else {
                return item;
              }
            })
          });
        });
      });
    }

    selectOption = (selectOption , index) => {
      const data = this.state.forms
      data[index].optionId = selectOption
      this.setState({
        forms : data
      })
    }

    infoSearchValue = (e , x) => {
      const data = this.state.forms
      
      if(e.target.type == "radio"){
        if(e.target.id.indexOf('ok') !== -1){
          data[x].is_active = true
        }else{
          data[x].is_active = false
        }
      }
      else{
          data[x][e.target.name] = e.target.value
      }
      this.setState({
        forms : data
      });
    };

    infoSearchValueByTrimSpec = (e , index) => {
      const data = this.state.forms
      if(e.target.name == "quota" && isNaN(e.target.value)){
        return false
      }else{
        data[index].trimSpec[e.target.name] = e.target.value
      }
      this.setState({
        forms : data
      })
    }

    infoSearchValueByTrimItem = (e , index)=> {
      const data = this.state.forms
      data[index].trimItem[e.target.name] = e.target.value
      this.setState({
        forms : data
      })
    }

    saveData = (id ,type, index) => {
      const state = this.state
      const data = state.forms
      if(data[index].name == ""){
        alert('트림명을 입력해주세요.')
        return false
      }else if(data[index].price == "" || data[index].price == 0){
        alert('가격을 입력해주세요.')
        return false
      }else if(data[index].lineUpId.value == 0){
        alert('라인업을 선택해주세요')
        return false
      }else if(isNaN(data[index].trimSpec.quota)){
        alert('제원 > 승차인원은 숫자만 입력 가능합니다.')
        return false
      }
      if(type == 'edit') {
        if (window.confirm('트림 수정을 완료하시겠습니까?')){
          const req = Service.editTrim(
            state.authToken,
            id,
            data[index].name,
            data[index].subname,
            data[index].optionId.value,
            data[index].price,
            data[index].lineUpId.value,
            data[index].is_active,
            data[index].save_price_options,
            data[index].trimSpec,
            data[index].trimItem
            )
            req.then(res => {
              if(res.status == 200){
                alert('저장이 완료되었습니다.');
              }else {
                alert('트림을 수정하는 중에 오류가 발생하였습니다.')
              }
            })
        }
      }else{
        if (window.confirm('트림 추가를 완료하시겠습니까?')){
          const req = Service.postTrim(
            state.authToken,
            data[index].name,
            data[index].subname,
            data[index].optionId.value,
            data[index].price,
            data[index].lineUpId.value,
            data[index].is_active,
            data[index].save_price_options,
            data[index].trimSpec,
            data[index].trimItem
            )
            req.then(res => {
              if(res.status == 200){
                const data = res.data.data
                const forms = this.state.forms
                forms[index].id = data.id
                forms[index].type = 'edit'
                alert('저장이 완료되었습니다.');
              }else {
                alert('트림을 수정하는 중에 오류가 발생하였습니다.')
              }
            })
        }
      }
      
    }

    addTrim = (index) => {
      const data = this.state.forms
      let pushdata = {
        id : id++,
        type : 'create',
        lineUpId : data[index].lineUpId,
        priceOptionId : data[index].priceOptionId,
        optionId : data[index].optionId,
        name : data[index].name,
        subname : data[index].subname,
        price : data[index].price,
        is_active : data[index].is_active,
        price_options: data[index].price_options,
        save_price_options: data[0].save_price_options,
        popActiveResource : false,
        popActiveOption : false,
        popActivePriceOption: false,
        trimSpec : data[index].trimSpec,
        trimItem : data[index].trimItem,
        trimSelector: {
          price_options: null,
          spec: null,
          item: null,
        }
      }
      data.push(pushdata)
      this.setState({
        forms : data
      });
    }

  handleClipboard = (e, index) => {
    let clipboardData, pastedData;
    let successCount = 0;
    // Stop data actually being pasted into div
    e.stopPropagation();
    e.preventDefault();

    // Get pasted data via clipboard API
    clipboardData = window.clipboardData || e.clipboardData;
    pastedData = clipboardData && clipboardData.getData('Text');

    // Do whatever with pasteddata
    let pastedArr = pastedData ? pastedData.split("\n") : '';
    pastedArr.forEach((item) =>  successCount += this.setClipboardData(item.split('\t'), index));
    alert(`${successCount}개가 복사되었습니다.`);
  };

  // 복사한 엑셀 데이터 State 값에 설정
  setClipboardData = (data, index) => {
    const { forms } = this.state;
    const name = data[0];
    let value = data[1];
    let stateName;

    if(name === '트림명') {
      stateName = 'name';
    } else if(name === '트림서브명') {
      stateName = 'subname';
    } else if(name === '가격') {
      stateName = 'price';
      value = value ? parseInt(value.replace(/,/g, '')) : '';
    } else if(name.indexOf('[사양]배기량') !== -1) {
      stateName = 'displacement';
    } else if(name.indexOf('[사양]엔진형식') !== -1) {
      stateName = 'engine_type';
    } else if(name === '[사양]연료') {
      stateName = 'fuel';
    } else if(name.indexOf('[사양]최고출력') !== -1) {
      stateName = 'engine_max_out';
    } else if(name === '[사양]최대토크 (kg.m/rpm)') {
      stateName = 'engine_max_torque';
    } else if(name.indexOf('[사양]공회전 제한장치') !== -1) {
      stateName = 'idle_stop_go';
    } else if(name.indexOf('[사양]친환경') !== -1) {
      stateName = 'eco_friendly';
    } else if(name.indexOf('[사양]배터리 종류') !== -1) {
      stateName = 'battery_type';
    } else if(name.indexOf('[사양]배터리 전압') !== -1) {
      stateName = 'battery_voltage';
    } else if(name.indexOf('[사양]배터리 용량') !== -1) {
      stateName = 'battery_capacity';
    } else if(name.indexOf('[사양]충전 용량') !== -1) {
      stateName = 'charging_capacity';
    } else if(name.indexOf('[사양]모터 최고출력') !== -1) {
      stateName = 'motor_max_out';
    } else if(name === '[사양]모터 최대토크 (kg.m)') {
      stateName = 'motor_max_torque';
    } else if(name === '[사양]충전방식 (완속)') {
      stateName = 'charging_type';
    } else if(name === '[사양]충전시간 (완속) (시간)') {
      stateName = 'charging_hour';
    } else if(name === '[사양]충전방식 (급속)') {
      stateName = 'quick_charging_type';
    } else if(name === '[사양]충전시간 (급속) (분)') {
      stateName = 'quick_charging_min';
    } else if(name.indexOf('[사양]굴림방식') !== -1) {
      stateName = 'wheel_drive_type';
    } else if(name.indexOf('[사양]변속기') !== -1) {
      stateName = 'gearbox';
    } else if(name.indexOf('[사양]서스펜션 (전)') !== -1) {
      stateName = 'suspension_front';
    } else if(name.indexOf('[사양]서스펜션 (후)') !== -1) {
      stateName = 'suspension_rear';
    } else if(name.indexOf('[사양]브레이크 (전)') !== -1) {
      stateName = 'break_front';
    } else if(name.indexOf('[사양]브레이크 (후)') !== -1) {
      stateName = 'break_rear';
    } else if(name.indexOf('[사양]타이어 (전)') !== -1) {
      stateName = 'tire_front';
    } else if(name.indexOf('[사양]타이어 (후)') !== -1) {
      stateName = 'tire_rear';
    } else if(name.indexOf('[사양]휠 (전) (인치)') !== -1) {
      stateName = 'wheel_front';
    } else if(name.indexOf('[사양]휠 (후) (인치)') !== -1) {
      stateName = 'weel_rear';
    } else if(name === '[사양]복합연비 (km/l)') {
      stateName = 'efficiency';
    } else if(name === '[사양]도심연비 (km/l)') {
      stateName = 'efficiency_city';
    } else if(name === '[사양]고속연비 (km/l)') {
      stateName = 'efficiency_high';
    } else if(name.indexOf('[사양]CO2 배출') !== -1) {
      stateName = 'co2_emission';
    } else if(name.indexOf('[사양]에너지소비효율 (등급)') !== -1) {
      stateName = 'energy_consumption';
    } else if(name === '[사양]복합연비 (전기) (km/kWh)') {
      stateName = 'efficiency_e';
    } else if(name === '[사양]도심연비 (전기) (km/kWh)') {
      stateName = 'efficiency_e_city';
    } else if(name === '[사양]고속연비 (전기) (km/kWh)') {
      stateName = 'efficiency_e_high';
    } else if(name.indexOf('[사양]항속거리') !== -1) {
      stateName = 'mileage';
    } else if(name.indexOf('[사양]전장') !== -1) {
      stateName = 'full_length';
    } else if(name.indexOf('[사양]전폭') !== -1) {
      stateName = 'full_width';
    } else if(name.indexOf('[사양]전고') !== -1) {
      stateName = 'full_height';
    } else if(name.indexOf('[사양]축거') !== -1) {
      stateName = 'wheel_base';
    } else if(name.indexOf('[사양]윤거 (전)') !== -1) {
      stateName = 'wheel_track_front';
    } else if(name.indexOf('[사양]윤거 (후)') !== -1) {
      stateName = 'wheel_track_rear';
    } else if(name.indexOf('[사양]오버행 (전)') !== -1) {
      stateName = 'overhang_front';
    } else if(name.indexOf('[사양]오버행 (후)') !== -1) {
      stateName = 'overhang_rear';
    } else if(name.indexOf('[사양]승차정원') !== -1) {
      stateName = 'quota';
      value = value ? parseInt(value.replace(/,/g, '')) : '';
    } else if(name.indexOf('[사양]공차중량') !== -1) {
      stateName = 'empty_weight';
    } else if(name.indexOf('[사양]연료탱크') !== -1) {
      stateName = 'tank_volume';
    } else if(name.indexOf('[사양]적재함 길이') !== -1) {
      stateName = 'cargo_width';
    } else if(name.indexOf('[사양]적재함 넓이') !== -1) {
      stateName = 'cargo_area';
    } else if(name.indexOf('[사양]적재함 높이') !== -1) {
      stateName = 'cargo_height';
    } else if(name.indexOf('[사양]적재량') !== -1) {
      stateName = 'cargo_weight';
    } else if(name.indexOf('[사양]제로백') !== -1) {
      stateName = 'zero_to_max';
    } else if(name.indexOf('[사양]최고속도') !== -1) {
      stateName = 'max_velocity';
    } else if(name.indexOf('[제원](외관)헤드램프') !== -1) {
      stateName = 'ext_head_lamp';
    } else if(name.indexOf('[제원](외관)전방 안개등') !== -1) {
      stateName = 'ext_fog_lamp_front';
    } else if(name.indexOf('[제원](외관)주간 안개등') !== -1) {
      stateName = 'ext_fog_lamp';
    } else if(name.indexOf('[제원](외관)리어 램') !== -1) {
      stateName = 'ext_rear_lamp';
    } else if(name.indexOf('[제원](외관)루프') !== -1) {
      stateName = 'ext_roof';
    } else if(name.indexOf('[제원](외관)도어포켓 라이트') !== -1) {
      stateName = 'ext_door_light';
    } else if(name.indexOf('[제원](외관)아웃 사이드미러') !== -1) {
      stateName = 'ext_out_mirror';
    } else if(name.indexOf('[제원](외관)와이퍼') !== -1) {
      stateName = 'ext_wiper';
    } else if(name.indexOf('[제원](외관)자외선 차단유리') !== -1) {
      stateName = 'ext_uv_glass';
    } else if(name.indexOf('[제원](외관)발수 유리') !== -1) {
      stateName = 'ext_no_water_glass';
    } else if(name.indexOf('[제원](외관)차음 유리') !== -1) {
      stateName = 'ext_no_sound_glass';
    } else if(name.indexOf('[제원](외관)머플러팁 형태') !== -1) {
      stateName = 'ext_muffler_tip';
    } else if(name.indexOf('[제원](내관)계기판') !== -1) {
      stateName = 'int_dashboard';
    } else if(name.indexOf('[제원](내관)파워윈도우') !== -1) {
      stateName = 'int_power_window';
    } else if(name.indexOf('[제원](내관)세이프티 파워윈도우') !== -1) {
      stateName = 'int_safty_power_window';
    } else if(name.indexOf('[제원](내관)스티어링 휠') !== -1) {
      stateName = 'int_steering_wheel';
    } else if(name.indexOf('[제원](내관)기어 노브') !== -1) {
      stateName = 'int_gear_knob';
    } else if(name.indexOf('[제원](내관)룸미러') !== -1) {
      stateName = 'int_room_mirror';
    } else if(name.indexOf('[제원](내관)도어포켓 라이트') !== -1) {
      stateName = 'int_door_light';
    } else if(name.indexOf('[제원](내관)뒷좌석 측면커튼') !== -1) {
      stateName = 'int_rear_side_curtain';
    } else if(name.indexOf('[제원](내관)뒷자석 후면커튼') !== -1) {
      stateName = 'int_rear_rear_curtaion';
    } else if(name.indexOf('[제원]에어백') !== -1) {
      stateName = 'sft_airbag';
    } else if(name.indexOf('[제원]주행안전') !== -1) {
      stateName = 'sft_drive';
    } else if(name.indexOf('[제원]안전벨트') !== -1) {
      stateName = 'sft_belt';
    } else if(name.indexOf('[제원]보행자 안전') !== -1) {
      stateName = 'sft_pedestrian';
    } else if(name.indexOf('[제원]후방충격 저감 시스템') !== -1) {
      stateName = 'sft_reduce_rear_impact';
    } else if(name.indexOf('[제원]주행모드변경') !== -1) {
      stateName = 'fac_change_mode';
    } else if(name.indexOf('[제원]정속주행') !== -1) {
      stateName = 'fac_fix_speed';
    } else if(name.indexOf('[제원]주차 브레이크') !== -1) {
      stateName = 'fac_break';
    } else if(name.indexOf('[제원]엔진시동') !== -1) {
      stateName = 'fac_engine_start';
    } else if(name.indexOf('[제원]트렁크') !== -1) {
      stateName = 'fac_trunk';
    } else if(name.indexOf('[제원]파워 아웃렛') !== -1) {
      stateName = 'fac_power_outlet';
    } else if(name.indexOf('[제원]주차보조') !== -1) {
      stateName = 'fac_parking';
    } else if(name.indexOf('[제원]시트배열') !== -1) {
      stateName = 'sht_sequence';
    } else if(name.indexOf('[제원]시트재질') !== -1) {
      stateName = 'sht_type';
    } else if(name.indexOf('[제원]운전석') !== -1) {
      stateName = 'sht_driver';
    } else if(name.indexOf('[제원]동승석') !== -1) {
      stateName = 'sht_passenger';
    } else if(name.indexOf('[제원]2열') !== -1) {
      stateName = 'sht_row2'
    } else if(name.indexOf('[제원]3열') !== -1) {
      stateName = 'sht_row3';
    } else if(name.indexOf('[제원]에어컨') !== -1) {
      stateName = 'acn_aircon';
    } else if(name.indexOf('[제원]온도조절 범위') !== -1) {
      stateName = 'acn_temp_range';
    } else if(name.indexOf('[제원]뒷좌석 송풍구') !== -1) {
      stateName ='acn_rear';
    } else if(name.indexOf('[제원]공기청정') !== -1) {
      stateName = 'acn_air_clean';
    } else if(name.indexOf('[제원]화면크기') !== -1) {
      stateName = 'mul_monitor_size';
    } else if(name.indexOf('[제원]주요기능') !== -1) {
      stateName = 'mul_functions';
    } else if(name.indexOf('[제원]부가기능') !== -1) {
      stateName = 'mul_extra_functions';
    } else if(name.indexOf('[제원]인터페이스') !== -1) {
      stateName = 'mul_interface';
    } else if(name.indexOf('[제원]스피커') !== -1) {
      stateName = 'mul_speaker';
    } else if(name.indexOf('[제원]사운드시스템') !== -1) {
      stateName = 'mul_sound_system';
    }

    value = !value ? null : value;

    if(name.indexOf('[사양]') !== -1) {
      forms[index] = {
        ...forms[index],
        trimSpec: {
          ...forms[index].trimSpec,
          [stateName]: value
        }
      };
    } else if(name.indexOf('[제원]') !== -1) {
      forms[index] = {
        ...forms[index],
        trimItem: {
          ...forms[index].trimItem,
          [stateName]: value
        }
      };
    } else {
      forms[index] = {
        ...forms[index],
        [stateName]: value
      };
    }

    this.setState({
      forms: forms
    });

    return 1;
  };

  addPriceOptionForm = (index) => {
    const { forms } = this.state;

    const tempForms = [ ...forms ];
    const tempPriceOptions = [ ...tempForms[index].price_options ];

    tempPriceOptions.push({
      name: null,
      price: null,
      description: null
    });

    tempForms[index] = {
      ...tempForms[index],
      price_options: tempPriceOptions
    };

    this.setState({
      forms: tempForms
    });
  };

  removePriceOptionForm = (index, priceOptionIndex) => {
    const { forms } = this.state;

    const tempForms = [ ...forms ];
    const tempPriceOption = tempForms[index].price_options[priceOptionIndex];

    tempForms[index] = {
      ...tempForms[index],
      price_options: forms[index].price_options.filter((item) => item !== tempPriceOption)
    };

    if (!tempForms[index].price_options || tempForms[index].price_options.length === 0) {
      tempForms[index] = {
        ...tempForms[index],
        price_options: [{
          name: null,
          price: null,
          description: null
        }]
      };
    }

    this.setState({
      forms: tempForms
    });
  };

  handlePriceOption = (e, index, priceOptionIndex) => {
    const { forms } = this.state;
    const name = e.target.name;
    const value = e.target.value;

    const tempForms = [ ...forms ];

    tempForms[index].price_options[priceOptionIndex] = {
      ...tempForms[index].price_options[priceOptionIndex],
      [name]: value
    };

    this.setState({
      forms: tempForms
    });
  };

  handleTrimSelect = (option, index, type) => {
    const { authToken, forms } = this.state;
    this.setState({
      forms: forms.map((item, itemIndex) => {
        if (index === itemIndex) {
          return {
            ...item,
            trimSelector: {
              ...item.trimSelector,
              [type]: option
            }
          }
        } else {
          return item;
        }
      })
    }, () => {
      Service.getAdditionalInfoOfTrim(authToken, option.value, type).then((response) => {
        const { forms: nextForms } = this.state;
        const tempForm = [ ...nextForms];

        switch (type) {
          case 'price_options':
            if (response.data.data.length !== 0) {
              tempForm[index].price_options = response.data.data.map((item) => ({
                name: item.name,
                price: item.price,
                description: item.description
              }));
            } else {
              alert('선택하신 트림은 가격옵션이 등록되지 않았습니다.');
            }
            break;
          case 'spec':
            if (response.data.data) {
              const spec = response.data.data;

              Object.keys(spec).map((key) => {
                if (key !== 'created_at' && key !== 'updated_at' && key !== 'trim_id' && key !== 'd_trim_id' && key !== 'id') {
                  tempForm[index].trimSpec = {
                    ...tempForm[index].trimSpec,
                    [key]: spec[key] || ''
                  }
                }
              });
            } else {
              alert('선택하신 트림은 제원이 등록되지 않았습니다.');
            }
            break;
          case 'item':
            if (response.data.data) {
              const item = response.data.data;

              Object.keys(item).map((key) => {
                if (key !== 'created_at' && key !== 'updated_at' && key !== 'trim_id' && key !== 'd_trim_id' && key !== 'id') {
                  tempForm[index].trimItem = {
                    ...tempForm[index].trimItem,
                    [key]: item[key] || ''
                  }
                }
              });
            } else {
              alert('선택하신 트림은 사양/옵션이 등록되지 않았습니다.');
            }
            break;
        }

        this.setState({
          forms: tempForm
        });
      }).catch((error) => {
        console.log(error);
      });
    });
  };

    render() {
        const cx = classNames.bind(styles);
        return (
            <Fragment>
                {/* 트림 일괄수정 영역 시작 */}
                <div className="title disi wd100"><p className="mb10"><span>홈</span> 〉 <span>차량DB</span> 〉 <span>트림</span> 〉 <span>수정</span></p>트림 수정
                    <div className="floatR disi">
                      <button type="button" className="btn navyBg ml10" onClick={() => this.props.history.goBack()}>목록</button>
                    </div>
                </div>
                <div className="form_tablewrap">
                        <table className="mt20">
                            <colgroup>
                                <col width="10%"></col>
                                <col width="*"></col>
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>브랜드/모델그룹/모델명</th>
                                    <td id="brandSelect">
                                    <Select className="reactSelect" 
                                    options={this.state.totalInfo} 
                                    value={this.state.brandId} 
                                    name="brandId" 
                                    isDisabled={true}
                                    />

                                    <Select className="reactSelect" 
                                    options={this.state.modelGroup} 
                                    value={this.state.modelGroupId} 
                                    name="brandId" 
                                    isDisabled={true}
                                    />  

                                    <Select className="reactSelect" 
                                    options={this.state.model} 
                                    value={this.state.modelId} 
                                    name="brandId" 
                                    isDisabled={true}
                                    />   
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        {
                          this.state.forms.map((item , index) => {
                            return(
                              <Fragment key={index}>
                            <table className="mt30">
                            <colgroup>
                                <col width="10%"></col>
                                <col width="30%"></col>
                                <col width="10%"></col>
                                <col width="50%"></col>
                            </colgroup>
                            <tbody>
                            <tr>
                                  <th>라인업명 <span className="redT">*</span></th>
                                  <td className="lineup_name_select" colSpan="3">
                                  <Select className="reactSelect" 
                                      options={this.state.lineUpList} 
                                      value={this.state.forms[index].lineUpId} 
                                      name="brandId" 
                                      onChange={selectOption => this.selectLineUp(selectOption , index)} 
                                      placeholder="전체"
                                      isSearchable={true}/> 
                                  </td>
                              </tr>
                              <tr>
                                  <th>트림명 <span className="redT">*</span></th>
                                  <td><input type="text" name="name" onChange={e => this.infoSearchValue(e, index)} value={item.name}></input></td>
                                  <th>서브트림명</th>
                                  <td><input type="text" name="subname" onChange={e => this.infoSearchValue(e, index)} value={item.subname}></input></td>
                              </tr>
                              <tr>
                                  <th>가격 <span className="redT">*</span></th>
                                  <td colSpan="3"><input type="number" name="price" onChange={e => this.infoSearchValue(e, index)} value={item.price}></input></td>
                              </tr>
                              <tr>
                                <th>변속기</th>
                                <td>
                                    <Select className="reactSelect" 
                                      options={this.state.optionList} 
                                      value={this.state.forms[index].optionId} 
                                      name="option" 
                                      onChange={selectOption => this.selectOption(selectOption , index)} 
                                      placeholder="전체"
                                      isSearchable={true}/>
                                </td>
                                <th>활성화 여부 <span className="redT">*</span></th>
                                <td>
                                  <input type="radio" name={"active"+index} id={"active"+index+"_ok"} checked={item.is_active ? true : false} onChange={e => this.infoSearchValue(e, index)}></input>
                                  <label htmlFor={"active"+index+"_ok"}> 예  </label>
                                  <input type="radio" name={"active"+index} id={"active"+index+"_no"} checked={item.is_active ? false : true} onChange={e => this.infoSearchValue(e, index)}></input>
                                  <label htmlFor={"active"+index+"_no"}> 아니오</label>
                                </td>
                              </tr>
                              <tr>
                                  <th>가격옵션</th>
                                <td colSpan="3">
                                  <button className="file_btn pl10 pr10" onClick={(e) => this.exposurePriceOption(e , index)}>가격옵션 입력</button>
                                </td>
                              </tr>
                              <tr>
                                  <th>제원</th>
                                  <td colSpan="3" className="table_popbtn">
                                      <button className="file_btn pl10 pr10" onClick={(e) => this.exposureResource(e , index)}>제원 입력</button>
                                  </td>
                              </tr>
                              <tr>
                                  <th>사양/옵션</th>
                                  <td colSpan="3" className="table_popbtn">
                                      <button className="file_btn pl10 pr10" onClick={(e) => this.exposureOption(e , index)}>사양 입력</button>
                                  </td>
                              </tr>
                            <tr>
                              <th>편의기능</th>
                              <td colSpan="3">
                                <input type="text" className="btn clipboard grayBg b_btn floatL" placeholder="이 곳을 클릭 한 뒤 붙여넣으세요." onPaste={(e) => this.handleClipboard(e, index)} disabled></input>
                              </td>
                            </tr>
                            </tbody>
                        </table>
                        <div className="rightT mt20">
                          <button type="button" className="btnLine navyBg ml10" onClick={() => this.saveData( item.id ,item.type , index)}>저장</button>
                        </div>
                        </Fragment>
                            )
                          })
                        }
                        <div className="rightT mt20">
                            <button className="btn navyBg" onClick={() => this.addTrim(0)}>트림 추가</button>
                            <button type="button" className="btn navyBg ml10" onClick={() => this.props.history.goBack()}>목록</button>
                        </div>
                </div>
                {/* 트림 일괄수정 영역 끝 */}
                {/*재원 시작*/}
              { this.state.forms.map((item , index) => {
              return(
              <Fragment key={index}>
                <div className={item.popActiveResource ? "popBg onPop" : "popBg"} onClick={() => this.closePop(index)} />
                <div className={item.popActiveResource
                   ? "resources on" : "resources"}>
                  <p>재원</p>
                  <span onClick={() => this.closePop(index)}>x</span>
                  <span>
                    <Select
                        className="reactSelect trim"
                        value={item.trimSelector.spec}
                        options={item.trims}
                        onChange={(option) => this.handleTrimSelect(option, index, 'spec')}
                        placeholder="트림을 선택해주세요."
                        isSearchable={true}
                    />
                  </span>
                      <div className="resources_table_box">
                      <table className="resources_table">
                          <colgroup>
                              <col width="50%"></col>
                              <col width="50%"></col>
                          </colgroup>
                          <tbody>
                              <tr>
                                  <th>엔진형식</th>
                                  <td><input type="text" name="engine_type" value={item.trimSpec.engine_type || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                              </tr>
                              <tr>
                                  <th>연료</th>
                                  <td><input type="text" name="fuel" value={item.trimSpec.fuel || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                              </tr>
                              <tr>
                                  <th>배기량 (cc)</th>
                                  <td><input type="text" name="displacement" value={item.trimSpec.displacement || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                              </tr>
                              <tr>
                                  <th>최고출력 (ps/rpm)</th>
                                  <td><input type="text" name="engine_max_out" value={item.trimSpec.engine_max_out || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                              </tr>
                              <tr>
                                  <th>최대토크 (kg.m/rpm)</th>
                                  <td><input type="text" name="engine_max_torque" value={item.trimSpec.engine_max_torque || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                              </tr>
                              <tr>
                                  <th>공회전제한장치</th>
                                  <td><input type="text" name="idle_stop_go" value={item.trimSpec.idle_stop_go || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                              </tr>
                              <tr>
                                  <th>친환경</th>
                                  <td><input type="text" name="eco_friendly" value={item.trimSpec.eco_friendly || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                              </tr>
                              <tr>
                                  <th>배터리종류</th>
                                  <td><input type="text" name="battery_type" value={item.trimSpec.battery_type || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                              </tr>
                              <tr>
                                  <th>배터리전압 (V)</th>
                                  <td><input type="text" name="battery_voltage" value={item.trimSpec.battery_voltage || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                              </tr>
                              <tr>
                                  <th>배터리용량 (Ah)</th>
                                  <td><input type="text" name="battery_capacity" value={item.trimSpec.battery_capacity || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                              </tr>
                              <tr>
                                  <th>충전용량 (kWh)</th>
                                  <td><input type="text" name="charging_capacity" value={item.trimSpec.charging_capacity || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                              </tr>
                              <tr>
                                  <th>모터최고출력 (ps)</th>
                                  <td><input type="text" name="motor_max_out" value={item.trimSpec.motor_max_out || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                              </tr>
                              <tr>
                                  <th>모터최대토크 (kg.m)</th>
                                  <td><input type="text" name="motor_max_torque" value={item.trimSpec.motor_max_torque || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                              </tr>
                              <tr>
                                  <th>충전방식 (완속)</th>
                                  <td><input type="text" name="charging_type" value={item.trimSpec.charging_type || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                              </tr>
                              <tr>
                                  <th>충전시간 (완속) (시간)</th>
                                  <td><input type="text" name="charging_hour" value={item.trimSpec.charging_hour || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                              </tr>
                              <tr>
                                  <th>충전방식 (급속)</th>
                                  <td><input type="text" name="quick_charging_type" value={item.trimSpec.quick_charging_type || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                              </tr>
                              <tr>
                                  <th>충전시간 (급속) (분)</th>
                                  <td><input type="text"  name="quick_charging_min" value={item.trimSpec.quick_charging_min || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                              </tr>
                              <tr>
                                  <th>굴림방식</th>
                                  <td><input type="text" name="wheel_drive_type" value={item.trimSpec.wheel_drive_type || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                              </tr>
                              <tr>
                                  <th>변속기</th>
                                  <td><input type="text" name="gearbox" value={item.trimSpec.gearbox || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                              </tr>       
                              <tr>
                                  <th>서스펜션 (전)</th>
                                  <td><input type="text" name="suspension_front" value={item.trimSpec.suspension_front || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                              </tr> 
                              <tr>
                                  <th>서스펜션 (후)</th>
                                  <td><input type="text" name="suspension_rear" value={item.trimSpec.suspension_rear || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                              </tr> 
                              <tr>
                                  <th>브레이크 (전)</th>
                                  <td><input type="text" name="break_front" value={item.trimSpec.break_front || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                              </tr> 
                              <tr>
                                  <th>브레이크 (후)</th>
                                  <td><input type="text" name="break_rear" value={item.trimSpec.break_rear || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                              </tr> 
                              <tr>
                                  <th>타이어 (전)</th>
                                  <td><input type="text" name="tire_front" value={item.trimSpec.tire_front || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                              </tr> 
                              <tr>
                                  <th>타이어 (후)</th>
                                  <td><input type="text" name="tire_rear" value={item.trimSpec.tire_rear || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                              </tr>                                                                                                                                                                                                                                                                                                                                                                      
                          </tbody>
                      </table>
                 
              <table className="resources_table">
                      <colgroup>
                          <col width="50%"></col>
                          <col width="50%"></col>
                      </colgroup>
                      <tbody>
                          <tr>
                              <th>휠 (전) (인치)</th>
                              <td><input type="text" name="wheel_front" value={item.trimSpec.wheel_front || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                          </tr>
                          <tr>
                              <th>휠 (후) (인치)</th>
                              <td><input type="text"  name="weel_rear" value={item.trimSpec.weel_rear || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                          </tr>
                          <tr>
                              <th>복합연비 (km/l)</th>
                              <td><input type="text" name="efficiency" value={item.trimSpec.efficiency || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                          </tr>
                          <tr>
                              <th>도심연비 (km/l)</th>
                              <td><input type="text" name="efficiency_city" value={item.trimSpec.efficiency_city || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                          </tr>
                          <tr>
                              <th>고속연비 (km/l)</th>
                              <td><input type="text" name="efficiency_high" value={item.trimSpec.efficiency_high || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                          </tr>
                          <tr>
                              <th>CO2배출 (g/km)</th>
                              <td><input type="text" name="co2_emission" value={item.trimSpec.co2_emission || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                          </tr>
                          <tr>
                              <th>에너지소비효율 (등급)</th>
                              <td><input type="text" name="energy_consumption" value={item.trimSpec.energy_consumption || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                          </tr>
                          <tr>
                              <th>복합연비 (전기) (km/kWh)</th>
                              <td><input type="text" name="efficiency_e" value={item.trimSpec.efficiency_e || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                          </tr>
                          <tr>
                              <th>도심연비 (전기) (km/kWh)</th>
                              <td><input type="text" name="efficiency_e_city" value={item.trimSpec.efficiency_e_city || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                          </tr>
                          <tr>
                              <th>고속연비 (전기) (km/kWh)</th>
                              <td><input type="text" name="efficiency_e_high" value={item.trimSpec.efficiency_e_high || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                          </tr>
                          <tr>
                              <th>항속거리 (km)</th>
                              <td><input type="text" name="mileage" value={item.trimSpec.mileage || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                          </tr>
                          <tr>
                              <th>전장 (mm)</th>
                              <td><input type="text" name="full_length" value={item.trimSpec.full_length || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                          </tr>
                          <tr>
                              <th>전폭 (mm)</th>
                              <td><input type="text" name="full_width" value={item.trimSpec.full_width || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                          </tr>
                          <tr>
                              <th>전고 (mm)</th>
                              <td><input type="text" name="full_height" value={item.trimSpec.full_height || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                          </tr>
                          <tr>
                              <th>축거 (mm)</th>
                              <td><input type="text" name="wheel_base" value={item.trimSpec.wheel_base || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                          </tr>
                          <tr>
                              <th>윤거 (전) (mm)</th>
                              <td><input type="text" name="wheel_track_front" value={item.trimSpec.wheel_track_front || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                          </tr>
                          <tr>
                              <th>윤거 (후) (mm)</th>
                              <td><input type="text" name="wheel_track_rear" value={item.trimSpec.wheel_track_rear || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                          </tr>
                          <tr>
                              <th>오버행 (전) (mm)</th>
                              <td><input type="text" name="overhang_front" value={item.trimSpec.overhang_front || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                          </tr>
                          <tr>
                              <th>오버행 (후) (mm)</th>
                              <td><input type="text" name="overhang_rear" value={item.trimSpec.overhang_rear || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                          </tr>       
                          <tr>
                              <th>승차정원</th>
                              <td><input type="text" name="quota" value={item.trimSpec.quota || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                          </tr> 
                          <tr>
                              <th>공차중량 (kg)</th>
                              <td><input type="text" name="empty_weight" value={item.trimSpec.empty_weight || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                          </tr> 
                          <tr>
                              <th>연료탱크 (l)</th>
                              <td><input type="text" name="tank_volume" value={item.trimSpec.tank_volume || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                          </tr> 
                          <tr>
                              <th>적재함길이 (mm)</th>
                              <td><input type="text" name="cargo_width" value={item.trimSpec.cargo_width || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                          </tr> 
                          <tr>
                              <th>적재함넓이 (mm)</th>
                              <td><input type="text" name="cargo_area" value={item.trimSpec.cargo_area || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                          </tr> 
                          <tr>
                              <th>적재함높이 (mm)</th>
                              <td><input type="text" name="cargo_height" value={item.trimSpec.cargo_height || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                          </tr>                                                                                                                                                                                                                                                                                                                                                                      
                          <tr>
                              <th>적재량 (kg)</th>
                              <td><input type="text" name="cargo_weight" value={item.trimSpec.cargo_weight || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                          </tr>
                          <tr>
                              <th>제로백 (초)</th>
                              <td><input type="text" name="zero_to_max" value={item.trimSpec.zero_to_max || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                          </tr>
                          <tr>
                              <th>최고속도 (km/h)</th>
                              <td><input type="text" name="max_velocity" value={item.trimSpec.max_velocity || ''} onChange={e => this.infoSearchValueByTrimSpec(e,index)}></input></td>
                          </tr>                                                        
                      </tbody>
                  </table> 
              </div>
              <div className="centerT mt10 mb10 dpb">
                  <Link to="#"><button type="button" className="btnLine navyBg disb" onClick={() => this.closePop(index)}>저장</button></Link>
              </div>                    
          </div>
                <div className={item.popActiveOption ? "popBg onPop" : "popBg"} onClick={() => this.closePop(index)}/>
                <div className={item.popActiveOption ? "resources on" : "resources"}>
                  <p>사양/옵션</p>
                  <span onClick={() => this.closePop(index)}>x</span>
                  <span>
                    <Select
                        className="reactSelect trim"
                        value={item.trimSelector.item}
                        options={item.trims}
                        onChange={(option) => this.handleTrimSelect(option, index, 'item')}
                        placeholder="트림을 선택해주세요."
                        isSearchable={true}
                    />
                  </span>
                      <div className="resources_table_box">
                      <table className="resources_table">
                          <colgroup>
                              <col width="50%"></col>
                              <col width="50%"></col>
                          </colgroup>
                          <tbody>
                              <tr>
                                  <th>헤드램프</th>
                                  <td><input type="text" name="ext_head_lamp" value={item.trimItem.ext_head_lamp || ''} onChange={e => this.infoSearchValueByTrimItem(e , index)}></input></td>
                              </tr>
                              <tr>
                                  <th>전방 안개등</th>
                                  <td><input type="text" name="ext_fog_lamp_front" value={item.trimItem.ext_fog_lamp_front || ''} onChange={e => this.infoSearchValueByTrimItem(e , index)}></input></td>
                              </tr>
                              <tr>
                                  <th>주간 안개들</th>
                                  <td><input type="text" name="ext_fog_lamp" value={item.trimItem.ext_fog_lamp || ''} onChange={e => this.infoSearchValueByTrimItem(e , index)}></input></td>
                              </tr>
                              <tr>
                                  <th>리어 램프</th>
                                  <td><input type="text" name="ext_rear_lamp" value={item.trimItem.ext_rear_lamp || ''} onChange={e => this.infoSearchValueByTrimItem(e , index)}></input></td>
                              </tr>
                              <tr>
                                  <th>루프</th>
                                  <td><input type="text" name="ext_roof" value={item.trimItem.ext_roof || ''} onChange={e => this.infoSearchValueByTrimItem(e , index)}></input></td>
                              </tr>
                              <tr>
                                  <th>도어포켓 라이트</th>
                                  <td><input type="text" name="ext_door_light" value={item.trimItem.ext_door_light || ''} onChange={e => this.infoSearchValueByTrimItem(e , index)}></input></td>
                              </tr>
                              <tr>
                                  <th>아웃 사이드미러</th>
                                  <td><input type="text" name="ext_out_mirror" value={item.trimItem.ext_out_mirror || ''} onChange={e => this.infoSearchValueByTrimItem(e , index)}></input></td>
                              </tr>
                              <tr>
                                  <th>와이퍼</th>
                                  <td><input type="text" name="ext_wiper" value={item.trimItem.ext_wiper || ''} onChange={e => this.infoSearchValueByTrimItem(e , index)}></input></td>
                              </tr>
                              <tr>
                                  <th>자외선 차단유리</th>
                                  <td><input type="text" name="ext_uv_glass" value={item.trimItem.ext_uv_glass || ''} onChange={e => this.infoSearchValueByTrimItem(e , index)}></input></td>
                              </tr>
                              <tr>
                                  <th>발수 유리</th>
                                  <td><input type="text" name="ext_no_water_glass" value={item.trimItem.ext_no_water_glass || ''} onChange={e => this.infoSearchValueByTrimItem(e , index)}></input></td>
                              </tr>
                              <tr>
                                  <th>차음 유리</th>
                                  <td><input type="text" name="ext_no_sound_glass" value={item.trimItem.ext_no_sound_glass || ''} onChange={e => this.infoSearchValueByTrimItem(e , index)}></input></td>
                              </tr>
                              <tr>
                                  <th>머플러팁 형태</th>
                                  <td><input type="text" name="ext_muffler_tip" value={item.trimItem.ext_muffler_tip || ''} onChange={e => this.infoSearchValueByTrimItem(e , index)}></input></td>
                              </tr>
                              <tr>
                                  <th>계기판</th>
                                  <td><input type="text" name="int_dashboard" value={item.trimItem.int_dashboard || ''} onChange={e => this.infoSearchValueByTrimItem(e , index)}></input></td>
                              </tr>
                              <tr>
                                  <th>파워원도우</th>
                                  <td><input type="text" name="int_power_window" value={item.trimItem.int_power_window || ''} onChange={e => this.infoSearchValueByTrimItem(e , index)}></input></td>
                              </tr>
                              <tr>
                                  <th>세이프티 파워윈도우</th>
                                  <td><input type="text" name="int_safty_power_window" value={item.trimItem.int_safty_power_window || ''} onChange={e => this.infoSearchValueByTrimItem(e , index)}></input></td>
                              </tr>
                              <tr>
                                  <th>스티어링 휠</th>
                                  <td><input type="text" name="int_steering_wheel" value={item.trimItem.int_steering_wheel || ''} onChange={e => this.infoSearchValueByTrimItem(e , index)}></input></td>
                              </tr>
                              <tr>
                                  <th>기어 노브</th>
                                  <td><input type="text" name="int_gear_knob" value={item.trimItem.int_gear_knob || ''} onChange={e => this.infoSearchValueByTrimItem(e , index)}></input></td>
                              </tr>
                              <tr>
                                  <th>룸미러</th>
                                  <td><input type="text" name="int_room_mirror" value={item.trimItem.int_room_mirror || ''} onChange={e => this.infoSearchValueByTrimItem(e , index)}></input></td>
                              </tr>
                              <tr>
                                  <th>도어포켓 라이트</th>
                                  <td><input type="text" name="int_door_light" value={item.trimItem.int_door_light || ''} onChange={e => this.infoSearchValueByTrimItem(e , index)}></input></td>
                              </tr>       
                              <tr>
                                  <th>뒷좌석 측면커튼</th>
                                  <td><input type="text" name="int_rear_side_curtain" value={item.trimItem.int_rear_side_curtain || ''} onChange={e => this.infoSearchValueByTrimItem(e , index)}></input></td>
                              </tr> 
                              <tr>
                                  <th>뒷좌석 후면커튼</th>
                                  <td><input type="text" name="int_rear_rear_curtaion" value={item.trimItem.int_rear_rear_curtaion || ''} onChange={e => this.infoSearchValueByTrimItem(e , index)}></input></td>
                              </tr> 
                              <tr>
                                  <th>에어백</th>
                                  <td><input type="text" name="sft_airbag" value={item.trimItem.sft_airbag || ''} onChange={e => this.infoSearchValueByTrimItem(e , index)}></input></td>
                              </tr> 
                              <tr>
                                  <th>주행안전</th>
                                  <td><input type="text" name="sft_drive" value={item.trimItem.sft_drive || ''} onChange={e => this.infoSearchValueByTrimItem(e , index)}></input></td>
                              </tr> 
                              <tr>
                                  <th>안전벨트</th>
                                  <td><input type="text" name="sft_belt" value={item.trimItem.sft_belt || ''} onChange={e => this.infoSearchValueByTrimItem(e , index)}></input></td>
                              </tr> 
                              <tr>
                                  <th>보행자 안전</th>
                                  <td><input type="text" name="sft_pedestrian" value={item.trimItem.sft_pedestrian || ''} onChange={e => this.infoSearchValueByTrimItem(e , index)}></input></td>
                              </tr>                                                                                                                                                                                                                                                                                                                                                                      
                          </tbody>
                      </table>
                 
              <table className="resources_table">
                      <colgroup>
                          <col width="50%"></col>
                          <col width="50%"></col>
                      </colgroup>
                      <tbody>
                          <tr>
                              <th>후반충격 저감 시스템</th>
                              <td><input type="text" name="sft_reduce_rear_impact" value={item.trimItem.sft_reduce_rear_impact || ''} onChange={e => this.infoSearchValueByTrimItem(e , index)}></input></td>
                          </tr>
                          <tr>
                              <th>주행모드변경</th>
                              <td><input type="text" name="fac_change_mode" value={item.trimItem.fac_change_mode || ''} onChange={e => this.infoSearchValueByTrimItem(e , index)}></input></td>
                          </tr>
                          <tr>
                              <th>정속주행</th>
                              <td><input type="text" name="fac_fix_speed" value={item.trimItem.fac_fix_speed || ''} onChange={e => this.infoSearchValueByTrimItem(e , index)}></input></td>
                          </tr>
                          <tr>
                              <th>주차 브레이크</th>
                              <td><input type="text" name="fac_break" value={item.trimItem.fac_break || ''} onChange={e => this.infoSearchValueByTrimItem(e , index)}></input></td>
                          </tr>
                          <tr>
                              <th>엔진시동</th>
                              <td><input type="text" name="fac_engine_start" value={item.trimItem.fac_engine_start || ''} onChange={e => this.infoSearchValueByTrimItem(e , index)}></input></td>
                          </tr>
                          <tr>
                              <th>트렁크</th>
                              <td><input type="text" name="fac_trunk" value={item.trimItem.fac_trunk || ''} onChange={e => this.infoSearchValueByTrimItem(e , index)}></input></td>
                          </tr>
                          <tr>
                              <th>파워 아웃렛</th>
                              <td><input type="text" name="fac_power_outlet" value={item.trimItem.fac_power_outlet || ''} onChange={e => this.infoSearchValueByTrimItem(e , index)}></input></td>
                          </tr>
                          <tr>
                              <th>주차보조</th>
                              <td><input type="text" name="fac_parking" value={item.trimItem.fac_parking || ''} onChange={e => this.infoSearchValueByTrimItem(e , index)}></input></td>
                          </tr>
                          <tr>
                              <th>시트배열</th>
                              <td><input type="text" name="sht_sequence" value={item.trimItem.sht_sequence || ''} onChange={e => this.infoSearchValueByTrimItem(e , index)}></input></td>
                          </tr>
                          <tr>
                              <th>시트재질</th>
                              <td><input type="text" name="sht_type" value={item.trimItem.sht_type || ''} onChange={e => this.infoSearchValueByTrimItem(e , index)}></input></td>
                          </tr>
                          <tr>
                              <th>운전석</th>
                              <td><input type="text" name="sht_driver" value={item.trimItem.sht_driver || ''} onChange={e => this.infoSearchValueByTrimItem(e , index)}></input></td>
                          </tr>
                          <tr>
                              <th>동승석</th>
                              <td><input type="text" name="sht_passenger" value={item.trimItem.sht_passenger || ''} onChange={e => this.infoSearchValueByTrimItem(e , index)}></input></td>
                          </tr>
                          <tr>
                              <th>2열</th>
                              <td><input type="text" name="sht_row2" value={item.trimItem.sht_row2 || ''} onChange={e => this.infoSearchValueByTrimItem(e , index)}></input></td>
                          </tr>
                          <tr>
                              <th>3열</th>
                              <td><input type="text" name="sht_row3" value={item.trimItem.sht_row3 || ''} onChange={e => this.infoSearchValueByTrimItem(e , index)}></input></td>
                          </tr>
                          <tr>
                              <th>에어컨</th>
                              <td><input type="text" name="acn_aircon" value={item.trimItem.acn_aircon || ''} onChange={e => this.infoSearchValueByTrimItem(e , index)}></input></td>
                          </tr>
                          <tr>
                              <th>온도조절 범위</th>
                              <td><input type="text" name="acn_temp_range" value={item.trimItem.acn_temp_range || ''} onChange={e => this.infoSearchValueByTrimItem(e , index)}></input></td>
                          </tr>
                          <tr>
                              <th>뒷좌석 송풍구</th>
                              <td><input type="text" name="acn_rear" value={item.trimItem.acn_rear || ''} onChange={e => this.infoSearchValueByTrimItem(e , index)}></input></td>
                          </tr>
                          <tr>
                              <th>공기청정</th>
                              <td><input type="text" name="acn_air_clean" value={item.trimItem.acn_air_clean || ''} onChange={e => this.infoSearchValueByTrimItem(e , index)}></input></td>
                          </tr>
                          <tr>
                              <th>화면크기 (인치)</th>
                              <td><input type="text" name="mul_monitor_size" value={item.trimItem.mul_monitor_size || ''} onChange={e => this.infoSearchValueByTrimItem(e , index)}></input></td>
                          </tr>       
                          <tr>
                              <th>주요기능</th>
                              <td><input type="text" name="mul_functions" value={item.trimItem.mul_functions || ''} onChange={e => this.infoSearchValueByTrimItem(e , index)}></input></td>
                          </tr> 
                          <tr>
                              <th>부가기능</th>
                              <td><input type="text" name="mul_extra_functions" value={item.trimItem.mul_extra_functions || ''} onChange={e => this.infoSearchValueByTrimItem(e , index)}></input></td>
                          </tr> 
                          <tr>
                              <th>인터페이스</th>
                              <td><input type="text" name="mul_interface" value={item.trimItem.mul_interface || ''} onChange={e => this.infoSearchValueByTrimItem(e , index)}></input></td>
                          </tr> 
                          <tr>
                              <th>스피커</th>
                              <td><input type="text" name="mul_speaker" value={item.trimItem.mul_speaker || ''} onChange={e => this.infoSearchValueByTrimItem(e , index)}></input></td>
                          </tr> 
                          <tr>
                              <th>사운드시스템</th>
                              <td><input type="text" name="mul_sound_system" value={item.trimItem.mul_sound_system || ''} onChange={e => this.infoSearchValueByTrimItem(e , index)}></input></td>
                          </tr>                                     
                      </tbody>
                  </table> 
              </div>
              <div className="centerT mt10 mb10 dpb">
                <Link to="#"><button type="button" className="btnLine navyBg disb" onClick={() => this.closePop(index)}>저장</button></Link>
              </div>                    
          </div>
                <div className={item.popActivePriceOption ? "popBg onPop" : "popBg"} onClick={() => this.closePop(index)}/>
                <div className={item.popActivePriceOption ? "resources on" : "resources"}>
                  <p>가격옵션</p>
                  <span onClick={() => this.closePop(index)}>x</span>
                  <span>
                    <Select
                        className="reactSelect trim"
                        options={item.trims}
                        value={item.trimSelector.price_options}
                        onChange={(option) => this.handleTrimSelect(option, index, 'price_options')}
                        placeholder="트림을 선택해주세요."
                        isSearchable={true}
                    />
                  </span>
                  <div>
                    <div className={cx('price_option_wrap')}>
                      <div className={cx('content_wrap')}>
                        <ul className={cx('price_option_list')}>
                          <li className={cx('pol_head')}>
                            <div className={cx('function')}>기능</div>
                            <div className={cx('price_option_name')}>옵션명</div>
                            <div className={cx('price_option_description')}>설명</div>
                            <div className={cx('price_option_sale')}>가격</div>
                          </li>
                          {item.price_options.map((priceOption, priceOptionIndex) => (
                              <li key={`trim-pc-${index}-${priceOptionIndex}`} className={cx('pol_default')}>
                                <div className={cx('pol_default_inner')}>
                                  <div className={cx('function')}>
                                    <span className={cx('remove')} onClick={() => this.removePriceOptionForm(index, priceOptionIndex)}>-</span>
                                    <span className={cx('add')} onClick={() => this.addPriceOptionForm(index)}>+</span>
                                    {/*<span className={cx('child_add')}>L</span>*/}
                                  </div>
                                  <div className={cx('price_option_name')}>
                                    <input
                                        type="text"
                                        placeholder="옵션명"
                                        name="name"
                                        value={priceOption.name || ''}
                                        onChange={(e) => this.handlePriceOption(e, index, priceOptionIndex)}
                                    />
                                  </div>
                                  <div className={cx('price_option_description')}>
                                    <textarea
                                        placeholder="옵션 설명"
                                        name="description"
                                        value={priceOption.description || ''}
                                        onChange={(e) => this.handlePriceOption(e, index, priceOptionIndex)}
                                    >
                                    </textarea>
                                  </div>
                                  <div className={cx('price_option_sale')}>
                                    <input
                                        type="number"
                                        placeholder="옵션 가격"
                                        name="price"
                                        value={priceOption.price || ''}
                                        onChange={(e) => this.handlePriceOption(e, index, priceOptionIndex)}
                                    />
                                  </div>
                                </div>
                              </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="centerT mt10 mb10 dpb">
                    <a onClick={() => this.closePop(index, 'priceOption')}>
                      <button type="button" className="btn navyBg disb">저장</button>
                    </a>
                    <a className="ml10" onClick={() => this.closePop(index)}>
                      <button type="button" className="btnLine navyBg disb">닫기</button>
                    </a>
                  </div>
                </div>
              </Fragment>
              )})}
            </Fragment>
        );
    }
}

export default TrimUpdate;