import React, { Component } from 'react';

import * as adsService from '../../component/services/operation/adsService';
import * as Common from '../../component/services/common/CommonService';

class ECommersAdv extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authToken: Common.getToken.call(this),
      commerceAds: {},
      adsItem: {},
      isDisabled: false,
      isAdd: false,
      isEdit: false,
      isNameDisabled: false,
      adsCategoryName: '',
      adsItemNumber: 0,
      imageName: '',
      imageInputState: true,
      imageUrl: ''
    };
  }

  componentDidMount() {
    this.getCommerceAds();
  }

  formatNumber = (num) => {
    if (num === 0 || num === '0') return 0;

    const reg = /(^[+-]?\d+)(\d{3})/;
    let n = (`${num}`);

    while (reg.test(n)) n = n.replace(reg, '$1,$2');

    return n;
  }

  getCommerceAds = () => {
    const { authToken } = this.state;
    const request = adsService.getCommerceAds(authToken);
    request.then((res) => {
      this.setState({
        commerceAds: res.data.data,
        adsCategoryName: res.data.data.name,
        isDisabled: false,
        isAdd: false,
        isEdit: false,
        adsItemNumber: 0
      });
    });
  }

  setOneAds = (ad, num) => {
    const { adsItemNumber } = this.state;
    if (adsItemNumber !== num) {
      this.setState({
        imageUrl: '',
        imageName: '',
        isDisabled: false
      });
    }
    this.setState({
      adsItemNumber: num,
      adsItem: {
        ...ad,
        image: null
      }
    });
  }

  postCommerceAdsItems = () => {
    const { authToken, adsItem } = this.state;
    if (!(adsItem.name && adsItem.owner && adsItem.url && adsItem.image)) {
      alert('필수 값을 입력해 주세요');
    }
    const request = adsService.postCommerceAdsItems(authToken, adsItem);
    request.then((res) => {
      if (res.status >= 200 && res.status < 400) {
        alert('수정되었습니다.');
        this.getCommerceAds();
      }
    }).catch((err) => alert(err));
  }

  putCommerceAdsItems = () => {
    const { authToken, adsItem } = this.state;
    const request = adsService.putCommerceAdsItems(authToken, adsItem);
    request.then((res) => {
      if (res.status >= 200 && res.status < 400) {
        alert('수정되었습니다.');
        this.getCommerceAds();
      }
    }).catch((err) => alert(err));
  }

  putCommerceAds = () => {
    const { authToken, adsCategoryName } = this.state;
    if (!adsCategoryName) {
      alert('필수 값을 입력해 주세요');
      return;
    }
    const request = adsService.putCommerceAds(authToken, adsCategoryName);
    request.then((res) => {
      if (res.status >= 200 && res.status < 400) {
        alert('수정되었습니다.');
        this.setState({
          isNameDisabled: false
        });
      }
    }).catch((err) => alert(err));
  }

  handleChange = (e) => {
    const { adsItem } = this.state;
    this.setState({
      adsItem: {
        ...adsItem,
        [e.target.name]: e.target.value
      }
    });
  }

  handleNameChange = (e) => {
    this.setState({
      adsCategoryName: e.target.value
    });
  }

  uploadImage = (e) => {
    const { adsItem } = this.state;
    this.setState({
      adsItem: {
        ...adsItem,
        image: e.target.files[0]
      },
      imageName: e.target.files[0].name
    });
    if (!e.target.files[0]) return;
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      this.setState({
        imageUrl: reader.result
      });
    };
  };


  render() {
    const {
      commerceAds, adsItem, isDisabled, isAdd, isEdit, isNameDisabled, adsCategoryName, adsItemNumber, imageName, imageInputState, imageUrl
    } = this.state;
    return (
      <>
        <div className="title">
          {' '}
          <p className="mb10">
            <span>홈</span>
            {' '}
            〉
            {' '}
            <span>운영</span>
            {' '}
            〉
            {' '}
            <span>E커머스광고</span>
          </p>
          E커머스광고
        </div>
        <div className="mt40 mb40">
          <form>
            <table className="">
              <colgroup>
                <col width="15%" />
                <col width="*" />
                <col width="10%" />
                <col width="10%" />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    광고 카테고리 명
                    {' '}
                    <span className="redT">*</span>
                  </th>
                  <td className="leftT cont_input">
                    <input type="text" onChange={this.handleNameChange} name="adsCategoryName" placeholder="10자 이내" value={adsCategoryName || ''} disabled={isNameDisabled ? '' : 'disabled'} />
                    {!isNameDisabled ? <button type="button" className="btnLine navyBg ml10" onClick={() => this.setState({ isNameDisabled: true })}>수정</button> : ''}
                    {isNameDisabled ? <button type="button" className="btn navyBg ml10" onClick={() => this.putCommerceAds()}>저장</button> : ''}
                  </td>
                  <th>조회수</th>
                  <td>
                    <span>{adsItem.view_count === 0 || adsItem.view_count ? `${this.formatNumber(adsItem.view_count)} 회` : ''}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
        <div className="e_cum_wrapper">
          {/* style 임시설정, 이후에 클래스를 이용하여 스타일 설정 필요 */}
          <ul className="e_cum_wrap" style={{ display: 'flex' }}>
            {commerceAds ? Object.entries(commerceAds).map((commerceAd, adsIdx) => {
              if (typeof commerceAd[1] !== 'object') return null;
              if (!commerceAd[1].id) {
                return (
                  <li
                    key={`${adsIdx}`}
                    onClick={() => {
                      this.setOneAds(
                        {
                          image: null,
                          is_active: true,
                          name: '',
                          owner: '',
                          position: adsIdx - 2,
                          url: '',
                          description: ''
                        },
                        adsIdx - 2
                      );
                      this.setState({
                        isAdd: true,
                        isEdit: false
                      });
                    }}
                    style={{
                      width: '100px', height: '100px', backgroundColor: 'gray', border: adsItemNumber === adsIdx - 2 ? '2px solid red' : '2px solid black', cursor: 'pointer', textAlign: 'center', padding: '20px'
                    }}
                  >
                    E 커머스 광고
                    {adsIdx - 2}
                  </li>
                );
              }
              return (
                <li
                  key={commerceAd[1].id}
                  onClick={() => {
                    this.setOneAds(
                      commerceAd[1],
                      adsIdx - 2
                    );
                    this.setState({
                      isAdd: false,
                      isEdit: true
                    });
                  }}
                  style={{
                    width: '100px', height: '100px', backgroundColor: 'gray', border: adsItemNumber === adsIdx - 2 ? '2px solid red' : '2px solid black', cursor: 'pointer'
                  }}
                >
                  <img src={(adsItemNumber === adsIdx - 2 && imageUrl) || commerceAd[1].image} alt={`E커머스 광고 이미지 ${commerceAd[1].position}`} style={{ width: '96px', height: '96px' }} />
                </li>
              );
            }) : ''}
          </ul>
        </div>
        <div className="rightT mt20">
          {isAdd && !isDisabled ? (
            <button
              type="button"
              className="btn grayBg"
              onClick={() => {
                this.setState({
                  isDisabled: true
                });
              }}
            >
          추가
            </button>
          ) : ''}
          {isEdit && !isDisabled ? (
            <button
              type="button"
              className="btnLine navyBg"
              onClick={() => {
                this.setState({
                  isDisabled: true
                });
              }}
            >
          수정
            </button>
          ) : ''}
          {isDisabled ? (
            <button
              type="button"
              className="btn navyBg"
              onClick={() => {
                if (isEdit) this.putCommerceAdsItems();
                if (isAdd) this.postCommerceAdsItems();
              }}
            >
          저장
            </button>
          ) : ''}
          <button type="button" className="btnLine navyBg ml10 mr10">추가</button>
          <button type="button" className="btn navyBg">저장</button> 
        </div>
        
        <table className="add_table mt40">
          <colgroup>
            <col width="10%" />
            <col width="" />
          </colgroup>
          <tbody>
            <tr>
              <th>
                광고 주체
                {' '}
                <span className="redT">*</span>
              </th>
              <td><input type="text" onChange={this.handleChange} name="owner" value={adsItem.owner || ''} disabled={isDisabled ? '' : 'disabled'} /></td>
            </tr>
            <tr>
              <th>
                광고 콘텐츠 제목
                {' '}
                <span className="redT">*</span>
              </th>
              <td><input type="text" onChange={this.handleChange} name="name" placeholder="15자 이내" value={adsItem.name || ''} disabled={isDisabled ? '' : 'disabled'} /></td>
            </tr>
            <tr>
              <th>
                썸네일 이미지
                {' '}
                <span className="redT">*</span>
              </th>
              <td colSpan="3" className="file_wrap">
                {imageInputState ? <input type="file" id="add_img" onChange={this.uploadImage} disabled={isDisabled ? '' : 'disabled'} /> : ''}
                <input type="text" readOnly className="file_input" value={imageName || ''} disabled={isDisabled ? '' : 'disabled'} />
                <label
                  htmlFor="add_img"
                  className="file_btn"
                  onClick={() => {
                    this.setState({
                      imageInputState: false
                    }, () => {
                      this.setState({
                        imageInputState: true
                      });
                    });
                  }}
                >
                업로드
                </label>
              </td>
            </tr>
            <tr>
              <th>
                URL
                {' '}
                <span className="redT">*</span>
              </th>
              <td><input type="text" onChange={this.handleChange} name="url" value={adsItem.url || ''} disabled={isDisabled ? '' : 'disabled'} /></td>
            </tr>
            <tr>
              <th>
                활성화 상태
                {' '}
                <span className="redT">*</span>
              </th>
              <td>
                <select value={adsItem.is_active} name="is_active" onChange={this.handleChange} disabled={isDisabled ? '' : 'disabled'}>
                  <option value="true">활성화</option>
                  <option value="false">비활성화</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
  }
}

export default ECommersAdv;
