import React from 'react';
import '../../../UIKitMonkeyPatch.scss'
import {includes} from "lodash";
import {useTrimState, useTrimDispatch, trimSpecMapper} from "./TrimProvider";

const TrimSpecModal = ({title ,index}) => {
  const trimSpec = useTrimState()[index].trim_spec;
  const trimDispatch = useTrimDispatch();
  const inputHandler = (value, inputName) => trimDispatch({
    type: 'CHANGE_TRIM_SPEC_INPUT',
    payload: {value, inputName, trimIndex: index}
  });

  return (
    <div key={`trim-modal-${title}-${index}`} className='uk-container'>
      <div id={`modal-ts-${index}`} data-uk-modal>
        <div className="uk-modal-dialog">
          <button className="uk-modal-close-default" type="button" data-uk-close/>
          <div className="uk-modal-header">
            <h4>{title}</h4>
          </div>
          <div className="uk-modal-body" data-uk-overflow-auto>
            {Object.entries(trimSpec).map(([fieldName, value], key) => {
              if (includes(Object.keys(trimSpecMapper), fieldName)) {
                return (
                  <div className='uk-margin' key={key}>
                    <label className="uk-form-label">{trimSpecMapper[fieldName]}</label>
                    <div className="uk-form-controls">
                      <input className="uk-input" type="text" value={value || ''}
                             onChange={e => inputHandler(e.target.value, fieldName)}/>
                    </div>
                  </div>
                )
              }
            })}
          </div>
        </div>
      </div>
    </div>
  )
};

export default TrimSpecModal
