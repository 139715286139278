import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import * as Common from "../../component/services/common/CommonService";
import * as Service from '../../component/services/brand/PriceOptionService';
import * as BrandService from "../../component/services/brand/BrandService";
import Pagination from '../../component/Pagination'
import QueryString from 'query-string';

class PriceOption extends Component{

  constructor(props){
    super(props)
    const queryStringHistory = QueryString.parse(this.props.location.search);
    this.state = {
      authToken :Common.getToken.call(this),
        brandId : queryStringHistory.brandId ? queryStringHistory.brandId : 0,
      per : 8,
        page: queryStringHistory.page ? queryStringHistory.page : 1,
        priceOptionName: queryStringHistory.priceOptionName ? queryStringHistory.priceOptionName : '',
      priceOption : [],
      pagination : [],
      totalLength : 0,
      brand_order : queryStringHistory.brand_order ? queryStringHistory.brand_order : null,
      totalInfo: [],
      isLinked : false,
      totalCnt : 0,
      checked : 0
    }
  }

  componentDidMount(){
    this.getTotalInfo()
    this.getPriceOption()

    const id = this.props.location.pathname.split('/')
      if(!isNaN(parseInt(id[id.length-1]))){
        this.setState({
          brandId : parseInt(id[id.length-1]),
          isLinked : true
        },() => {
          this.getPriceOptionList()
        })
      }
      else{
        this.getPriceOptionList()
      }
  }

  getTotalInfo = aysnc => {
    const brandList = BrandService.getTotalInfo(this.state.authToken);
    brandList.then(response => {
      this.setState({
        totalInfo: response.data.data
      });
    });
  };

  getPriceOption = async => {
    const PriceOptions = Service.getPriceOption(
      this.state.authToken
    )
    PriceOptions.then(res => {
      this.setState({
        totalLength : res.data.data.length
      })
    })
  }

  getPriceOptionList = is_render => {
    const PriceOptionList = Service.getPriceOptionList(
      this.state.brandId,
      this.state.per,
      this.state.page,
      this.state.authToken,
      this.state.brand_order,
      this.state.priceOptionName
    )
    PriceOptionList.then(res => {
      this.setState({
        priceOption : res.data.data,
        pagination : res.data.pagination,
        totalCnt : res.data.total_entries
      },() => {
        if(is_render){
          if(document.querySelector('#first_btn'))
            document.querySelector('#first_btn').click()
        }
      })
    })
  }

  onSearch = () => {
    this.setState({
        page: 1
    }, () => {
        this.handlePage();
        this.getPriceOptionList();
    });
  };

  orderBrand = (order) => {
    this.setState({
      brand_order : order,
    },
    () => {
        this.handlePage();
        this.getPriceOptionList();
    })
  }

  onClickPage = (page) => {
    this.setState(
      {
        page
      },
      () => {
          this.handlePage();
        this.getPriceOptionList();
      }
    );
  };

  infoSearchValue = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  componentDidUpdate(){
    if(this.state.isLinked)
      document.getElementsByName('brandId')[0].value = this.state.brandId
  }

  handleKeyPress = (e) => {
      this.setState({
          [e.target.name]: e.target.value
      });
  }

  checkBoxCheck = e => {
    const data = document.querySelectorAll('.checkBox')
    let cnt = 0;
    for(let x = 0; x<data.length; x++){
      if(data[x].checked) cnt++;
    }
    if(cnt>1){
      alert('하나만 체크 가능합니다.')
      e.target.checked = false
    }else {
      this.setState({
        checked : e.target.id.replace('price_','')
      })
    }
  }

  goEdit = () => {
    const data = document.querySelectorAll('.checkBox')
    let cnt = 0;
    for(let x = 0; x<data.length; x++){
      if(data[x].checked) cnt++;
    }
    if(cnt == 0){
      alert('하나 이상 체크해야 가능합니다.')
      return false
    }

    this.props.history.push("/brand/priceOptionDetail/brand_id="+this.state.checked)
  }

  setComma = ( value ) => {
    if (value == null || value == ""){
        return value;
    }
    
    // 소수점 분리
    var valueArr = value.toString().split(".");
    
    var str = valueArr[0].toString();
        str = str.replace ( /,/g ,'' );
    var retValue = "";
 
    for( var i = 1; i <= str.length; i++ ) {
        if ( i > 1 && ( i % 3 ) == 1 )
            retValue = str.charAt ( str.length - i ) + "," + retValue;
        else
            retValue = str.charAt ( str.length - i ) + retValue;
    }
 
    return retValue + (valueArr.length > 1 ? "." + valueArr[1] : "");
  }

    // 가격옵션 페이지 내의 모든 페이지 라우팅 핸들링
    handlePage = (path) => {
        const {
            brandId,
            priceOptionName,
            brand_order,
            page
        } = this.state;
        let searchInfo = {
            brandId,
            priceOptionName,
            brand_order,
            page
        };
        Object.keys(searchInfo).map((item) => {
            if (item === 'brandId') {
                (searchInfo[item] === 0 || searchInfo[item].toString().length === 0) && delete searchInfo[item];
            } else {
                (searchInfo[item] === null || searchInfo[item].toString().length === 0) && delete searchInfo[item];
            }
        });
        let queryString = Object.keys(searchInfo).map((item, index) => searchInfo[item] !== null && `${item}=${searchInfo[item]}`).join('&');
        this.props.history.push({
            pathname: path ? path : '/brand/priceOption',
            search: `?${queryString}`
        });
    }

    render(){
        const { page } = this.state;
        return(
            <Fragment>
                {/* ///// 가격옵션 영역 시작 ///// */}
                <div className="title"><p className="mb10"><span>홈</span> 〉 <span>브랜드</span> 〉 <span>가격옵션</span></p>가격옵션 </div>
                {/* 타이틀 */}
                <div className="info_searchwrap">
                        <table>
                            <colgroup>
                                <col width="10%"></col>
                                <col width="*"></col>
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>브랜드명</th>
                                    <td>
                                    <select
                                      name="brandId"
                                      onChange={this.infoSearchValue}
                                      value={this.state.brandId}
                                    >
                                      <option value={0}>전체</option>
                                      {this.state.totalInfo.map((item, key) => {
                                        return (
                                          <Fragment key={key}>
                                            <option value={item["id"]}>{item["name"]}</option>
                                          </Fragment>
                                        );
                                      })}
                                    </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th>가격옵션명</th>
                                    <td><input id="priceOptionName" name="priceOptionName" type="text" value={this.state.priceOptionName || ''} onChange={this.handleKeyPress}></input></td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="centerT mt20">
                            <button className="btnLine navyBg medium" onClick={this.onSearch}>검색</button>
                        </div>
                </div>
                {/* 가격옵션 검색영역 끝 */}
                <div className="info_resultwrap">
                    <div className="info_tabletop">
                        <div className="mb10">
                            <button className="btnLine navyBg" onClick={() => this.goEdit()}>일괄수정</button>
                            <button className="btn navyBg centerT ml10" onClick={() => this.handlePage(`/brand/priceOptionDetail`)}>추가</button>
                        </div>
                    </div>
                    <table>
                        <colgroup>
                            <col width="5%"></col>
                            <col width=""></col>
                            <col width=""></col>
                            <col width=""></col>
                            <col width=""></col>
                            <col width=""></col>
                            <col width=""></col>
                            <col width="10%"></col>
                        </colgroup>
                        <thead>
                        <tr><th className="ccc" colsPan="8">총 <span>{this.state.totalCnt}</span>개</th></tr>
                            <tr>
                                <th><input type="checkbox" id="price_all" name="price_option"></input></th>
                                <th>번호</th>
                                <th>
                                    브랜드명
                                    <button className="set_btn" onClick={() => this.orderBrand("desc")}>▼</button>
                                    <button className="set_btn" onClick={() => this.orderBrand("asc")}>▲</button>
                                </th>
                                <th>가격옵션 명</th>
                                <th>가격</th>
                                <th>수정일</th>
                                <th>등록일</th>
                                <th>관리</th>
                            </tr>
                        </thead>
                        <tbody>
                          {this.state.priceOption.map((item,index)=>{
                            return(
                            <tr>
                                <td><input type="checkbox" id={`price_${item.brand_id}`} className="checkBox" name="price_option" onClick={this.checkBoxCheck}></input></td>
                                <td>{item.id}</td>
                                <td>{item.brand_name}</td>
                            <td>{item.name}</td>
                            <td><span>{this.setComma(item.price)}</span>원</td>
                            <td>{`${new Date(item.updated_at).getFullYear() - 2000}.
                                  ${new Date(item.updated_at).getMonth()+1 < 10 ? `0${new Date(item.updated_at).getMonth()+1}` : new Date(item.updated_at).getMonth()+1}.
                                  ${new Date(item.updated_at).getDate() < 10 ? `0${new Date(item.updated_at).getDate()}` : new Date(item.updated_at).getDate()}`}</td>
                            <td>{
                             `${new Date(item.created_at).getFullYear() - 2000}.
                              ${new Date(item.created_at).getMonth()+1 < 10 ? `0${new Date(item.created_at).getMonth()+1}` : new Date(item.created_at).getMonth()+1}.
                              ${new Date(item.created_at).getDate() < 10 ? `0${new Date(item.created_at).getDate()}` : new Date(item.created_at).getDate()}`}</td>
                                <td><button className="btn grayBg" onClick={() => this.handlePage(`/brand/priceOptionDetail/${item.id}`)}>수정</button></td>
                            </tr>
                            )
                          })}
                        </tbody>
                    </table>
                    <div className="pagination">
                    <Pagination
                      onClickPage={this.onClickPage}
                      paginationOption={this.state.pagination}
                    />
                    </div>
                </div>
                {/* 종합정보 검색결과 영역 끝 */}
            </Fragment>
        );
    }
  }

export default PriceOption;