import React, { useCallback, memo } from "react";
import Modal from "react-modal";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import moment from "moment";

const TrimsOrderModalStyles = {
    overlay: {
        background: "rgba(0, 0, 0, .6)",
        zIndex: 1010
    },
    content : {
        width: "100%",
        maxWidth: 1000,
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        transform: "translate(-50%, -50%)"
    }
};

const SortableItem = SortableElement(({ item, sortIndex }) => (
    <li className="uk-grid-collapse uk-background-default uk-z-index-large uk-cursor-grab uk-custom-sortable" data-uk-grid>
        <div className="uk-width-1-5">
            <div className="uk-padding-small uk-text-center">
                <span className="uk-badge">{sortIndex}</span>
            </div>
        </div>
        <div className="uk-width-3-5">
            <div className="uk-padding-small">[{item.id}] {item.name}</div>
        </div>
        <div className="uk-width-1-5">
            <div className="uk-padding-small uk-text-center">{moment(item.created_at).format("YYYY-MM-DD")}</div>
        </div>
    </li>
));

const SortableList = SortableContainer(({ items }) => (
    <ul>
        {items.map((item, index) => (
            <SortableItem key={`trim-${item.id}`} item={item} index={index} sortIndex={index + 1} />
        ))}
    </ul>
));

function TrimsOrderModal({
    isModalOpen,
    trimElements,
    handleCloseTrimsOrderModal,
    handleLineupTrimOnSortEnd,
    handleLineupTrimsOrderSave
}) {
    const handleUpdateBeforeSortStart = useCallback((event) => {
        event.node.style = 'box-shadow: 0 14px 25px rgba(0,0,0,.16);';
    }, []);

    return (
        <Modal
            isOpen={isModalOpen}
            onRequestClose={handleCloseTrimsOrderModal}
            style={TrimsOrderModalStyles}
        >
            <div className="uk-modal-header">
                <div className="uk-span-tag">
                    <span className="uk-text-muted">{trimElements.brandName}</span>
                    <span className="uk-text-muted">{trimElements.carModelGroupName}</span>
                    <span className="uk-text-muted">{trimElements.carModelName}</span>
                </div>
                <h3 className="uk-modal-title uk-text-large">{trimElements.lineupName}</h3>
            </div>
            <div>
                <div className="uk-grid-collapse" data-uk-grid>
                    <div className="uk-width-1-5">
                        <div className="uk-background-muted uk-padding-small uk-text-center">순서</div>
                    </div>
                    <div className="uk-width-3-5">
                        <div className="uk-background-muted uk-padding-small uk-text-center">트림명</div>
                    </div>
                    <div className="uk-width-1-5">
                        <div className="uk-background-muted uk-padding-small uk-text-center">등록일</div>
                    </div>
                </div>
                <div className="uk-max-height-medium" data-uk-overflow-auto>
                    <SortableList
                        items={trimElements.trims}
                        updateBeforeSortStart={handleUpdateBeforeSortStart}
                        onSortEnd={handleLineupTrimOnSortEnd}
                    />
                </div>
            </div>
            <div className="uk-modal-footer uk-text-right">
                <button
                    type="button"
                    className="uk-button uk-button-primary uk-margin-small-right"
                    onClick={handleLineupTrimsOrderSave}
                >
                    저장
                </button>
                <button
                    type="button"
                    className="uk-button uk-button-default"
                    onClick={handleCloseTrimsOrderModal}
                >
                    닫기
                </button>
            </div>
        </Modal>
    );
}

export default memo(TrimsOrderModal);
