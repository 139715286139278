import { devUrl } from '../../serviceComm/variable';

const axios = require('axios');
const format = require('date-format');

export function getMovieList(authToken, page, searchOption) {
  const params = () => {
    if (!searchOption) return null;

    let p = {};
    if (searchOption.uploader !== '') p = { ...p, uploader: searchOption.uploader };
    if (searchOption.title !== '') p = { ...p, title: searchOption.title };
    if (searchOption.is_active !== '') p = { ...p, is_active: searchOption.is_active };
    if (searchOption.start_of_published_at !== '') p = { ...p, start_of_published_at: format('yyyy-MM-dd', searchOption.start_of_published_at) };
    if (searchOption.end_of_published_at !== '') p = { ...p, end_of_published_at: format('yyyy-MM-dd', searchOption.end_of_published_at) };
    if (searchOption.order_by !== '') p = { ...p, order_by: searchOption.order_by };
    if (searchOption.per !== '') p = { ...p, per: searchOption.per };
    return p;
  };
  const config = {
    method: 'get',
    url:
      `${devUrl}/admin/cms/videos`,
    headers: {
      Authorization: `Bearer  ${authToken}`
    },
    params: {
      page,
      ...params()
    }
  };

  return axios(config);
}

export function deleteMovieItem(authToken, id) {
  const config = {
    method: 'delete',
    url:
      `${devUrl}/admin/cms/videos`,
    headers: {
      //   "x-api-key": "b2cb6699e9c4e10cf36a5f5451309069",
      Authorization: `Bearer  ${authToken}`
    },
    data: {
      video_ids: id
    }
  };

  return axios(config);
}
