import React, { Component, Fragment } from 'react';
import * as Service from '../../component/services/vehicleDB/ModelGroupService'
import * as Common from "../../component/services/common/CommonService";
import * as BrandService from "../../component/services/brand/BrandService";
import { Link } from 'react-router-dom';
class ModelGroupDetail extends Component{

  constructor(props){
    super(props)
    this.state = {
      authToken :Common.getToken.call(this),
      totalInfo : [],
      brandId : 0,
      isEdit : false,
      editId : 0,
      defaultId : 0,
      defaultName : "",
      defaultType : "경차",
      defaultActive : true,
        // page_history: this.props.location.search.replace(/\?page=/g, '') ? this.props.location.search.replace(/\?page=/g, '') : 1
    }
  }

  componentDidMount(){
    this.getTotalInfo()
    const id = this.props.location.pathname.split('/')
    if(!isNaN(parseInt(id[id.length-1]))) {
        this.getDetail()
        this.setState({
            isEdit: true,
            editId: parseInt(id[id.length - 1])
        })
    }
  }

  getDetail = () => {
    const id = this.props.location.pathname.split('/')
    const Detail = Service.getDetail(
      this.state.authToken,
      id[id.length-1]
    )

    Detail.then(res => {
      const data = res.data.data
      this.setState({
        brandId : data.brand_id,
        defaultId : data.brand_id,
        defaultName : data.name,
        defaultType : data.vehicle_type,
        defaultActive : data.is_active
      })
      document.querySelector('#modelGroupName').value = data.name
      document.querySelector('#vehicle_type').value = data.vehicle_type
      document.querySelector('#isActive').value = data.is_active
    })
  }

  getTotalInfo = aysnc => {
    const brandList = BrandService.getTotalInfo(this.state.authToken);
    brandList.then(response => {
      this.setState({
        totalInfo: response.data.data
      });
    });
  };

  infoSearchValue = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  componentDidUpdate(){
    if(this.state.isEdit)
      document.getElementsByName('brandId')[0].value = this.state.brandId
  }

  onClick = () => {

      if(this.state.brandId == 0){
        alert('브랜드를 선택해주세요')
        return false;
      }
      if(document.querySelector('#modelGroupName').value == ""){
        alert('모델 그룹명을 입력해주세요')
        return false;
      }
  
      const saveBrandGroup = this.state.isEdit ? 
      Service.editModelGroup(
        this.state.authToken , 
        this.state.brandId , 
        document.querySelector('#modelGroupName').value,
        document.querySelector('#vehicle_type').value,
        document.querySelector('#isActive').value,
        this.state.editId
      ) :
      Service.postModelGroup(
        this.state.authToken , 
        this.state.brandId , 
        document.querySelector('#modelGroupName').value,
        document.querySelector('#vehicle_type').value,
        document.querySelector('#isActive').value
      )

      saveBrandGroup.then(res => {
        this.props.history.push('/vehicleDB/modelGroup')
      })
  }

  onClickBack = () => {
    
    if(this.state.defaultId != this.state.brandId){
      if (window.confirm('현재 수정된 값이 반영되지 않습니다. 그래도 이동하시겠습니까?')){
        // this.props.history.push(`/vehicleDB/modelGroup?page=${this.state.page_history}`)
        this.props.history.goBack();
        return false
      }
    }
    if(this.state.defaultName != document.querySelector('#modelGroupName').value){
      console.log('name')
      if (window.confirm('현재 수정된 값이 반영되지 않습니다. 그래도 이동하시겠습니까?')){
          // this.props.history.push(`/vehicleDB/modelGroup?page=${this.state.page_history}`)
          this.props.history.goBack();
        return false
      }
    }
    if(this.state.defaultType !=document.querySelector('#vehicle_type').value){
      if (window.confirm('현재 수정된 값이 반영되지 않습니다. 그래도 이동하시겠습니까?')){
          // this.props.history.push(`/vehicleDB/modelGroup?page=${this.state.page_history}`)
          this.props.history.goBack();
        return false
      }
    }
    if(this.state.defaultActive.toString() !=document.querySelector('#isActive').value){
      if (window.confirm('현재 수정된 값이 반영되지 않습니다. 그래도 이동하시겠습니까?')){
          // this.props.history.push(`/vehicleDB/modelGroup?page=${this.state.page_history}`)
          this.props.history.goBack();
        return false
      }
    }
    if(this.state.defaultId == this.state.brandId && this.state.defaultName == document.querySelector('#modelGroupName').value &&
    this.state.defaultType ==document.querySelector('#vehicle_type').value && this.state.defaultActive.toString() ==document.querySelector('#isActive').value)
        // this.props.history.push(`/vehicleDB/modelGroup?page=${this.state.page_history}`)
        this.props.history.goBack();
  }

    render(){
        return(
            <Fragment>
               <div className="title disi wd100"><p className="mb10"><span>홈</span> 〉 <span>차량DB</span> 〉 <span>모델그룹</span> 〉 <span>추가/수정</span></p>모델그룹 추가/수정
                    <div className="floatR disi">
                            <button className="btnLine navyBg" onClick={() => { if (window.confirm('저장하시겠습니까?')) this.onClick() }}>저장</button>
                            <button className="btn navyBg ml10" onClick={() => this.onClickBack()}>목록</button>
                    </div>
               </div>
                <div className="form_tablewrap">
                        <table className="mt20">
                            <colgroup>
                                <col width="10%"></col>
                                <col width="*"></col>
                                <col width="10%"></col>
                                <col width="*"></col>
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>브랜드명 <span className="redT">*</span></th>
                                    <td>
                                      <select
                                        name="brandId"
                                        onChange={this.infoSearchValue}
                                        defaultValue={this.state.brandId}
                                      >
                                        <option value="0">전체</option>
                                        {this.state.totalInfo.map((item, key) => {
                                          return (
                                            <Fragment key={key}>
                                              <option value={item["id"]}>{item["name"]}</option>
                                            </Fragment>
                                          );
                                        })}
                                      </select>
                                    </td>
                                    <th>모델 그룹명 <span className="redT">*</span></th>
                                    <td><input id="modelGroupName" defaultValue=""></input></td>
                                </tr>
                                <tr>
                                    <th>차종 <span className="redT">*</span></th>
                                    <td>
                                        <select id="vehicle_type">
                                            <option>경차</option>
                                            <option>경승합</option>
                                            <option>경트럭</option>
                                            <option>대형</option>
                                            <option>대형MPV</option>
                                            <option>대형SUV</option>
                                            <option>소형</option>
                                            <option>소형MPV</option>
                                            <option>소형SUV</option>
                                            <option>소형버스</option>
                                            <option>소형트럭</option>
                                            <option>스포츠카</option>
                                            <option>승합</option>
                                            <option>준대형</option>
                                            <option>준중형</option>
                                            <option>중형</option>
                                            <option>중형SUV</option>
                                            <option>중형트럭</option>
                                            <option>픽업/밴</option>
                                        </select>
                                    </td>
                                    <th>활성화 상태 <span className="redT">*</span></th>
                                    <td>
                                        <select id="isActive">
                                            <option value={true}>활성화</option>
                                            <option value={false}>비활성화</option>
                                        </select>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="rightT mt20">
                            <button className="btnLine navyBg" onClick={() => { if (window.confirm('저장하시겠습니까?')) this.onClick() }}>저장</button>
                            <button type="button" className="btn navyBg ml10" onClick={() => this.onClickBack()}>목록</button>
                        </div>
                </div>
            </Fragment>
        );
    }
}

export default ModelGroupDetail;