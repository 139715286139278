import React, { useCallback, memo } from "react";
import { isEmpty } from "lodash";
import Modal from "react-modal"

import "../../../style/contents/contentModal.scss";

Modal.setAppElement("#root");

const ContentModalStyles = {
    overlay: {
        background: "rgba(0, 0, 0, .6)",
        zIndex: 1010
    },
    content : {
        width: "100%",
        maxWidth: 1000,
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        transform: "translate(-50%, -50%)"
    }
};

function ContentsModal({ featuredNews, isOpenModal, closeContentModal }) {
    const selectedFeaturedNews = !isEmpty(featuredNews) ? featuredNews[0] : {};
    const contents = !isEmpty(selectedFeaturedNews) ? selectedFeaturedNews.contents : [];

    const ContentList = useCallback(() => {
        if (isEmpty(selectedFeaturedNews)) {
            return <div>선택된 추천뉴스가 존재하지 않습니다.</div>;
        }

        const { content_ids } = selectedFeaturedNews;

        return content_ids.map((id, index) => {
            const selectedContent = contents.filter((item) => item.id === id);
            const content = !isEmpty(selectedContent) ? selectedContent[0] : {};

            if (isEmpty(content)) {
                return (
                    <tr key={`fn-content-${id}`}>
                        <td className="uk-text-middle uk-text-center">
                            <span className="uk-badge">{index + 1}</span>
                        </td>
                        <td colSpan="2">
                            <div className="uk-text-center uk-text-danger">
                                {`[${id}] 해당 콘텐츠는 현재 존재하지 않습니다.`}
                            </div>
                        </td>
                    </tr>
                );
            }
            return (
                <tr key={`fn-content-${content.id}`}>
                    <td className="uk-text-middle uk-text-center"><span className="uk-badge">{index + 1}</span></td>
                    <td>
                        <div>
                            {`[${content.id}] ${content.title}`}
                        </div>
                        <div className="uk-text-meta">
                            {content.provider_name}
                        </div>
                    </td>
                    <td className="uk-text-middle uk-text-center">{content.published_at}</td>
                </tr>
            );
        });
    }, [selectedFeaturedNews]);

    return (
        <Modal
            isOpen={isOpenModal}
            onRequestClose={closeContentModal}
            style={ContentModalStyles}
        >
            <div className="uk-modal-header">
                <h2 className="uk-modal-title">콘텐츠 목록 ({selectedFeaturedNews.date})</h2>
            </div>
            <div className="custom-modal-body" data-uk-overflow-auto>
                <table className="uk-table uk-table-divider">
                    <thead>
                        <tr>
                            <th className="uk-width-small uk-text-center">순서</th>
                            <th className="uk-width-auto uk-text-center">제목 / 매체명</th>
                            <th className="uk-width-small uk-text-center">등록일</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ContentList()}
                    </tbody>
                </table>
            </div>
            <div className="uk-modal-footer uk-text-right">
                <button
                    type="button"
                    className="uk-button uk-button-default"
                    onClick={closeContentModal}
                >
                    닫기
                </button>
            </div>
        </Modal>
    );
}

export default memo(ContentsModal);
