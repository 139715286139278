import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class MenuBox extends Component{
    render(){
        return(
            <div className="menuBox">
                <NavLink to="/brand/totalInfo" className="item" activeClassName="active">브랜드</NavLink>
                <NavLink to="/vehicleDB/modelGroup" className="item" activeClassName="active">차량DB</NavLink>
                <NavLink to="/contents/media" className="item" activeClassName="active">콘텐츠</NavLink>
                <NavLink to="/operation/commonAdv" className="item" activeClassName="active">운영</NavLink>
            </div>
        );
    }
}

export default MenuBox