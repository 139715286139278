import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class MenuFooter extends Component {

  componentDidMount() {
  }

  render() {
    const { changeLoginState, loginState } = this.props;
    return (
      <div className="menuFooter floatR">
        <p>{loginState ? null : '로그인을 해주세요'}</p>
        <p>{window.localStorage.getItem('username')}님으로 로그인됨</p>
        <NavLink
          to="/login"
          className="item"
          activeClassName="active"
          onClick={changeLoginState}
        >
          {loginState ? '로그아웃' : '로그인'}
        </NavLink>
      </div>
    );
  }
}

export default MenuFooter;
