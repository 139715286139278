import React, {useEffect, useState, useMemo} from 'react';
import {isEmpty} from 'lodash';

const PriorityFormValidator = ({valueList, readyToSaveHandler, minimumQuantity, isForCarModel}) => {
    const compacted = valueList.filter(object => !isEmpty(object));
    const [readyToSave, setReadyToSave] = useState(false);
    const [totalFulfilled, setTotaltotalFulfilled] = useState(false);
    const [domesticFulfilled, setDomesticFulfilled] = useState(isForCarModel ? false : true);
    const [importedFulfilled, setImportedFulfilled] = useState(isForCarModel ? false : true);

    const totalCount = useMemo(() => compacted.length, [valueList]);
    const domesticCount = useMemo(() => compacted.filter(object => !object.isImported).length, [valueList]);
    const importedCount = useMemo(() => compacted.filter(object => object.isImported).length, [valueList]);
    const counterBtnClassName = useMemo(() => `uk-button uk-button-${totalCount >= minimumQuantity.total ? 'primary' : 'default'}`, [totalCount]);


    useEffect(() => {
        setTotaltotalFulfilled((totalCount >= minimumQuantity.total));
        if (isForCarModel) {
            setDomesticFulfilled((domesticCount >= minimumQuantity.domestic));
            setImportedFulfilled(importedCount >= minimumQuantity.imported);
        }
    }, [valueList]);

    useEffect(() => {
        if (isForCarModel) {
            setReadyToSave(totalFulfilled && domesticFulfilled && importedFulfilled);
        } else {
            setReadyToSave(totalFulfilled);
        }
    }, [totalFulfilled, domesticFulfilled, importedFulfilled]);

    useEffect(() => {
        readyToSaveHandler(readyToSave);
    }, [readyToSave]);

    return (
        <>
            <div className="uk-button-group">
                <button className={counterBtnClassName} disabled={!totalFulfilled}>
                    최소등록수량 {totalCount} / {minimumQuantity.total}
                </button>
                {isForCarModel &&
                <button className={counterBtnClassName} disabled={!domesticFulfilled}>
                    최소등록수량-국내 {domesticCount} / {minimumQuantity.domestic}
                </button>}
                {isForCarModel &&
                <button className={counterBtnClassName} disabled={!importedFulfilled}>
                    최소등록수량-수입 {importedCount} / {minimumQuantity.imported}
                </button>}
            </div>
        </>
    );
};

export default PriorityFormValidator;
