import { devUrl } from '../../serviceComm/variable';

// 매체 리스트 Axios 요청
export function getMediaList(authToken) {
  const axios = require('axios');
  const config = {
    method: 'get',
    url: `${devUrl}/admin/cms/providers`,
    headers: {
      //   "x-api-key": "b2cb6699e9c4e10cf36a5f5451309069",
      Authorization: `Bearer ${authToken}`
    }
  };

  return axios(config);
}

// 컨텐츠 리스트 요청
export function getContentList(authToken, per, page) {
  const axios = require('axios');
  const config = {
    method: 'get',
    url:
      `${devUrl}/admin/cms/contents?per=${per}&page=${page}`,
    headers: {
      Authorization: `Bearer ${authToken}`
    }
  };

  return axios(config);
}

// 컨텐츠 리스트 카운트를 위한 요청
export function getContentListCount(authToken, per, total_pages) {
  const axios = require('axios');
  const totalPer = per * total_pages;
  const config = {
    method: 'get',
    url: `${devUrl}/admin/cms/contents`,
    headers: {
      Authorization: `Bearer ${authToken}`
    },
    params: {
      per: totalPer === 0 ? 1 : totalPer
    }
  };

  return axios(config);
}

// 검색된 컨텐츠 리스트 카운트를 위한 요청
export function getSearchContentListCount(authToken, per, total_pages, formData) {
  const axios = require('axios');
  const totalPer = per * total_pages;
  const config = {
    method: 'get',
    url: `${devUrl}/admin/cms/contents`,
    headers: {
      Authorization: `Bearer ${authToken}`
    },
    params: {
      provider_id:
        Number(formData.get('provider_id')) === 0
          ? null
          : formData.get('provider_id'),
      title:
        String(formData.get('title')) === '' ? null : formData.get('title'),
      category:
        String(formData.get('category')) === 'ALL'
          ? null
          : formData.get('category'),
      featured_news_only:
        String(formData.get('featured_news_only')) === 'ALL'
          ? null
          : formData.get('featured_news_only'),
      is_active:
        String(formData.get('is_active')) === 'ALL'
          ? null
          : formData.get('is_active'),
      start_of_published_at: formData.get('start_of_published_at'),
      end_of_published_at: formData.get('end_of_published_at'),
      per: totalPer === 0 ? 1 : totalPer
    }
  };

  return axios(config);
}

// 컨텐츠 리스트 검색 요청
export function getSearchContentList(authToken, formData, per, page) {
  const axios = require('axios');
  const config = {
    method: 'get',
    url: `${devUrl}/admin/cms/contents`,
    headers: {
      Authorization: `Bearer ${authToken}`
    },
    params: {
      provider_id:
        Number(formData.get('provider_id')) === 0
          ? null
          : formData.get('provider_id'),
      title:
        String(formData.get('title')) === '' ? null : formData.get('title'),
      category:
        String(formData.get('category')) === 'ALL'
          ? null
          : formData.get('category'),
      featured_news_only:
        String(formData.get('featured_news_only')) === 'ALL'
          ? null
          : formData.get('featured_news_only'),
      is_active:
        String(formData.get('is_active')) === 'ALL'
          ? null
          : formData.get('is_active'),
      start_of_published_at: formData.get('start_of_published_at'),
      end_of_published_at: formData.get('end_of_published_at'),
      per,
      page
    }
  };

  return axios(config);
}

// 컨텐츠 삭제
export function deleteContents(authToken, formData) {
  const axios = require('axios');
  const config = {
    method: 'delete',
    url: `${devUrl}/admin/cms/contents`,
    headers: {
      Authorization: `Bearer ${authToken}`
    },
    data: formData
  };

  return axios(config);
}

// 추천 뉴스 리스트 요청
export function getFeaturedNewsList(authToken) {
  const axios = require('axios');
  const config = {
    method: 'get',
    url: `${devUrl}/admin/cms/contents/featured`,
    headers: {
      Authorization: `Bearer ${authToken}`
    }
  };

  return axios(config);
}

// 추천 뉴스 순서 변경
export function putFeaturedNewsList(authToken, formData) {
  const axios = require('axios');
  const config = {
    method: 'put',
    url: `${devUrl}/admin/cms/contents/featured`,
    headers: {
      Authorization: `Bearer ${authToken}`
    },
    data: formData
  };

  return axios(config);
}
