import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import * as Common from "../../component/services/common/CommonService";
import * as Service from "../../component/services/brand/BrandService";
import DatePicker from 'react-datepicker'

class TotalInfoDetail extends Component{
    constructor(props){
      super(props)
      this.state = {
        authToken : Common.getToken.call(this),
        isEdit : false,
        editId : 0,
        startDate: new Date(),
        page_history: this.props.location.search.replace(/\?page=/g, '') ? this.props.location.search.replace(/\?page=/g, '') : 1
      }
    }

    componentDidMount(){
      const id = this.props.location.pathname.split('/')
      if(!isNaN(parseInt(id[id.length-1]))){
        this.setState({
          isEdit : true,
          editId : parseInt(id[id.length-1])
        },() => this.getDetail())
      }
      else {
        document.getElementById('orderBy').disabled = true
      }
    }

    handleChange = date => {
      this.setState({
        startDate: date
      });
    };
    
    getDetail = () => {
      const brandDetail = Service.getBrandDetail(
        this.state.authToken,
        this.state.editId
      )

      brandDetail.then(res => {
        const data = res.data.data
        document.getElementById('nation').value = data.nation
        document.getElementById('name').value = data.name
        document.getElementById('address').value = data.address
        document.getElementById('parentName').value = data.parent_name
        document.getElementById('seller').value = data.seller
        document.getElementById('founder').value= data.founder
        document.getElementById('slogan').value= data.slogan
        document.getElementById('businessType').value= data.business_type
        document.getElementById('description').value= data.description
        document.getElementById('orderBy').value= data.order_id
        document.getElementById('isActive').value= data.is_active
        document.getElementById('isImported').value= data.is_imported
        document.getElementById('officialUrl').value= data.official_site_url
        document.getElementById('showroomUrl').value= data.showroom_url
        document.getElementById('carcenterUrl').value= data.car_center_url
        document.getElementById('warrantyUrl').value= data.warranty_url
        document.getElementsByClassName('file_input')[0].value = data.logo
        this.setState({
          startDate : new Date(data.start_at)
        })
      })
    }

    onClick(){

      if(document.getElementById('name').value == ""){
        alert('브랜드명을 입력해주세요')
        return false;
      }
      if(document.getElementsByClassName('react-datepicker-ignore-onclickoutside').value == ""){
        alert('창립일을 입력해주세요')
        return false;
      }
      if(document.getElementById('officialUrl').value == ""){
        alert('공식사이트 링크를 입력해주세요')
        return false;
      }
      if(document.getElementById('made_logo').files[0] == undefined){
        if(!this.state.isEdit){
          alert('제조사 로고를 업로드해주세요')
          return false;
        }
      }
      if(document.getElementById('description').value == ""){
        alert('브랜드 설명을 입력해주세요')
        return false;
      }
      const saveBrand = this.state.isEdit ? 
      Service.editBrand(
        this.state.editId,
        this.state.authToken,
        document.getElementById('nation').value,
        document.getElementById('name').value,
        document.getElementById('address').value,
        document.getElementById('parentName').value,
        document.getElementById('seller').value,
        document.querySelector('.react-datepicker-wrapper input').value,
        document.getElementById('founder').value,
        document.getElementById('slogan').value,
        document.getElementById('businessType').value,
        document.getElementById('description').value,
        document.getElementById('orderBy').value,
        document.getElementById('isActive').value,
        document.getElementById('isImported').value,
        document.getElementById('officialUrl').value,
        document.getElementById('showroomUrl').value,
        document.getElementById('carcenterUrl').value,
        document.getElementById('warrantyUrl').value,
        document.getElementById('made_logo').files[0] ? document.getElementById('made_logo').files[0] : 'none',
      )
      :
      Service.postBrand(
        this.state.authToken,
        document.getElementById('nation').value,
        document.getElementById('name').value,
        document.getElementById('address').value,
        document.getElementById('parentName').value,
        document.getElementById('seller').value,
        document.querySelector('.react-datepicker-wrapper input').value,
        document.getElementById('founder').value,
        document.getElementById('slogan').value,
        document.getElementById('businessType').value,
        document.getElementById('description').value,
        document.getElementById('orderBy').value,
        document.getElementById('isActive').value,
        document.getElementById('isImported').value,
        document.getElementById('officialUrl').value,
        document.getElementById('showroomUrl').value,
        document.getElementById('carcenterUrl').value,
        document.getElementById('warrantyUrl').value,
        document.getElementById('made_logo').files[0],
      )

      saveBrand.then((res)=>{
        alert('저장이 되었습니다.')
        this.props.history.push('/brand/totalInfo')
      })
  }
    render(){
        const { page_history } = this.state;
        return(
            <Fragment>
                {/* //종합정보 추가.수정페이지 시작 // */}
                <div className="title disi wd100"><p className="mb10"><span>홈</span> 〉 <span>브랜드</span> 〉 <span>종합정보</span> 〉 <span>추가/수정</span></p>종합정보 추가/수정 
                    <div className="floatR disi">
                                <button type="submit" className="btnLine navyBg" onClick={() =>  this.onClick() }>저장</button>
                        <button type="button" className="btn navyBg ml10" onClick={() => this.props.history.goBack()}>목록</button>
                    </div>
                </div>
                <div className="form_tablewrap">
                        <table className="mt20">
                            <colgroup>
                                <col width="20%"></col>
                                <col width="*"></col>
                                <col width="20%"></col>
                                <col width="*"></col>
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>브랜드명 <span className="redT">*</span></th>
                                    <td colSpan="3"><input id="name" className="wd100" type="text"></input></td>
                                </tr>
                                <tr>
                                    <th>활성화 상태 <span className="redT">*</span></th>
                                    <td>
                                        <select id="isActive">
                                            <option value={true}>활성화</option>
                                            <option value={false}>비활성화</option>
                                        </select>
                                    </td>
                                    <th>노출순서 <span className="redT">*</span></th>
                                    <td><input id="orderBy" type="text" defaultValue="0"></input></td>
                                </tr>
                                <tr>
                                    <th>소재지</th>
                                    <td><input id="address" type="text"></input></td>
                                    <th>모기업</th>
                                    <td><input id="parentName" type="text"></input></td>
                                </tr>
                                <tr>
                                    <th>핀매사</th>
                                    <td><input id="seller" type="text"></input></td>
                                    <th>창립일<span className="redT">*</span></th>
                                    <td><DatePicker
                                          selected={this.state.startDate}
                                          onChange={this.handleChange}
                                          dateFormat="yyyy-MM-dd"
                                          maxDate={new Date()}
                                        /></td>
                                </tr>
                                <tr>
                                    <th>창립자</th>
                                    <td><input id="founder" type="text"></input></td>
                                    <th>슬로건</th>
                                    <td><input id="slogan" type="text"></input></td>
                                </tr>
                                <tr>
                                    <th>대표업종</th>
                                    <td><input id="businessType" type="text"></input></td>
                                    <th>국가 <span className="redT">*</span></th>
                                    <td>
                                        <select id="nation">
                                            <option>국산</option>
                                            <option>유럽</option>
                                            <option>일본/중국</option>
                                            <option>미국</option>
                                        </select>    
                                    </td>
                                </tr>
                                <tr>
                                    <th>수입여부 <span className="redT">*</span></th>
                                    <td>
                                        <select id="isImported">
                                            <option value={false}>국산</option>
                                            <option value={true}>수입</option>
                                        </select>    
                                    </td>
                                    <th>제조사 로고 <span className="redT">*</span></th>
                                    <td className="file_wrap">
                                        <input type="file" accept="image/*" id="made_logo" onChange={e => {
                                          document.getElementsByClassName('file_input')[0].value = e.target.files[0].name
                                        }}></input>
                                        <input type="text" readOnly  className="file_input"></input>
                                        <label htmlFor="made_logo" className="file_btn">업로드</label>
                                    </td>
                                </tr>
                                <tr>
                                    <th>공식사이트 링크 <span className="redT">*</span></th>
                                    <td><input id="officialUrl" type="text"></input></td>
                                    <th>전시장 안내 링크</th>
                                    <td><input id="showroomUrl" type="text"></input></td>
                                </tr>
                                <tr>
                                    <th>활성화센터 링크</th>
                                    <td><input id="carcenterUrl" type="text"></input></td>
                                    <th>보증안내 링크</th>
                                    <td><input id="warrantyUrl" type="text"></input></td>
                                </tr>
                                <tr>
                                    <th>브랜드 설명 <span className="redT">*</span></th>
                                    <td colSpan="3"><textarea id="description"></textarea></td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="rightT mt20">
                            <button type="submit" className="btnLine navyBg"  onClick={() =>  this.onClick() }>저장</button>
                            <button type="button" className="btn navyBg ml10" onClick={() => this.props.history.goBack()}>목록</button>
                        </div>
                </div>
                {/* // 종합정보 추가/수정 영역 끝 // */}
            </Fragment>
        );
    }
}

export default TotalInfoDetail;