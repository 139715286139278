import axios from "axios";
import { devUrl } from "../../serviceComm/variable";

export function getFeaturedNewsList({ authToken, start_at, end_at }) {
    const config = {
        url: `${devUrl}/admin/cms/featured_news`,
        method: "GET",
        headers: {
            Authorization: `Bearer ${authToken}`
        },
        params: {
            start_at,
            end_at,
            with_contents: true
        }
    };

    return axios(config);
}

export function postFeaturedNews({ authToken, data }) {
    const config = {
        url: `${devUrl}/admin/cms/featured_news`,
        method: "POST",
        headers: {
            Authorization: `Bearer ${authToken}`
        },
        data
    };

    return axios(config);
}

export function getFeaturedNews({ authToken, id }) {
    const config = {
        url: `${devUrl}/admin/cms/featured_news/${id}`,
        method: "GET",
        headers: {
            Authorization: `Bearer ${authToken}`
        },
        params: {
            with_contents: true
        }
    };

    return axios(config);
}

export function putFeaturedNews({ authToken, id, data }) {
    const config = {
        url: `${devUrl}/admin/cms/featured_news/${id}`,
        method: "PUT",
        headers: {
            Authorization: `Bearer ${authToken}`
        },
        data
    };

    return axios(config);
}

export function deleteFeaturedNews({ authToken, id }) {
    const config = {
        url: `${devUrl}/admin/cms/featured_news/${id}`,
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${authToken}`
        }
    };

    return axios(config);
}

export function postPublishFeaturedNews({ authToken, date }) {
    const config = {
        url: `${devUrl}/admin/cms/featured_news/publish`,
        method: "POST",
        headers: {
            Authorization: `Bearer ${authToken}`
        },
        data: {
            date
        }
    };

    return axios(config);
}
