import React from 'react';
import '../../../UIKitMonkeyPatch.scss'
import './TrimForm.scss'
import {isEmpty} from "lodash";
import {useTrimState, useTrimDispatch} from "./TrimProvider";

const SalesConditionsModal = ({title, index, isEdit}) => {

  const salesConditions = useTrimState()[index].sales_conditions;
  const trimDispatch = useTrimDispatch();

  const salesConditionHandler = (value, inputName, salesConditionIndex) => trimDispatch({
    type: 'CHANGE_SALES_CONDITION_INPUT',
    payload: {value, inputName, trimIndex: index, salesConditionIndex}
  });

  const addSalesCondition = salesConditionIndex => trimDispatch({
    type: 'ADD_SALES_CONDITION',
    payload: {trimIndex: index, salesConditionIndex}
  });

  const deleteSalesCondition = salesConditionIndex => trimDispatch({
    type: 'DELETE_SALES_CONDITION',
    payload: {trimIndex: index, salesConditionIndex}
  });

  const scItemHandler = (value, inputName, salesConditionIndex, scItemIndex) => trimDispatch({
    type: 'CHANGE_SALES_CONDITION_ITEM_INPUT',
    payload: {value, inputName, trimIndex: index, salesConditionIndex, scItemIndex}
  });

  const addScItem = (salesConditionIndex, scItemIndex) => trimDispatch({
    type: 'ADD_SALES_CONDITION_ITEM',
    payload: {trimIndex: index, salesConditionIndex, scItemIndex}
  });

  const deleteScItem = (salesConditionIndex, scItemIndex) => trimDispatch({
    type: 'DELETE_SALES_CONDITION_ITEM',
    payload: {trimIndex: index, salesConditionIndex, scItemIndex}
  });

  return (
    <div key={`trim-modal-${title}-${index}`} className='uk-container'>
      <div id={`modal-sc-${index}`} data-uk-modal>
        <div className="uk-modal-dialog">
          <button className="uk-modal-close-default" type="button" data-uk-close/>
          <div className="uk-modal-header">
            <h4>{title}</h4>
          </div>
          <div className="uk-modal-body" data-uk-overflow-auto>
            {isEmpty(salesConditions) && <p>등록된 가격옵션이 삭제됩니다.</p>}
            {
              !isEmpty(salesConditions) && salesConditions.map((salesCondition, key) => (
                  <div className="uk-card uk-card-default uk-margin" key={key}>
                    <div className='uk-card-body'>
                      <div className='uk-grid uk-grid-column-small uk-grid-row-small'>
                        <div className="uk-width-1-1 uk-margin-bottom">
                          <label>조건명</label>
                          <input className='uk-input' type='text' value={salesCondition.name || ''}
                                 onChange={e => salesConditionHandler(e.target.value, 'name', key)}/>
                        </div>
                        <div className="uk-width-1-1 uk-margin-bottom">
                          <label>설명</label>
                          <textarea className='uk-textarea' value={salesCondition.description || ''}
                                    onChange={e => salesConditionHandler(e.target.value, 'description', key)}/>
                        </div>

                        {salesCondition.items.map((scItem, scItemKey) => (
                          <div className='uk-grid uk-grid-small uk-margin-small-left' key={scItemKey}>
                            <div className='uk-grid uk-grid-small uk-width-expand'>
                              <div className="uk-width-1-2">
                                <label>세부조건명</label>
                                <input className='uk-input' value={scItem.name || ''}
                                       onChange={e => scItemHandler(e.target.value, 'name', key, scItemKey)}/>
                              </div>
                              <div className="uk-width-1-4">
                                <label>할인금액</label>
                                <input className='uk-input' type='number' value={scItem.price || ''}
                                       onChange={e => scItemHandler(e.target.value, 'price', key, scItemKey)}/>
                              </div>
                              <div className="uk-width-1-4">
                                <label>할인율</label>
                                <input className='uk-input' type='number' step='0.1' value={scItem.price_rate || ''}
                                       onChange={e => scItemHandler(e.target.value, 'price_rate', key, scItemKey)}/>
                              </div>
                            </div>
                            <div className="uk-width-auto uk-flex uk-flex-bottom">
                              <button className="uk-button uk-button-primary" style={{padding: "0 12px"}}
                                      onClick={() => addScItem(key, scItemKey)}>+
                              </button>
                              <button className="uk-button uk-button-default" style={{padding: "0 12px"}}
                                      onClick={() => deleteScItem(key, scItemKey)}>-
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className='uk-card-footer'>
                      <div className="uk-button-group uk-position-bottom-right add-delete-button">
                        <button className="uk-button uk-button-primary" onClick={() => addSalesCondition(key)}>+</button>
                        <button className="uk-button uk-button-default" disabled={!isEdit && salesConditions.length === 1}
                                onClick={() => deleteSalesCondition(key)}>-
                        </button>
                      </div>
                    </div>
                  </div>
                )
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
};

export default SalesConditionsModal
