import React from 'react';
import '../../../UIKitMonkeyPatch.scss'
import './TrimForm.scss'
import {isEmpty} from 'lodash'
import {useTrimState, useTrimDispatch} from "./TrimProvider";

const PriceOptionsModal = ({title, index, isEdit}) => {
  const priceOptions = useTrimState()[index].price_options;
  const trimDispatch = useTrimDispatch();

  const priceOptionHandler = (value, inputName, priceOptionIndex) => trimDispatch({
    type: 'CHANGE_PRICE_OPTION_INPUT',
    payload: {value, inputName, trimIndex: index, priceOptionIndex}
  });

  const addPriceOption = priceOptionIndex => trimDispatch({
    type: 'ADD_PRICE_OPTION',
    payload: {trimIndex: index, priceOptionIndex}
  });

  const deletePriceOption = priceOptionIndex => trimDispatch({
    type: 'DELETE_PRICE_OPTION',
    payload: {trimIndex: index, priceOptionIndex}
  });

  return (
    <div key={`trim-modal-${title}-${index}`} className='uk-container'>
      <div id={`modal-po-${index}`} data-uk-modal>
        <div className="uk-modal-dialog">
          <button className="uk-modal-close-default" type="button" data-uk-close/>
          <div className="uk-modal-header">
            <h4>{title}</h4>
          </div>
          <div className="uk-modal-body" data-uk-overflow-auto>
            {isEmpty(priceOptions) && <p>등록된 가격옵션이 삭제됩니다.</p>}
            {
              !isEmpty(priceOptions) && priceOptions.map((priceOption, key) => {
                return (
                  <div className="uk-card uk-card-default uk-margin" key={key}>
                    <div className='uk-card-body'>
                      <div className='uk-grid uk-grid-column-small uk-grid-row-small'>
                        <div className="uk-width-1-1 uk-margin-bottom">
                          <label>옵션명</label>
                          <input className='uk-input' type='text' value={priceOption.name || ''}
                                 onChange={e => priceOptionHandler(e.target.value, 'name', key)}/>
                        </div>
                        <div className="uk-width-1-1 uk-margin-bottom">
                          <label>가격</label>
                          <input className='uk-input' type='number' value={priceOption.price || ''}
                                 onChange={e => priceOptionHandler(e.target.value, 'price', key)}/>
                        </div>
                        <div className="uk-width-1-1 uk-margin-bottom">
                          <label>설명</label>
                          <textarea className='uk-textarea' value={priceOption.description || ''}
                                    onChange={e => priceOptionHandler(e.target.value, 'description', key)}/>
                        </div>
                      </div>

                    </div>
                    <div className='uk-card-footer'>
                      <div className="uk-button-group uk-position-bottom-right add-delete-button">
                        <button className="uk-button uk-button-primary" onClick={() => addPriceOption(key)}>+</button>
                        <button className="uk-button uk-button-default" disabled={!isEdit && priceOptions.length === 1}
                                onClick={() => deletePriceOption(key)}>-
                        </button>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
};

export default PriceOptionsModal
