import { devUrl, keyNumber } from '../../serviceComm/variable';
import Moment from 'moment'

export const getLineUpList = (authToken , per ,  page , bo , mgo , mo , no , ao ,co ,uo ,id ,cmg , cm , name , isActive) => {
  const axios = require('axios')
  const config = {
    url : devUrl + '/admin/car_db/lineups'+
    "?per=" + per +
    "&page=" + page + "&id_desc=true" +
    `${bo == 'desc' ? "&brand_desc=true" : bo == 'asc' ? "&brand_asc=true" : ""}`+
    `${mgo == 'desc' ? "&car_model_group_desc=true" : mgo == 'asc' ? "&car_model_group_asc=true" : ""}`+
    `${mo == 'desc' ? "&car_model_desc=true" : mo == 'asc' ? "&car_model_asc=true" : ""}`+
    `${no == 'desc' ? "&name_desc=true" : no == 'asc' ? "&name_asc=true" : ""}`+
    `${ao == 'desc' ? "&active_desc=true" : ao == 'asc' ? "&active_asc=true" : ""}`+
    `${co == 'desc' ? "&created_desc=true" : co == 'asc' ? "&created_asc=true" : ""}`+
    `${uo == 'desc' ? "&updated_desc=true" : uo == 'asc' ? "&updated_asc=true" : ""}`+
    `${id == 0 ? "" : `&brand_id=${id}`}`+
    `${cmg == 0 ? "" :  "&car_model_group_id="+cmg}`+
    `${cm == 0 ? "" :  "&car_model_id="+cm}`+
    `${name == 0 ? ""  : "&id="+name}` +
    `${isActive == 'all' ? ""  : "&is_active="+isActive}`
    ,
    method : 'get',
    headers: {
      "x-api-key": keyNumber,
      Authorization: "Bearer " + authToken
    }
  }

  return axios(config)
}

export const getLineUpDetail = (authToken , id ) => {
  const axios = require('axios')
  const config = {
    url : devUrl + '/admin/car_db/lineups/'+id,
    method : 'get',
    headers: {
      "x-api-key": keyNumber,
      Authorization: "Bearer " + authToken
    }
  }

  return axios(config)
}

export const updateLineUp = (authToken , id , data) => {
  const axios = require('axios')
  let form = {}
  form.name = data.name
  form.is_primary = data.primary
  form.is_onsale = data.sale
  form.is_active = data.active
  form.year = data.year
  form.published_at = Moment(data.date).format('YYYY-MM-DD')
  form.mileage = data.mileage
  form.displacement = parseInt(data.displacement)
  form.fuel_type = data.fuelType
  const config = {
    url : devUrl + '/admin/car_db/lineups/'+id,
    method : 'put',
    headers: {
      "x-api-key": keyNumber,
      Authorization: "Bearer " + authToken
    },
    data : form
  }

  return axios(config)
}

export const postLineUp = (authToken , brand_id , group_id , model_id , form) => {
  const axios = require('axios')
  let jsonData = {}
  jsonData.name = form.name
  jsonData.fuel_type = typeof(form.fuelType) !== 'string' ? form.fuelType.value : form.fuelType
  jsonData.mileage = parseInt(form.mileage)
  jsonData.displacement = parseInt(form.displacement)
  jsonData.car_model_id = model_id.value
  jsonData.is_primary = form.primary
  jsonData.is_onsale = form.sale
  jsonData.is_active = form.active
  jsonData.year = form.year
  jsonData.published_at = Moment(form.date).format('YYYY-MM-DD')
  jsonData.lineup_sales_conditions = []
  let index = 1;
  for(var x = 0; x<form.conditions.length; x++){
    if(form.conditions[x].status == "done"){
      if(form.conditions[x].type == "main"){
        let pushData = {
          sales_condition_id : index++,
          year : parseInt(form.conditions[x].year),
          month : parseInt(form.conditions[x].month),
          name : form.conditions[x].name,
          description : form.conditions[x].description
        }
        if(form.conditions[x].price !== ""){
          pushData.price = form.conditions[x].price
        }else{
          pushData.price_rate = form.conditions[x].percentage
        }
        jsonData.lineup_sales_conditions.push(pushData)
      }
      else{
        let pushData = {
          sales_condition_id : index++,
          year :  parseInt(form.conditions[x].year),
          month : parseInt(form.conditions[x].month),
          name : form.conditions[x].name,
          description : form.conditions[x].description
        }
        if(form.conditions[x].child.length == 0){
          if(form.conditions[x].price !== ""){
            pushData.price = parseInt(form.conditions[x].price)
          }else{
            pushData.price_rate = parseInt(form.conditions[x].percentage)
          }
        }else{
          pushData.items = []
          for(var y = 0; y<form.conditions[x].child.length; y++){
            if(form.conditions[x].child[y].type == "done"){
              let pushChild = {
                name : form.conditions[x].child[y].childName
              }
              if(form.conditions[x].child[y].childPrice !== ""){
                pushChild.price = parseInt(form.conditions[x].child[y].childPrice)
              }else {
                pushChild.price_rate = parseInt(form.conditions[x].child[y].childPercentage)
              }
              pushData.items.push(pushChild)
            }
          }
        }
        jsonData.lineup_sales_conditions.push(pushData)
      }
    }
  }
  const config = {
    url : devUrl + '/admin/car_db/lineups',
    method : 'POST',
    headers: {
      "x-api-key": keyNumber,
      Authorization: "Bearer " + authToken
    },
    data : jsonData
  }

  return axios(config)
}

export const getLineUpConditions = (authToken , id , year , month) => {
  const axios = require('axios')
  const config = {
    url : devUrl + '/admin/car_db/lineups/'+id+'/lineup_sales_conditions?year='+year+"&month="+month,
    method : 'GET',
    headers: {
      "x-api-key": keyNumber,
      Authorization: "Bearer " + authToken
    },
  }

  return axios(config)
} 

export const deleteLineUp = (authToken , ids) => {
  const axios = require('axios')
  let data = {
    ids : ids
  }
  const config = {
    url : devUrl + '/admin/car_db/lineups',
    method : 'DELETE',
    headers: {
      "x-api-key": keyNumber,
      Authorization: "Bearer " + authToken
    },
    data : data
  }

  return axios(config);
}

export const putLineUpSalesCondition = (data) => {
  const axios = require('axios');
  const resultData = getPutParameter(data);
  const config = {
    url: `${devUrl}/admin/car_db/lineups/${data.lineup_id}/lineup_sales_conditions/${data.id}`,
    method: 'put',
    headers: {
      "x-api-key": keyNumber,
      Authorization: `Bearer ${data.authToken}`
    },
    data: resultData
  };

  return axios(config);
};

export const deleteLienUpSalesCondition = (data) => {
  const axios = require('axios');
  const config = {
    url: `${devUrl}/admin/car_db/lineups/${data.lineup_id}/lineup_sales_conditions/${data.id}`,
    method: 'delete',
    headers: {
      "x-api-key": keyNumber,
      Authorization: `Bearer ${data.authToken}`
    }
  };

  return axios(config);
};

export const postLienUpSalesCondition = (data) => {
  const axios = require('axios');
  const resultData = getPostParameter(data);

  const config = {
    url: `${devUrl}/admin/car_db/lineups/${data.lineup_id}/lineup_sales_conditions`,
    method: 'post',
    headers: {
      "x-api-key": keyNumber,
      Authorization: `Bearer ${data.authToken}`
    },
    data: resultData
  };

  return axios(config);
};

const getPostParameter = (data) => {
  let resultData = {};
  const hasItem = data.condition.items && data.condition.items.length !== 0;
  const hasPrice = data.condition.price && true;
  const hasPriceRate = data.condition.price_rate && true;

  if(hasItem) {
    resultData = {
      year: data.condition.year,
      month: data.condition.month,
      name: data.condition.name,
      description: data.condition.description,
      items: data.condition.items.map((item) => {
        if(!item.price || item.price === '') {
          return {
            id: item.id,
            name: item.name,
            price_rate: item.price_rate
          }
        } else {
          return {
            id: item.id,
            name: item.name,
            price: item.price
          }
        }
      })
    };
  } else if(hasPrice) {
    resultData = {
      year: data.condition.year,
      month: data.condition.month,
      name: data.condition.name,
      price: data.condition.price,
      description: data.condition.description
    };
  } else if(hasPriceRate) {
    resultData = {
      year: data.condition.year,
      month: data.condition.month,
      name: data.condition.name,
      price_rate: data.condition.price_rate,
      description: data.condition.description
    };
  }

  return resultData;
};

const getPutParameter = (data) => {
  let resultData = {};
  const hasPrice = data.price && true;
  const hasPriceRate = data.price_rate && true;
  const hasItems = (data.items && data.items.length !== 0) && true
  if(hasPrice && !hasItems) {
    resultData = {
      sales_condition_id: data.sales_condition_id,
      year: data.year,
      month: data.month,
      name: data.name,
      description: data.description,
      price: data.price,
      items: !data.items ? new Array() : data.items
    };
  } else if(hasPriceRate && !hasItems) {
    resultData = {
      sales_condition_id: data.sales_condition_id,
      year: data.year,
      month: data.month,
      name: data.name,
      description: data.description,
      price_rate: data.price_rate,
      items: !data.items ? new Array() : data.items
    };
  } else {
    resultData = {
      sales_condition_id: data.sales_condition_id,
      year: data.year,
      month: data.month,
      name: data.name,
      description: data.description,
      price: null,
      price_rate: null,
      items: (!data.items || data.items.length === 0) ? new Array() : data.items.map((item) => {
        if(!item.price || item.price === '') {
          return {
            id: item.id,
            name: item.name,
            price_rate: item.price_rate
          }
        } else {
          return {
            id: item.id,
            name: item.name,
            price: item.price
          }
        }
      })
    };
  }

  return resultData;
};

export const getLineupTrims = (authToken, lineupId) => {
  const axios = require('axios');
  const config = {
    url: `${devUrl}/admin/car_db/lineups/${lineupId}/trims`,
    method: "get",
    headers: {
      "x-api-key": keyNumber,
      Authorization: `Bearer ${authToken}`
    },
    params: {
      is_active: true
    }
  };

  return axios(config);
};

export const putLineupTrimsOrder = (authToken, lineupId, order_trim_ids) => {
  const axios = require('axios');
  const config = {
    url: `${devUrl}/admin/car_db/lineups/${lineupId}/trims/order`,
    method: "put",
    headers: {
      "x-api-key": keyNumber,
      Authorization: `Bearer ${authToken}`
    },
    data: {
      order_trim_ids
    }
  };

  return axios(config);
};
