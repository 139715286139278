import {devUrl} from '../../serviceComm/variable';
import axios from 'axios';

const baseUrl =  `${devUrl}/admin/priorities`;

export const getLatestPriority = (authToken, resourceName) => {
    return axios({
        method: 'get',
        url: `${baseUrl}/${resourceName}/latest`,
        headers: {Authorization: `Bearer ${authToken}`}
    })
};

export const createPriority = (authToken, data) => {
    return axios({
        method: 'post',
        url: `${baseUrl}`,
        headers: {Authorization: `Bearer ${authToken}`},
        data: data
    })
};
