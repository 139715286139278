import React, { memo } from 'react';

function DatePickerButton({ value, onClick }) {
    return (
        <button
            className="uk-button uk-button-default uk-text-nowrap"
            onClick={onClick}
        >
            {value}
            <span className="uk-margin-small-left" data-uk-icon="icon: calendar" />
        </button>
    );
}

export default memo(DatePickerButton);
