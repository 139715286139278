import React, { memo } from "react";
import moment from "moment";

function PublishIcon({ date, is_published }) {
    let PublishIcon = <span className="uk-margin-small-right uk-icon-color-success" data-uk-icon={`icon: check`} />;
    const currentDate = moment();
    const featuredNewsDate = moment(date);

    const isToday = featuredNewsDate.format("YYYY-MM-DD") === currentDate.format("YYYY-MM-DD");

    if (isToday && (is_published || !is_published)) {
        PublishIcon = <span className="uk-margin-small-right uk-icon-color-danger" data-uk-icon={`icon: clock`} />;
    } else if (featuredNewsDate > currentDate && !is_published) {
        PublishIcon = <span className="uk-margin-small-right uk-icon-color-primary" data-uk-icon={`icon: future`} />;
    }

    return PublishIcon;
}

export default memo(PublishIcon);
