import React, { memo } from "react";
import { isEmpty } from "lodash";
import { Link } from "react-router-dom";

// Child Components
import PublishIcon from "./PublishIcon";
import ManageButton from "./ManageButton";

function ListTable({
    featuredNews,
    handleManageButton,
    handleContentModal
}) {
    return (
        <div className="uk-overflow-auto">
            <table className="uk-table uk-table-divider">
                <thead>
                <tr>
                    <th className="uk-width-small uk-text-nowrap uk-text-center">발행일</th>
                    <th className="uk-width-auto uk-text-center">컨텐츠 목록</th>
                    <th className="uk-width-small uk-text-nowrap uk-text-center">발행여부 / 상태</th>
                    <th className="uk-width-medium uk-text-center"></th>
                </tr>
                </thead>
                <tbody>
                {featuredNews.map((item, index) => (
                    <tr key={`${item.id ? `fn-${item.id}` : `fn-new-${index}`}`}>
                        <td className="uk-text-middle">
                            <div className="uk-text-nowrap uk-text-center">
                                {item.date}
                            </div>
                        </td>
                        {item.is_exist ? (
                            <>
                                <td>
                                    <div className="uk-flex uk-flex-between uk-flex-middle">
                                        <div className="uk-flex-1 uk-text-nowrap uk-text-overflow-ellipsis uk-margin-small-right">
                                            {!isEmpty(item.contents) ? (
                                                `[${item.contents[0].id}] ${item.contents[0].title}`
                                            ) : (
                                                <>
                                                    <span
                                                        className="uk-margin-small-right uk-icon-color-danger"
                                                        data-uk-icon="icon: ban"
                                                    />
                                                    <span className="uk-text-danger">콘텐츠 목록이 존재하지 않습니다.</span>
                                                </>
                                            )}
                                        </div>
                                        <div>
                                            <button
                                                className="uk-button uk-button-default uk-text-nowrap"
                                                data-id={item.id}
                                                onClick={handleContentModal}>
                                                상세보기
                                            </button>
                                        </div>
                                    </div>
                                </td>
                                <td className="uk-text-middle">
                                    <div className="uk-text-center uk-text-middle">
                                        <PublishIcon date={item.date} is_published={item.is_published} />
                                    </div>
                                </td>
                                <td className="uk-text-middle">
                                    <ManageButton
                                        id={item.id}
                                        date={item.date}
                                        is_published={item.is_published}
                                        handleManageButton={handleManageButton}
                                    />
                                </td>
                            </>
                        ) : (
                            <td className="uk-text-middle" colSpan="3">
                                {item.is_create_expired ? (
                                    <div className="uk-text-center">
                                        <span className="uk-margin-small-right" data-uk-icon="icon: lock" />
                                    </div>
                                ) : (
                                    <div className="uk-text-center">
                                        <Link to={`featuredNews/featuredNewsDetail?publish_date=${item.date}`}>
                                            <button className="uk-button uk-button-primary">추천뉴스 발행 예약</button>
                                        </Link>
                                    </div>
                                )}
                            </td>
                        )}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
}

export default memo(ListTable);
