import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Select from 'react-select';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/lib/ReactCrop.scss';

import '../../../node_modules/croppie/croppie.css'

import * as Service from '../../component/services/operation/homePhotoService';
import * as Common from '../../component/services/common/CommonService';
import * as brandService from "../../component/services/brand/BrandService";
import * as modelGroupService from "../../component/services/vehicleDB/ModelGroupService"
import * as modelService from "../../component/services/vehicleDB/ModelService"

class HomePhotoDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            authToken: Common.getToken(this),
            brandId: {label: '전체', value: 0},
            modelGroupId: {label: '전체', value: 0},
            modelId: {label: '전체', value: 0},

            totalInfo: [],
            modelGroup: [{label: '전체', value: 0}],
            model: [{label: '전체', value: 0}],

            title: "",
            imageCnt: 0,
            images: [],
            selectImage: 0,
            crop: {unit: '%', aspect: 16 / 9},
            image_url: "",
            base64Image: "",
            position: 0,
            imageLoad: 'notYet'
        };
    }

    // Select Functions
    selectBrand = async selectOption => {
        await this.setState({
            brandId: selectOption,
            modelGroup: [{label: '전체', value: 0}],
            model: [{label: '전체', value: 0}],
            modelGroupId: {label: '전체', value: 0},
            modelId: {label: '전체', value: 0},
        }, async () => {
            const req = modelGroupService.getModelG(this.state.authToken, this.state.brandId.value)
            await req.then(response => {
                const data = response.data.data
                let options = []
                options.push({label: '전체', value: 0})
                for (var x = 0; x < data.length; x++) {
                    options.push({label: data[x].name, value: data[x].id})
                }
                this.setState({
                    modelGroup: options
                })
            })
        });
    }

    selectModelGroup = async selectOption => {
        await this.setState({
            modelGroupId: selectOption,
            model: [{label: '전체', value: 0}],
            lineUpList: [{label: '전체', value: 0}],
            modelId: {label: '전체', value: 0},
            lineUpId: {label: '전체', value: 0},
        }, () => {
            const req = modelService.getModelList(
                this.state.brandId.value,
                10000,
                1,
                this.state.authToken,
                this.state.modelGroupId.label,
                "", "all", "", "", "", "", "", ""
            )
            req.then(response => {
                const data = response.data.data
                let options = []
                options.push({label: '전체', value: 0})
                for (var x = 0; x < data.length; x++) {
                    if (data[x].is_active === true) {
                        options.push({label: data[x].name, value: data[x].id})
                    }
                }
                this.setState({
                    model: options
                })
            })
        });
    }

    selectModel = async selectOption => {
        await this.setState({
            modelId: selectOption
        })
    }

    getTotalInfo = async () => {
        const brandList = brandService.getTotalInfo(this.state.authToken);
        await brandList.then(response => {
            const data = response.data.data
            let options = []
            options.push({label: '전체', value: 0})
            for (var x = 0; x < data.length; x++) {
                options.push({label: data[x].name, value: data[x].id})
            }
            this.setState({
                totalInfo: options
            });
        });
    }

    getModelImage = async () => {
        if (this.state.modelId.value === 0) {
            alert('모델을 선택해주세요.')
            return
        } else {
            this.setState({})
            await modelService.getCarModelImages(this.state.authToken, this.state.modelId.value).then(res => {
                this.setState({
                    title: `${this.state.brandId.label} | ${this.state.modelId.label}`,
                    imageCnt: res.data.data.length,
                    images: res.data.data,
                    imageLoad: "done",
                    selectImage: 0,
                    base64Image: "",
                    src: "",
                    croppedImageUrl: ""
                })
            })
        }
    }

    initializing = async () => {
        const id = window.location.href.split('/')[window.location.href.split('/').length - 1].split('&')[1]
        const position = window.location.href.split('/')[window.location.href.split('/').length - 1].split('&')[0]
        this.setState({position}, () => {
            if (id !== 0) {
                modelService.getModelDetail(this.state.authToken, id).then(async (res) => {
                    const detailData = res.data.data;

                    // 선택한 포토 갤러리 차량 모델의 브랜드 정보 가져와서 셀렉하기
                    let brandId = 0
                    for (let item of this.state.totalInfo) {
                        if (item.value === detailData.brand_id) brandId = item.label
                    }
                    this.setState({brandId: {label: brandId, value: detailData.brand_id}}, async () => {

                        //셀렉 후 모델 그룹 렌더링

                        const req = modelGroupService.getModelG(this.state.authToken, this.state.brandId.value)
                        await req.then(response => {
                            const data = response.data.data
                            let options = []
                            options.push({label: '전체', value: 0})
                            for (var x = 0; x < data.length; x++) {
                                options.push({label: data[x].name, value: data[x].id})
                            }
                            this.setState({
                                modelGroup: options
                            }, () => {

                                // 모델 그룹 셀렉하기

                                let modelGroupId = 0
                                for (let item of this.state.modelGroup) {
                                    if (item.value === detailData.car_model_group_id) {
                                        modelGroupId = item.label
                                    }
                                }
                                this.setState({
                                    modelGroupId: {
                                        label: modelGroupId,
                                        value: detailData.car_model_group_id
                                    }
                                }, () => {

                                    // 모델 리스트 렌더링
                                    // 모델 셀렉
                                    const req = modelService.getModelList(
                                        this.state.brandId.value,
                                        10000,
                                        1,
                                        this.state.authToken,
                                        this.state.modelGroupId.label,
                                        "", "all", "", "", "", "", "", ""
                                    )
                                    req.then(response => {
                                        const data = response.data.data
                                        let options = []
                                        options.push({label: '전체', value: 0})
                                        for (var x = 0; x < data.length; x++) {
                                            if (data[x].is_active === true) {
                                                options.push({label: data[x].name, value: data[x].id})
                                            }
                                        }
                                        this.setState({
                                            model: options,
                                            modelId: {label: detailData.name, value: detailData.id},
                                        }, () => {
                                            this.getModelImage()
                                        })
                                    })
                                })
                            })
                        })
                    })
                })
            }
        })
    }

    onClickImage = (selectImage) => {
        this.setState({selectImage: this.state.selectImage === selectImage ? 0 : selectImage})
    }

    onClickSelectOk = async () => {
        console.log(this.state.images);

        for (let item of this.state.images) {
            if (item.id === this.state.selectImage) {


                let response = await modelService.getCarModelImage(this.state.authToken, item.car_model_id, item.id, true);
                let base64String = await response.data.data.base64;
                let base64Response = await fetch(base64String); //base64 String을 fetch로 가져오면 response에서 blob object로 바꾸기가 쉬움. Base64 string을 blob으로 바꾸는건 좀 복잡함.
                let data = await base64Response.blob();

                let metadata = {
                    type: 'image/*'
                };
                let file = new File([data], "test.jpg", metadata);
                console.log(file);
                const reader = new FileReader();
                reader.addEventListener('load', () =>
                    this.setState({src: reader.result})
                );
                reader.readAsDataURL(file);
            }
        }
    }

    onClickOk = () => {
        const {authToken, position, modelId, base64Image} = this.state
        if (modelId.value === 0) {
            alert('모델을 선택해주세요.');
            return false
        } else if (base64Image === "") {
            alert('이미지를 잘라주세요.');
            return false
        }
        Service.putHomePhoto(authToken, position, base64Image, modelId.value).then(res => {
            alert('수정이 완료되었습니다.');
            this.props.history.push('/operation/homePhoto')
        }).catch(err => {
            alert('수정 요청 중 오류가 발생하였습니다.')
        })
    }

    onClickReSelect = () => {
        this.setState({
            selectImage: 0,
            base64Image: "",
            src: "",
            croppedImageUrl: ""
        })
    };

    onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                this.setState({src: reader.result})
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    // If you setState the crop in here you should return false.
    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({crop});
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg'
            );
            this.setState({croppedImageUrl});
        }
    };

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        // As Base64 string
        const base64Image = canvas.toDataURL('image/jpeg');
        this.setState({base64Image})

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve(this.fileUrl);
            }, 'image/jpeg');
        });
    };

    componentDidMount = async () => {
        await this.getTotalInfo();
        await this.initializing();
    };

    render() {
        const {crop, croppedImageUrl, src} = this.state;
        return (
            <>
                <div className="title disi wd100"><p className="mb10">
                    <span>홈</span> 〉 <span>운영</span> 〉 <span>홈 인기포토</span> 〉 <span>추가/수정</span></p>홈 인기포토 추가/수정
                    <div className="btnwrap floatR disi">
                        <button type="button" className="btnLine navyBg ml10" onClick={this.putHomePhoto}>저장</button>
                        <Link to="/operation/homePhoto">
                            <button type="button" className="btn navyBg ml10">목록</button>
                        </Link>
                    </div>
                </div>
                <form>
                    <table className="mt20 mda_reset_table">
                        <colgroup>
                            <col width="10%"/>
                            <col width=""/>
                        </colgroup>
                        <tbody>
                        <tr>
                            <th>브랜드명/모델명</th>
                            <td id="brandSelect">
                                <Select className="reactSelect"
                                        options={this.state.totalInfo}
                                        value={this.state.brandId}
                                        name="brandId"
                                        onChange={this.selectBrand}
                                        placeholder="전체"
                                        isSearchable={true}/>

                                <Select className="reactSelect"
                                        options={this.state.modelGroup}
                                        value={this.state.modelGroupId}
                                        name="brandId"
                                        onChange={this.selectModelGroup}
                                        placeholder="전체"
                                        isSearchable={true}/>

                                <Select className="reactSelect"
                                        options={this.state.model}
                                        value={this.state.modelId}
                                        name="brandId"
                                        onChange={this.selectModel}
                                        placeholder="전체"
                                        isSearchable={true}/>

                                <button type="button" className="btnLine navyBg ml10 b_btn"
                                        onClick={this.getModelImage}>이미지 불러오기
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <th>모델 이미지</th>
                            {
                                this.state.imageLoad === "notYet" ?
                                    <td><span>이미지를 불러오세요.</span></td> :
                                    this.state.imageCnt === 0 ?
                                        <td><span>해당 모델에 등록된 이미지가 없습니다.</span></td> :
                                        <td>
                                            <p>{this.state.title}의 이미지 (총 <span>{this.state.imageCnt}</span>개)</p>
                                            <div>
                                                <ul className="gall_managewrap">
                                                    {
                                                        this.state.images.map((item, index) => {
                                                            return (
                                                                <li key={index}
                                                                    className={`${this.state.selectImage === item.id ? "selectImage" : ""}`}
                                                                    onClick={() => this.onClickImage(item.id)}>
                                                                    <label className={`gall_label`}>
                                                                        <img src={item.image_url} alt=""/>
                                                                    </label>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                                <div className="centerT mt20">
                                                    <button type="button" className="btnLine navyBg"
                                                            onClick={this.onClickSelectOk}
                                                            disabled={this.state.selectImage === 0}>선택완료
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                            }
                        </tr>
                        <tr>
                            <th>이미지 수정</th>
                            <td className="flexCol">
                                <h1 className="editorTitle">이미지는 16 : 9 비율로 자를 수 있습니다</h1>
                                <ReactCrop
                                    src={src}
                                    crop={crop}
                                    ruleOfThirds
                                    onImageLoaded={this.onImageLoaded}
                                    onComplete={this.onCropComplete}
                                    onChange={this.onCropChange}/>
                                {croppedImageUrl && (
                                    <img alt="Crop" style={{maxWidth: '100%'}} src={croppedImageUrl}/>
                                )}
                                <div className="centerT mt20">
                                    <button type="button" className="btnLine navyBg ml10"
                                            onClick={this.onClickReSelect}>다시선택
                                    </button>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div className="btnwrap rightT mt20">
                        <button type="button" className="btnLine navyBg ml10" onClick={this.onClickOk}>저장</button>
                        <Link to="/operation/homePhoto">
                            <button type="button" className="btn navyBg ml10">목록</button>
                        </Link>
                    </div>
                </form>
                {/* // 포토갤러리 추가/수정 영역 끝 // */}
            </>
        );
    }
}

export default HomePhotoDetail;
