import React, { memo } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

function ManageButton({ id, date, is_published, handleManageButton }) {
    let ManageButton = (
        <div className="uk-text-center">
            <span className="uk-margin-small-right" data-uk-icon="icon: lock" />
        </div>
    );
    const currentDate = moment();
    const featuredNewsDate = moment(date);

    const isToday = featuredNewsDate.format("YYYY-MM-DD") === currentDate.format("YYYY-MM-DD");

    if (isToday && (is_published || !is_published)) {
        ManageButton = (
            <div className="uk-text-center">
                <div className="uk-button-group uk-text-nowrap">
                    <Link to={`featuredNews/featuredNewsDetail/${id}`}>
                        <button className="uk-button uk-button-default">수정</button>
                    </Link>
                    <button
                        className="uk-button uk-button-default"
                        data-type="delete"
                        data-id={id}
                        onClick={handleManageButton}
                    >
                        삭제
                    </button>
                    <button
                        className="uk-button uk-button-default"
                        data-type="publish"
                        data-id={id}
                        onClick={handleManageButton}
                    >
                        즉시반영
                    </button>
                </div>
            </div>
        );
    } else if (featuredNewsDate > currentDate && !is_published) {
        ManageButton = (
            <div className="uk-text-center">
                <div className="uk-button-group uk-text-nowrap">
                    <Link to={`featuredNews/featuredNewsDetail/${id}`}>
                        <button className="uk-button uk-button-default">수정</button>
                    </Link>
                    <button
                        className="uk-button uk-button-default"
                        data-type="delete"
                        data-id={id}
                        onClick={handleManageButton}
                    >
                        삭제
                    </button>
                </div>
            </div>
        );
    }

    return ManageButton;
}

export default memo(ManageButton);
