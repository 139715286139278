// 액션
const COMPLETE_LOGIN = 'COMPLETE_LOGIN';
const COMPLETE_LOGOUT = 'COMPLETE_LOGOUT';

// 액션 함수
export const completeLogin = () => ({ type: COMPLETE_LOGIN });
export const completeLogout = () => ({ type: COMPLETE_LOGOUT });

// 초기 상태 할당
const initialState = {
  loginState: !!window.localStorage.getItem('token')
};

// 리듀서
export default function login(state = initialState, action) {
  switch (action.type) {
    case COMPLETE_LOGIN:
      return {
        ...state,
        loginState: true
      };
    case COMPLETE_LOGOUT:
      return {
        ...state,
        loginState: false
      };
    default:
      return state;
  }
}
