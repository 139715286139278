import { devUrl } from '../../serviceComm/variable';

const axios = require('axios');

export function postMediaDetail(authToken, data, editors) {
  const form = new FormData();
  form.append('category', data.category);
  form.append('name', data.name);
  form.append('address', data.address);
  form.append('contact', data.contact);
  editors.forEach((editor) => {
    form.append('editors[][name]', editor.name);
    form.append('editors[][phone]', editor.phone);
  });
  if (data.logo && data.logo.name) { form.append('logo', data.logo); }
  form.append('homepage_url', data.homepage_url);
  form.append('naver_post_url', data.naver_post_url);
  form.append('naver_tv_url', data.naver_tv_url);
  form.append('youtube_url', data.youtube_url);
  form.append('etc_url', data.etc_url);

  const config = {
    method: 'post',
    url:
      `${devUrl}/admin/cms/providers`,
    headers: {
      // 'x-api-key': 'b2cb6699e9c4e10cf36a5f5451309069',
      Authorization: `Bearer ${authToken}`
    },
    data: form
  };

  return axios(config);
}

export function putMediaDetail(authToken, data, editors) {
  const form = new FormData();
  form.append('category', data.category);
  form.append('name', data.name);
  form.append('address', data.address);
  form.append('contact', data.contact);
  editors.forEach((editor) => {
    form.append('editors[][name]', editor.name);
    form.append('editors[][phone]', editor.phone);
  });
  if (data.logo && data.logo.name) { form.append('logo', data.logo); }
  form.append('homepage_url', data.homepage_url);
  form.append('naver_post_url', data.naver_post_url);
  form.append('naver_tv_url', data.naver_tv_url);
  form.append('youtube_url', data.youtube_url);
  form.append('etc_url', data.etc_url);

  const config = {
    method: 'put',
    url:
      `${devUrl}/admin/cms/providers/${data.id}`,
    headers: {
      // 'x-api-key': 'b2cb6699e9c4e10cf36a5f5451309069',
      Authorization: `Bearer ${authToken}`
    },
    data: form
  };

  return axios(config);
}

export function getMediaDetail(authToken, id) {
  const config = {
    method: 'get',
    url:
      `${devUrl}/admin/cms/providers/${id}`,
    headers: {
      // 'x-api-key': 'b2cb6699e9c4e10cf36a5f5451309069',
      Authorization: `Bearer ${authToken}`
    }
  };

  return axios(config);
}
