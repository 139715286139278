import React, { Component } from 'react';
import $ from 'jquery';
import DatePicker from 'react-datepicker';
import Moment from 'react-moment';
import 'moment-timezone';
import 'react-datepicker/dist/react-datepicker.css';
import '../../style/contents/content.scss';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import Choice from '../../component/Choice';
import Pagination from '../../component/Pagination';
import * as Common from '../../component/services/common/CommonService';
import * as Service from '../../component/services/content/ContentService';
import { clientUrl } from '../../component/serviceComm/variable';
import QueryString from 'query-string';

const SortableItem = SortableElement(({ item }) => (
  <li className="sortable_news_box" key={item.id}>
    <div className="leftT">{item.provider_name}</div>
    <div className="title_box">
      <span>{item.title}</span>
    </div>
    <div className="date_box">{item.published_at}</div>
  </li>
));

const SortableList = SortableContainer(({ items }) => (
  <ul className="sortable">
    {items.map((item, index) => (
      <SortableItem key={item.id} index={index} item={item} />
    ))}
  </ul>
));

class Content extends Component {
  constructor(props) {
    super(props);
    const queryStringHistory = QueryString.parse(this.props.location.search);
    this.state = {
      authToken: Common.getToken.call(this),
      medias: [],
      contents: [],
      content_count: 0,
      per: 10,
      page: queryStringHistory.page ? queryStringHistory.page : 1,
      pagination: [],
      provider_id: queryStringHistory.provider_id ? queryStringHistory.provider_id : 0,
      provider_name: queryStringHistory.provider_name ? queryStringHistory.provider_name : null,
      category: queryStringHistory.category ? queryStringHistory.category : 'ALL',
      title: queryStringHistory.title ? queryStringHistory.title : '',
      start_of_published_at: queryStringHistory.start_of_published_at ? new Date(queryStringHistory.start_of_published_at) : new Date(),
      end_of_published_at: queryStringHistory.end_of_published_at ? new Date(queryStringHistory.end_of_published_at) : new Date(),
      featured_news: [],
      featured_news_only: queryStringHistory.featured_news_only ? queryStringHistory.featured_news_only : 'ALL',
      is_active: queryStringHistory.is_active ? queryStringHistory.is_active : 'ALL',
      is_search: queryStringHistory.is_search ? queryStringHistory.is_search : false
    };
  }

  componentDidMount() {
    this.getContentList();
    this.getMediaList();
    $('#media_all').on('click', function () {
      if ($(this).is(':checked')) {
        $('input[type="checkbox"][name="deleteCheckBox"]').prop(
          'checked',
          true
        );
      } else {
        $('input[type="checkbox"][name="deleteCheckBox"]').prop(
          'checked',
          false
        );
      }
    });
  }

  // 매체 리스트 요청
  getMediaList = () => {
    const { authToken } = this.state;
    const mediaList = Service.getMediaList(authToken);
    mediaList.then((response) => {
      this.setState({
        medias: response.data.data
      });
    }).catch((error) => {
      if (error.response) {
        if (error.response.data.message) {
          alert(error.response.data.message);
        } else {
          alert(`매체 목록을 불러오는 중 오류가 발생하였습니다.\r\n${error}`);
        }
      } else {
        alert(`매체 목록을 불러오는 중 오류가 발생하였습니다.\r\n${error}`);
      }
    });
  };

  // 컨텐츠 목록 가져오기
  getContentList = () => {
    const {
      authToken, title, category, per, page,
      is_search, provider_id, featured_news_only, is_active,
      start_of_published_at, end_of_published_at
    } = this.state;
    this.setState(
      {
        contents: []
      },
      () => {
        if (is_search === 'true') {
          const format = require('date-format');
          const formData = new FormData();
          formData.append('provider_id', provider_id);
          formData.append('title', title);
          formData.append('category', category);
          formData.append('featured_news_only', featured_news_only);
          formData.append('is_active', is_active);
          formData.append(
            'start_of_published_at',
            format('yyyy-MM-dd', start_of_published_at)
          );
          formData.append(
            'end_of_published_at',
            format('yyyy-MM-dd', end_of_published_at)
          );

          const contentList = Service.getSearchContentList(
            authToken,
            formData,
            per,
            page
          );
          contentList
            .then((response) => {
              this.setState(
                {
                  contents: response.data.data,
                  pagination: response.data.pagination
                }, () => this.getContentListCount()
              );
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.data.message) {
                  alert(error.response.data.message);
                } else {
                  alert(`컨텐츠 목록을 불러오는 중 오류가 발생하였습니다.\r\n${error}`);
                }
              } else {
                alert(`컨텐츠 목록을 불러오는 중 오류가 발생하였습니다.\r\n${error}`);
              }
            });
        } else {
          const contentList = Service.getContentList(
            authToken,
            per,
            page
          );
          contentList
            .then((response) => {
              this.setState(
                {
                  contents: response.data.data,
                  pagination: response.data.pagination
                }, () => this.getContentListCount()
              );
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.data.message) {
                  alert(error.response.data.message);
                } else {
                  alert(`컨텐츠 목록을 불러오는 중 오류가 발생하였습니다.\r\n${error}`);
                }
              } else {
                alert(`컨텐츠 목록을 불러오는 중 오류가 발생하였습니다.\r\n${error}`);
              }
            });
        }
      }
    );
  };

  // 렌더링된 컨텐츠 리스트 카운트
  getContentListCount = () => {
    const {
      authToken, title, category, per, pagination,
      is_search, provider_id, featured_news_only, is_active,
      start_of_published_at, end_of_published_at
    } = this.state;
    if (is_search) {
      const format = require('date-format');
      const formData = new FormData();
      formData.append('provider_id', provider_id);
      formData.append('title', title);
      formData.append('category', category);
      formData.append('featured_news_only', featured_news_only);
      formData.append('is_active', is_active);
      formData.append(
        'start_of_published_at',
        format('yyyy-MM-dd', start_of_published_at)
      );
      formData.append(
        'end_of_published_at',
        format('yyyy-MM-dd', end_of_published_at)
      );

      const count = Service.getSearchContentListCount(
        authToken,
        per,
        pagination.total_pages,
        formData
      );
      count.then((response) => {
        this.setState({
          content_count: response.data.data.length
        });
      }).catch((error) => {
        if (error.response) {
          if (error.response.data.message) {
            alert(error.response.data.message);
          } else {
            alert(`컨텐츠 목록 카운트 중 오류가 발생하였습니다.\r\n${error}`);
          }
        } else {
          alert(`컨텐츠 목록 카운트 중 오류가 발생하였습니다.\r\n${error}`);
        }
      });
    } else {
      const count = Service.getContentListCount(
        authToken,
        per,
        pagination.total_pages
      );
      count.then((response) => {
        this.setState({
          content_count: response.data.data.length
        });
      }).catch((error) => {
        if (error.response) {
          if (error.response.data.message) {
            alert(error.response.data.message);
          } else {
            alert(`컨텐츠 목록 카운트 중 오류가 발생하였습니다.\r\n${error}`);
          }
        } else {
          alert(`컨텐츠 목록 카운트 중 오류가 발생하였습니다.\r\n${error}`);
        }
      });
    }
  };

  onChangeInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  onChange = (e) => {
    if (e.target.name === 'provider_id') {
      const provider_name = e.target.querySelector('option') ? e.target.querySelector('option').innerHTML : this.state.provider_name;
      this.setState({
        [e.target.name]: e.target.value,
        provider_name
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value
      });
    }
  };

  // 등록일 onChange
  setStartDateOnChange = (date) => {
    this.setState({
      start_of_published_at: date
    });
  };

  // 수정일 onChange
  setEndDateOnChange = (date) => {
    this.setState({
      end_of_published_at: date
    });
  };

  // 페이지 이동
  onClickPage = (page) => {
    const { authToken, per } = this.state;
    this.setState(
      {
        page
      },
      () => {
        this.handlePage();
        this.getContentList(
          authToken,
          per,
          page
        );
      }
    );
  };

  // 추천뉴스 목록 PopUp Open
  openRecommendNewsPopUp = () => {
    const { is_on_pop } = this.state;
    if (is_on_pop === 'true') {
      this.setState({
        is_on_pop: 'false'
      });
    } else {
      this.setState(
        {
          is_on_pop: 'true'
        },
        () => this.getFeaturedNewsList()
      );
    }
  };

  // 추천뉴스 목록 렌더링
  getFeaturedNewsList = () => {
    const { authToken } = this.state;
    const feturedNewsList = Service.getFeaturedNewsList(authToken);
    feturedNewsList.then((response) => {
      this.setState({
        featured_news: response.data.data
      });
    });
  };

  // 추천뉴스 등록
  putFeaturedNewsList = () => {
    const formData = new FormData();
    const { authToken, featured_news } = this.state;
    for (let i = 0; i < featured_news.length; i++) {
      formData.append('content_ids[]', featured_news[i].id);
    }
    const putFeturedNewsList = Service.putFeaturedNewsList(
      authToken,
      formData
    );
    putFeturedNewsList
      .then((response) => {
        if (response.status === 200 || (response.status === 201 && response.statusText === 'OK')) {
          this.openRecommendNewsPopUp();
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.message) {
            alert(error.response.data.message);
          } else {
            alert(`추천뉴스를 등록하는 중 오류가 발생하였습니다.\r\n${error}`);
          }
        } else {
          alert(`추천뉴스를 등록하는 중 오류가 발생하였습니다.\r\n${error}`);
        }
        console.log(error);
      });
  };

  // 추천뉴스 Sortable End
  onSortEnd = ({ oldIndex, newIndex }) => {
    const { featured_news } = this.state;
    this.setState({
      featured_news: arrayMove(featured_news, oldIndex, newIndex)
    });
  };

  // 컨텐츠 삭제
  deleteContent = () => {
    const ids = [];
    const { authToken } = this.state;
    $("input[type='checkbox'][name='deleteCheckBox']:checked").each(function () {
      ids.push($(this).val());
    });
    if (ids.length !== 0) {
      const confirm = window.confirm('정말로 삭제하시겠습니까?');

      if (confirm) {
        const formData = new FormData();
        formData.append('content_ids[]', ids);
        const deletes = Service.deleteContents(authToken, formData);
        deletes
          .then((response) => {
            if (response.status === 200 || (response.status === 201 && response.statusText === 'OK')) {
              alert('삭제되었습니다.');
              window.location.reload();
            }
            console.log(response);
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.message) {
                alert(error.response.data.message);
              } else {
                alert(`컨텐츠를 삭제하는 중 오류가 발생하였습니다.\r\n${error}`);
              }
            } else {
              alert(`컨텐츠를 삭제하는 중 오류가 발생하였습니다.\r\n${error}`);
            }
          });
      }
    } else {
      alert('삭제할 컨텐츠를 선택해주세요.');
    }
  };

  // 컨텐츠 검색
  searchContent = (e) => {
    e.preventDefault();
    const { authToken } = this.state;
    this.setState(
      {
        page: 1,
        is_search: 'true'
      },
      () => {
        this.handlePage();
        this.getContentList(authToken);
      }
    );
  };

  // 콘텐츠 페이지 내의 모든 페이지 라우팅 핸들링
  handlePage = (path) => {
    const format = require('date-format');
    const {
      provider_id,
      provider_name,
      title,
      category,
      featured_news_only,
      is_active,
      start_of_published_at,
      end_of_published_at,
      is_search,
      page
    } = this.state;
    let searchInfo = {
      provider_id,
      provider_name,
      title,
      category,
      featured_news_only,
      is_active,
      start_of_published_at,
      end_of_published_at,
      is_search,
      page
    };
    Object.keys(searchInfo).map((item) => {
      if (item === 'provider_id') {
        (searchInfo[item] === 0 || searchInfo[item].toString().length === 0) && delete searchInfo[item];
      } else if (item === 'category') {
        (searchInfo[item] === 'ALL' || searchInfo[item].toString().length === 0) && delete searchInfo[item];
      } else if (item === 'featured_news_only') {
        (searchInfo[item] === 'ALL' || searchInfo[item].toString().length === 0) && delete searchInfo[item];
      } else if (item === 'is_active') {
        (searchInfo[item] === 'ALL' || searchInfo[item].toString().length === 0) && delete searchInfo[item];
      } else if (item === 'start_of_published_at' || item === 'end_of_published_at') {
        if (searchInfo[item] && is_search === 'true') {
          searchInfo[item] = format('yyyy-MM-dd', searchInfo[item]);
        } else {
          delete searchInfo[item];
        }
      } else {
        (searchInfo[item] === null || searchInfo[item].toString().length === 0) && delete searchInfo[item];
      }
    });
    let queryString = Object.keys(searchInfo).map((item, index) => searchInfo[item] !== null && `${item}=${searchInfo[item]}`).join('&');
    this.props.history.push({
      pathname: path ? path : '/contents/content',
      search: `?${queryString}`
    });
  }

  render() {
    const {
      title, medias, contents, pagination, per, page,
      start_of_published_at, end_of_published_at, content_count, is_on_pop, featured_news,
      featured_news_only, is_active, provider_id, provider_name, category
    } = this.state;
    return (
      <>
        <div className="title">
          {' '}
          <p className="mb10">
            <span>홈</span>
            {' '}
            〉
            {' '}
            <span>콘텐츠</span>
            {' '}
            〉
            {' '}
            <span>콘텐츠등록/관리</span>
          </p>
          콘텐츠등록/관리
        </div>
        <form>
          <table className="mt40 mda_reset_table">
            <colgroup>
              <col width="10%" />
              <col width="" />
            </colgroup>
            <tbody>
              <tr>
                <th>매체명</th>
                <td>
                  <Choice
                    medias={medias}
                    onChangeSelect={this.onChange}
                    historyMedia={{
                      provider_id,
                      provider_name
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th>콘텐츠 제목</th>
                <td>
                  <input
                    type="text"
                    name="title"
                    value={title}
                    onChange={this.onChangeInput}
                  />
                </td>
              </tr>
              <tr>
                <th>카테고리</th>
                <td>
                  <select name="category" value={category} onChange={this.onChange}>
                    <option value="ALL">전체</option>
                    <option value="0">뉴스</option>
                    <option value="1">시승기</option>
                  </select>
                </td>
              </tr>
              <tr>
                <th>추천뉴스</th>
                <td>
                  <select name="featured_news_only" value={featured_news_only} onChange={this.onChange}>
                    <option value="ALL">전체</option>
                    <option value="true">예</option>
                  </select>
                </td>
              </tr>
              <tr>
                <th>공개여부</th>
                <td>
                  <select name="is_active" value={is_active} onChange={this.onChange}>
                    <option value="ALL">전체</option>
                    <option value="true">공개</option>
                    <option value="false">비공개</option>
                  </select>
                </td>
              </tr>
              <tr>
                <th>등록일</th>
                <td>
                  <DatePicker
                    onChange={this.setStartDateOnChange}
                    selected={start_of_published_at}
                  />
                  {' '}
                  -
                  {' '}
                  <DatePicker
                    onChange={this.setEndDateOnChange}
                    selected={end_of_published_at}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div className="centerT mt20">
            <button
              type="button"
              className="btnLine navyBg medium"
              onClick={this.searchContent}
            >
              검색
            </button>
          </div>
        </form>
        <div className="">
          <div className="info_tabletop">
            <div className="mb10">
              <button
                type="button"
                className="btnLine navyBg b_btn recom_pop"
                onClick={this.openRecommendNewsPopUp}
              >
                추천뉴스 리스트
              </button>
              <button type="button" className="btnLine navyBg ml10" onClick={() => this.handlePage(`/contents/content/contentDetail`)}>
                등록
              </button>
              <button
                type="button"
                id="content-delete"
                className="btn navyBg ml10"
                onClick={this.deleteContent}
              >
                삭제
              </button>
            </div>
          </div>
          <table className="">
            <colgroup>
              <col width="3%" />
              <col width="4%" />
              <col width="8%" />
              <col width="18%" />
              <col width="12%" />
              <col width="12%" />
              <col width="12%" />
              <col width="10%" />
              <col width="8%" />
              <col width="10%" />
              <col width="10%" />
            </colgroup>
            <thead>
            <tr>
                <th className="ccc" colsPan="11">
                  총 <span>{content_count}</span>개
                </th>
              </tr>
              <tr>
                <th>
                  <input type="checkbox" name="contents" id="media_all" />
                </th>
                <th>번호</th>
                <th>매체명</th>
                <th>콘텐츠 제목</th>
                <th>카테고리</th>
                <th>추천뉴스</th>
                <th>공개여부</th>
                <th>조회수</th>
                <th>수정일</th>
                <th>등록일</th>
                <th>관리</th>
              </tr>
            </thead>
            <tbody>
              {contents.map((item, index) => (
                <tr key={item.id}>
                  <td>
                    <input
                      type="checkbox"
                      name="deleteCheckBox"
                      id={`delete-${item.id}`}
                      value={item.id}
                    />
                  </td>
                  <td>{item.id}</td>
                  <td>{item.provider_name}</td>
                  <td className="title-link"><a href={`${clientUrl}/news/main_view?id=${item.id}`} target={'_blank'}>{item.title}</a></td>
                  <td>{item.category}</td>
                  <td>
                    {item.is_featured_news === true ? '예' : '아니오'}
                  </td>
                  <td>{item.is_active === true ? '공개' : '비공개'}</td>
                  <td>{item.view_count}</td>
                  <td>
                    <Moment format="YYYY-MM-DD">
                      {item.revised_at}
                    </Moment>
                  </td>
                  <td>
                    <Moment format="YYYY-MM-DD">
                      {item.published_at}
                    </Moment>
                  </td>
                  <td>
                    <button type="button" className="btn grayBg ml10" onClick={() => this.handlePage(`/contents/content/contentDetail/${item.id}`)}>수정</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination">
            {/* <ul className="clearfix"> */}
            <Pagination
              onClickPage={this.onClickPage}
              paginationOption={pagination}
            />
            {/* </ul> */}
          </div>
        </div>
        {/* // 추천뉴스 리스트 팝업 시작 // */}
        <div className={is_on_pop === 'true' ? 'popWrap recomend_news_pop on ' : 'popWrap recomend_news_pop'} onClick={this.openRecommendNewsPopUp} role="presentation">
          {/*<div className="popBg" />*/}
          <div className="pop_cont" onClick={(e) => { e.stopPropagation(); }} role="presentation">
            <div className="pop_title">
              <p>추천뉴스 리스트</p>
              <span onClick={this.openRecommendNewsPopUp} role="presentation">X</span>
            </div>
            <div className="pop_contwrap">
              <div className="head">
                <div>매체명</div>
                <div>콘텐츠 제목</div>
                <div>등록일</div>
              </div>
              <SortableList
                items={featured_news}
                onSortEnd={this.onSortEnd}
              />
            </div>
            <div className="btnwrap centerT mt20 mb10">
              <button
                type="button"
                className="btnLine navyBg ml10"
                onClick={this.putFeaturedNewsList}
              >
                저장
              </button>
              <button
                type="button"
                className="btn navyBg ml10"
                onClick={this.openRecommendNewsPopUp}
              >
                닫기
              </button>
            </div>
          </div>
        </div>
        {/* // 추천뉴스 리스트 팝업 끝 // */}
      </>
    );
  }
}

export default Content;
