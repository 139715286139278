import { devUrl } from '../../serviceComm/variable';

const axios = require('axios');
const format = require('date-format');

export function postMovieDetail(authToken, data) {
  const form = new FormData();
  form.append('uploader', data.uploader);
  form.append('title', data.title);
  form.append('url', data.url);
  form.append('play_time', data.play_time);
  form.append('is_active', data.is_active);
  if (data.thumbnail) { form.append('thumbnail', data.thumbnail); }
  if (data.published_at) form.append('published_at', format('yyyy-MM-dd', data.published_at));
  if (data.revised_at) form.append('revised_at', format('yyyy-MM-dd', data.revised_at));
  if (data.car_model_id) form.append('car_model_id', data.car_model_id);

  const config = {
    method: 'post',
    url:
        `${devUrl}/admin/cms/videos`,
    headers: {
      Authorization: `Bearer ${authToken}`
    },
    data: form
  };

  return axios(config);
}

export function putMovieDetail(authToken, data) {
  const form = new FormData();
  form.append('uploader', data.uploader);
  form.append('title', data.title);
  form.append('url', data.url);
  form.append('play_time', data.play_time);
  form.append('is_active', data.is_active);
  if (data.thumbnail) { form.append('thumbnail', data.thumbnail); }
  if (data.published_at) form.append('published_at', format('yyyy-MM-dd', data.published_at));
  if (data.revised_at) form.append('revised_at', format('yyyy-MM-dd', data.revised_at));
  form.append('car_model_id', data.car_model_id ? data.car_model_id : null);

  const config = {
    method: 'put',
    url:
      `${devUrl}/admin/cms/videos/${data.id}`,
    headers: {
      Authorization: `Bearer ${authToken}`
    },
    data: form
  };

  return axios(config);
}

export function getYoutubeData(id) {
  const config = {
    method: 'get',
    url:
      `https://www.googleapis.com/youtube/v3/videos?id=${id}&key=AIzaSyDFw1NbtCkKU8sVj0ZGjActDR91ys7hJZM&part=snippet,contentDetails`
  };
  return axios(config);
}

export function deleteMovieThumnail(authToken, id) {
  const config = {
    method: 'delete',
    url:
        `${devUrl}/admin/cms/videos/${id}/thumbnail`,
    headers: {
      //   "x-api-key": "b2cb6699e9c4e10cf36a5f5451309069",
      Authorization: `Bearer  ${authToken}`
    }
  };

  return axios(config);
}

export function getMovieDetail(authToken, id) {
  const config = {
    method: 'get',
    url:
        `${devUrl}/admin/cms/videos/${id}`,
    headers: {
      //   "x-api-key": "b2cb6699e9c4e10cf36a5f5451309069",
      Authorization: `Bearer  ${authToken}`
    }
  };

  return axios(config);
}

export function getBrandSimpleList(authToken, param) {
  const params = () => {
    let p = {};
    if (param.is_active === null) p = { ...p, is_active: param.is_active };
    return p;
  };
  const config = {
    method: 'get',
    url:
        `${devUrl}/admin/car_info/brands`,
    headers: {
      //   "x-api-key": "b2cb6699e9c4e10cf36a5f5451309069",
      Authorization: `Bearer  ${authToken}`
    },
    params: params()
  };

  return axios(config);
}

export function getCarModelGroupList(authToken, param) {
  const params = () => {
    let p = {};
    if (param.is_active === null) p = { ...p, is_active: param.is_active };
    if (param.brand_id) p = { ...p, brand_id: param.brand_id };
    return p;
  };
  const config = {
    method: 'get',
    url:
        `${devUrl}/admin/car_info/car_model_groups`,
    headers: {
      //   "x-api-key": "b2cb6699e9c4e10cf36a5f5451309069",
      Authorization: `Bearer  ${authToken}`
    },
    params: params()
  };

  return axios(config);
}

export function getCarModelSimpleList(authToken, param) {
  const params = () => {
    let p = {};
    if (param.is_active === null) p = { ...p, is_active: param.is_active };
    if (param.brand_id) p = { ...p, brand_id: param.brand_id };
    if (param.car_model_group_id) p = { ...p, car_model_group_id: param.car_model_group_id };
    return p;
  };
  const config = {
    method: 'get',
    url:
        `${devUrl}/admin/car_info/car_models`,
    headers: {
      //   "x-api-key": "b2cb6699e9c4e10cf36a5f5451309069",
      Authorization: `Bearer  ${authToken}`
    },
    params: params()
  };

  return axios(config);
}
