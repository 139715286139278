import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import * as Service from '../../component/services/vehicleDB/LineUpService'
import * as Common from "../../component/services/common/CommonService";
import * as BrandService from "../../component/services/brand/BrandService";
import * as modelGroupService from "../../component/services/vehicleDB/ModelGroupService"
import * as modelService from "../../component/services/vehicleDB/ModelService"
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import Select from 'react-select';
import DatePicker from 'react-datepicker'

let id = 0;
class LineUpAdd extends Component {
    SortableItem = SortableElement(({value}) =>
      <div className="popUp del_file_btn btnLine navyBg car_img_btn img_data" data-id={value.id} style={{backgroundImage : 'url(' + value.image_url + ')' , backgroundSize : "cover" , backgroundPosition : 'center'}}>
        <button className="popUp img_delete" onClick={() => {this.deleteImage(value.id)}}>X</button>
      </div>
    );

    SortableList = SortableContainer(({items}) => {
      return (
        <div className="popUp car_box_inner">
          {items.map((data , key) => {
            return (
            <this.SortableItem key={`item-${key}`} index={key} value={data}/>
            )
          })}
          <button className="popUp del_file_btn btnLine navyBg car_img_btn" onClick={() => { document.querySelector('#images').click() }}>+</button>

        </div>
      );
    });

    constructor(props){
      super(props)
      this.state = {
        page_history: this.props.location.search.replace(/\?page=/g, '') ? this.props.location.search.replace(/\?page=/g, '') : 1,
        startDate : new Date(),
        authToken : Common.getToken.call(this),
        brandId : {label : '브랜드' , value : 0},
        modelGroupId : {label : '모델그룹' , value : 0},
        modelId : {label: '모델' , value : 0},
        totalInfo : [],
        modelGroup : [{label: '모델그룹' , value : 0}],
        model : [{label: '모델' , value : 0}],
        forms : [
          {
            id : id++,
            name : "",
            fuelType : "휘발유",
            displacement : "",
            mileage : "",
            date : new Date(),
            year : new Date().getFullYear(),
            sale : true,
            primary : false,
            active : true,
            conditions : [],
            condName : "",
            condPrice : "",
            condPercentage : "",
            condDescription : "",
            isSave: false
          }
        ]
      }
    }
    componentDidMount(){
      this.getTotalInfo()
    }

    getTotalInfo = aysnc => {
      const brandList = BrandService.getTotalInfo(this.state.authToken);
      brandList.then(response => {
        const data = response.data.data
        let options = []
        options.push({label: '브랜드' , value : 0})
        for(var x = 0; x<data.length; x++){
          options.push({label: data[x].name , value : data[x].id})
        }
        this.setState({
          totalInfo: options
        },() => {

          const id = this.props.location.pathname.split('/')
          if(!isNaN(parseInt(id[id.length-1]))){
            if(id[id.length-1].indexOf('&') !== -1){
              this.setState({
                brandId : {label : this.getBrandName(id[id.length-1].split('&')[0]) ,value : id[id.length-1].split('&')[0]},
              },() => {
                this.selectBrand(this.state.brandId)
              })
            }
          }

        });
      });
    };

    getBrandName = id => {
      for(var x = 0; x<this.state.totalInfo.length; x++){
        if(this.state.totalInfo[x].value == id){
          return this.state.totalInfo[x].label
        }
      }
    }

    getModelGroupName = id => {
      for(var x = 0; x<this.state.modelGroup.length; x++){
        if(this.state.modelGroup[x].value == id){
          return this.state.modelGroup[x].label
        }
      }
    }

    getModelName= id => {

      for(var x = 0; x<this.state.model.length; x++){
        if(this.state.model[x].value == id){
          return this.state.model[x].label
        }
      }
    }

    selectBrand = selectOption => {
      this.setState({
        brandId : selectOption,
        modelGroupId : {label : '모델그룹' , value : 0},
        modelId : {label: '모델' , value : 0},
        modelGroup : [{label: '모델그룹' , value : 0}],
        model : [{label: '모델' , value : 0}],
      } , () => {
        const req = modelGroupService.getModelG(this.state.authToken , this.state.brandId.value)
        req.then(response => {
          const data = response.data.data
          let options = []
          options.push({label: '모델그룹' , value : 0})
          for(var x = 0; x<data.length; x++){
            options.push({label: data[x].name , value : data[x].id})
          }
          this.setState({
            modelGroup : options
          } , () => {
            const id = this.props.location.pathname.split('/')
            if(!isNaN(parseInt(id[id.length-1]))){
              if(id[id.length-1].indexOf('&') !== -1){
                this.setState({
                  modelGroupId : {label : this.getModelGroupName(id[id.length-1].split('&')[1]) ,value : id[id.length-1].split('&')[1]},
                } , () => this.selectModelGroup(this.state.modelGroupId))
              }
            }
          })
        })
      });
    };

    selectModelGroup = selectOption => {
      this.setState({
        modelGroupId : selectOption,
        modelId : {label: '모델' , value : 0},
        model : [{label: '모델' , value : 0}],
      } , () => {
        const req = modelService.getModelList(
          this.state.brandId.value,
        10000,
        1,
        this.state.authToken,
        this.state.modelGroupId.label,
        "","all","","","","","",""
        )
        req.then(response => {
          const data = response.data.data
          let options = []
          options.push({label: '모델' , value : 0})
          for(var x = 0; x<data.length; x++){
            options.push({label: data[x].name , value : data[x].id})
          }
          this.setState({
            model : options
          } , () => {
            const id = this.props.location.pathname.split('/')
            if(!isNaN(parseInt(id[id.length-1]))){
              if(id[id.length-1].indexOf('&') !== -1){
                this.setState({
                  modelId : {label : this.getModelName(id[id.length-1].split('&')[2]) ,value : id[id.length-1].split('&')[2]},
                })
              }
            }
          })
        })
      });
    };

    selectModel = selectOption => {
      this.setState({
        modelId : selectOption
      })
    }

    handleChange = (date , index) => {
      let data = this.state.forms
      data[index].date = date
      this.setState({
        forms : data
      });
    };

    formDataChange = ( e , index , itemIndex , childIndex) => {
      const state = this.state
      var data = state.forms

      // 폼 데이터
      if(e.target.className == "name"){
        data[index].name = e.target.value
      }else if(e.target.className == "fuelType"){
        data[index].fuelType = e.target.value
      }else if(e.target.className == "mileage"){
        if(isNaN(e.target.value)){
          return false
        }
        data[index].mileage = e.target.value
      }else if(e.target.className == "displacement"){
        if(isNaN(e.target.value)){
          return false
        }
        data[index].displacement = e.target.value
      }else if(e.target.className == "year"){
        if(isNaN(e.target.value)){
          return false
        }
        data[index].year = e.target.value
      }else if(e.target.name == "selling"+index){
        e.target.id == 'sell'+index+'_ok' ? data[index].sale = true : data[index].sale = false
      }else if(e.target.name == "rp_line"+index){
        e.target.id == 'rp'+index+'_ok' ? data[index].primary = true : data[index].primary = false
      }else if(e.target.name == "active"+index){
        e.target.id == 'active'+index+'_ok' ? data[index].active = true : data[index].active = false
      //폼 데이터

      //판매 조건 데이터
      }else if(e.target.className == "condName"){
        data[index].condName = e.target.value
      }else if(e.target.className == "condPrice"){
        if(isNaN(e.target.value)){
          return false
        }else if(data[index].condPercentage !== ""){
          alert('할인가격 또는 할인율 중 하나만 입력 가능합니다.')
          return false
        }
        data[index].condPrice = e.target.value
      }else if(e.target.className == "condPercentage"){
        if(isNaN(e.target.value)){
          return false
        }else if(data[index].condPrice !== ""){
          alert('할인가격 또는 할인율 중 하나만 입력 가능합니다.')
          return false
        }
        data[index].condPercentage = e.target.value
      }else if(e.target.className == "condDescription"){
        data[index].condDescription = e.target.value
      // 판매 조건 데이터
      }

      this.setState({
        forms : data
      })
    }


    onLineUpAdd = () => {
      const state = this.state
      let data = {
        id : id++,
        name : state.forms[0].name,
        fuelType : state.forms[0].fuelType,
        mileage : state.forms[0].mileage,
        displacement : state.forms[0].displacement,
        date : new Date(state.forms[0].date),
        year : state.forms[0].year,
        sale : state.forms[0].sale,
        primary : state.forms[0].primary,
        active : state.forms[0].active,
        conditions : [],
        isSave: false
      }
      this.setState({
        forms : this.state.forms.concat(data)
      })
    }

    onAddConditions = (index) => {
      const data = this.state.forms
      if(data[index].condName == "") {
        alert('판매조건 명을 입력해주세요.')
        return false;
      }
      if(data[index].condPrice == "" && data[index].condPercentage == "") {
        alert('할인가격 또는 할인율을 입력해주세요.')
        return false;
      }
      const pushData = {
        name : data[index].condName,
        price : data[index].condPrice,
        percentage : data[index].condPercentage,
        description : data[index].condDescription,
        type : "main",
        status : "done",
        year : document.querySelector('#condYear').value,
        month : document.querySelector('#condMonth').value,
        child : []
      }
      data[index].conditions.push(pushData)
      data[index].condName = ''
      data[index].condPrice = ''
      data[index].condPercentage = ''
      data[index].condDescription = ''
      this.setState({
        forms : data,
      } , ()=> {
        document.querySelector('#condYear').value = new Date().getFullYear()
        document.querySelector('#condMonth').value = new Date().getMonth()+1
      })
    }

    addCategory = (index , itemIndex) => {
      const data = this.state.forms
      data[index].conditions[itemIndex].type = "parentChild"
      data[index].conditions[itemIndex].child.push({
        childName : "",
        childPrice : "",
        childPercentage : "",
        type : "ing"
      })
      this.setState({
        forms : data
      })
    }

    toggleDoneToEdit = (index , conditionIndex ) => {
      const data = this.state.forms
      data[index].conditions[conditionIndex].status = "edit"
      this.setState({
        forms : data
      })
    }

    toggleEditToDone = (index , conditionIndex ) => {
      const data = this.state.forms
      if(document.querySelector('#condName'+conditionIndex).value == ""){
        alert('판매조건을 입력해주세요.')
        return false
      }
      if(document.querySelector('#condPrice'+conditionIndex).value == "" && document.querySelector('#condPercentage'+conditionIndex).value){
        alert('할인가격 또는 할인율을 입력해주세요.')
        return false
      }
      data[index].conditions[conditionIndex].status = "done"
      data[index].conditions[conditionIndex].name = document.querySelector('#condName'+conditionIndex).value
      data[index].conditions[conditionIndex].price = document.querySelector('#condPrice'+conditionIndex).value
      data[index].conditions[conditionIndex].percentage = document.querySelector('#condPercentage'+conditionIndex).value
      data[index].conditions[conditionIndex].description = document.querySelector('#condDescription'+conditionIndex).value
      this.setState({
        forms : data
      })
    }

    removeCondition = (index , conditionIndex) => {
      const data = this.state.forms
      data[index].conditions.splice(conditionIndex,1)
      this.setState({
        forms : data
      })
    }

    saveCategory = (index , conditionIndex , itemIndex) => {
      const data = this.state.forms
      if(document.querySelector('#childName'+itemIndex).value == ""){
        alert('판매조건을 입력해주세요.')
        return false
      }
      if(document.querySelector('#childPrice'+itemIndex).value == "" && document.querySelector('#childPercentage'+itemIndex).value == ""){
        alert('할인가격 또는 할인율을 입력해주세요.')
        return false
      }
      data[index].conditions[conditionIndex].child[itemIndex].type = "done"
      data[index].conditions[conditionIndex].child[itemIndex].childName = document.querySelector('#childName'+itemIndex).value
      data[index].conditions[conditionIndex].child[itemIndex].childPrice = document.querySelector('#childPrice'+itemIndex).value
      data[index].conditions[conditionIndex].child[itemIndex].childPercentage = document.querySelector('#childPercentage'+itemIndex).value
      this.setState({
        forms : data
      })
    }

    editCategory = (index , conditionIndex , itemIndex) => {
      const data = this.state.forms
      data[index].conditions[conditionIndex].child[itemIndex].type = "ing"
      this.setState({
        forms : data
      })
    }

    removeCategory = (index , conditionIndex , itemIndex) => {
      const data = this.state.forms
      data[index].conditions[conditionIndex].child.splice(itemIndex,1)
      this.setState({
        forms : data
      })
    }

    saveData = (index, isAllSave) => {
      const state = this.state
      const data = state.forms[index]
      if (!isAllSave) {
        if (state.brandId.value == 0) {
          alert('브랜드를 선택해주세요.')
          return false
        }
        if (state.modelGroupId.value == 0) {
          alert('모델그룹을 선택해주세요.')
          return false
        }
        if (state.modelId.value == 0) {
          alert('모델을 선택해주세요.')
          return false
        }
        if (data.name == '') {
          alert('라인업명을 입력해주세요')
          return false
        }
        if (data.fuelType == '전기') {
          if (data.mileage == "") {
            alert('주행거리를 입력해주세요')
            return false
          }
        } else {
          if (data.displacement == "") {
            alert('배기량을 입력해주세요')
            return false
          }
        }
        if (data.year == '') {
          alert('연식을 입력해주세요')
          return false
        }
        if (data.sale === null) {
          alert('시판여부를 체크해주세요')
          return false
        }
        if (data.primary === null) {
          alert('대표 라인업 선택을 체크해주세요')
          return false
        }
        if (data.active === null) {
          alert('활성화 여부를 체크해주세요')
          return false
        }
        if (window.confirm('라인업 추가를 완료하시겠습니까?')) {
          const req = Service.postLineUp(this.state.authToken, this.state.brandId, this.state.modelGroupId, this.state.modelId, data)
          req.then(res => {
            if (res.status == 200) {
              alert('저장이 완료되었습니다.');
              const data = this.state.forms;
              data[index] = {
                ...data[index],
                isSave: true
              };
              this.setState({
                forms: data
              });
            } else {
              alert('라인업을 추가하는 중에 오류가 발생하였습니다.')
            }
          }).catch(err => {
            console.log(err.messege)
          })
        }
      } else {
        let result = {
          status: 'Success',
          index,
          message: '저장이 완료되었습니다.'
        };
        if (state.brandId.value == 0) {
          result = {
            ...result,
            status: 'Fail',
            message: '브랜드를 선택해주세요.'
          };
        }
        if (state.modelGroupId.value == 0) {
          result = {
            ...result,
            status: 'Fail',
            message: '모델그룹을 선택해주세요.'
          };
        }
        if (state.modelId.value == 0) {
          result = {
            ...result,
            status: 'Fail',
            message: '모델을 선택해주세요.'
          };
        }
        if (data.name == '') {
          result = {
            ...result,
            status: 'Fail',
            message: '라인업명을 입력해주세요.'
          };
        }
        if (data.fuelType == '전기') {
          if (data.mileage == "") {
            result = {
              ...result,
              status: 'Fail',
              message: '주행거리를 입력해주세요.'
            };
          }
        } else {
          if (data.displacement == "") {
            result = {
              ...result,
              status: 'Fail',
              message: '배기량을 입력해주세요.'
            };
          }
        }
        if (data.year == '') {
          result = {
            ...result,
            status: 'Fail',
            message: '연식을 입력해주세요.'
          };
        }
        if (data.sale === null) {
          result = {
            ...result,
            status: 'Fail',
            message: '시판여부를 체크해주세요.'
          };
        }
        if (data.primary === null) {
          result = {
            ...result,
            status: 'Fail',
            message: '대표 라인업 선택을 체크해주세요.'
          };
        }
        if (data.active === null) {
          result = {
            ...result,
            status: 'Fail',
            message: '활성화 여부를 체크해주세요.'
          };
        }

        if(result.status === 'Success') {
          const req = Service.postLineUp(this.state.authToken, this.state.brandId, this.state.modelGroupId, this.state.modelId, data)
          req.then(res => {
            if (res.status == 200) {
              const data = this.state.forms;
              data[index] = {
                ...data[index],
                isSave: true
              };
              this.setState({
                forms: data
              });
            } else {
              alert('라인업을 추가하는 중에 오류가 발생하였습니다.')
            }
          }).catch(err => {
            console.log(err.messege)
          })
        }

        return result;
      }
    }

    pricePercentageCheck = (e , targetid) => {
      if(document.querySelector(targetid).value !== ""){
        alert('할인가격 또는 할인율 중 하나만 입력 가능합니다.')
        e.target.value = ""
        return false
      }
    }

    // 해당 폼을 삭제합니다.
    deleteForm = (id) => {
      const { forms } = this.state;
      if(forms.length !== 1) { // 폼이 1개 남았을 경우는 제외합니다.
        this.setState({
          forms: forms.filter((item) => item.id !== parseInt(id))
        });
      }
    };

    // 일괄저장합니다.
    saveAllData = () => {
      const confirm = window.confirm('추가된 라인업을 일괄저장 하시겠습니까?');

      if(confirm) {
        const { forms } = this.state;
        forms.forEach((item, index) => {
          if(!item.isSave) {
            const result = this.saveData(index, true);
            if(result.status !== 'Success') {
              alert(`${result.index + 1}번 폼 저장 중 문제가 발생하였습니다.\nMessage: ${result.message}`);
            }
          }
        });
      }
    };

    render() {
      let year = []
      let month = []
      for(var x  = 2010; x<2026; x++){
        year.push(x)
      }
      for(var x = 1; x<=12; x++){
        month.push(x)
      }
        return (
            <Fragment>
                {/* 라인업 추가 */}
                <div className="title disi wd100"><p className="mb10"><span>홈</span> 〉 <span>차량DB</span> 〉 <span>라인업</span> 〉 <span>추가</span></p>라인업 추가
                    <div className="floatR disi">
                        <button type="button" className="btn navyBg ml10" onClick={() => this.props.history.goBack()}>목록</button>
                    </div>
                </div>
                <div className="form_tablewrap">
                        <table className="mt20">
                            <colgroup>
                                <col width="14%"></col>
                                <col width="*"></col>
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>브랜드 / 모델그룹 / 모델명</th>
                                    <td id="brandSelect">
                                    <Select className="reactSelect"
                                    options={this.state.totalInfo}
                                    value={this.state.brandId}
                                    name="brandId"
                                    onChange={this.selectBrand}
                                    placeholder="전체"
                                    isSearchable={true}/>

                                    <Select className="reactSelect"
                                    options={this.state.modelGroup}
                                    value={this.state.modelGroupId}
                                    name="brandId"
                                    onChange={this.selectModelGroup}
                                    placeholder="전체"
                                    isSearchable={true}/>

                                    <Select className="reactSelect"
                                    options={this.state.model}
                                    value={this.state.modelId}
                                    name="brandId"
                                    onChange={this.selectModel}
                                    placeholder="전체"
                                    isSearchable={true}/>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        {
                          this.state.forms.map((item, index) => {
                          return(
                          <>
                          <table className="lineup_table mt30">
                            <colgroup>
                                <col width="10%"></col>
                                <col width="30%"></col>
                                <col width="12%"></col>
                                <col width="50%"></col>
                            </colgroup>
                            <tbody>
                                {item.isSave &&
                                <>
                                  <div className="lineup_save_wrap"></div>
                                  <div className="lineup_save_badge navyBg">저장됨</div>
                                </>}
                                <tr>
                                    <th>라인업명 <span className="redT">*</span></th>
                                    <td><input type="text" className="name" value={item.name} onChange={(e) => this.formDataChange(e , index)}></input></td>
                                    <th>연료 <span className="redT">*</span></th>
                                    <td>
                                        <select className="ml10" className="fuelType" value={item.fuelType} onChange={(e) => this.formDataChange(e , index)}>
                                          <option>휘발유</option>
                                          <option>경유</option>
                                          <option>LPG</option>
                                          <option>전기</option>
                                          <option>휘발유+전기</option>
                                          <option>수소</option>
                                          <option>CNG</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                  {
                                    item.fuelType == "전기" ?
                                    <>
                                    <th>주행거리(km/kWh) <span className="redT">*</span></th>
                                    <td><input type="number" className="mileage" value={item.mileage} onChange={(e) => this.formDataChange(e , index)}></input></td>
                                    </>
                                     :
                                    <>
                                    <th>배기량(cc) <span className="redT">*</span></th>
                                    <td><input type="number" className="displacement" value={item.displacement} onChange={(e) => this.formDataChange(e , index)}></input></td>
                                    </>
                                  }
                                    <th>출시일</th>
                                    <td><DatePicker
                                          selected={item.date}
                                          onChange={(date) => this.handleChange(date , index)}
                                          dateFormat="yyyy-MM-dd"
                                        /></td>
                                </tr>
                                <tr>
                                    <th>연식 <span className="redT">*</span></th>
                                    <td><input type="number" className="year"value={item.year} onChange={(e) => this.formDataChange(e , index)}></input></td>
                                    <th>활성화 여부 <span className="redT" >*</span></th>
                                    <td>
                                        <input type="radio" name={"active"+index} id={"active"+index+"_ok"} onChange={(e) => this.formDataChange(e , index)} checked={item.active ? true : false}></input>
                                        <label htmlFor={"active"+index+"_ok"}> 예</label>
                                        <input type="radio" name={"active"+index} id={"active"+index+"_no"} className="ml10" onChange={(e) => this.formDataChange(e , index)} checked={!item.active ? true : false}></input>
                                        <label htmlFor={"active"+index+"_no"}> 아니오</label>
                                    </td>
                                </tr>
                                <tr>
                                    <th>시판 여부 <span className="redT">*</span></th>
                                    <td>
                                        <input type="radio" name={"selling"+index} id={"sell"+index+"_ok"} onChange={(e) => this.formDataChange(e , index)} checked={item.sale ? true : false}></input>
                                        <label htmlFor={"sell"+index+"_ok"}> 예</label>
                                        <input type="radio" name={"selling"+index} id={"sell"+index+"_no"} className="ml10" onChange={(e) => this.formDataChange(e , index)} checked={!item.sale ? true : false}></input>
                                        <label htmlFor={"sell"+index+"_no"} > 아니오</label>
                                    </td>
                                    <th>대표 라인업 선택 <span className="redT">*</span></th>
                                    <td>
                                        <input type="radio" name={"rp_line"+index} id={"rp"+index+"_ok"}  onChange={(e) => this.formDataChange(e , index)} checked={item.primary ? true : false}></input>
                                        <label htmlFor={"rp"+index+"_ok"}> 예</label>
                                        <input type="radio" name={"rp_line"+index} id={"rp"+index+"_no"} className="ml10"  onChange={(e) => this.formDataChange(e , index)} checked={!item.primary ? true : false}></input>
                                        <label htmlFor={"rp"+index+"_no"}> 아니오</label>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                            <div className="rightT mt20">
                              {!item.isSave &&
                                  <>
                                    <button type="submit" className="btnLine navyBg ml10" onClick={() => this.saveData(index)}>저장</button>
                                    <button type="submit" className="btnLine navyBg ml10" onClick={() => this.deleteForm(item.id)}>삭제</button>
                                  </>
                              }
                        </div>
                        </>)
                          })
                        }
                        <div className="rightT mt20">
                            <button className="btn navyBg" onClick={() => this.onLineUpAdd()}>라인업 추가</button>
                            <button className="btn navyBg ml10" onClick={this.saveAllData}>일괄저장</button>
                            <button type="button" className="btn navyBg ml10" onClick={() => this.props.history.goBack()}>목록</button>
                        </div>
                </div>
                {/* 라인업 추가 영역 끝 */}
            </Fragment>
        );
    }
}

export default LineUpAdd;
