import React, { memo } from "react";
import Select, { createFilter } from "react-select";

// Child Component
import MenuList from "./MenuList";

const ContentSelectStyles = {
    menu: (provided) => ({
        ...provided,
        borderColor: '#e5e5e5',
        borderRadius: 'inherit',
        zIndex: 1010
    }),
    control: (provided) => ({
        ...provided,
        height: 40,
        borderColor: '#e5e5e5',
        borderRadius: 'inherit'
    })
};

function DetailManageTable({
    options,
    contentRows,
    handleSelect,
    handleURL,
    handleURLEnter,
    handleContentRowsSequence
}) {
    return (
        <table className="uk-table uk-table-divider uk-margin-remove">
            <thead>
            <tr>
                <th className="uk-width-small uk-text-nowrap uk-text-center">순위</th>
                <th className="uk-width-auto uk-text-nowrap uk-text-center">콘텐츠</th>
            </tr>
            </thead>
            <tbody>
            {contentRows.map((item) => (
                <tr key={`fn-content-row-${item.id}`}>
                    <td className="uk-text-middle uk-text-center"><span className="uk-badge">{item.id}</span></td>
                    <td className="uk-text-middle">
                        <div className="uk-flex">
                            <div className="uk-flex-1">
                                <Select
                                    filterOption={createFilter({ ignoreAccents: false })}
                                    components={{ MenuList }}
                                    options={options}
                                    value={item.selected}
                                    placeholder="ID, 콘텐츠 제목으로 검색"
                                    onChange={(content) => handleSelect(content, item.id)}
                                    styles={ContentSelectStyles}
                                />
                                <input
                                    className="uk-input uk-margin-small-top"
                                    type="text"
                                    placeholder="자동차줌 뉴스 상세 페이지 URL 입력 후 엔터를 누르면 자동으로 선택됩니다."
                                    onChange={handleURL}
                                    onKeyUp={handleURLEnter}
                                    value={item.url}
                                    data-id={item.id}
                                />
                            </div>
                            <div className="uk-margin-left">
                                <div className="uk-flex uk-flex-column">
                                    <button
                                        className="uk-button uk-button-default"
                                        data-type="up"
                                        data-id={item.id}
                                        onClick={handleContentRowsSequence}
                                    >
                                        <span data-uk-icon="icon: chevron-up" />
                                    </button>
                                    <button
                                        className="uk-button uk-button-default uk-margin-small-top"
                                        data-type="down"
                                        data-id={item.id}
                                        onClick={handleContentRowsSequence}
                                    >
                                        <span data-uk-icon="icon: chevron-down" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            ))}
            </tbody>
        </table>
    );
}

export default memo(DetailManageTable);
