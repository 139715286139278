import axios from 'axios';
import { devUrl } from '../../serviceComm/variable';

export function getPhotoGallery(authToken) {
  const config = {
    method: 'get',
    url: `${devUrl}/admin/photo_galleries`,
    headers: {
      Authorization: `Bearer ${authToken}`
    }
  };

  return axios(config);
}

export function putPhotoGallery(authToken, position , image , car_model_id) {
  let formData = new FormData()
  formData.append('position',position)
  formData.append('car_model_id',car_model_id)
  formData.append('image',image)
  const config = {
    method: 'put',
    url: `${devUrl}/admin/photo_galleries`,
    headers: {
      Authorization: `Bearer ${authToken}`
    },
    data: formData
  };

  return axios(config);
}
