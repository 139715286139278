import { devUrl, keyNumber } from '../../serviceComm/variable';
import DateFormat from 'date-format';

export function getModel(authToken , name , list) {
  const axios = require("axios");
  const config = {
    method: "get",
    url:
    devUrl+"/admin/car_db/car_models/" + "?name=" + name + "&id_desc=true" +
    `${list == 'list' ? "&per=100" : ""}`,
    headers: {
      "x-api-key": keyNumber,
      Authorization: "Bearer " + authToken
    }
  };

  return axios(config);
}

export function getModelList(brandId, per, page, authToken , gname , name , is_active, brandName,modelGroup ,modelName,active,updated,created) {
  const axios = require("axios");
  const config = {
    method: "get",
    url:
      devUrl+"/admin/car_db/car_models" +
      "?per=" +
      per +
      "&page=" +
      page +
      `${brandId != 0 ? `&brand_id=${brandId}` : "" }` +
      "&car_model_group_name=" + gname +
      "&name=" + name +"&id_desc=true" +
      `${is_active !== "all" ? "&is_active=" + is_active : ""}` +
      `${brandName == "desc" ? "&brand_desc=true" : brandName == "asc" ? "&brand_asc=true" : ""}`+
      `${modelGroup == "desc" ? "&car_model_group_desc=true" : modelGroup == "asc" ? "&car_model_group_asc=true" : ""}` +
      `${modelName == "desc" ? "&name_desc=true" : modelName == "asc" ? "&name_asc=true" : ""}` +
      `${active == "desc" ? "&active_desc=true" : active == "asc" ? "&active_asc=true" : ""}` +
      `${updated == "desc" ? "&updated_desc=true" : updated == "asc" ? "&updated_asc=true" : ""}` +
      `${created == "desc" ? "&created_desc=true" : created == "asc" ? "&created_asc=true" : ""}`
      ,
    headers: {
      "x-api-key": keyNumber,
      Authorization: "Bearer " + authToken
    }
  };

  return axios(config);
}


export const uploadImages = (authToken , images) => {
  const axios = require('axios')
  let form = new FormData()
  for(var x = 0; x<images.length; x++){
    form.append('images[]',images[x])
  }
  const config = {
    method : 'post',
    url : devUrl + "/admin/car_db/car_models/draft_images",
    headers: {
      "x-api-key": keyNumber,
      Authorization: "Bearer " + authToken
    },
    data : form
  }

  return axios(config)
}

export const uploadImagesEdit = (authToken , id , images) => {
  const axios = require('axios')
  let form = new FormData()
  for(var x = 0; x<images.length; x++){
    form.append('images[]',images[x])
  }
  const config = {
    method : 'post',
    url : devUrl + "/admin/car_db/car_models/"+id+"/car_model_images",
    headers: {
      "x-api-key": keyNumber,
      Authorization: "Bearer " + authToken
    },
    data : form
  }

  return axios(config)
}

export const getImages = ( authToken ) => {
  const axios = require('axios')
  const config = {
    method : 'get',
    url : devUrl + "/admin/car_db/car_models/draft_images",
    headers: {
      "x-api-key": keyNumber,
      Authorization: "Bearer " + authToken
    },
  }

  return axios(config)
}

export const flushImages = (authToken) => {
  const axios = require('axios')
  const config = {
    method : 'delete',
    url : devUrl + "/admin/car_db/car_models/draft_images/flush",
    headers: {
      "x-api-key": keyNumber,
      Authorization: "Bearer " + authToken
    },
  }

  return axios(config)
}

export const postModel = (authToken , name , code_name , is_new_car , is_preproduct , is_coming ,
   brand_id , car_model_group_id , is_active , exterior_type ,
   car_model_colors_name , car_model_colors_color_code , car_model_colors_image , rival_ids , image_order, last_at, synonyms) => {

    const form = new FormData();
    /* FormData 등록 */
    form.append('name',name)
    form.append('code_name',code_name !== "" ? code_name : null)
    form.append('is_new_car',is_new_car)
    form.append('is_preproduct',is_preproduct)
    form.append('is_coming',is_coming)
    form.append('brand_id',brand_id)
    form.append('car_model_group_id',car_model_group_id)
    form.append('is_active',is_active)
    form.append('exterior_type',exterior_type !== "" ? exterior_type : null)
    form.append('last_at', DateFormat( 'yyyy-MM-dd', last_at))

    synonyms.forEach((item) => {
      form.append('synonyms[]', item);
    });

    for(let x = 0; x<car_model_colors_name.length; x++){
      form.append('car_model_colors[]name',car_model_colors_name[x])
      form.append('car_model_colors[]color_code',car_model_colors_color_code[x].replace('#',''))
      form.append('car_model_colors[]image',car_model_colors_image[x])
    }
    for(let y = 0; y<rival_ids.length; y++){
      form.append('rival_ids[]',rival_ids[y].value)
    }
    for(let z= 0; z<image_order.length; z++){
      form.append('image_order[]',image_order[z])
    }
    /* FormData 등록 */
    const axios = require('axios')
    const config = {
      method : "post",
      headers: {
        "x-api-key": keyNumber,
        Authorization: "Bearer " + authToken
      },
      url : devUrl + "/admin/car_db/car_models",
      data : form
    }

    return axios(config)
}

export const editModel = (authToken , id , name , code_name , is_new_car , is_preproduct , is_coming , brand_id , car_model_group_id , is_active ,
  exterior_type , rival_ids , image_order, last_at, synonyms) => {
    let form = new FormData()
    /* FormData 등록 */
    form.append('name',name)
    form.append('code_name',code_name !== "" ? code_name : null)
    form.append('is_new_car',is_new_car)
    form.append('is_preproduct',is_preproduct)
    form.append('is_coming',is_coming)
    form.append('brand_id',brand_id)
    form.append('car_model_group_id',car_model_group_id)
    form.append('is_active',is_active)
    form.append('exterior_type',exterior_type !== "" ? exterior_type : null)
    form.append('last_at', DateFormat( 'yyyy-MM-dd', last_at))

    synonyms.forEach((item) => {
      form.append('synonyms[]', item);
    });

    // 경쟁차 비교 선택 값이 존재하는지 확인하여 존재하지 않는다면 empty string을 formData에 append한다.
    if(rival_ids.length !== 0) {
      for(let y = 0; y<rival_ids.length; y++){
        form.append('rival_ids[]',rival_ids[y].value)
      }
    } else {
      form.append('rival_ids[]', '');
    }

    for(let z= 0; z<image_order.length; z++){
      form.append('image_order[]',image_order[z])
    }
    /* FormData 등록 */
    const axios = require('axios')
    const config = {
      method : "put",
      headers: {
        "x-api-key": keyNumber,
        Authorization: "Bearer " + authToken
      },
      url : devUrl + "/admin/car_db/car_models/"+id,
      data : form
    }

    return axios(config)
  }

export const deleteModel = (authToken , ids) => {

  let form = new FormData()

  for(let x = 0; x<ids.length; x++){
    form.append('ids[]',ids[x])
  }
  const axios = require('axios')
    const config = {
      method : "delete",
      headers: {
        "x-api-key": keyNumber,
        Authorization: "Bearer " + authToken
      },
      url : devUrl + "/admin/car_db/car_models",
      data : form
    }

    return axios(config)
}

export const getModelDetail = (authToken , id) => {
  const axios = require('axios')
    const config = {
      method : "get",
      headers: {
        "x-api-key": keyNumber,
        Authorization: "Bearer " + authToken
      },
      url : devUrl + "/admin/car_db/car_models/"+id,
    }

    return axios(config)
}

export const deleteDraftImage = (authToken , id) => {
  const axios = require('axios')
    const config = {
      method : "delete",
      headers: {
        "x-api-key": keyNumber,
        Authorization: "Bearer " + authToken
      },
      url : devUrl + '​/admin/car_db/car_models/draft_images',
      data : {
        ids : [id]
      }
    }

    return axios(config)
}

export const deleteImage = (authToken , id , imgId) => {
  const axios = require('axios')
    const config = {
      method : "delete",
      headers: {
        "x-api-key": keyNumber,
        Authorization: "Bearer " + authToken
      },
      url : devUrl + '​/admin/car_db/car_models/'+id+'/car_model_images',
      data : {
        ids : [imgId]
      }
    }

    return axios(config)
}

export const getCarModelImages = (authToken , id ) => {
  const axios = require('axios')
    const config = {
      method : "get",
      headers: {
        "x-api-key": keyNumber,
        Authorization: "Bearer " + authToken
      },
      url : devUrl + '​​/admin/car_db/car_models/'+id+'/car_model_images',
    }

    return axios(config)
}

export const getCarModelImage = (authToken, car_model_id, id, with_base64 = false) => {
  const axios = require('axios')
  const config = {
    method : "get",
    headers: {
      "x-api-key": keyNumber,
      Authorization: "Bearer " + authToken
    },
    url : `${devUrl}/admin/car_db/car_models/${car_model_id}/car_model_images/${id}?with_base64=${with_base64}`
  };

  return axios(config)
};

export const postCarModelColor = (authToken , id , name , color_code , image) => {
  let form = new FormData()
  form.append('name',name)
  form.append('color_code',color_code)
  form.append('image',image)
  const axios = require('axios')
    const config = {
      method : "post",
      headers: {
        "x-api-key": keyNumber,
        Authorization: "Bearer " + authToken
      },
      url : devUrl + '​​/admin/car_db/car_models/'+id+'/car_model_colors',
      data : form
    }

    return axios(config)
}

export const deleteCarModelColor = (authToken , car_id , id) => {
  const axios = require('axios')
    const config = {
      method : "delete",
      headers: {
        "x-api-key": keyNumber,
        Authorization: "Bearer " + authToken
      },
      url : devUrl + '/admin/car_db/car_models/'+car_id+'/car_model_colors/'+id,
    }

    return axios(config)
}

export const putImageOrder = (authToken, id, formData) => {
  const axios = require('axios');
  const config = {
    url: `${devUrl}/admin/car_db/car_models/${id}`,
    method: 'put',
    headers: {
      "x-api-key": keyNumber,
      Authorization: `Bearer ${authToken}`
    },
    data: formData
  }

  return axios(config);
};

export const getModelLineups = (authToken, id) => {
  const axios = require('axios');
  const config = {
    url: `${devUrl}/admin/car_db/car_models/${id}/lineups`,
    method: "get",
    headers: {
      "x-api-key": keyNumber,
      Authorization: `Bearer ${authToken}`
    },
    params: {
      is_active: true
    }
  };

  return axios(config);
};

export const putModelLineupsOrder = (authToken, id, order_lineup_ids) => {
  const axios = require('axios');
  const config = {
    url: `${devUrl}/admin/car_db/car_models/${id}/lineups/order`,
    method: "put",
    headers: {
      "x-api-key": keyNumber,
      Authorization: `Bearer ${authToken}`
    },
    data: {
      order_lineup_ids
    }
  };

  return axios(config);
};
