import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as Common from '../../component/services/common/CommonService';
import * as Service from '../../component/services/content/MediaDetailService';

class MediaDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authToken: Common.getToken.call(this),
      data: {
        category: 0,
        name: '',
        address: '',
        contact: '',
        logo: '',
        homepage_url: '',
        naver_post_url: '',
        naver_tv_url: '',
        youtube_url: '',
        etc_url: ''
      },
      logoPath: '',
      editors: [
        {
          name: '',
          phone: ''
        }
      ]
    };
  }

  componentDidMount() {
    this.getMediaDetail();
  }

  componentDidUpdate() {
  }

  postMediaDetail = (e) => {
    const { data, authToken, editors } = this.state;
    const { history } = this.props;
    if (!data.name) {
      e.preventDefault();
      alert('필수란을 작성해주세요!');
      return;
    }
    const mediaDetail = Service.postMediaDetail(authToken, data, editors);
    mediaDetail.then((res) => {
      if (!(res.status >= 200 && res.status < 400)) return;
      alert('저장되었습니다!');
      history.push('/contents/media');
    }).catch((error) => {
      alert(error.response.data.message);
      if (error.response.status === 401) {
        alert('로그인을 해주세요');
        history.push('/login');
      }
    });
  };

  putMediaDetail = (e) => {
    const { data, authToken, editors } = this.state;
    const { history } = this.props;
    if (!data.name) {
      e.preventDefault();
      alert('필수란을 작성해주세요!');
      return;
    }
    const request = Service.putMediaDetail(authToken, data, editors.length === 0 ? [{ name: '', phone: '' }] : editors);
    request.then((res) => {
      if (!(res.status >= 200 && res.status < 400)) return;
      alert('수정되었습니다!');
      // history.push('/contents/media');
    }).catch((error) => {
      alert(error.response.data.message);
      if (error.response.status === 401) {
        alert('로그인을 해주세요');
        history.push('/login');
      }
    });
  };

  getMediaDetail = () => {
    const { match: { params, params: { id } }, history } = this.props;
    const { authToken, editors } = this.state;
    if (params.id) {
      // 데이터 할당을 파라미터 의존에서 API 의존으로 옮김.
      const request = Service.getMediaDetail(authToken, id);
      request.then(
        (res) => {
          this.setState({
            data: res.data.data.category === '컨텐츠' ? { ...res.data.data, category: 0 } : { ...res.data.data, category: 1 }
            // category type이 req와 res.data.data 각각 값이 달라서 재할당함.
          });
          this.setState({
            editors: res.data.data.editors || [{ name: '', phone: '' }]
            // data 내부에서 있을 경우 데이터 값 변화 감지를 못함.
          });
        }
      ).catch((error) => {
        alert(error.response.data.message);
        if (error.response.status === 401) {
          alert('로그인을 해주세요');
          history.push('/login');
        }
      });
    }
    if (!editors) {
      this.setState({
        editors: [
          {
            name: '',
            phone: ''
          }
        ]
      });
    }
  }

  // getToken = () => {
  //   if (!window.localStorage.getItem('token')) {
  //     alert('로그인을 해주세요');
  //     return;
  //   }
  //   this.setState({
  //     authToken: window.localStorage.getItem('token')
  //   });
  // };

  handleChange = (e) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        [e.target.name]: e.target.value
      }
    });
  };

  uploadImage = (e) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        logo: e.target.files[0]
      }
    });
    if (!e.target.files[0]) return;
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      this.setState({
        // img src에 할당할 url
        logoPath: reader.result
      });
    };
  };

  handleOptionChange = (e) => {
    let category = 0;
    if (e.target.value === '0') {
      category = 0;
    }
    if (e.target.value === '1') {
      category = 1;
    }
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        category
      }
    });
  };

  addEditor = (e) => {
    const { editors } = this.state;
    e.preventDefault();
    this.setState({
      editors: [
        ...editors,
        {
          name: '',
          phone: ''
        }
      ]
    });
  };

  removeEditor = (index) => {
    const { editors } = this.state;
    this.setState({
      editors: [...editors.filter((editor, idx) => index !== idx)]
    });
  };

  editEditor = (e, index) => {
    const { editors } = this.state;
    this.setState({
      editors: [
        ...editors.map((editor, idx) => (
          index === idx ? { ...editor, [e.target.name]: e.target.value } : editor))
      ]
    });
  }

  render() {
    const { data, logoPath, editors } = this.state;
    const { match: { params, params: { id } } } = this.props;
    return (
      <>
        <div className="title disi wd100">
            {' '}
          <p className="mb10">
            <span>홈</span>
            {' '}
            〉
            {' '}
            <span>콘텐츠</span>
            {' '}
            〉
            {' '}
            <span>매체등록/관리</span>
            {' '}
            〉
            {' '}
            <span>등록/수정</span>
          </p>
          매체등록/관리 등록/수정
          <div className="floatR disi">
          <button type="button" className="btnLine navyBg ml10" onClick={params && id ? this.putMediaDetail : this.postMediaDetail}>저장</button>
          <Link to="/contents/media"><button type="button" className="btn navyBg ml10">목록</button></Link>
        </div>
        </div>

        <div className="mt20">
          <form>
            <table className="mda_reset_table">
              <colgroup>
                <col width="10%" />
                <col width="" />
                <col width="10%" />
                <col width="" />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    구분
                      {' '}
                    <span className="redT">*</span>
                  </th>
                  <td colSpan="3">
                    <select onChange={this.handleOptionChange} value={data.category}>
                      <option value={0}>뉴스</option>
                      <option value={1}>동영상</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <th>
                    매체명
                      {' '}
                    <span className="redT">*</span>
                  </th>
                  <td>
                    <input
                      id="test"
                      type="text"
                      onChange={this.handleChange}
                      name="name"
                      value={data.name}
                    />
                  </td>
                  <th>로고이미지</th>
                  <td>
                    <div className="file_wrap">
                      <input type="file" id="mda_log" onChange={this.uploadImage} />
                      <input
                        type="text"
                        readOnly
                        className="file_input"
                        defaultValue={data.logo ? data.logo.name || data.logo : ''}
                      />
                      <label htmlFor="mda_log" className="file_btn">업로드</label>
                    </div>
                    <div className="upload_logimg ml10">
                      {logoPath || data.logo ? <img src={logoPath || data.logo} alt="logo" width="210px" height="118px" /> : ''}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>주소지</th>
                  <td>
                    <input
                      type="text"
                      name="address"
                      onChange={this.handleChange}
                      value={data.address}
                    />

                  </td>
                  <th>연락처</th>
                  <td>
                    <input
                      type="text"
                      name="contact"
                      onChange={this.handleChange}
                      value={data.contact}
                    />

                  </td>
                </tr>
                <tr>
                  <th>편집자</th>
                  <td colSpan="3">
                    <button type="button" className="btnLine navyBg mb10" onClick={this.addEditor}>추가</button>
                    <table className="auto_table">
                      <thead>
                        <tr>
                          <th>편집자</th>
                          <th>연락처</th>
                          <th>관리</th>
                        </tr>
                      </thead>
                      <tbody>
                        {editors.map((editor, index) => (
                          <tr key={index}>
                            <td><input type="text" name="name" onChange={(e) => this.editEditor(e, index)} value={editor.name} /></td>
                            <td><input type="text" name="phone" onChange={(e) => this.editEditor(e, index)} value={editor.phone} /></td>
                            <td>
                              <button
                                type="button"
                                className="btnLine grayBg"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.removeEditor(index);
                                }}
                              >
                                삭제
                                </button>

                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <th>홈페이지</th>
                  <td colSpan="3"><input type="text" name="homepage_url" onChange={this.handleChange} value={data.homepage_url} /></td>
                </tr>
                <tr>
                  <th>네이버포스트</th>
                  <td colSpan="3"><input type="text" name="naver_post_url" onChange={this.handleChange} value={data.naver_post_url} /></td>
                </tr>
                <tr>
                  <th>네이버TV</th>
                  <td colSpan="3"><input type="text" name="naver_tv_url" onChange={this.handleChange} value={data.naver_tv_url} /></td>
                </tr>
                <tr>
                  <th>유튜브</th>
                  <td colSpan="3"><input type="text" name="youtube_url" onChange={this.handleChange} value={data.youtube_url} /></td>
                </tr>
                <tr>
                  <th>기타</th>
                  <td colSpan="3"><input type="text" name="etc_url" onChange={this.handleChange} value={data.etc_url} /></td>
                </tr>
              </tbody>
            </table>
          </form>
          <div className="rightT mt20">
            <button type="button" className="btnLine navyBg ml10" onClick={params && id ? this.putMediaDetail : this.postMediaDetail}>저장</button>
            <Link to="/contents/media"><button type="button" className="btn navyBg ml10">목록</button></Link>
          </div>
        </div>
      </>
    );
  }
}

export default MediaDetail;
