import React, { memo } from "react";
import DatePicker from "react-datepicker";
import DatePickerButton from "../DatePickerButton";

function DatePickerGroup({ start_at, end_at, handleDatePicker }) {
    return (
        <div className="uk-grid-small uk-flex-middle uk-flex-nowrap" data-uk-grid>
            <div>
                <DatePicker
                    locale="ko"
                    dateFormat="yyyy. MM. dd"
                    selected={new Date(start_at)}
                    onChange={(date) => handleDatePicker('start_at', date)}
                    customInput={<DatePickerButton />}
                />
            </div>
            <div>
                <DatePicker
                    locale="ko"
                    dateFormat="yyyy. MM. dd"
                    selected={new Date(end_at)}
                    onChange={(date) => handleDatePicker('end_at', date)}
                    customInput={<DatePickerButton />}
                />
            </div>
        </div>
    );
}

export default memo(DatePickerGroup);
