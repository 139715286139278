import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const ArrowLeft = require('../image/arrow_left.png');
const ArrowRight = require('../image/arrow_right.png');

// param[0] = 클릭 시 이벤트, 페이지네이션 옵션
const Pagination = ({ onClickPage, paginationOption }) => {
  // console.log(requestServer, , paginationOption);
  const [paginationArray, setPaginationArray] = useState([]);
  const [componentOption, setcomponentOption] = useState({
    total_pages: 0,
    current_page: 1,
    prev_page: 0,
    next_page: 0
  });

  // 페이지네이션 기본 배열 생성
  const createPaginationArray = () => {
    let arr = [];
    for (let i = 1; i <= 10; i += 1) {
      arr = [...arr, i];
    }
    return arr;
  };

  // 페이지네이션 기본 배열 생성
  const paginationHistoryArray = (startPage, endPage) => {
    let arr = [];
    for (let i = startPage; i <= endPage; i += 1) {
      arr = [...arr, i];
    }
    return arr;
  };

  // componentDidMount
  useEffect(() => {
    setPaginationArray(createPaginationArray());
  }, []);

  // 총 페이지 수 변화 시 props로 넘겨받은 current_page 할당, 조건에 따라 페이지 개수 증감
  // 변화 기준은 옵션이 바뀌었다고 판단하는 조건이 될 수 있는 것으로 대체 가능.
  useEffect(() => {
    setcomponentOption({
      total_pages: paginationOption.total_pages,
      current_page: paginationOption.current_page
    });

    if (paginationOption.total_pages < 10 && paginationOption.total_pages >= 1) {
      setPaginationArray(createPaginationArray().filter((pageNum) => (
        pageNum <= paginationOption.total_pages
      )));
    }

    if (paginationOption.total_pages >= 10) {
      setPaginationArray(createPaginationArray());
    }
  }, [paginationOption.total_pages]);

  // 현재 페이지 변화 시 props로 넘겨받은 current_page 할당
  useEffect(() => {
    setcomponentOption({
      total_pages: paginationOption.total_pages,
      current_page: paginationOption.current_page
    });
    if (paginationOption.total_pages < 10 && paginationOption.total_pages >= 1) {
      setPaginationArray(createPaginationArray().filter((pageNum) => (
          pageNum <= paginationOption.total_pages
      )));
    }

    // 1페이지를 요청했다면 페이지네이션 요소를 새로 렌더링한다.(EX. 30페이지를 보고 있는 상태에서 정렬을하여 1페이지를 response 받아야하는 경우)
    if (paginationOption.current_page <= 10 && paginationOption.total_pages >= 10) {
      setPaginationArray(createPaginationArray());
    } else {
      if(paginationOption.length !== 0) {
        const startPage = Math.floor((paginationOption.current_page - 1) / 10) * 10 + 1;
        let endPage = startPage + 10 - 1;
        const pageCount = Math.floor(paginationOption.total_pages / paginationOption.per_page + (paginationOption.total_pages % paginationOption.per_page === 0 ? 0 : 1));
        if(endPage > paginationOption.total_pages) {
          endPage = paginationOption.total_pages;
        }
        setPaginationArray(paginationHistoryArray(startPage, endPage));
      }
    }
  }, [paginationOption.current_page]);

  // 다음 페이지, 이전 페이지 클릭 시 호출
  const paginate = (page) => {
    onClickPage(page);
    // 페이지 리스트가 넘어갈때 알고리즘
    if (page > paginationArray[paginationArray.length - 1]) {
      // 넘어가려는 페이지가 현재 페이지네이션의 최대 페이지보다 클 경우
      const arr = [...paginationArray.map((pageNum) => pageNum + 10)];
      // 넘어가려는 페이지 리스트에서 페이지 총 개수가 10개 미만일 경우
      if (arr.indexOf(componentOption.total_pages) !== -1) {
        setPaginationArray(arr.filter((pageNum) => (
          pageNum <= componentOption.total_pages
        )));
      } else setPaginationArray(arr);
    } else if (page < paginationArray[0]) {
      // 넘어가려는 페이지가 현재 페이지네이션의 최소 페이지보다 작을 경우
      if (paginationArray.length < 10) {
      // 마지막 페이지리스트 -> 이전 페이지 이동
        let arr = [];
        for (
          let i = page - 9;
          i <= page;
          i += 1
        ) {
          arr = [...arr, i];
        }
        setPaginationArray([...arr]);
      } else setPaginationArray([...paginationArray.map((pageNum) => pageNum - 10)]);
    }
  };


  return (
    <>
      {paginationOption.total_pages
        ? (
          <ul>
            {componentOption.current_page > 1 ? (
              <li
                onClick={(e) => {
                  e.preventDefault();
                  paginate(componentOption.current_page - 1);
                  setcomponentOption({
                    ...componentOption,
                    current_page: componentOption.current_page - 1
                  });
                }}
              >
                <Link to="#"><img src={ArrowLeft} alt="전 페이지로" /></Link>
              </li>
            ) : ''}
            <li id="first_btn"
                style={{display:'none'}}
                onClick={(e) => {
                  e.preventDefault();
                  onClickPage(1);
                  setcomponentOption({
                    ...componentOption,
                    current_page: 1
                  });
                }}
              >
                <Link to="#">{1}</Link>
              </li>
            {paginationArray.map((page) => (
              <li
                key={page}
                onClick={(e) => {
                  e.preventDefault();
                  onClickPage(page);
                  setcomponentOption({
                    ...componentOption,
                    current_page: page
                  });
                }}
              >
                <Link to="#" className={page === componentOption.current_page ? 'active' : ''}>{page}</Link>
              </li>
            ))}
            {componentOption.current_page < componentOption.total_pages ? (
              <li
                onClick={(e) => {
                  e.preventDefault();
                  paginate(componentOption.current_page + 1);
                  setcomponentOption({
                    ...componentOption,
                    current_page: componentOption.current_page + 1
                  });
                }}
              >
                <Link to="#"><img src={ArrowRight} alt="다음 페이지로" /></Link>
              </li>
            ) : ''}
          </ul>
        )
        : ''}
    </>
  );
};

export default Pagination;
