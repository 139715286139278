import React, { useCallback, memo } from "react";
import Modal from "react-modal";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

const LineupsOrderModalStyles = {
    overlay: {
        background: "rgba(0, 0, 0, .6)",
        zIndex: 1010
    },
    content : {
        width: "100%",
        maxWidth: 1000,
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        transform: "translate(-50%, -50%)"
    }
};

const SortableItem = SortableElement(({ item, sortIndex }) => (
    <li className="uk-grid-collapse uk-background-default uk-z-index-large uk-cursor-grab uk-custom-sortable" data-uk-grid>
        <div className="uk-width-1-5">
            <div className="uk-padding-small uk-text-center">
                <span className="uk-badge">{sortIndex}</span>
            </div>
        </div>
        <div className="uk-width-3-5">
            <div className="uk-padding-small">[{item.id}] {`${item.year}년형`} {item.name}</div>
        </div>
        <div className="uk-width-1-5">
            <div className="uk-padding-small uk-text-center">{item.published_at}</div>
        </div>
    </li>
));

const SortableList = SortableContainer(({ items }) => (
    <ul>
        {items.map((item, index) => (
            <SortableItem key={`lineup-${item.id}`} item={item} index={index} sortIndex={index + 1} />
        ))}
    </ul>
));

function LineupsOrderModal({
    isModalOpen,
    lineupElements,
    handleCloseLineupsOrderModal,
    handleOnSaleLineupOnSortEnd,
    handleNotSaleLineupOnSortEnd,
    handleModelLineupsOrderSave
}) {
    const handleUpdateBeforeSortStart = useCallback((event) => {
        event.node.style = 'box-shadow: 0 14px 25px rgba(0,0,0,.16);';
    }, []);

    return (
        <Modal
            isOpen={isModalOpen}
            onRequestClose={handleCloseLineupsOrderModal}
            style={LineupsOrderModalStyles}
        >
            <div className="uk-modal-header">
                <div className="uk-span-tag">
                    <span className="uk-text-muted">{lineupElements.brandName}</span>
                    <span className="uk-text-muted">{lineupElements.carModelGroupName}</span>
                </div>
                <h3 className="uk-modal-title uk-text-large">{lineupElements.carModelName}</h3>
            </div>
            <div>
                <div className="uk-modal-header uk-logo">
                    판매
                </div>
                <div>
                    <div className="uk-grid-collapse" data-uk-grid>
                        <div className="uk-width-1-5">
                            <div className="uk-background-muted uk-padding-small uk-text-center">순서</div>
                        </div>
                        <div className="uk-width-3-5">
                            <div className="uk-background-muted uk-padding-small uk-text-center">라인업명</div>
                        </div>
                        <div className="uk-width-1-5">
                            <div className="uk-background-muted uk-padding-small uk-text-center">등록일</div>
                        </div>
                    </div>
                    <div className="uk-max-height-small" data-uk-overflow-auto>
                        <SortableList
                            items={lineupElements.on_sales}
                            updateBeforeSortStart={handleUpdateBeforeSortStart}
                            onSortEnd={handleOnSaleLineupOnSortEnd}
                        />
                    </div>
                </div>
                <div className="uk-modal-header uk-logo">
                    미판매
                </div>
                <div>
                    <div className="uk-grid-collapse" data-uk-grid>
                        <div className="uk-width-1-5">
                            <div className="uk-background-muted uk-padding-small uk-text-center">순서</div>
                        </div>
                        <div className="uk-width-3-5">
                            <div className="uk-background-muted uk-padding-small uk-text-center">라인업명</div>
                        </div>
                        <div className="uk-width-1-5">
                            <div className="uk-background-muted uk-padding-small uk-text-center">등록일</div>
                        </div>
                    </div>
                    <div className="uk-max-height-small" data-uk-overflow-auto>
                        <SortableList
                            items={lineupElements.not_sales}
                            updateBeforeSortStart={handleUpdateBeforeSortStart}
                            onSortEnd={handleNotSaleLineupOnSortEnd}
                        />
                    </div>
                </div>
            </div>
            <div className="uk-modal-footer uk-text-right">
                <button
                    type="button"
                    className="uk-button uk-button-primary uk-margin-small-right"
                    onClick={handleModelLineupsOrderSave}
                >
                    저장
                </button>
                <button
                    type="button"
                    className="uk-button uk-button-default"
                    onClick={handleCloseLineupsOrderModal}
                >
                    닫기
                </button>
            </div>
        </Modal>
    );
}

export default memo(LineupsOrderModal);
