import { devUrl } from '../../serviceComm/variable';

const axios = require('axios');

// 지정한 위치에 4분할 광고를 추가합니다.
export function postOneFourAds(authToken, formData) {
  const form = new FormData();
  if (formData.position) form.append('position', formData.position);
  if (formData.name) form.append('name', formData.name);
  if (formData.owner) form.append('owner', formData.owner);
  if (formData.description) form.append('description', formData.description);
  if (formData.url) form.append('url', formData.url);
  if (formData.is_active !== null) form.append('is_active', formData.is_active);
  if (formData.image) form.append('image', formData.image);
  if (formData.start_at) form.append('start_at', formData.start_at);
  if (formData.end_at) form.append('end_at', formData.end_at);
  const config = {
    method: 'post',
    url:
      `${devUrl}/admin/ads/one_four_ads`,
    headers: {
      Authorization: `Bearer ${authToken}`
    },
    data: form
  };

  return axios(config);
}

// 4분할 광고를 보여줍니다.
export function getOneFourAds(authToken) {
  const config = {
    method: 'get',
    url:
      `${devUrl}/admin/ads/one_four_ads`,
    headers: {
      Authorization: `Bearer ${authToken}`
    }
  };

  return axios(config);
}

// 특정 위치의 4분할 광고 내용을 수정합니다.
export function putOneFourAds(authToken, formData) {
  const form = new FormData();
  if (formData.position) form.append('position', formData.position);
  if (formData.name) form.append('name', formData.name);
  if (formData.owner) form.append('owner', formData.owner);
  if (formData.description) form.append('description', formData.description);
  if (formData.url) form.append('url', formData.url);
  if (formData.is_active !== null) form.append('is_active', formData.is_active);
  if (formData.image) form.append('image', formData.image);
  if (formData.start_at) form.append('start_at', formData.start_at);
  if (formData.end_at) form.append('end_at', formData.end_at);
  const config = {
    method: 'put',
    url:
      `${devUrl}/admin/ads/one_four_ads`,
    headers: {
      Authorization: `Bearer ${authToken}`
    },
    data: form
  };

  return axios(config);
}

// 지정한 위치에 이머커스 광고를 추가합니다.
export function postCommerceAdsItems(authToken, formData) {
  const form = new FormData();
  if (formData.position) form.append('position', formData.position);
  if (formData.name) form.append('name', formData.name);
  if (formData.owner) form.append('owner', formData.owner);
  if (formData.description) form.append('description', formData.description);
  if (formData.url) form.append('url', formData.url);
  if (formData.is_active !== null) form.append('is_active', formData.is_active);
  if (formData.image) form.append('image', formData.image);
  const config = {
    method: 'post',
    url:
      `${devUrl}/admin/ads/commerce_ads/items`,
    headers: {
      Authorization: `Bearer ${authToken}`
    },
    data: form
  };

  return axios(config);
}

// 이커머스 광고를 보여줍니다.
export function getCommerceAds(authToken) {
  const config = {
    method: 'get',
    url:
      `${devUrl}/admin/ads/commerce_ads`,
    headers: {
      Authorization: `Bearer ${authToken}`
    }
  };

  return axios(config);
}

// 지정한 위치의 이머커스 광고를 수정합니다.
export function putCommerceAdsItems(authToken, formData) {
  const form = new FormData();
  if (formData.position) form.append('position', formData.position);
  if (formData.name) form.append('name', formData.name);
  if (formData.owner) form.append('owner', formData.owner);
  if (formData.description) form.append('description', formData.description);
  if (formData.url) form.append('url', formData.url);
  if (formData.is_active !== null) form.append('is_active', formData.is_active);
  if (formData.image) form.append('image', formData.image);
  const config = {
    method: 'put',
    url:
      `${devUrl}/admin/ads/commerce_ads/items`,
    headers: {
      Authorization: `Bearer ${authToken}`
    },
    data: form
  };

  return axios(config);
}

// 지정한 위치의 이머커스 광고명을 수정합니다.
export function putCommerceAds(authToken, name) {
  const form = new FormData();
  if (name) form.append('name', name);
  const config = {
    method: 'put',
    url:
      `${devUrl}/admin/ads/commerce_ads`,
    headers: {
      Authorization: `Bearer ${authToken}`
    },
    data: form
  };

  return axios(config);
}
