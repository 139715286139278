import { devUrl, keyNumber } from '../../serviceComm/variable';

const axios = require('axios');

export function login(loginData) {
  const config = {
    method: 'post',
    url: `${devUrl}/admin/sessions`,
    headers: {
      'x-api-key': keyNumber
    },
    data: {
      email: loginData.email,
      password: loginData.password
    }
  };
  return axios(config);
}

export function logout(authToken) {
  const config = {
    method: 'delete',
    url: `${devUrl}/admin/sessions`,
    headers: {
      Authorization: `Bearer ${authToken}`
    }
  };

  return axios(config);
}

export function getToken() {
  if (!window.localStorage.getItem('token')) {
    alert('로그인을 해주세요');
    this.props.history.push('/login');
    return null;
  }
  return window.localStorage.getItem('token');
}
