import React, { Component, Fragment } from 'react';
import {Link} from 'react-router-dom';
import * as Service from '../../component/services/vehicleDB/TrimService'
import * as Common from "../../component/services/common/CommonService";
import Pagination from '../../component/Pagination'
import * as BrandService from "../../component/services/brand/BrandService";
import * as modelGroupService from "../../component/services/vehicleDB/ModelGroupService"
import * as modelService from "../../component/services/vehicleDB/ModelService"
import * as LineUpService from '../../component/services/vehicleDB/LineUpService'
import Select from 'react-select';
import QueryString from 'query-string';

class Trim extends Component {

  constructor(props) {
    super(props)
    const queryStringHistory = QueryString.parse(this.props.location.search);
    this.state = {
      authToken : Common.getToken.call(this),
      trimList : [],
      pagination : [],
      totalCnt : 0,
      per : 8,
        page: queryStringHistory.page ? queryStringHistory.page : 1,

        brandId : {label : queryStringHistory.brandName ? queryStringHistory.brandName : '전체', value : queryStringHistory.brandId ? queryStringHistory.brandId : 0},
        modelGroupId : {label : queryStringHistory.modelGroupName ? queryStringHistory.modelGroupName : '전체' , value : queryStringHistory.modelGroupId ? queryStringHistory.modelGroupId : 0},
        modelId : {label: queryStringHistory.modelName ? queryStringHistory.modelName : '전체' , value : queryStringHistory.modelId ? queryStringHistory.modelId : 0},
        lineUpId : {label: queryStringHistory.lineUpName ? queryStringHistory.lineUpName : '전체' , value : queryStringHistory.lineUpId ? queryStringHistory.lineUpId : 0},
        trimId : {label: queryStringHistory.trimName ? queryStringHistory.trimName : '전체' , value : queryStringHistory.trimId ? queryStringHistory.trimId : 0},
        isActive : {label: queryStringHistory.isActive ? (queryStringHistory.isActive === 'true' ? '활성화' : '비활성화') : '전체', value : queryStringHistory.isActive ? (queryStringHistory.isActive === 'true' ? true : false) : 'all'},

      totalInfo : [],
      modelGroup : [{label: '전체' , value : 0}],
      model : [{label: '전체' , value : 0}],
      trim : [{label: '전체' , value : 0}],
      is_active : [{label: '전체' , value : 'all'} , {label: '활성화' , value : true} ,{label: '비활성화' , value : false}],
      lineUpList : [{label: '전체' , value : 0}],

        brandOrder : queryStringHistory.brandOrder ? queryStringHistory.brandOrder : null,
        modelGroupOrder : queryStringHistory.modelGroupOrder ? queryStringHistory.modelGroupOrder : null,
        activeOrder : queryStringHistory.activeOrder ? queryStringHistory.activeOrder : null,
        updatedOrder : queryStringHistory.updatedOrder ? queryStringHistory.updatedOrder : null,
        createdOrder : queryStringHistory.createdOrder ? queryStringHistory.createdOrder : null,
        modelOrder : queryStringHistory.modelOrder ? queryStringHistory.modelOrder : null,
        nameOrder : queryStringHistory.nameOrder ? queryStringHistory.nameOrder : null,
        lineupOrder : queryStringHistory.lineupOrder ? queryStringHistory.lineupOrder : null,
    }
  }

    componentDidMount(){
        this.getTrimList()
        this.getTotalInfo()
        this.getHistoryData();
    }

    getHistoryData = () => {
        // 브랜드를 선택한 history가 존재한다면 해당 브랜드의 모델그룹 목록 렌더링
        const { brandId: { value } } = this.state;
        if(value !== 0) {
            const req = modelGroupService.getModelG(this.state.authToken , this.state.brandId.value);
            req.then(response => {
                const data = response.data.data
                let options = []
                options.push({label: '전체' , value : 0})
                for(var x = 0; x<data.length; x++){
                    options.push({label: data[x].name , value : data[x].id})
                }
                this.setState({
                    modelGroup : options
                }, () => {
                    // 모델그룹을 선택한 history가 존재한다면 해당 브랜드의 모델 목록 렌더링
                    const { modelGroupId: { label } } = this.state;
                    if(label && label !== '전체') {
                        const req = modelService.getModelList(
                            this.state.brandId.value,
                            10000,
                            1,
                            this.state.authToken,
                            this.state.modelGroupId.label,
                            "","all","","","","","",""
                        )
                        req.then(response => {
                            const data = response.data.data
                            let options = []
                            options.push({label: '전체' , value : 0})
                            for(var x = 0; x<data.length; x++){
                                options.push({label: data[x].name , value : data[x].id})
                            }
                            this.setState({
                                model : options
                            }, () => {
                                const { modelId: { value } } = this.state;
                                if (value && value !== 0) {
                                    const req = LineUpService.getLineUpList(
                                        this.state.authToken,10000,1,"","","","","","","",this.state.brandId.value,this.state.modelGroupId.value,this.state.modelId.value,0,'all'
                                    )
                                    req.then(response => {
                                        const data = response.data.data
                                        let options = []
                                        options.push({label: '전체' , value : 0})
                                        for(var x = 0; x<data.length; x++){
                                            options.push({label: `${data[x].name} : [${data[x].id}]` , value : data[x].id})
                                        }
                                        this.setState({
                                            lineUpList : options
                                        }, () => {
                                           const { lineUpId: { value: lineUpIdValue } } = this.state;
                                           if(lineUpIdValue && lineUpIdValue !== 0) {
                                               const req = Service.getTrimList(this.state.authToken,10000,1,"","","","","","","","",
                                                   this.state.brandId.value,this.state.modelGroupId.value,this.state.modelId.value,this.state.lineUpId.value,0,'all')
                                               req.then(response => {
                                                   const data = response.data.data
                                                   let options = []
                                                   options.push({label: '전체' , value : 0})
                                                   for(var x = 0; x<data.length; x++){
                                                       options.push({label: `${data[x].name} : [${data[x].id}]` , value : data[x].id})
                                                   }
                                                   this.setState({
                                                       trim : options
                                                   })
                                               })
                                           }
                                        });
                                    });
                                }
                            })
                        })
                    }
                });
            })
        }
    };

  setOrder = (name , order) => {
    this.setState({
        brandOrder : null,
        modelGroupOrder : null,
        activeOrder : null,
        updatedOrder : null,
        createdOrder : null,
        modelOrder : null,
        nameOrder : null,
        lineupOrder : null,
    }, () => {
        this.setState({
            [name] : order
        },() => {
            this.handlePage();
            this.getTrimList(1)
        })
    });
  }

  onSearch = () => {
    this.setState({
        page: 1
    }, () => {
        this.handlePage();
        this.getTrimList(1);
    });
  };

  onClickPage = (page) => {
    this.setState({page} , () => {
        this.handlePage();
        this.getTrimList();
    })
  };

  getTotalInfo = aysnc => {
    const brandList = BrandService.getTotalInfo(this.state.authToken);
    brandList.then(response => {
      const data = response.data.data
      let options = []
      options.push({label: '전체' , value : 0})
      for(var x = 0; x<data.length; x++){
        options.push({label: data[x].name , value : data[x].id})
      }
      this.setState({
        totalInfo: options
      });
    });
  };

  selectBrand = selectOption => {
    this.setState({
      brandId : selectOption,
      modelGroupId : {label : '전체' , value : 0},
      modelId : {label: '전체' , value : 0},
      lineUpId : {label: '전체' , value : 0},
      trimId : {label: '전체' , value : 0},

      modelGroup : [{label: '전체' , value : 0}],
      model : [{label: '전체' , value : 0}],
      trim : [{label: '전체' , value : 0}],
    } , () => {
      const req = modelGroupService.getModelG(this.state.authToken , this.state.brandId.value)
      req.then(response => {
        const data = response.data.data
        let options = []
        options.push({label: '전체' , value : 0})
        for(var x = 0; x<data.length; x++){
          options.push({label: data[x].name , value : data[x].id})
        }
        this.setState({
          modelGroup : options
        })
      })
    });
  };

  selectModelGroup = selectOption => {
    this.setState({
      modelGroupId : selectOption,
      modelId : {label: '전체' , value : 0},
      lineUpId : {label: '전체' , value : 0},
      trimId : {label: '전체' , value : 0},

      model : [{label: '전체' , value : 0}],
      lineUpList : [{label: '전체' , value : 0}],
      trim : [{label: '전체' , value : 0}],

    } , () => {
      const req = modelService.getModelList(
        this.state.brandId.value,
      10000,
      1,
      this.state.authToken,
      this.state.modelGroupId.label,
      "","all","","","","","",""
      )
      req.then(response => {
        const data = response.data.data
        let options = []
        options.push({label: '전체' , value : 0})
        for(var x = 0; x<data.length; x++){
          options.push({label: data[x].name , value : data[x].id})
        }
        this.setState({
          model : options
        })
      })
    });
  };

  selectModel = selectOption => {
    this.setState({
      modelId : selectOption,
      lineUpId : {label: '전체' , value : 0},
      trimId : {label: '전체' , value : 0},

      lineUpList : [{label: '전체' , value : 0}],
      trim : [{label: '전체' , value : 0}],
    } , () => {
      const req = LineUpService.getLineUpList(
        this.state.authToken,10000,1,"","","","","","","",this.state.brandId.value,this.state.modelGroupId.value,this.state.modelId.value,0,'all'
      )
      req.then(response => {
        const data = response.data.data
        let options = []
        options.push({label: '전체' , value : 0})
        for(var x = 0; x<data.length; x++){
          options.push({label: `${data[x].name} : [${data[x].id}]` , value : data[x].id})
        }
        this.setState({
          lineUpList : options
        })
      })
    })
  }

  selectLineUp = selectOption => {
    this.setState({
      lineUpId : selectOption,
      trimId : {label: '전체' , value : 0},
      trim : [{label: '전체' , value : 0}],
    } , () => {
      const req = Service.getTrimList(this.state.authToken,10000,1,"","","","","","","","",
      this.state.brandId.value,this.state.modelGroupId.value,this.state.modelId.value,this.state.lineUpId.value,0,'all')
      req.then(response => {
        const data = response.data.data
        let options = []
        options.push({label: '전체' , value : 0})
        for(var x = 0; x<data.length; x++){
          options.push({label: `${data[x].name} : [${data[x].id}]` , value : data[x].id})
        }
        this.setState({
          trim : options
        })
      })
    })
  }

  selectTrim = selectOption => {
    this.setState({
      trimId : selectOption
    })
  }

  getTrimList = is_render => {
    const req = Service.getTrimList(this.state.authToken , this.state.per, this.state.page ,
      this.state.brandOrder     ,
      this.state.modelGroupOrder,
      this.state.modelOrder    ,
      this.state.lineupOrder   ,
      this.state.nameOrder     ,
      this.state.activeOrder   ,
      this.state.createdOrder  ,
      this.state.updatedOrder  ,
      this.state.brandId.value,
      this.state.modelGroupId.value,
      this.state.modelId.value,
      this.state.lineUpId.value,
      this.state.trimId.value,
      this.state.isActive.value
    )
    req.then(res => {
      this.setState({
        trimList : res.data.data,
        pagination : res.data.pagination,
        totalCnt : res.data.total_entries,
      } , () => {
        if(is_render){
          if(document.querySelector('#first_btn'))
          document.querySelector('#first_btn').click()
        }
      })
    })
  }

  activeSelect = selectOption => {
    this.setState({
      isActive : selectOption
    })
  }

  checkBoxCheck = e => {
    const data = document.querySelectorAll('.checkBox')
    const chkAll = document.getElementById('check_all');
    let check = 0;
    for(let x = 0; x<data.length; x++){
      if(data[x].checked){
        check+=1
      }
    }
    if(check == 8){
      chkAll.checked = true
    }else{
      chkAll.checked = false
    }
  }

  checkAll = () => {
    const chk = document.getElementsByClassName('checkBox');
    const chkAll = document.getElementById('check_all');
    if(chkAll.checked) {
      for(var x = 0; x<chk.length; x++) {
          chk[x].checked = true
      }
    }
    else {
      for(var x = 0; x<chk.length; x++) {
          chk[x].checked = false
      }
    }
  }

  DELETE = () => {

    const ids = []
   const checkBoxs = document.querySelectorAll('.checkBox')
   for(let x = 0; x<checkBoxs.length; x++) {
     if(checkBoxs[x].checked){
       ids.push(parseInt(checkBoxs[x].id))
     }
   }
   if(ids.length > 0){
     if (window.confirm('삭제하시겠습니까?')){
       const deleteTrim = Service.deleteTrim(
         this.state.authToken,
         ids
       )

       deleteTrim.then(res => {
         if (res.status >= 200 && res.status < 400) {
           this.getTrimList(1)
           const chk = document.getElementsByClassName('checkBox');
           for(var x = 0; x<chk.length; x++) {
             chk[x].checked = false
           }
         }
       }).catch(err => alert('요청을 처리하는 도중에 문제가 발생하였습니다.'))
     }

   }
 }

    goEdit = () => {
        if(Array.from(document.querySelector('#bulkEditButton').classList).indexOf('bulkEditButtonOn') !== -1){
        const data = document.querySelectorAll('.checkBox')
        let check = 0;
        for(let x = 0; x<data.length; x++){
          if(data[x].checked){
            check = data[x].name
          }
        }
        this.handlePage(`/vehicleDB/trim/trimUpdate/bulk${check}`);
        }
    }

    // 트림 페이지 내의 모든 페이지 라우팅 핸들링
    handlePage = (path) => {
        const {
            brandId: { label: brandName, value: brandId },
            modelGroupId: { label: modelGroupName, value: modelGroupId },
            modelId: { label: modelName, value: modelId },
            lineUpId: { label: lineUpName, value: lineUpId },
            trimId: { label: trimName, value: trimId },
            isActive: { value: isActive },
            brandOrder,
            modelGroupOrder,
            activeOrder,
            updatedOrder,
            createdOrder,
            modelOrder,
            nameOrder,
            lineupOrder,
            page
        } = this.state;
        let searchInfo = {
            brandId,
            brandName,
            modelGroupId,
            modelGroupName,
            modelId,
            modelName,
            lineUpId,
            lineUpName,
            trimName,
            trimId,
            isActive,
            brandOrder,
            modelGroupOrder,
            activeOrder,
            updatedOrder,
            createdOrder,
            modelOrder,
            nameOrder,
            lineupOrder,
            page
        };
        // 해당 키가 가진 값이 존재하지 않는다면 제거
        Object.keys(searchInfo).map((item) => {
            if (item === 'brandId') {
                (searchInfo[item] === 0 || searchInfo[item].toString().length === 0) && delete searchInfo[item];
            } else if (item === 'brandName') {
                (searchInfo[item] === '전체' || searchInfo[item].toString().length === 0) && delete searchInfo[item];
            } else if (item === 'modelGroupId') {
                (searchInfo[item] === 0 || searchInfo[item].toString().length === 0) && delete searchInfo[item];
            } else if (item === 'modelGroupName') {
                (searchInfo[item] === '전체' || searchInfo[item].toString().length === 0) && delete searchInfo[item];
            } else if (item === 'modelId') {
                (searchInfo[item] === 0 || searchInfo[item].toString().length === 0) && delete searchInfo[item];
            } else if (item === 'modelName') {
                (searchInfo[item] === '전체' || searchInfo[item].toString().length === 0) && delete searchInfo[item];
            } else if (item === 'lineUpId') {
                (searchInfo[item] === 0 || searchInfo[item].toString().length === 0) && delete searchInfo[item];
            } else if (item === 'lineUpName') {
                (searchInfo[item] === '전체' || searchInfo[item].toString().length === 0) && delete searchInfo[item];
            } else if (item === 'trimId') {
                (searchInfo[item] === 0 || searchInfo[item].toString().length === 0) && delete searchInfo[item];
            } else if (item === 'trimName') {
                (searchInfo[item] === '전체' || searchInfo[item].toString().length === 0) && delete searchInfo[item];
            } else if (item === 'isActive') {
                (searchInfo[item] === 'all' || searchInfo[item].toString().length === 0) && delete searchInfo[item];
            } else {
                (searchInfo[item] === null || searchInfo[item].toString().length === 0) && delete searchInfo[item];
            }
        });
        let queryString = Object.keys(searchInfo).map((item, index) => searchInfo[item] !== null && `${item}=${searchInfo[item]}`).join('&');
        this.props.history.push({
            pathname: path ? path : '/vehicleDB/trim',
            search: `?${queryString}`
        });
    };

  render() {
    const state = this.state
      return (
          <Fragment>
              {/* 차량DB> 트림 영역 시작 */}
              <div className="title"><p className="mb10"><span>홈</span> 〉 <span>차량DB</span> 〉 <span>트림</span></p>트림</div>
              <div className="info_searchwrap">
                      <table>
                          <colgroup>
                              <col width="10%"></col>
                              <col width="*"></col>
                          </colgroup>
                          <tbody>
                              <tr>
                                  <th>브랜드명</th>
                                  <td>
                                  <Select className="reactSelect w-500"
                                    options={this.state.totalInfo}
                                    value={this.state.brandId}
                                    name="brandId"
                                    onChange={this.selectBrand}
                                    placeholder="전체"
                                    isSearchable={true}/>
                                  </td>
                              </tr>
                              <tr>
                                  <th>모델그룹명</th>
                                  <td>
                                  <Select className="reactSelect w-500"
                                      options={this.state.modelGroup}
                                      value={this.state.modelGroupId}
                                      name="brandId"
                                      onChange={this.selectModelGroup}
                                      placeholder="전체"
                                      isSearchable={true}/>
                                  </td>
                              </tr>
                              <tr>
                                  <th>모델명</th>
                                  <td>
                                  <Select className="reactSelect w-500"
                                      options={this.state.model}
                                      value={this.state.modelId}
                                      name="brandId"
                                      onChange={this.selectModel}
                                      placeholder="전체"
                                      isSearchable={true}/>
                                  </td>
                              </tr>
                              <tr>
                                  <th>라인업명</th>
                                  <td>
                                  <Select className="reactSelect w-500"
                                      options={this.state.lineUpList}
                                      value={this.state.lineUpId}
                                      name="brandId"
                                      onChange={this.selectLineUp}
                                      placeholder="전체"
                                      isSearchable={true}/>
                                  </td>
                              </tr>
                              <tr>
                                  <th>트림명</th>
                                  <td>
                                  <Select className="reactSelect w-500"
                                      options={this.state.trim}
                                      value={this.state.trimId}
                                      name="brandId"
                                      onChange={this.selectTrim}
                                      placeholder="전체"
                                      isSearchable={true}/>
                                  </td>
                              </tr>
                              <tr>
                                  <th>활성화 상태</th>
                                  <td>
                                  <Select className="reactSelect w-500"
                                      options={this.state.is_active}
                                      value={this.state.isActive}
                                      name="brandId"
                                      onChange={this.activeSelect}
                                      placeholder="전체"
                                      isSearchable={true}/>
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                      <div className="centerT mt20">
                          <button type="button" className="btnLine navyBg medium" onClick={this.onSearch}>검색</button>
                      </div>
              </div>
              <div className="info_resultwrap">
                  <div className="info_tabletop">
                      <div className="mb10">
                          <button className="btnLine navyBg ml10" onClick={() => this.handlePage(`/vehicleDB/trim/new`)}>추가</button>
                          <button href="#" className="btn navyBg centerT ml10" onClick={this.DELETE}>삭제</button>
                      </div>
                  </div>
                  <table>
                      <colgroup>
                          <col width="5%"></col>
                          <col width=""></col>
                          <col width=""></col>
                          <col width=""></col>
                          <col width=""></col>
                          <col width=""></col>
                          <col width=""></col>
                          <col width=""></col>
                          <col width=""></col>
                          <col width=""></col>
                          <col width="10%"></col>
                      </colgroup>
                      <thead>
                          <tr>
                              <th colSpan="11" className="ccc">
                                총 <span>{this.state.totalCnt}</span>개
                              </th>
                          </tr>
                          <tr>
                              <th><input type="checkbox" id="check_all" name="trim-check_all" onClick={() => this.checkAll()}></input></th>
                              <th>번호</th>
                              <th>
                                  브랜드명
                                  <button className="set_btn" onClick={() => this.setOrder("brandOrder","desc")}>▼</button>
                                  <button className="set_btn" onClick={() => this.setOrder("brandOrder","asc")}>▲</button>
                              </th>
                              <th>
                                  모델그룹명
                                  <button className="set_btn"  onClick={() => this.setOrder("modelGroupOrder","desc")}>▼</button>
                                  <button className="set_btn"  onClick={() => this.setOrder("modelGroupOrder","asc")}>▲</button>
                              </th>
                              <th>
                                  모델명
                                  <button className="set_btn" onClick={() => this.setOrder("modelOrder","desc")}>▼</button>
                                  <button className="set_btn" onClick={() => this.setOrder("modelOrder","asc")}>▲</button>
                              </th>
                              <th>
                                  라인업명
                                  <button className="set_btn" onClick={() => this.setOrder("lineupOrder","desc")}>▼</button>
                                  <button className="set_btn" onClick={() => this.setOrder("lineupOrder","asc")}>▲</button>
                              </th>
                              <th>
                                  트림명
                                  <button className="set_btn" onClick={() => this.setOrder("nameOrder","desc")}>▼</button>
                                  <button className="set_btn" onClick={() => this.setOrder("nameOrder","asc")}>▲</button>
                              </th>
                              <th>
                                  활성화 상태
                                  <button className="set_btn" onClick={() => this.setOrder("activeOrder","desc")}>▼</button>
                                  <button className="set_btn" onClick={() => this.setOrder("activeOrder","asc")}>▲</button>
                              </th>
                              <th>
                                  수정일
                                  <button className="set_btn" onClick={() => this.setOrder("updatedOrder","desc")}>▼</button>
                                  <button className="set_btn" onClick={() => this.setOrder("updatedOrder","asc")}>▲</button>
                              </th>
                              <th>
                                  등록일
                                  <button className="set_btn" onClick={() => this.setOrder("createdOrder","desc")}>▼</button>
                                  <button className="set_btn" onClick={() => this.setOrder("createdOrder","asc")}>▲</button>
                              </th>
                              <th>관리</th>
                          </tr>
                      </thead>
                      <tbody>
                        {
                          state.trimList ?
                          state.trimList.map((item , index) => {
                            return(
                            <tr key={index}>
                              <td><input type="checkbox" className="checkBox" id={item.id} name={item.car_model_id}  onChange={this.checkBoxCheck}></input></td>
                              <td>{item.id}</td>
                              <td><Link to={"/brand/totalInfo/totalInfoDetail/"+item.brand_id}>{item.brand_name}</Link></td>
                              <td><Link to={"/vehicleDB/modelGroup/modelGroupDetail/"+item.car_model_group_id}>{item.car_model_group_name}</Link></td>
                              <td><Link to={"/vehicleDB/model/modelDetail/"+item.car_model_id}>{item.car_model_name}</Link></td>
                              <td><Link to={"/vehicleDB/lineUp/lineUpUpdate/"+item.brand_id+"&"+item.car_model_group_id+"&"+item.lineup_id}>{item.lineup_name}</Link></td>
                              <td><Link to={"/vehicleDB/trim/trimUpdate/"+item.id}>{item.name}</Link></td>
                              <td>{item.is_active ? "활성화" : "비활성화"}</td>
                              <td>{`${new Date(item.updated_at).getFullYear() - 2000}.
                                  ${new Date(item.updated_at).getMonth()+1 < 10 ? `0${new Date(item.updated_at).getMonth()+1}` : new Date(item.updated_at).getMonth()+1}.
                                  ${new Date(item.updated_at).getDate() < 10 ? `0${new Date(item.updated_at).getDate()}` : new Date(item.updated_at).getDate()}`}</td>
                              <td>{
                                 `${new Date(item.created_at).getFullYear() - 2000}.
                                  ${new Date(item.created_at).getMonth()+1 < 10 ? `0${new Date(item.created_at).getMonth()+1}` : new Date(item.created_at).getMonth()+1}.
                                  ${new Date(item.created_at).getDate() < 10 ? `0${new Date(item.created_at).getDate()}` : new Date(item.created_at).getDate()}`}</td>
                              <td>
                                  <button className="btn grayBg ml10" onClick={() => this.handlePage(`/vehicleDB/trim/${item.id}`)}>수정</button>
                              </td>
                            </tr>
                            )
                          })
                          :
                          <></>
                        }
                      </tbody>
                  </table>
                  <div className="pagination">
                  <Pagination
                      onClickPage={this.onClickPage}
                      paginationOption={this.state.pagination}
                    />
                  </div>
              </div>
              {/* 차량DB> 트림 영역 끝 */}
          </Fragment>
      );
  }
}

export default Trim;
