import React, {useEffect, useState} from 'react';
import {isEmpty, some, keys, last} from 'lodash'
import {devUrl} from '../../component/serviceComm/variable.js';

// FontAwesome
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFileExcel, faFileUpload, faClock, faTrash} from '@fortawesome/free-solid-svg-icons';

// DatePicker
import DatePicker, {registerLocale} from 'react-datepicker';
import ko from 'date-fns/locale/ko';
import moment from 'moment';

// Services
import * as Common from '../../component/services/common/CommonService';
import * as Service from '../../component/services/brand/SalesConditionService';

// Emotion & Spinner
import {css} from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';

import '../../style/brand/salesRecord.scss';

// DatePicker Locale
registerLocale('ko', ko);

function SalesCondition() {
    const [authToken] = useState(Common.getToken.call(this));
    const [loading, setLoading] = useState(true);
    const [salesConditions, setSalesConditions] = useState(null);
    const [targetDate, setTargetDate] = useState(null);
    const [latestDate, setLatestDate] = useState(null);
    const [downloadable, setDownloadable] = useState(false);
    const [message, setMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const spinnerCSS = css`
        margin-left: 5px;
        vertical-align: middle;
    `;

    useEffect(() => {
        loadAndSetSalesConditions();
    }, []);

    useEffect(() => {
        let targetDate;
        let latestDate;

        if (isEmpty(salesConditions)) {
            targetDate = new Date();
            latestDate = null;
        } else {
            let latestData = last(salesConditions);
            latestDate = last(keys(latestData));
            targetDate = moment(latestDate).add(1, 'months').toDate();
        }

        setLatestDate(latestDate);
        setTargetDate(targetDate);
    }, [salesConditions]);


    useEffect(() => {
        if (targetDate && !loading) {
            let targetDateString = moment(targetDate).format('YYYY-MM').toString();
            let downloadable = some(salesConditions, el => last(keys(el)) === targetDateString);
            setDownloadable(downloadable);
        }

    }, [targetDate]);


    const loadAndSetSalesConditions = () => {
        Service.getSalesConditions(authToken).then((response) => {
            setSalesConditions(response.data.data);
            setLoading(false);
        });
    };

    const handleDateChange = (date) => {
        setTargetDate(date);
    };

    const handleDestroy = () => {
        if (window.confirm("삭제하시겠습니까?\n삭제 완료 후 삭제된 내역이 다운로드 됩니다.")) {
            window.location.href = `${devUrl}/admin/brands/sales_conditions/destroy/${moment(targetDate).format('YYYY')}/${moment(targetDate).format('MM')}.xlsx`;
            setLoading(true);
            setMessage("삭제중입니다... 잠시만 기다려주세요.");
            setErrorMessage(null);
            setTimeout(() => {
                setMessage(`${moment(targetDate).format('YYYY')}년 ${moment(targetDate).format('MM')}일 판매조건이 삭제되었습니다.`);
                loadAndSetSalesConditions();
            }, 5000);
        }
    };

    const uploadExcelFile = (event) => {
        const file = event.currentTarget.files[0];
        const originalFileName = file.name;
        const fileExtension = originalFileName.split('.')[1].toLocaleLowerCase();
        const year = moment(targetDate).format('YYYY');
        const month = moment(targetDate).format('MM');

        if (fileExtension !== 'xlsx') {
            alert('확장자가 올바르지 않습니다.\nxlsx 확장자를 가진 엑셀 파일만 업로드 할 수 있습니다.');
            return false;
        }

        setLoading(true);
        Service.postSalesConditions(authToken, file, year, month).then((response) => {
            afterUpload(response.data.data);
        }).catch((error) => {
            setTargetDate(new Date(`${year}-${month}-01`));
            setErrorMessage(`[업로드 실패] ${error.response.data.message}`);
        }).finally(() => {
            setLoading(false);
        });

        event.currentTarget.value = null;
    };

    const afterUpload = (data) => {
        setMessage(`${data.year}년 ${data.month}월 판매조건 데이터 ${data.size}개가 등록되었습니다.`);
        setErrorMessage(null);
        loadAndSetSalesConditions();
    };

    return (
        <>
            <div className="title">
                <p className="mb10">
                    <span>홈</span> 〉 <span>브랜드</span> 〉 <span>판매조건관리</span>
                </p>
                판매조건관리{' '}
            </div>
            <div className={'sr-content-wrapper'}>
                <div className={'sr-content-wrapper-inner'}>

                    <div className={'sr-content-head mb10'}>
                        <span className={'info-text'}>{message}</span>
                    </div>

                    <div className={'sr-content-head mb10'}>
                        <span className={'error-text'}>{errorMessage}</span>
                    </div>

                    <div className={'sr-content-head mb10'}>
                        <FontAwesomeIcon icon={faClock}/>
                        {' '} 최근 등록한 판매조건 날짜: {' '}
                        <span>{latestDate ? moment(latestDate).format('YYYY. MM') : '없음'}</span>
                        <ClipLoader
                            css={spinnerCSS}
                            size={20}
                            color={'#4260e9'}
                            loading={loading}
                        />
                    </div>
                    <div className={'sr-content-head'}>
                        <DatePicker
                            locale={'ko'}
                            dateFormat={'yyyy-MM'}
                            onChange={handleDateChange}
                            selected={targetDate}
                            showMonthYearPicker
                            disabled={loading}
                        />
                    </div>

                    {!loading &&
                    <>
                        <div className={'mb10 sr-content-head font_small'}>판매조건을 등록할 날짜를 선택해주세요.</div>
                        <a className={'btn download-excel'}
                           href={`${devUrl}/admin/brands/sales_conditions/new.xlsx`}>
                            <FontAwesomeIcon icon={faFileExcel} size={'2x'}/>판매조건 양식 다운로드
                        </a>

                        {downloadable &&
                        <a href={`${devUrl}/admin/brands/sales_conditions/${moment(targetDate).format('YYYY')}/${moment(targetDate).format('MM')}.xlsx`}
                           className={'btn download-excel ml10'}>
                            <FontAwesomeIcon icon={faFileExcel}
                                             size={'2x'}/> {moment(targetDate).format('YYYY. MM')} 판매조건 다운로드
                        </a>}

                        <label htmlFor={'upload'}>
                            <div className={'btn upload-excel ml10'}>
                                <FontAwesomeIcon icon={faFileUpload} size={'2x'}/> 판매조건 업로드
                            </div>
                        </label>

                        {downloadable &&
                        <a onClick={handleDestroy}
                           className={'btn download-excel ml10'}>
                            <FontAwesomeIcon icon={faTrash}
                                             size={'2x'}/> {moment(targetDate).format('YYYY. MM')} 판매조건 삭제
                        </a>

                        }

                        <input type={'file'} id={'upload'} name={'file'} onChange={uploadExcelFile}/>
                    </>
                    }

                </div>
            </div>
        </>
    );
}

export default SalesCondition;
