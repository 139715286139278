import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import * as Service from '../../component/services/vehicleDB/ModelGroupService'
import * as Common from "../../component/services/common/CommonService";
import * as BrandService from "../../component/services/brand/BrandService";
import * as modelGroupService from "../../component/services/vehicleDB/ModelGroupService"
import Pagination from '../../component/Pagination'
import Select from 'react-select';
import QueryString from 'query-string';

class ModelGroup extends Component{
  constructor(props){
    super(props)
    const queryStringHistory = QueryString.parse(this.props.location.search);
    console.log(queryStringHistory);
    this.state = {
      authToken :Common.getToken.call(this),

      brandId : {label : queryStringHistory.brandName ? queryStringHistory.brandName : '전체', value : queryStringHistory.brandId ? queryStringHistory.brandId : 0},
      modelGroupId : {label : queryStringHistory.modelGroupId ? queryStringHistory.modelGroupId : '전체' , value : 0},
      isActive : {label: queryStringHistory.isActive ? (queryStringHistory.isActive === 'true' ? '활성화' : '비활성화') : '전체', value : queryStringHistory.isActive ? (queryStringHistory.isActive === 'true' ? true : false) : 'all'},
      totalInfo : [],
      modelGroup : [{label: '전체' , value : 0}],
      is_active : [{label: '전체' , value : 'all'} , {label: '활성화' , value : true} ,{label: '비활성화' , value : false}],
      per : 10,
      page : queryStringHistory.page ? queryStringHistory.page : 1,
      modelGroupList : [],
      pagination : [],
      totalLength : 0,
      name : "",
      brandNameOrder : queryStringHistory.brandNameOrder ? queryStringHistory.brandNameOrder : null,
      modelGroupNameOrder : queryStringHistory.modelGroupNameOrder ? queryStringHistory.modelGroupNameOrder : null,
      activeOrder : queryStringHistory.activeOrder ? queryStringHistory.activeOrder : null,
      updatedOrder : queryStringHistory.updatedOrder ? queryStringHistory.updatedOrder : null,
      createdOrder : queryStringHistory.createdOrder ? queryStringHistory.createdOrder : null,
      totalCnt :0    
    }
  }

  componentDidMount(){    
    this.getTotalInfo()
    this.getModelGroup()
    this.getModelGroupList()
    this.getHistoryData();
  }

  getHistoryData = () => {
      // 브랜드를 선택한 history가 존재한다면 해당 브랜드의 모델그룹 목록 렌더링
      const { brandId: { value } } = this.state;
      if(value !== 0) {
          const req = modelGroupService.getModelG(this.state.authToken , this.state.brandId.value);
          req.then(response => {
              const data = response.data.data
              let options = []
              options.push({label: '전체' , value : 0})
              for(var x = 0; x<data.length; x++){
                  options.push({label: data[x].name , value : data[x].id})
              }
              this.setState({
                  modelGroup : options
              })
          })
      }
  };

  getTotalInfo = aysnc => {
    const brandList = BrandService.getTotalInfo(this.state.authToken);
    brandList.then(response => {
      const data = response.data.data
      let options = []
      options.push({label: '전체' , value : 0})
      for(var x = 0; x<data.length; x++){
        options.push({label: data[x].name , value : data[x].id})
      }
      this.setState({
        totalInfo: options
      });
    });
  };

  getModelGroup = async => {
    const PriceOptions = Service.getModelG(
      this.state.authToken
    )
    PriceOptions.then(res => {
      this.setState({
        totalLength : res.data.data.length
      })
    })
  }

  getModelGroupList = is_render => {

    const ModelList = Service.getModelGList(
      this.state.brandId.value,
        is_render ? 10 : this.state.per,
        is_render ? 1 : this.state.page,
      this.state.authToken,
      this.state.modelGroupId.label == "전체" ? "" : this.state.modelGroupId.label ,
      this.state.isActive.value,
      this.state.brandNameOrder,
      this.state.modelGroupNameOrder,
      this.state.activeOrder,
      this.state.updatedOrder,
      this.state.createdOrder
    );
    ModelList.then(response => {
      this.setState({
        modelGroupList: response.data.data,
        pagination : response.data.pagination,
        totalCnt : response.data.total_entries
      });
    });
  };

  selectBrand = selectOption => {
    this.setState({
      brandId : selectOption,
      modelGroup : [{label: '전체' , value : 0}],
      modelGroupId : {label : '전체' , value : 0},
    } , () => {
      const req = modelGroupService.getModelG(this.state.authToken , this.state.brandId.value)
      req.then(response => {
        const data = response.data.data
        let options = []
        options.push({label: '전체' , value : 0})
        for(var x = 0; x<data.length; x++){
          options.push({label: data[x].name , value : data[x].id})
        }
        this.setState({
          modelGroup : options
        })
      })
    });
  };

  selectModelGroup = selectOption => {
    this.setState({
      modelGroupId : selectOption,
    });
  };

  activeSelect = selectOption => {
    this.setState({
      isActive : selectOption
    })
  }

  onClickPage = (page) => {
    this.setState(
      {
        page
    },
      () => {
          this.handlePage();
          this.getModelGroupList();
          // this.props.history.push({
          //     pathname: '/vehicleDB/modelGroup',
          //     search: `?page=${page}`
          // });
      }
    );
  };

  onSearch = () => {
      this.setState({
          page: 1
      }, () => {
          this.handlePage();
          this.getModelGroupList(1);
      });
  }

  brandNameOrder = (order) => {
    this.setState({
        brandNameOrder : order,
        modelGroupNameOrder: null,
        activeOrder: null,
        updatedOrder: null,
        createdOrder: null
    }, () => {
        this.handlePage();
        this.getModelGroupList(1);
    })
  }

  modelGroupNameOrder = (order) => {
    this.setState({
        brandNameOrder: null,
        modelGroupNameOrder : order,
        activeOrder: null,
        updatedOrder: null,
        createdOrder: null
    } , () => {
        this.handlePage();
        this.getModelGroupList(1);
    })
  }

  activeOrder = (order) => {
    this.setState({
        brandNameOrder: null,
        modelGroupNameOrder: null,
        activeOrder : order,
        updatedOrder: null,
        createdOrder: null
    } , () => {
        this.handlePage();
        this.getModelGroupList(1);
    })
  }

  updatedOrder = order => {
    this.setState({
        brandNameOrder: null,
        modelGroupNameOrder: null,
        activeOrder: null,
        updatedOrder : order,
        createdOrder: null
    } , () => {
        this.handlePage();
        this.getModelGroupList(1);
    })
  }

  createdOrder = order => {
    this.setState({
        brandNameOrder: null,
        modelGroupNameOrder: null,
        activeOrder: null,
        updatedOrder: null,
        createdOrder : order
    } , () => {
        this.handlePage();
        this.getModelGroupList(1);
    })
  }

  infoSearchValue = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onDelete = () => {
    const chk = document.getElementsByClassName('checkbox');
    const chkAll = document.getElementById('model_all');
    let ids = []
    for(var x = 0; x<chk.length; x++) {
      if(chk[x].checked){
        ids.push(parseInt(chk[x].classList[1]))
      }
    }

    if (window.confirm('삭제하시겠습니까?')){
      const deleteModelGroups = Service.deleteModelGroups(
        this.state.authToken,
        ids
      )
  
      deleteModelGroups.then(res =>{
        this.getModelGroupList()
        chkAll.checked = false;
        for(var x = 0; x<chk.length; x++) {
          if(chk[x].checked){
            chk[x].checked = false
          }
        }
      })
    }
    
  }

  onClickCheckAll = () => {
    const chk = document.getElementsByClassName('checkbox');
    const chkAll = document.getElementById('model_all');

    if(chkAll.checked) {
      for(var x = 0; x<chk.length; x++) {
          chk[x].checked = true
      }
    }
    else {
      for(var x = 0; x<chk.length; x++) {
          chk[x].checked = false
      }
    }
      
  }

  checkAll = () => {
    const chk = document.getElementsByClassName('checkbox');
    const chkAll = document.getElementById('model_all');
    let isCheck = true
    for(var x = 0; x<chk.length; x++) {
      if(!chk[x].checked){
        chkAll.checked = false
        isCheck = false;
      }
    }
    if(isCheck) chkAll.checked = true;
  }

  handleKeyPress = () => {
    if (window.event.keyCode == 13) {
      this.onSearch()
    }
  }

  // 모델그룹 페이지 내의 모든 페이지 라우팅 핸들링
  handlePage = (path) => {
      const {
          brandId: { label: brandName, value: brandId },
          modelGroupId: { label: modelGroupId },
          isActive: { value: isActive },
          brandNameOrder,
          modelGroupNameOrder,
          activeOrder,
          updatedOrder,
          createdOrder,
          page
      } = this.state;
      let searchInfo = {
          brandId,
          brandName,
          modelGroupId,
          isActive,
          brandNameOrder,
          modelGroupNameOrder,
          activeOrder,
          updatedOrder,
          createdOrder,
          page
      };
      Object.keys(searchInfo).map((item) => {
          if (item === 'brandId') {
              (searchInfo[item] === 0 || searchInfo[item].toString().length === 0) && delete searchInfo[item];
          } else if (item === 'brandName') {
              (searchInfo[item] === '전체' || searchInfo[item].toString().length === 0) && delete searchInfo[item];
          } else if (item === 'modelGroupId') {
              (searchInfo[item] === '전체' || searchInfo[item].toString().length === 0) && delete searchInfo[item];
          } else if (item === 'isActive') {
              (searchInfo[item] === 'all' || searchInfo[item].toString().length === 0) && delete searchInfo[item];
          } else {
              (searchInfo[item] === null || searchInfo[item].toString().length === 0) && delete searchInfo[item];
          }
      });
      let queryString = Object.keys(searchInfo).map((item, index) => searchInfo[item] !== null && `${item}=${searchInfo[item]}`).join('&');
      this.props.history.push({
          pathname: path ? path : '/vehicleDB/modelGroup',
          search: `?${queryString}`
      });
  };

    render(){
        return(
            <Fragment>
                {/*삭제 popup
                <div className="delete_pop">
                    <p>삭제하시겠습니까</p>
                </div>
                */}

               <div className="title"><p className="mb10"><span>홈</span> 〉 <span>차량DB</span> 〉 <span>모델그룹</span></p>모델그룹 </div>
                <div className="info_searchwrap">
                        <table>
                            <colgroup>
                                <col width="14%"></col>
                                <col width="*"></col>
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>브랜드명</th>
                                    <td>
                                    <Select className="reactSelect w-500"
                                    options={this.state.totalInfo} 
                                    value={this.state.brandId}
                                    name="brandId" 
                                    onChange={this.selectBrand} 
                                    placeholder="전체"
                                    isSearchable={true}/>
                                    </td>
                                </tr>
                                <tr>
                                    <th>모델그룹명</th>
                                    <td>
                                    <Select className="reactSelect w-500"
                                      options={this.state.modelGroup} 
                                      value={this.state.modelGroupId} 
                                      name="brandId" 
                                      onChange={this.selectModelGroup} 
                                      placeholder="전체"
                                      isSearchable={true}/>    
                                    </td>
                                </tr>
                                <tr>
                                    <th>활성화 상태</th>
                                    <td>
                                        <Select className="reactSelect w-500"
                                      options={this.state.is_active} 
                                      value={this.state.isActive} 
                                      name="brandId" 
                                      onChange={this.activeSelect} 
                                      placeholder="전체"
                                      isSearchable={true}/>    
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="centerT mt20">
                            <button type="submit" className="btnLine navyBg medium" onClick={() => this.onSearch()}>검색</button>
                        </div>
                </div>
                {/* 모델그룹 검색영역 끝 */}
                <div className="info_resultwrap">
                    <div className="info_tabletop">
                        <div className="mb10">
                            <button className="btnLine navyBg"onClick={() => this.handlePage(`/vehicleDB/modelGroup/modelGroupDetail`)}>추가</button>
                            <button className="btn navyBg centerT ml10" onClick={this.onDelete}>삭제</button>
                        </div>
                    </div>
                    <table>
                        <colgroup>
                            <col width="5%"></col>
                            <col width=""></col>
                            <col width=""></col>
                            <col width=""></col>
                            <col width=""></col>
                            <col width=""></col>
                            <col width=""></col>
                            <col width="16%"></col>
                        </colgroup>
                        <thead>
                            <tr><th className="ccc" colSpan="8">총 <span>{this.state.totalCnt}</span>개</th></tr>
                            <tr>
                                <th><input type="checkbox" id="model_all" name="model_group" onClick={() => this.onClickCheckAll()}></input></th>
                                <th>번호</th>
                                <th>
                                    브랜드명
                                    <button className="set_btn" onClick={() => this.brandNameOrder("desc")}>▼</button>
                                    <button className="set_btn" onClick={() =>  this.brandNameOrder("asc")}>▲</button>
                                </th>
                                <th>
                                    모델그룹명
                                    <button className="set_btn" onClick={() => this.modelGroupNameOrder("desc")}>▼</button>
                                    <button className="set_btn" onClick={() => this.modelGroupNameOrder("asc") }>▲</button>
                                </th>
                                <th>
                                    활성화 상태
                                    <button className="set_btn" onClick={() => this.activeOrder("desc")}>▼</button>
                                    <button className="set_btn" onClick={() => this.activeOrder("asc") }>▲</button>
                                </th>
                                <th>
                                    수정일
                                    <button className="set_btn" onClick={() => this.updatedOrder("desc")}>▼</button>
                                    <button className="set_btn" onClick={() => this.updatedOrder("asc") }>▲</button>
                                </th>
                                <th>
                                    등록일
                                    <button className="set_btn" onClick={() => this.createdOrder("desc")}>▼</button>
                                    <button className="set_btn" onClick={() => this.createdOrder("asc") }>▲</button>
                                </th>
                                <th>관리</th>
                            </tr>
                        </thead>
                        <tbody>
                          {this.state.modelGroupList.map((item,index)=> {
                            return(
                              <tr key={`model-group-${item.id}`}>
                                <td><input className={"checkbox "+item.id} type="checkbox" id={"model_"+item.id} name="model_group" onClick={() => this.checkAll()}></input></td>
                                <td>{item.id}</td>
                                <td><Link to={"/brand/totalInfo/totalInfoDetail/"+item.brand_id}>{item.brand_name}</Link></td>
                                <td><Link to={"/vehicleDB/modelGroup/modelGroupDetail/"+item.id}>{item.name}</Link></td>
                                <td>{item.is_active ? "활성화" : "비활성화"}</td>
                                <td>{`${new Date(item.updated_at).getFullYear() - 2000}.
                                  ${new Date(item.updated_at).getMonth()+1 < 10 ? `0${new Date(item.updated_at).getMonth()+1}` : new Date(item.updated_at).getMonth()+1}.
                                  ${new Date(item.updated_at).getDate() < 10 ? `0${new Date(item.updated_at).getDate()}` : new Date(item.updated_at).getDate()}`}</td>
                                <td>{
                                 `${new Date(item.created_at).getFullYear() - 2000}.
                                  ${new Date(item.created_at).getMonth()+1 < 10 ? `0${new Date(item.created_at).getMonth()+1}` : new Date(item.created_at).getMonth()+1}.
                                  ${new Date(item.created_at).getDate() < 10 ? `0${new Date(item.created_at).getDate()}` : new Date(item.created_at).getDate()}`}</td>
                                <td>
                                    <button className="btn navyBg" onClick={() => this.handlePage(`/vehicleDB/model/modelDetail/${item.brand_id}&${item.id}`)}>모델추가</button>
                                  <button className="btn grayBg ml10" onClick={() => this.handlePage(`/vehicleDB/modelGroup/modelGroupDetail/${item.id}`)}>수정</button>
                                </td>
                                </tr>)
                          })}
                        </tbody>
                    </table>
                    <div className="pagination">
                    <Pagination
                      onClickPage={this.onClickPage}
                      paginationOption={this.state.pagination}
                    />
                    </div>
                </div>

            </Fragment>

        );
    }
}

export default ModelGroup;

