import { devUrl } from '../../serviceComm/variable';

// 컨텐츠 정보 요청
export function getContentInfo(authToken) {
  const axios = require('axios');
  const config = {
    method: 'get',
    url: `${devUrl}/admin/cms/info`,
    headers: {
      Authorization: `Bearer ${authToken}`
    }
  };

  return axios(config);
}

// 컨텐츠 초안 등록
export function initDrafts(authToken) {
  const axios = require('axios');
  const config = {
    method: 'post',
    url: `${devUrl}/admin/cms/contents/drafts`,
    headers: {
      Authorization: `Bearer ${authToken}`
    }
  };

  return axios(config);
}

// 해당 컨텐츠 정보 요청
export function getContent(authToken, id) {
  const axios = require('axios');
  const config = {
    method: 'get',
    url: `${devUrl}/admin/cms/contents/${id}`,
    headers: {
      Authorization: `Bearer ${authToken}`
    }
  };

  return axios(config);
}

// 추천 뉴스 리스트 요청
export function getFeaturedNewsList(authToken) {
  const axios = require('axios');
  const config = {
    method: 'get',
    url: `${devUrl}/admin/cms/contents/featured`,
    headers: {
      Authorization: `Bearer ${authToken}`
    }
  };

  return axios(config);
}

// 추천 뉴스 추가
export function putFeaturedNewsList(authToken, formData) {
  const axios = require('axios');
  const config = {
    method: 'post',
    url: `${devUrl}/admin/cms/contents/featured`,
    headers: {
      Authorization: `Bearer ${authToken}`
    },
    data: formData
  };

  return axios(config);
}

// 매체 리스트 요청
export function getMediaList(authToken) {
  const axios = require('axios');
  const config = {
    method: 'get',
    url: `${devUrl}/admin/cms/providers`,
    headers: {
      Authorization: `Bearer ${authToken}`
    }
  };

  return axios(config);
}

// 해당 매체에 속한 편집자 목록 요청
export function getEditorList(authToken, id) {
  const axios = require('axios');
  const config = {
    method: 'get',
    url: `${devUrl}/admin/cms/providers/${id}`,
    headers: {
      Authorization: `Bearer ${authToken}`
    }
  };

  return axios(config);
}

// 컨텐츠 등록 또는 수정
export function putContents(authToken, formData) {
  const axios = require('axios');
  const config = {
    method: 'put',
    url:
      `${devUrl}/admin/cms/contents/${formData.get('id')}`,
    headers: {
      Authorization: `Bearer ${authToken}`
    },
    data: formData
  };

  return axios(config);
}

// 컨텐츠 이미지 업로드
export function putContentImage(authToken, id, formData) {
  const axios = require('axios');
  const inputFormData = new FormData()
  for(var x = 0; x<formData.length; x++){
    inputFormData.append('images[]',formData[x])
  }
  console.log(formData);
  const config = {
    method: 'post',
    url: `${devUrl}/admin/cms/contents/${id}/images`,
    headers: {
      Authorization: `Bearer ${authToken}`
    },
    data: inputFormData
  };

  return axios(config);
}

// 브랜드 심플 리스트 요청
export function getBrandSimples(authToken) {
  const axios = require('axios');
  const config = {
    method: 'get',
    url: `${devUrl}/admin/car_info/brands`,
    headers: {
      Authorization: `Bearer ${authToken}`
    },
    params: {
      is_active: true
    }
  };

  return axios(config);
}

// 브랜드에 속한 모델 그룹 심플 리스트 요청
export function getModelGroupSimples(authToken, brand_id) {
  const axios = require('axios');
  const config = {
    method: 'get',
    url: `${devUrl}/admin/car_info/car_model_groups`,
    headers: {
      Authorization: `Bearer ${authToken}`
    },
    params: {
      is_active: true,
      brand_id
    }
  };

  return axios(config);
}

// 모델 그룹에 속한 자동차 모델 심플 리스트 요청
export function getCarModelSimples(authToken, brand_id, car_model_group_id) {
  const axios = require('axios');
  const config = {
    method: 'get',
    url: `${devUrl}/admin/car_info/car_models`,
    headers: {
      Authorization: `Bearer ${authToken}`
    },
    params: {
      is_active: true,
      brand_id,
      car_model_group_id
    }
  };

  return axios(config);
}

export function putContentImageNew(authToken, id, image) {
  const axios = require('axios');
  const formData = new FormData();

  formData.append('images[]', image);
  const config = {
    method: 'post',
    url: `${devUrl}/admin/cms/contents/${id}/images`,
    headers: {
      Authorization: `Bearer ${authToken}`
    },
    data: formData
  };

  return axios(config);
}
