/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import './App.scss';

import Header from './component/Header';

/* 브랜드 */
import PriceOption from './pages/brand/PriceOption';
import PriceOptionDetail from './pages/brand/PriceOptionDetail';
import TotalInfo from './pages/brand/TotalInfo';
import TotalInfoDetail from './pages/brand/TotalInfoDetail';
import SalesRecord from './pages/brand/SalesRecord';
import SalesCondition from './pages/brand/SalesCondition';

/* 차량 DB */
import Model from './pages/vehicleDB/Model';
import ModelDetail from './pages/vehicleDB/ModelDetail';
import ModelGroup from './pages/vehicleDB/ModelGroup';
import ModelGroupDetail from './pages/vehicleDB/ModelGroupDetail';
import LineUp from './pages/vehicleDB/LineUp';
import LineUpUpdate from './pages/vehicleDB/LineUpUpdate';
import LineUpAdd from './pages/vehicleDB/LineUpAdd';
import Trim from './pages/vehicleDB/Trim';
import TrimAdd from './pages/vehicleDB/TrimAdd';
import TrimUpdate from './pages/vehicleDB/TrimUpdate';
import TrimManager from "./pages/vehicleDB/Trim/TrimManager";
import VehicleAdd from './pages/vehicleDB/VehicleAdd';

/* 콘텐츠 */
import Media from './pages/contents/Media';
import MediaDetail from './pages/contents/MediaDetail';
import Content from './pages/contents/Content';
import ContentDetail from './pages/contents/ContentDetail';
import Movie from './pages/contents/Movie';
import MovieDetail from './pages/contents/MovieDetail';
import PhotoNews from './pages/contents/PhotoNews';
import PhotoNewsDetail from './pages/contents/PhotoNewsDetail';
import FeaturedNews from "./pages/contents/FeaturedNews";
import FeaturedNewsDetail from './pages/contents/FeaturedNewsDetail';

/* 운영 */
import Operation from './pages/operation/CommonAdv';
import ECommers from './pages/operation/ECommersAdv';
import PriorityManager from './pages/operation/PriorityManager'
import PhotoGallery from './pages/operation/PhotoGallery';
import PhotoGalleryDetail from './pages/operation/PhotoGalleryDetail';
import HomePhoto from './pages/operation/HomePhoto';
import HomePhotoDetail from './pages/operation/HomePhotoDetail';

import Estimate from './pages/operation/Estimate';
import Estimate_detail from './pages/operation/Estimate_detail';

import Page404 from './pages/page404';

import LoginContainer from './pages/LoginContainer';

class App extends Component {
  render() {
    return (
      <Router>
        <>
          <Header />
          <div>
            <div className="container">
              <Switch>
                <Route exact path="(/|/login)" component={LoginContainer} />

                <Route exact path="/brand/totalInfo" component={TotalInfo} />
                <Route path="/brand/totalInfo/totalInfoDetail" component={TotalInfoDetail} />

                <Route path="/brand/priceOption/" component={PriceOption} />
                <Route path="/brand/priceOptionDetail" component={PriceOptionDetail} />

                <Route exact path="/brand/salesRecord" component={SalesRecord} />
                <Route exact path="/brand/salesCondition" component={SalesCondition} />

                <Route exact path="/vehicleDB/modelGroup" component={ModelGroup} />
                <Route path="/vehicleDB/modelGroup/modelGroupDetail" component={ModelGroupDetail} />

                <Route exact path="/vehicleDB/model" component={Model} />
                <Route path="/vehicleDB/model/modelDetail" component={ModelDetail} />

                <Route exact path="/vehicleDB/lineUp" component={LineUp} />
                <Route path="/vehicleDB/lineUp/lineUpUpdate" component={LineUpUpdate} />
                <Route path="/vehicleDB/lineUp/lineUpAdd" component={LineUpAdd} />

                <Route exact path="/vehicleDB/trim" component={Trim} />
                <Route path="/vehicleDB/trim/trimUpdate" component={TrimUpdate} />
                <Route path="/vehicleDB/trim/trimAdd" component={TrimAdd} />
                <Route path="/vehicleDB/trim/:trimId" component={TrimManager} />
                <Route path="/vehicleDB/trim/new" component={TrimManager} />

                <Route exact path="/vehicleDB/vehicleAdd" component={VehicleAdd} />

                <Route exact path="/contents/media" component={Media} />
                <Route path="/contents/media/mediaDetail/:id" component={MediaDetail} />
                <Route path="/contents/media/mediaDetail" component={MediaDetail} />

                <Route exact path="/contents/content" component={Content} />
                <Route path="/contents/content/contentDetail/:id" component={ContentDetail} />
                <Route path="/contents/content/contentDetail" component={ContentDetail} />

                <Route exact path="/contents/movie" component={Movie} />
                <Route path="/contents/movie/movieDetail/:id" component={MovieDetail} />
                <Route path="/contents/movie/movieDetail" component={MovieDetail} />

                <Route exact path="/contents/photoNews" component={PhotoNews} />
                <Route path="/contents/photoNews/photoNewsDetail/:id" component={PhotoNewsDetail} />
                <Route path="/contents/photoNews/photoNewsDetail" component={PhotoNewsDetail} />

                <Route exact path="/contents/featuredNews" component={FeaturedNews} />
                <Route path="/contents/featuredNews/featuredNewsDetail/:id" component={FeaturedNewsDetail} />
                <Route path="/contents/featuredNews/featuredNewsDetail" component={FeaturedNewsDetail} />

                <Route path="/operation/commonAdv" component={Operation} />
                <Route path="/operation/ecommersAdv" component={ECommers} />
                <Route exact path="/operation/photoGallery" component={PhotoGallery} />
                <Route path="/operation/photoGallery/photoGalleryDetail" component={PhotoGalleryDetail}/>
                <Route exact path="/operation/homePhoto" component={HomePhoto} />
                <Route path="/operation/homePhoto/homePhotoDetail" component={HomePhotoDetail}/>
                <Route exact path="/operation/priority/:targetModel" component={PriorityManager} />

                <Route path="/operation/Estimate" component={Estimate} />
                <Route path="/operation/Estimate_detail" component={Estimate_detail} />

                <Route component={Page404} />
              </Switch>
            </div>
          </div>
        </>
      </Router>
    );
  }
}

export default App;
