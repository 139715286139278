import React, { Component } from 'react';
import Choices from 'choices.js';

class Choice extends Component {

  componentDidMount() {
    setTimeout(() => {
      this.setChoice();
    }, 500);
  }

  setChoice = () => {
    const choice = document.getElementById('choice');
    const ChoicesConvert = new Choices(choice, {
      searchFields: ['label', 'value'],
      delimiter: ',',
      editItems: true,
      maxItemCount: 2,
      removeItemButton: true,
      searchEnabled: true,
      searchChoices: true,
      searchFloor: 1,
      searchResultLimit: 10
    });
    if (this.props.historyMedia && this.props.historyMedia.provider_name && this.props.historyMedia.provider_name !== '전체') {
      ChoicesConvert.setValue([{ value: this.props.historyMedia.provider_id, label: this.props.historyMedia.provider_name }]);
    } else {
      ChoicesConvert.setValue([{ value: '0', label: '-' }]);
    }
  };

  render() {
    const { medias, onChangeSelect } = this.props;
    return (
      <>
        <select
          id="choice"
          name="provider_id"
          onChange={onChangeSelect}
        >
          {medias.length !== 0 && medias.map((item, key) => {
            if (item.category === '컨텐츠') {
              return (
                <option name={item.name} value={item.id} key={key}>
                  {item.name}
                </option>
              );
            }
          })}
        </select>
      </>
    );
  }
}

export default Choice;
