import React, { Component, Fragment } from 'react';
import { Link, Router } from 'react-router-dom';
import * as Service from '../../component/services/vehicleDB/LineUpService'
import * as Common from "../../component/services/common/CommonService";
import * as BrandService from "../../component/services/brand/BrandService";
import * as modelGroupService from "../../component/services/vehicleDB/ModelGroupService"
import * as modelService from "../../component/services/vehicleDB/ModelService"
import Select from 'react-select';
import DatePicker from 'react-datepicker'

let id = 0
class LineUpDetail extends Component {
  constructor(props){
    super(props)
    this.state = {
      page_history: this.props.location.search.replace(/\?page=/g, '') ? this.props.location.search.replace(/\?page=/g, '') : 1,
      authToken : Common.getToken.call(this),
      brandId : {label : '전체' , value : 0},
      modelGroupId : {label : '전체' , value : 0},
      modelId : {label: '전체' , value : 0},
      totalInfo : [],
      modelGroup : [{label: '전체' , value : 0}],
      model : [{label: '전체' , value : 0}],
      editId : 0,
      name : "",
      fuelType : "휘발유",
      displacement : "",
      mileage : "",
      date : new Date(),
      year : new Date().getFullYear(),
      sale : null,
      primary : null,
      active : null,
      conditions : [],
      yearByList : new Date().getFullYear(),
      monthByList : new Date().getMonth()+1 ,
      isBulk : false,
      bulkId: 0,
      forms: [

      ],
      childItemId: 0
    }
  }

  componentDidMount(){
    this.getTotalInfo()
  }

  infoSettingByBulk = ( e , type ,index) => {
    let data = this.state.forms

    if(e.target.name == "selling"+index){
      e.target.id == 'sell'+index+'_ok' ? data[index].sale = true : data[index].sale = false
    }else if(e.target.name == "rp_line"+index){
      e.target.id == 'rp'+index+'_ok' ? data[index].primary = true : data[index].primary = false
    }else if(e.target.name == "active"+index){
      e.target.id == 'active'+index+'_ok' ? data[index].active = true : data[index].active = false
    }
    else{
      data[index][e.target.id] = e.target.value;
    }
    this.setState({
      forms : data
    },() => {
      if(type == "select"){
        const req = Service.getLineUpConditions(this.state.authToken , this.state.forms[index].id , this.state.forms[index].yearByList , this.state.forms[index].monthByList)
        req.then(res => {
          const data = this.state.forms
          data[index].conditions = res.data.data
          this.setState({
            forms : data
          })
        })
      }
    })
  }

  infoSetting = (e ,type , index)=> {

    if(e.target.type == "radio"){
      if(e.target.className.indexOf("ok") !== -1){
        this.setState({
          [e.target.name] : true
        })
      }else{
        this.setState({
          [e.target.name] : false
        })
      }
    }
    else{
      this.setState({
        [e.target.id] : e.target.value
      },() => {
        if(type == 'select'){
          this.getLineUpConditions();
        }
      })
    }

  }

  getTotalInfo = aysnc => {
    const brandList = BrandService.getTotalInfo(this.state.authToken);
    brandList.then(response => {
      const data = response.data.data
      let options = []
      options.push({label: '전체' , value : 0})
      for(var x = 0; x<data.length; x++){
        options.push({label: data[x].name , value : data[x].id})
      }
      this.setState({
        totalInfo: options
      } , () => {
        const id = this.props.location.pathname.split('/')
          if(!isNaN(parseInt(id[id.length-1]))){
            if(id[id.length-1].indexOf('&') !== -1){
              if(id[id.length-1].split('&')[3]){
                this.setState({
                  isBulk : true,
                  bulkId : id[id.length-1].split('&')[2]
                },() => {
                  this.getLineUpList(id[id.length-1].split('&')[0],id[id.length-1].split('&')[1],this.state.bulkId,id[id.length-1].split('&')[4],id[id.length-1].split('&')[5],id[id.length-1].split('&')[6])
                })
              }
              else{
                this.setState({
                  editId : id[id.length-1].split('&')[2]
                },() => {
                  this.getLineUpDetail(this.state.editId)
                })
              }
            }
          }
      });
    });
  };

  getModelGroup = ( id ) =>{
    const req = modelGroupService.getModelG(this.state.authToken , id)
    req.then(response => {
      const data = response.data.data
      let options = []
      options.push({label: '모델그룹' , value : 0})
      for(var x = 0; x<data.length; x++){
        options.push({label: data[x].name , value : data[x].id})
      }
      this.setState({
        modelGroup : options
      })
    })
  }

  getModels = (id , gname) => {
    const req = modelService.getModelList(
      id,
    10000,
    1,
    this.state.authToken,
    gname,
    "","all","","","","","",""
    )
    req.then(response => {
      const data = response.data.data
      let options = []
      options.push({label: '전체' , value : 0})
      for(var x = 0; x<data.length; x++){
        options.push({label: data[x].name , value : data[x].id})
      }
      this.setState({
        model : options
      })
    })
  }

  getLineUpList = ( brandId , modelGroupId ,modelId ,brand_name,car_model_group_name,car_model_name)=> {
    this.setState({
      brandId : {label :  brand_name , value : brandId},
      modelGroupId : {label : car_model_group_name , value : modelGroupId},
      modelId : {label: car_model_name , value : modelId},
    })
    const req = Service.getLineUpList(this.state.authToken,10000,1,"","","","","","","",brandId,modelGroupId,modelId,0,'all')
    req.then(res => {
      const data =res.data.data
      for(let x = 0; x<data.length; x++){
        this.getLineUpDetailByBulk(data[x].id)
      }
    })
  }

  getLineUpDetail = (id) => {
    const req = Service.getLineUpDetail(this.state.authToken , id)
    req.then(res => {
      const data = res.data.data
      //라인업 디테일을 불러온 후 최상단 브랜드/모델그룹/모델 자동 선택
      this.getModelGroup(data.brand_id)
      this.getModels(data.brand_id,data.car_model_group_name)
      this.setState({
        brandId : {label :  data.brand_name , value : data.brand_id},
        modelGroupId : {label : data.car_model_group_name , value : data.car_model_group_id},
        modelId : {label: data.car_model_name , value : data.car_model_id},
        name :data.name,
        fuelType : data.fuel_type,
        mileage : data.mileage == null ? "" : data.mileage,
        displacement : data.displacement == null ? "" : data.displacement,
        date : new Date(data.published_at),
        year : data.year,
        active : data.is_active,
        sale : data.is_onsale,
        primary : data.is_primary,
      },() => this.getLineUpConditions())
    })
  }

  getLineUpDetailByBulk = (id) => {
    const req = Service.getLineUpDetail(this.state.authToken , id)
    req.then(res => {
      const data = res.data.data
      let forms = this.state.forms
      const form = {
        id : data.id,
        type : 'edit',
        name : data.name,
        fuelType : data.fuel_type ? data.fuel_type : '경유',
        displacement : data.displacement,
        mileage : data.mileage,
        date : new Date(data.published_at),
        year : data.year,
        sale : data.is_onsale,
        primary : data.is_primary,
        active : data.is_active,
        conditions : data.current_sales_conditions && data.current_sales_conditions.map((item) => {
          return {
            ...item,
            items: item.items && item.items.map((subItem) => {
              return {
                ...subItem,
                status: false
              }
            }),
            status: false
          }
        }),
        addConditions: {
          year: new Date().getFullYear(),
          month: new Date().getMonth()+1,
          name: null,
          price: null,
          price_rate: null,
          items: []
        },
        yearByList : new Date().getFullYear(),
        monthByList : new Date().getMonth()+1
      }
      forms = forms.concat(form)
      this.setState({
        forms : forms
      });
    })
  }

  getLineUpConditions = () => {
    const req = Service.getLineUpConditions(this.state.authToken , this.state.editId , this.state.yearByList , this.state.monthByList)
    req.then(res => {
      const data = res.data.data
      this.getLineUpDetailByBulk(this.state.editId);
      this.setState({
        conditions : data
      });
    })
  }

  handleChange = (date , index) => {
    let data = this.state.forms
    data[index].date = date
    if(index){
      this.setState({
        forms : data
      });
    }
    else{
      this.setState({
        date : date
      });
    }
  };

  setComma = ( value ) => {
    if (value == null || value == ""){
        return value;
    }

    // 소수점 분리
    var valueArr = value.toString().split(".");

    var str = valueArr[0].toString();
        str = str.replace ( /,/g ,'' );
    var retValue = "";

    for( var i = 1; i <= str.length; i++ ) {
        if ( i > 1 && ( i % 3 ) == 1 )
            retValue = str.charAt ( str.length - i ) + "," + retValue;
        else
            retValue = str.charAt ( str.length - i ) + retValue;
    }

    return retValue + (valueArr.length > 1 ? "." + valueArr[1] : "");
  }

  updateData = () =>{
    const state = this.state
    if(state.name == ""){
      alert('라인업명을 입력해주세요.')
      return false
    }if(state.fuelType == "전기"){
      if(state.mileage == ''){
        alert('주행거리를 입력해주세요')
        return false
      }
    }else{
      if(!state.displacement || state.displacement == ''){
        alert('배기량을 입력해주세요')
        return false
      }
    }if(state.year ==''){
      alert('연식을 입력해주세요')
      return false
    }

    if (window.confirm('라인업 수정을 완료하시겠습니까?')){
      const req = Service.updateLineUp(state.authToken , state.editId , state)
      req.then(res => {
        if(res.status == 200){
          alert('저장이 완료되었습니다.')
          this.props.history.push('/vehicleDB/lineUp/')
        }
      }).catch(err => {
        alert('라인업 수정 요청 중 오류가 발생하였습니다.')
      })
    }
  }

  updateDataByBulk = (index , type) =>{
    const data = this.state.forms[index]
    if(data.name == ""){
      alert('라인업명을 입력해주세요.')
      return false
    }if(data.fuelType == "전기"){
      if(!data.mileage || data.mileage == ''){
        alert('주행거리를 입력해주세요')
        return false
      }
    }else{
      if(!data.displacement || data.displacement == ''){
        alert('배기량을 입력해주세요')
        return false
      }
    }if(data.year ==''){
      alert('연식을 입력해주세요')
      return false
    }
    if(type == 'edit'){
      if (window.confirm('라인업 수정을 완료하시겠습니까?')){
        const req = Service.updateLineUp(this.state.authToken , data.id , data)
        req.then(res => {
          if(res.status == 200){
            alert('저장이 완료되었습니다.')
          }
        }).catch(err => {
          alert('라인업 수정 요청 중 오류가 발생하였습니다.')
        })
      }
    }else{
      if (window.confirm('라인업 저장을 완료하시겠습니까?')){
        const req = Service.postLineUp(this.state.authToken , this.state.brandId , this.state.modelGroupId , this.state.modelId , data)
        req.then(res => {
          if(res.status == 200){
            const data = res.data.data
            const forms = this.state.forms
            forms[index].id = data.id
            forms[index].type = 'edit'
            alert('저장이 완료되었습니다.')
          }
        }).catch(err => {
          alert('라인업 수정 요청 중 오류가 발생하였습니다.')
        })
      }
    }

  }

  onLineUpAdd = () => {
    let data = {
      id : id++,
      type : 'create',
      name : '',
      fuelType : '휘발유',
      mileage : '',
      displacement : '',
      date : new Date(),
      year : new Date().getFullYear(),
      sale : true,
      primary : false,
      active : true,
      conditions : [],
      addConditions: {
        year: new Date().getFullYear(),
        month: new Date().getMonth()+1,
        name: null,
        price: null,
        price_rate: null,
        items: []
      }
    }
    this.setState({
      forms : this.state.forms.concat(data)
    });
  }

  // 해당 라인업의 판매조건 수정 Input 토글 여부 Set
  setLineUpSalesConditionEditStatus = (e) => {
    const ids = String(e.target.name).split('-');
    const id = ids[1];
    const conditionId = ids[2];
    const { forms } = this.state;
    const tempSalesCondition = [ ...forms ];
    const selectedSalesConditionIndex = forms.findIndex((item) => item.id === parseInt(id));
    const selectedSalesCondition = tempSalesCondition[selectedSalesConditionIndex].conditions.filter((item) => item.id === parseInt(conditionId))[0];
    const selectedSalesConditionChildIndex = tempSalesCondition[selectedSalesConditionIndex].conditions.findIndex((item) => item.id == parseInt(conditionId));
    tempSalesCondition[selectedSalesConditionIndex].conditions[selectedSalesConditionChildIndex] = {
      ...selectedSalesCondition,
      status: !selectedSalesCondition.status
    };

    this.setState({
      forms: tempSalesCondition
    });
  };

  // 해당 라인업의 판매조건 Handler
  handleLineUpSalesCondition = (e) => {
    const ids = String(e.target.name).split('-');
    const name = ids[0];
    const id = ids[1];
    const conditionId = ids[2];
    const value = e.target.value;
    const { forms } = this.state;
    const tempSalesCondition = [ ...forms ];
    const selectedSalesConditionIndex = forms.findIndex((item) => item.id === parseInt(id));
    const selectedSalesCondition = tempSalesCondition[selectedSalesConditionIndex].conditions.filter((item) => item.id === parseInt(conditionId))[0];
    const selectedSalesConditionChildIndex = tempSalesCondition[selectedSalesConditionIndex].conditions.findIndex((item) => item.id == parseInt(conditionId));
    tempSalesCondition[selectedSalesConditionIndex].conditions[selectedSalesConditionChildIndex] = {
      ...selectedSalesCondition,
      [name]: value
    };

    this.setState({
      forms: tempSalesCondition
    });
  };

  // 해당 라인업의 판매조건을 수정합니다.
  putLineUpSalesCondition = (e) => {
    const ids = String(e.target.name).split('-');
    // const name = ids[0];
    const id = ids[1];
    const conditionId = ids[2];
    // const value = e.target.value;
    const { forms } = this.state;
    const tempLineUps = [ ...forms ];
    const selectedLineUpIndex = forms.findIndex((item) => item.id === parseInt(id));
    const selectedSalesCondition = tempLineUps[selectedLineUpIndex].conditions.filter((item) => item.id === parseInt(conditionId))[0];
    const selectedSalesConditionIndex = tempLineUps[selectedLineUpIndex].conditions.findIndex((item) => item.id === parseInt(conditionId));
    const hasChildItem = (selectedSalesCondition.items && selectedSalesCondition.items.length !== 0) && true;
    const hasChildItemStatusEdit = (selectedSalesCondition.items && selectedSalesCondition.items.filter((item) => item.status)[0]) && true;
    if(!hasChildItem) {
      if((selectedSalesCondition.price && selectedSalesCondition.price_rate) || (!selectedSalesCondition.price && !selectedSalesCondition.price_rate)) {
        alert('할인가격과 할인율 중 한가지만 입력해주세요.');
        return false;
      }
    } else if(hasChildItemStatusEdit) {
      alert('수정 중인 하위 카테고리 아이템을 저장해주세요.');
      return false;
    } else if(hasChildItem) {
      tempLineUps[selectedLineUpIndex].conditions[selectedSalesConditionIndex] = {
        ...tempLineUps[selectedLineUpIndex].conditions[selectedSalesConditionIndex],
        price: null,
        price_rate: null
      };
    }
    const { authToken } = this.state;

    Service.putLineUpSalesCondition({
      ...selectedSalesCondition,
      authToken
    }).then((response) => {
      if(response.status === 200) {
        alert('수정되었습니다.');
        this.setState({
          forms: tempLineUps
        });
      }
    }).catch((error) => {
      if(error.response) {
        if(error.response.data.message) {
          alert(error.response.data.message);
        }
      }
    });
    // 해당 라인업 판매조건 Input Toggle
    this.setLineUpSalesConditionEditStatus(e);
  };

  // 해당 라인업의 판매조건을 삭제합니다.
  deleteLienUpSalesCondition = (e) => {
    const ids = String(e.target.name).split('-');
    const id = ids[1];
    const conditionId = ids[2];
    const { forms } = this.state;
    const tempLineUps = [ ...forms ];
    const selectedLineUpIndex = forms.findIndex((item) => item.id === parseInt(id));
    const selectedSalesCondition = tempLineUps[selectedLineUpIndex].conditions.filter((item) => item.id === parseInt(conditionId))[0];
    // const selectedSalesConditionIndex = tempLineUps[selectedLineUpIndex].conditions.findIndex((item) => item.id === parseInt(conditionId));

    tempLineUps[selectedLineUpIndex] = {
      ...tempLineUps[selectedLineUpIndex],
      conditions: tempLineUps[selectedLineUpIndex].conditions.filter((item) => item.id !== parseInt(conditionId))
    };

    const { authToken } = this.state;

    Service.deleteLienUpSalesCondition({
      authToken,
      lineup_id: selectedSalesCondition.lineup_id,
      id: selectedSalesCondition.id
    }).then((response) => {
      if(response.status === 200) {
        alert('삭제되었습니다.');
      }
      this.setState({
        forms: tempLineUps
      });
    }).catch((error) => {
      if(error.response) {
        if(error.response.data.message) {
          alert(error.response.data.message);
        }
      }
    });
  };

  // 새로운 하위 카테고리 아이템을 추가합니다.
  addNewChildItem = (e) => {
    const { forms, childItemId } = this.state;
    const ids = String(e.target.name).split('-');
    const lineupId = ids[1];
    const tempLineUps = [ ...forms ];
    const selectedLineUpIndex = forms.findIndex((item) => item.id === parseInt(lineupId));
    const selectedLineUp = forms.filter((item) => item.id === parseInt(lineupId))[0];
    if(ids.length === 3) {
      const conditionId = ids[2];
      const selectedCondition = selectedLineUp.conditions.filter((item) => item.id === parseInt(conditionId))[0];
      const selectedConditionIndex = selectedLineUp.conditions.findIndex((item) => item.id === parseInt(conditionId));
      const hasChildItems = selectedCondition.items;
      // if(selectedCondition.price || selectedCondition.price_rate) {
      //   alert('하위 카테고리 아이템을 추가하려는 경우,\n부모 판매조건의 할인가격과 할인율을 지워주세요.');
      //   return false;
      // }
      if(hasChildItems) {
        tempLineUps[selectedLineUpIndex].conditions[selectedConditionIndex] = {
          ...selectedCondition,
          price: null,
          price_rate: null,
          items: [...selectedCondition.items, {
            id: childItemId,
            name: null,
            price: null,
            price_rate: null,
            status: true
          }]
        };
      } else {
        tempLineUps[selectedLineUpIndex].conditions[selectedConditionIndex] = {
          ...selectedCondition,
          price: null,
          price_rate: null,
          items: [{
            id: childItemId,
            name: null,
            price: null,
            price_rate: null,
            status: true
          }]
        };
      }
    } else {
      tempLineUps[selectedLineUpIndex] = {
        ...selectedLineUp,
        addConditions: {
          ...selectedLineUp.addConditions,
          price: null,
          price_rate: null,
          items: selectedLineUp.addConditions.items.concat({
            id: childItemId,
            name: null,
            price: null,
            price_rate: null
          })
        }
      };
    }

    this.setState({
      forms: tempLineUps,
      childItemId: childItemId + 1
    });
  };

  // 하위 카테고리 아이템 Handler
  handleChildItem = (e) => {
    const { forms, childItemId } = this.state;
    const ids = String(e.target.name).split('-');
    const name = ids[0];
    const lineupId = ids[1];
    const itemId = ids[2];
    const tempLineUps = [ ...forms ];
    const selectedLineUpIndex = forms.findIndex((item) => item.id === parseInt(lineupId));
    const selectedLineUp = forms.filter((item) => item.id === parseInt(lineupId))[0];
    const selectedAddCondition = selectedLineUp.addConditions.items.filter((item) => item.id === parseInt(itemId))[0];
    const selectedAddConditionIndex = selectedLineUp.addConditions.items.findIndex((item) => item.id === parseInt(itemId));

    tempLineUps[selectedLineUpIndex].addConditions.items[selectedAddConditionIndex] = {
      ...selectedAddCondition,
      [name]: e.target.value
    };

    this.setState({
      forms: tempLineUps
    });
  };

  // 해당 하위 카테고리 아이템을 삭제합니다.
  deleteChildItem = (e) => {
    const { forms } = this.state;
    const ids = String(e.target.name).split('-');
    const lineupId = ids[1];
    const itemId = ids[2];
    const tempLineUps = [ ...forms ];
    const selectedLineUpIndex = forms.findIndex((item) => item.id === parseInt(lineupId));
    const selectedLineUp = forms.filter((item) => item.id === parseInt(lineupId))[0];
    const selectedAddConditions = selectedLineUp.addConditions.items.filter((item) => item.id !== parseInt(itemId));

    tempLineUps[selectedLineUpIndex].addConditions = {
      ...selectedLineUp.addConditions,
      items: selectedAddConditions
    };

    this.setState({
      forms: tempLineUps
    });
  };

  // 새로운 판매조건 Handler
  handleNewLineUpSalesCondition = (e) => {
    const { forms } = this.state;
    const ids = String(e.target.name).split('-');
    const name = ids[0];
    const lineupId = ids[1];
    const tempLineUps = [ ...forms ];
    const selectedLineUpIndex = forms.findIndex((item) => item.id === parseInt(lineupId));
    const selectedLineUp = forms.filter((item) => item.id === parseInt(lineupId))[0];

    tempLineUps[selectedLineUpIndex].addConditions = {
      ...tempLineUps[selectedLineUpIndex].addConditions,
      [name]: e.target.value
    };

    this.setState({
      forms: tempLineUps
    })
  };

  // 새로운 판매조건을 추가합니다.
  postLineUpSalesCondition = (e) => {
    const { authToken, forms } = this.state;
    const ids = String(e.target.name).split('-');
    const lineupId = ids[1];
    // const itemId = ids[2];
    const tempLineUps = [ ...forms ];
    const selectedLineUpIndex = forms.findIndex((item) => item.id === parseInt(lineupId));
    const selectedLineUp = forms.filter((item) => item.id === parseInt(lineupId))[0];
    // const selectedAddCondition = selectedLineUp.addConditions.items.filter((item) => item.id === parseInt(itemId));
    if(!selectedLineUp.addConditions.name) {
      alert('판매조건 명을 입력해주세요.');
      return false;
    }
    // 판매조건 하위 카테고리 아이템이 존재여부에 따라 유효성 검사를 다르게 한다.
    if(selectedLineUp.addConditions.items && selectedLineUp.addConditions.items.length !== 0) {
      const invalidCondition = selectedLineUp.addConditions.items.filter((item) => (!item.name || (item.price && item.price_rate) || (!item.price && !item.price_rate)));

      if(invalidCondition.length !== 0) {
        alert('하위 카테고리 중 올바르지 않거나 입력하지 않은 정보가 존재합니다.');
        return false;
      }
    } else {
      if((selectedLineUp.addConditions.price && selectedLineUp.addConditions.price_rate) || (!selectedLineUp.addConditions.price && !selectedLineUp.addConditions.price_rate)) {
        alert('할인가격과 할인율 중 한가지만 입력해주세요.');
        return false;
      }
    }

    Service.postLienUpSalesCondition({
      authToken,
      lineup_id: selectedLineUp.id,
      condition: {
        ...selectedLineUp.addConditions,
        year: selectedLineUp.yearByList,
        month: selectedLineUp.monthByList
      }
    }).then((response) => {
      alert('추가되었습니다.');
      tempLineUps[selectedLineUpIndex] = {
        ...tempLineUps[selectedLineUpIndex],
        addConditions: {
          year: selectedLineUp.yearByList,
          month: selectedLineUp.monthByList,
          name: null,
          price: null,
          price_rate: null,
          items: []
        }
      }
      tempLineUps[selectedLineUpIndex].conditions = [
        ...tempLineUps[selectedLineUpIndex].conditions, response.data.data
      ];
      this.setState({
        forms: tempLineUps
      });
    });
  };

  // 해당 판매조건의 하위 카테고리 수정 Input 토글 여부 Set
  setLineUpSalesConditionChildEditStatus = (e) => {
    const ids = String(e.target.name).split('-');
    const lineupId = ids[1];
    const conditionId = ids[2];
    const childItemId = ids[3];

    const { forms } = this.state;
    const tempLineUps = [ ...forms ];
    const selectedLienUp = forms.filter((item) => item.id === parseInt(lineupId))[0];
    const selectedLineUpIndex = forms.findIndex((item) => item.id === parseInt(lineupId));
    const selectedLineUpSalesCondition = selectedLienUp.conditions.filter((item) => item.id === parseInt(conditionId))[0];
    const selectedLineUpSalesConditionIndex = selectedLienUp.conditions.findIndex((item) => item.id === parseInt(conditionId));
    const selectedLineUpSalesConditionChildItem = selectedLineUpSalesCondition.items.filter((item) => item.id === parseInt(childItemId))[0];
    const selectedLineUpSalesConditionChildItemIndex = selectedLineUpSalesCondition.items.findIndex((item) => item.id === parseInt(childItemId));

    tempLineUps[selectedLineUpIndex].conditions[selectedLineUpSalesConditionIndex].items[selectedLineUpSalesConditionChildItemIndex] = {
      ...selectedLineUpSalesConditionChildItem,
      status: !selectedLineUpSalesConditionChildItem.status
    };

    this.setState({
      forms: tempLineUps
    });
  };

  // 해당 판매조건의 아이템 Handler
  handleLineUpSalesConditionChild = (e) => {
    const ids = String(e.target.name).split('-');
    const name = ids[0];
    const lineupId = ids[1];
    const conditionId = ids[2];
    const childItemId = ids[3];
    const value = e.target.value;
    const { forms } = this.state;
    const tempLineUps = [ ...forms ];
    const selectedLienUp = forms.filter((item) => item.id === parseInt(lineupId))[0];
    const selectedLineUpIndex = forms.findIndex((item) => item.id === parseInt(lineupId));
    const selectedLineUpSalesCondition = selectedLienUp.conditions.filter((item) => item.id === parseInt(conditionId))[0];
    const selectedLineUpSalesConditionIndex = selectedLienUp.conditions.findIndex((item) => item.id === parseInt(conditionId));
    const selectedLineUpSalesConditionChildItem = selectedLineUpSalesCondition.items.filter((item) => item.id === parseInt(childItemId))[0];
    const selectedLineUpSalesConditionChildItemIndex = selectedLineUpSalesCondition.items.findIndex((item) => item.id === parseInt(childItemId));

    tempLineUps[selectedLineUpIndex].conditions[selectedLineUpSalesConditionIndex].items[selectedLineUpSalesConditionChildItemIndex] = {
      ...selectedLineUpSalesConditionChildItem,
      [name]: value
    };

    this.setState({
      forms: tempLineUps
    });
  };

  // 해당 판매조건의 하위 카테고리 아이템을 수정합니다.
  putLineUpSalesConditionChildItem = (e) => {
    const ids = String(e.target.name).split('-');
    const id = ids[1];
    const conditionId = ids[2];
    const { forms } = this.state;
    const tempSalesCondition = [ ...forms ];
    const selectedSalesConditionIndex = forms.findIndex((item) => item.id === parseInt(id));
    const selectedSalesCondition = tempSalesCondition[selectedSalesConditionIndex].conditions.filter((item) => item.id === parseInt(conditionId))[0];
    const invalidConditionChildItem = selectedSalesCondition.items.filter((item) => (item.price && item.price_rate) || (!item.price && !item.price_rate) || !item.name);

    if(invalidConditionChildItem.length !== 0) {
      alert('하위 카테고리 중 올바르지 않거나 입력하지 않은 정보가 존재합니다.');
      return false;
    }

    const { authToken } = this.state;

    Service.putLineUpSalesCondition({
      ...selectedSalesCondition,
      authToken
    }).then((response) => {
      if(response.status === 200) {
        alert('수정되었습니다.');
      }
    }).catch((error) => {
      if(error.response) {
        if(error.response.data.message) {
          alert(error.response.data.message);
        }
      }
    });

    // 해당 판매조건 하위 카테고리 아이템 Input Toggle
    this.setLineUpSalesConditionChildEditStatus(e);
  };

  // 해당 판매조건의 하위 카테고리 아이템을 삭제합니다.
  deleteLineUpSalesConditionChildItem = (e) => {
    const ids = String(e.target.name).split('-');
    const lineupId = ids[1];
    const conditionId = ids[2];
    const childItemId = ids[3];
    const { forms } = this.state;
    const tempLineUp = [ ...forms ];
    const selectedLienUp = forms.filter((item) => item.id === parseInt(lineupId))[0];
    const selectedLineUpIndex = forms.findIndex((item) => item.id === parseInt(lineupId));
    const selectedLineUpSalesCondition = selectedLienUp.conditions.filter((item) => item.id === parseInt(conditionId))[0];
    const selectedLineUpSalesConditionIndex = selectedLienUp.conditions.findIndex((item) => item.id === parseInt(conditionId));
    // const selectedLineUpSalesConditionChildItem = selectedLineUpSalesCondition.items.filter((item) => item.id === parseInt(childItemId))[0];
    // const selectedLineUpSalesConditionChildItemIndex = selectedLineUpSalesCondition.items.findIndex((item) => item.id === parseInt(childItemId));

    if(selectedLineUpSalesCondition.items.length === 1) {
      if(!(selectedLineUpSalesCondition.price || selectedLineUpSalesCondition.price_rate) || (selectedLineUpSalesCondition.price && selectedLineUpSalesCondition.price_rate)) {
        alert('마지막 하위 카테고리 아이템을 삭제하려면,\n해당 아이템 부모 판매조건의 할인가격 또는 할인율을 입력해주세요.');
        return false;
      }
      tempLineUp[selectedLineUpIndex].conditions[selectedLineUpSalesConditionIndex] = {
        ...selectedLineUpSalesCondition,
        items: selectedLineUpSalesCondition.items.filter((item) => item.id !== parseInt(childItemId))
      };

      this.setState({
        forms: tempLineUp
      });
    } else {
      tempLineUp[selectedLineUpIndex].conditions[selectedLineUpSalesConditionIndex] = {
        ...selectedLineUpSalesCondition,
        items: selectedLineUpSalesCondition.items.filter((item) => item.id !== parseInt(childItemId))
      };

      const { authToken } = this.state;
      Service.putLineUpSalesCondition({
        ...tempLineUp[selectedLineUpIndex].conditions[selectedLineUpSalesConditionIndex],
        authToken
      }).then((response) => {
        if(response.status === 200) {
          alert('삭제되었습니다.');
          this.setState({
            forms: tempLineUp
          });
        }
      }).catch((error) => {
        if(error.response) {
          if(error.response.data.message) {
            alert(error.response.data.message);
          }
        }
      });
    }

    // const lienUp = forms.filter((item) => item.id === parseInt(lineupId))[0];
    // const lineUpSalesCondition = lienUp.conditions.filter((item) => item.id === parseInt(conditionId))[0];
  };

  render() {
    let year = []
    let month = []
    for(var x  = 2010; x<2026; x++){
      year.push(x)
    }
    for(var x = 1; x<=12; x++){
      month.push(x)
    }
    const state = this.state
      return (
          <Fragment>
             {/* 라인업 수정 */}
             <div className="title disi wd100"><p className="mb10"><span>홈</span> 〉 <span>차량DB</span> 〉 <span>라인업</span> 〉 <span>수정</span></p>라인업 수정
                  <div className="floatR disi">
                      {/*{!this.state.isBulk ? <Link to="#"><button type="submit" className="btnLine navyBg ml10" onClick={this.updateData}>저장</button></Link> : <></>}*/}
                      <button type="button" className="btn navyBg ml10" onClick={() => this.props.history.goBack()}>목록</button>
                  </div>
             </div>
              <div className="form_tablewrap">
                      <table className="mt20">
                          <colgroup>
                              <col width="14%"></col>
                              <col width="*"></col>
                          </colgroup>
                          <tbody>
                              <tr>
                                  <th>브랜드/모델그룹/모델명</th>
                                  <td id="brandSelect">
                                  <Select className="reactSelect"
                                    options={this.state.totalInfo}
                                    value={this.state.brandId}
                                    name="brandId"
                                    isDisabled={true}
                                    />

                                    <Select className="reactSelect"
                                    options={this.state.modelGroup}
                                    value={this.state.modelGroupId}
                                    name="brandId"
                                    isDisabled={true}
                                    />

                                    <Select className="reactSelect"
                                    options={this.state.model}
                                    value={this.state.modelId}
                                    name="brandId"
                                    isDisabled={true}
                                    />
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                      {
                        this.state.isBulk || !this.state.isBulk ?
                        this.state.forms.map((item,index) => {
                          return(
                          <Fragment key={index}>
                          <table className="mt30">
                          <colgroup>
                              <col width="10%"></col>
                              <col width="30%"></col>
                              <col width="10%"></col>
                              <col width="50%"></col>
                          </colgroup>
                          <tbody>
                              <tr>
                                  <th>라인업명 <span className="redT">*</span></th>
                                  <td><input type="text" id="name" value={item.name} onChange={e => this.infoSettingByBulk(e,"" , index)}></input></td>
                                  <th>연료 <span className="redT">*</span></th>
                                  <td>
                                      <select className="ml10" id="fuelType" value={item.fuelType} onChange={e => this.infoSettingByBulk(e,"" , index)}>
                                          <option>휘발유</option>
                                          <option>경유</option>
                                          <option>LPG</option>
                                          <option>전기</option>
                                          <option>휘발유+전기</option>
                                          <option>수소</option>
                                          <option>CNG</option>
                                      </select>
                                  </td>
                              </tr>
                              <tr>
                                  {
                                    item.fuelType == "전기" ?
                                    <>
                                    <th>주행거리(km/kWh) <span className="redT">*</span></th>
                                    <td><input type="number" className="mileage" id="mileage" value={item.mileage || ''} onChange={e => this.infoSettingByBulk(e,"" , index)}></input></td>
                                    </>
                                     :
                                    <>
                                    <th>배기량(cc) <span className="redT">*</span></th>
                                    <td><input type="number" className="displacement" id="displacement" value={item.displacement || ''} onChange={e => this.infoSettingByBulk(e,"" , index)}></input></td>
                                    </>
                                  }
                                  <th>출시일</th>
                                  <td>
                                    <DatePicker
                                          selected={item.date}
                                          onChange={(date) => this.handleChange(date ,index)}
                                          dateFormat="yyyy-MM-dd"
                                        />
                                  </td>
                              </tr>
                              <tr>
                                  <th>연식 <span className="redT">*</span></th>
                                  <td><input type="number" value={item.year} id="year" onChange={e => this.infoSettingByBulk(e,"" , index)}></input></td>
                                  <th>활성화 여부 <span className="redT">*</span></th>
                                  <td>
                                        <input type="radio" name={"active"+index} id={"active"+index+"_ok"} onChange={(e) => this.infoSettingByBulk(e , 'radio' , index)} checked={item.active ? true : false}></input>
                                        <label htmlFor={"active"+index+"_ok"}> 예</label>
                                        <input type="radio" name={"active"+index} id={"active"+index+"_no"} className="ml10" onChange={(e) => this.infoSettingByBulk(e , 'radio' , index)} checked={!item.active ? true : false}></input>
                                        <label htmlFor={"active"+index+"_no"}> 아니오</label>
                                    </td>
                                </tr>
                                <tr>
                                    <th>시판 여부 <span className="redT">*</span></th>
                                    <td>
                                        <input type="radio" name={"selling"+index} id={"sell"+index+"_ok"} onChange={(e) => this.infoSettingByBulk(e , 'radio' , index)} checked={item.sale ? true : false}></input>
                                        <label htmlFor={"sell"+index+"_ok"}> 예</label>
                                        <input type="radio" name={"selling"+index} id={"sell"+index+"_no"} className="ml10" onChange={(e) => this.infoSettingByBulk(e , 'radio' , index)} checked={!item.sale ? true : false}></input>
                                        <label htmlFor={"sell"+index+"_no"} > 아니오</label>
                                    </td>
                                    <th>대표 라인업 선택 <span className="redT">*</span></th>
                                    <td>
                                        <input type="radio" name={"rp_line"+index} id={"rp"+index+"_ok"}  onChange={(e) => this.infoSettingByBulk(e , 'radio' , index)} checked={item.primary ? true : false}></input>
                                        <label htmlFor={"rp"+index+"_ok"}> 예</label>
                                        <input type="radio" name={"rp_line"+index} id={"rp"+index+"_no"} className="ml10"  onChange={(e) => this.infoSettingByBulk(e , 'radio' , index)} checked={!item.primary ? true : false}></input>
                                        <label htmlFor={"rp"+index+"_no"}> 아니오</label>
                                    </td>
                              </tr>
                          </tbody>
                      </table>
                      <div className="rightT mt20">
                      <Link to="#"><button type="submit" className="btnLine navyBg ml10" onClick={() => this.updateDataByBulk(index , item.type)}>저장</button></Link>
                      </div>
                      </Fragment>
                          )
                        })
                        :
                        <table className="mt30">
                          <colgroup>
                              <col width="10%"></col>
                              <col width="30%"></col>
                              <col width="10%"></col>
                              <col width="50%"></col>
                          </colgroup>
                          <tbody>
                              <tr>
                                  <th>라인업명 <span className="redT">*</span></th>
                                  <td><input type="text" id="name" value={state.name} onChange={this.infoSetting}></input></td>
                                  <th>연료 <span className="redT">*</span></th>
                                  <td>
                                      <select className="ml10" id="fuelType" value={state.fuelType} onChange={this.infoSetting}>
                                          <option>경유</option>
                                          <option>휘발유</option>
                                          <option>LPG</option>
                                          <option>전기</option>
                                          <option>휘발유+전기</option>
                                          <option>수소</option>
                                          <option>CNG</option>
                                      </select>
                                  </td>
                              </tr>
                              <tr>
                                  {
                                    state.fuelType == "전기" ?
                                    <>
                                    <th>주행거리(km/kWh) <span className="redT">*</span></th>
                                    <td><input type="number" className="mileage" id="mileage" value={state.mileage} onChange={this.infoSetting}></input></td>
                                    </>
                                     :
                                    <>
                                    <th>배기량(cc) <span className="redT">*</span></th>
                                    <td><input type="number" className="displacement" id="displacement" value={state.displacement} onChange={this.infoSetting}></input></td>
                                    </>
                                  }
                                  <th>출시일</th>
                                  <td>
                                    <DatePicker
                                          selected={this.state.date}
                                          onChange={(date) => this.handleChange(date)}
                                          dateFormat="yyyy-MM-dd"
                                        />
                                  </td>
                              </tr>
                              <tr>
                                  <th>연식 <span className="redT">*</span></th>
                                  <td><input type="number" value={state.year} id="year" onChange={this.infoSetting}></input></td>
                                  <th>활성화 여부 <span className="redT">*</span></th>
                                  <td>
                                      <input type="radio" name="active" className="ok" id="active_ok" checked={state.active ? true : false} onChange={this.infoSetting}></input>
                                      <label htmlFor="active_ok"> 예</label>
                                      <input type="radio" name="active" id="active_no" className="ml10 no" checked={!state.active ? true : false} onChange={this.infoSetting}></input>
                                      <label htmlFor="active_no"> 아니오</label>
                                  </td>
                              </tr>
                              <tr>
                                  <th>시판 여부 <span className="redT">*</span></th>
                                  <td>
                                      <input type="radio" name="sale" className="ok" id="sell_ok" checked={state.sale ? true : false}onChange={this.infoSetting}></input>
                                      <label htmlFor="sell_ok"> 예</label>
                                      <input type="radio" name="sale" id="sell_no" className="ml10 no" checked={!state.sale ? true : false} onChange={this.infoSetting}></input>
                                      <label htmlFor="sell_no"> 아니오</label>
                                  </td>
                                  <th>대표 라인업 선택 <span className="redT">*</span></th>
                                  <td>
                                      <input type="radio" name="primary" className="ok" id="rp_ok" checked={state.primary ? true : false} onChange={this.infoSetting}></input>
                                      <label htmlFor="rp_ok"> 예</label>
                                      <input type="radio" name="primary" id="rp_no" className="ml10 no" checked={!state.primary ? true : false} onChange={this.infoSetting}></input>
                                      <label htmlFor="rp_no"> 아니오</label>
                                  </td>
                              </tr>
                              <tr>
                                  <th>판매조건</th>
                                  <td colSpan="3">
                                      <div>
                                      <select className="mr10" id="yearByList" value={state.yearByList}onChange={e => this.infoSetting( e, 'select')}>
                                              {
                                                year.map((y , i) => {
                                                    return(
                                                      <option key={i} value={y}>{y}</option>
                                                    )
                                                  })
                                                }

                                            </select>
                                            년 /
                                            <select className="ml10 mr10" id="monthByList" value={state.monthByList}onChange={e => this.infoSetting(e, 'select')}>
                                              {
                                                month.map((m , i) => {
                                                    return(
                                                      <option key={i} value={m}>{m}</option>
                                                    )
                                                  })
                                                }
                                            </select>
                                            월
                                      </div>
                                      <table className="nonborder_table">
                                          <colgroup>
                                              <col width="15%"></col>
                                              <col width="15%"></col>
                                              <col width="15%"></col>
                                              <col width=""></col>
                                          </colgroup>
                                          <tbody>
                                          {
                                            state.conditions.map((pItem , index) => {
                                              return (
                                                  <Fragment key={index}>
                                                    <tr>
                                                      {!pItem.status ?
                                                          <>
                                                            <td>&nbsp;&nbsp;&nbsp;{pItem.name}</td><td>&nbsp;&nbsp;&nbsp;{pItem.price == null ? "" : this.setComma(pItem.price)}</td>
                                                            <td>&nbsp;&nbsp;&nbsp;{pItem.price_rate == null ? "" : pItem.price_rate+"%"}</td>
                                                            <td>&nbsp;&nbsp;&nbsp;{pItem.description}</td>
                                                          </>
                                                          :
                                                          <>
                                                            <td><input type="text" name={`name-${state.editId}-${pItem.id}`} value={pItem.name || ''} onChange={this.handleLineUpSalesCondition}></input></td>
                                                            <td><input type="number" name={`price-${state.editId}-${pItem.id}`} value={pItem.price || ''} onChange={this.handleLineUpSalesCondition} placeholder="할인가격" disabled={pItem.items}></input></td>
                                                            <td><input type="number" name={`price_rate-${state.editId}-${pItem.id}`} value={pItem.price_rate || ''} onChange={this.handleLineUpSalesCondition} placeholder="할인율" disabled={pItem.items}></input></td>
                                                            <td><input type="text" name={`description-${state.editId}-${pItem.id}`} value={pItem.description || ''} onChange={this.handleLineUpSalesCondition}></input></td>
                                                          </>
                                                      }
                                                      <td>s
                                                        <button type="button" className="btn navyBg" name={`ps-${state.editId}-${pItem.id}`} onClick={!pItem.status ? this.setLineUpSalesConditionEditStatus : this.putLineUpSalesCondition}>{!pItem.status ? '수정' : '저장'}</button>
                                                        <button type="button" className="btnLine navyBg ml10" name={`ps-${state.editId}-${pItem.id}`} onClick={this.deleteLienUpSalesCondition}>삭제</button>
                                                      </td>
                                                    </tr>
                                                    {
                                                      pItem.items ? pItem.items.map((child , i) => {
                                                            return(
                                                                <tr key={i}>
                                                                  {!child.status ?
                                                                      <>
                                                                        <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{child.name}</td>
                                                                        <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{child.price == null ? "" : this.setComma(child.price)}</td>
                                                                        <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{child.price_rate == null ? "" : child.price_rate+"%"}</td>
                                                                      </>
                                                                      :
                                                                      <>
                                                                        <td><input type="text" name={`name-${state.editId}-${pItem.id}-${child.id}`} value={child.name || ''} onChange={this.handleLineUpSalesConditionChild}></input></td>
                                                                        <td><input type="number" name={`price-${state.editId}-${pItem.id}-${child.id}`} value={child.price || ''} onChange={this.handleLineUpSalesConditionChild}></input></td>
                                                                        <td><input type="number" name={`price_rate-${state.editId}-${pItem.id}-${child.id}`} value={child.price_rate || ''} onChange={this.handleLineUpSalesConditionChild}></input></td>
                                                                      </>
                                                                  }
                                                                  <td></td>
                                                                  <td>
                                                                    <button type="button" className="btn navyBg" name={`ps-${state.editId}-${pItem.id}-${child.id}`} onClick={!child.status ? this.setLineUpSalesConditionChildEditStatus : this.putLineUpSalesConditionChildItem}>{!child.status ? '수정' : '저장'}</button>
                                                                    <button type="button" className="btnLine navyBg ml10" name={`ps-${state.editId}-${pItem.id}-${child.id}`} onClick={this.deleteLineUpSalesConditionChildItem}>삭제</button>
                                                                  </td>
                                                                </tr>
                                                            )
                                                          })
                                                          :
                                                          <></>
                                                    }
                                                  </Fragment>
                                              )
                                            })
                                          }
                                          <tr>
                                            <td><input type="text" className="condName" name={`name-${state.editId}`} value={state.forms[0] && state.forms[0].addConditions.name || ''} placeholder="판매조건 명" onChange={this.handleNewLineUpSalesCondition}></input></td>
                                            <td><input type="number" className="condPrice" name={`price-${state.editId}`} value={state.forms[0] && state.forms[0].addConditions.price || ''} placeholder="할인가격" onChange={this.handleNewLineUpSalesCondition} disabled={state.forms[0] && state.forms[0].addConditions.items.length !== 0}></input></td>
                                            <td><input type="number" className="condPercentage" name={`price_rate-${state.editId}`} value={state.forms[0] && state.forms[0].addConditions.price_rate || ''} placeholder="할인율" onChange={this.handleNewLineUpSalesCondition} disabled={state.forms[0] && state.forms[0].addConditions.items.length !== 0}></input></td>
                                            <td><input type="text" className="condDescription" name={`description-${state.editId}`} value={state.forms[0] && state.forms[0].addConditions.description || ''} placeholder="판매조건 설명" onChange={this.handleNewLineUpSalesCondition}></input></td>
                                            <td>
                                              <button type="submit" name={`cond-${state.editId}`} className="btn navyBg" onClick={this.postLineUpSalesCondition}>추가</button>
                                            </td>
                                          </tr>
                                          </tbody>
                                      </table>
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                      }
                      <div className="rightT mt20">
                          <button className="btn navyBg" onClick={() => this.onLineUpAdd()}>라인업 추가</button>
                          {/*{!this.state.isBulk ? <Link to="#"><button type="submit" className="btnLine navyBg ml10" onClick={this.updateData}>저장</button></Link> : <></>}*/}
                          <button type="button" className="btn navyBg ml10" onClick={() => this.props.history.goBack()}>목록</button>
                      </div>
              </div>
              {/* 라인업 수정 영역 끝 */}
          </Fragment>
      );
  }
}

export default LineUpDetail;
