import {devUrl} from '../../serviceComm/variable';
import axios from 'axios';

export const getSimpleActiveCarModels = authToken => {
  return axios({
    method: 'get',
    url: `${devUrl}/admin/car_info/car_models?is_active=true&with_brand=true`,
    headers: {Authorization: `Bearer ${authToken}`}
  })
};

export function getSimpleActiveLineups(authToken) {
  const config = {
    method: 'get',
    url: `${devUrl}/admin/car_info/lineups?is_active=true`,
    headers: {
      Authorization: `Bearer ${authToken}`
    }
  };

  return axios(config);
}

export function getSimpleActiveLineupsWithVerbose(authToken) {
  const config = {
    method: 'get',
    url: `${devUrl}/admin/car_info/lineups/verbose`,
    headers: {
      Authorization: `Bearer ${authToken}`
    }
  };

  return axios(config);
}

export const getSimpleContents = authToken => {
  return axios({
    method: 'get',
    url: `${devUrl}/admin/cms/simple_list/contents`,
    headers: {Authorization: `Bearer ${authToken}`}
  })
};

export const getSimpleVideos = authToken => {
  return axios({
    method: 'get',
    url: `${devUrl}/admin/cms/simple_list/videos`,
    headers: {Authorization: `Bearer ${authToken}`}
  })
};
