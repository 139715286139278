import { devUrl } from '../../serviceComm/variable';

// 컨텐츠 리스트 요청
export function getContentList(authToken, per, page) {
  const axios = require('axios');
  const config = {
    method: 'get',
    url: `${devUrl}/admin/cms/contents?with_images=true&per=${per}&page=${page}`,
    headers: {
      Authorization: `Bearer ${authToken}`
    }
  };

  return axios(config);
}

// 컨텐츠 리스트 검색 요청
export function getSearchContentList(authToken, formData, per, page) {
  const axios = require('axios');

  const config = {
    method: 'get',
    url: `${devUrl}/admin/cms/contents`,
    headers: {
      Authorization: `Bearer ${authToken}`
    },
    params: {
      provider_id:
        Number(formData.get('provider_id')) === 0
          ? null
          : formData.get('provider_id'),
      title:
        String(formData.get('title')) === '' ? null : formData.get('title'),
      category:
        String(formData.get('category')) === 'ALL'
          ? null
          : formData.get('category'),
      with_images: true,
      per: per,
      page: page
    }
  };

  return axios(config);
}

// 매체 리스트 요청
export function getMediaList(authToken) {
  const axios = require('axios');
  const config = {
    method: 'get',
    url: `${devUrl}/admin/cms/providers`,
    headers: {
      Authorization: `Bearer ${authToken}`
    }
  };

  return axios(config);
}

// 포토뉴스 등록 또는 수정
export function putPhotoNews(authToken, formData) {
  const axios = require('axios');
  let config;
  if (formData.get('id')) {
    config = {
      method: 'put',
      url:
        `${devUrl}/admin/cms/content_groups/${formData.get('id')}`,
      headers: {
        Authorization: `Bearer ${authToken}`
      },
      data: formData
    };
  } else {
    config = {
      method: 'post',
      url: `${devUrl}/admin/cms/content_groups`,
      headers: {
        Authorization: `Bearer ${authToken}`
      },
      data: formData
    };
  }

  return axios(config);
}
