import React, {Component, Fragment} from 'react';
import { isEmpty } from "lodash";
import { Link } from 'react-router-dom';
import * as Service from '../../component/services/vehicleDB/ModelService'
import * as Common from "../../component/services/common/CommonService";
import * as BrandService from "../../component/services/brand/BrandService";
import * as modelGroupService from "../../component/services/vehicleDB/ModelGroupService"
import * as modelService from "../../component/services/vehicleDB/ModelService"
import Pagination from '../../component/Pagination'
import Select from 'react-select';
import QueryString from 'query-string';
import { arrayMove } from 'react-sortable-hoc';
import UIkit from "uikit";

// Components
import LineupsOrderModal from "../../component/model/LineupsOrderModal";

// Styles
import '../../UIKitMonkeyPatch.scss';

class Model extends Component{

  constructor(props){
    super(props)
    const queryStringHistory = QueryString.parse(this.props.location.search);
    this.state = {
        authToken :Common.getToken.call(this),
        brandId : {label : queryStringHistory.brandName ? queryStringHistory.brandName : '전체', value : queryStringHistory.brandId ? queryStringHistory.brandId : 0},
        modelGroupId : {label : queryStringHistory.modelGroupId ? queryStringHistory.modelGroupId : '전체' , value : 0},
        modelId : {label: queryStringHistory.modelId ? queryStringHistory.modelId : '전체' , value : 0},
        isActive : {label: queryStringHistory.isActive ? (queryStringHistory.isActive === 'true' ? '활성화' : '비활성화') : '전체', value : queryStringHistory.isActive ? (queryStringHistory.isActive === 'true' ? true : false) : 'all'},
        totalInfo : [],
        modelGroup : [{label: '전체' , value : 0}],
        model : [{label: '전체' , value : 0}],
        is_active : [{label: '전체' , value : 'all'} , {label: '활성화' , value : true} ,{label: '비활성화' , value : false}],
        models : [],
        per : 10,
        page : queryStringHistory.page ? queryStringHistory.page : 1,
        pagination : [],
        totalLength : 0,
        groupName : "",
        name : "",
        brandNameOrder : queryStringHistory.brandNameOrder ? queryStringHistory.brandNameOrder : null,
        modelGroupNameOrder : queryStringHistory.modelGroupNameOrder ? queryStringHistory.modelGroupNameOrder : null,
        activeOrder : queryStringHistory.activeOrder ? queryStringHistory.activeOrder : null,
        updatedOrder : queryStringHistory.updatedOrder ? queryStringHistory.updatedOrder : null,
        createdOrder : queryStringHistory.createdOrder ? queryStringHistory.createdOrder : null,
        modelNameOrder : queryStringHistory.modelNameOrder ? queryStringHistory.modelNameOrder : null,
        totalCnt : 0,
        isModalOpen: false,
        lineupElements: {
            brandName: null,
            carModelGroupName: null,
            carModelName: null,
            carModelId: null,
            on_sales: [],
            not_sales: [],
            order_lineup_ids: []
        }
    }
  }

  componentDidMount(){
    this.getModels()
    this.getModelList()
    this.getTotalInfo()
    this.getHistoryData();
  }

    getHistoryData = () => {
      // 브랜드를 선택한 history가 존재한다면 해당 브랜드의 모델그룹 목록 렌더링
      const { brandId: { value } } = this.state;
      if(value !== 0) {
          const req = modelGroupService.getModelG(this.state.authToken , this.state.brandId.value);
          req.then(response => {
              const data = response.data.data
              let options = []
              options.push({label: '전체' , value : 0})
              for(var x = 0; x<data.length; x++){
                  options.push({label: data[x].name , value : data[x].id})
              }
              this.setState({
                  modelGroup : options
              }, () => {
                  // 모델그룹을 선택한 history가 존재한다면 해당 브랜드의 모델 목록 렌더링
                  const { modelGroupId: { label } } = this.state;
                  if(label && label !== '전체') {
                      const req = modelService.getModelList(
                          this.state.brandId.value,
                          10000,
                          1,
                          this.state.authToken,
                          this.state.modelGroupId.label,
                          "","all","","","","","",""
                      )
                      req.then(response => {
                          const data = response.data.data
                          let options = []
                          options.push({label: '전체' , value : 0})
                          for(var x = 0; x<data.length; x++){
                              options.push({label: data[x].name , value : data[x].id})
                          }
                          this.setState({
                              model : options
                          })
                      })
                  }
              });
          })
      }
  };

  getTotalInfo = aysnc => {
    const brandList = BrandService.getTotalInfo(this.state.authToken);
    brandList.then(response => {
      const data = response.data.data
      let options = []
      options.push({label: '전체' , value : 0})
      for(var x = 0; x<data.length; x++){
        options.push({label: data[x].name , value : data[x].id})
      }
      this.setState({
        totalInfo: options
      });
    });
  };

  getModels = async => {
    const PriceOptions = Service.getModel(
      this.state.authToken
    )
    PriceOptions.then(res => {
      this.setState({
        totalLength : res.data.data.length
      })
    })
  }

  getModelList = is_render => {
    const ModelList = Service.getModelList(
      this.state.brandId.value,
        is_render ? 10 : this.state.per,
        is_render ? 1 : this.state.page,
      this.state.authToken,
      this.state.modelGroupId.label == "전체" ? "" : this.state.modelGroupId.label,
      this.state.modelId.label == "전체" ? "" : this.state.modelId.label,
      this.state.isActive.value,
      this.state.brandNameOrder,
      this.state.modelGroupNameOrder,
      this.state.modelNameOrder,
      this.state.activeOrder,
      this.state.updatedOrder,
      this.state.createdOrder
    );
    ModelList.then(response => {
      this.setState({
        models: response.data.data,
        pagination : response.data.pagination,
        totalCnt : response.data.total_entries
      });
    });
  };

  selectBrand = selectOption => {
    this.setState({
      brandId : selectOption,
      modelGroup : [{label: '전체' , value : 0}],
      model : [{label: '전체' , value : 0}],
      modelGroupId : {label : '전체' , value : 0},
      modelId : {label: '전체' , value : 0},
    } , () => {
      const req = modelGroupService.getModelG(this.state.authToken , this.state.brandId.value)
      req.then(response => {
        const data = response.data.data
        let options = []
        options.push({label: '전체' , value : 0})
        for(var x = 0; x<data.length; x++){
          options.push({label: data[x].name , value : data[x].id})
        }
        this.setState({
          modelGroup : options
        })
      })
    });
  };

  selectModelGroup = selectOption => {
    this.setState({
      modelGroupId : selectOption,
      model : [{label: '전체' , value : 0}],
      modelId : {label: '전체' , value : 0},
    } , () => {
      const req = modelService.getModelList(
        this.state.brandId.value,
      10000,
      1,
      this.state.authToken,
      this.state.modelGroupId.label,
      "","all","","","","","",""
      )
      req.then(response => {
        const data = response.data.data
        let options = []
        options.push({label: '전체' , value : 0})
        for(var x = 0; x<data.length; x++){
          options.push({label: data[x].name , value : data[x].id})
        }
        this.setState({
          model : options
        })
      })
    });
  };

  selectModel = selectOption => {
    this.setState({
      modelId : selectOption
    })
  }

  activeSelect = selectOption => {
    this.setState({
      isActive : selectOption
    })
  }

  onClickPage = (page) => {
    this.setState(
      {
        page
      },
      () => {
          this.handlePage();
          this.getModelList();
      }
    );
  };

  infoSearchValue = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSearch = () => {
      this.setState({
          page: 1
      }, () => {
        this.handlePage();
        this.getModelList(1)
      });
  };

  DELETE = () => {
     const ids = []
    const checkBoxs = document.querySelectorAll('.delete_checkBox')
    for(let x = 0; x<checkBoxs.length; x++) {
      if(checkBoxs[x].checked){
        ids.push(parseInt(checkBoxs[x].id))
      }
    }
    if(ids.length > 0){
      if (window.confirm('삭제하시겠습니까?')){
        const deleteModel = Service.deleteModel(
          this.state.authToken,
          ids
        )

        deleteModel.then(res => {
          if (res.status >= 200 && res.status < 400) {
            this.getModelList(1)
            const chk = document.getElementsByClassName('delete_checkBox');
            for(var x = 0; x<chk.length; x++) {
              chk[x].checked = false
            }
          }
        }).catch((error) => {
           if(error) {
               if(error.response.data.message) {
                   alert(error.response.data.message);
               }
           }
        });
      }

    }
  }

  onClickCheckAll = () => {
    const chk = document.getElementsByClassName('delete_checkBox');
    const chkAll = document.getElementById('model_all');

    if(chkAll.checked) {
      for(var x = 0; x<chk.length; x++) {
          chk[x].checked = true
      }
    }
    else {
      for(var x = 0; x<chk.length; x++) {
          chk[x].checked = false
      }
    }

  }

  checkAll = () => {
    const chk = document.getElementsByClassName('delete_checkBox');
    const chkAll = document.getElementById('model_all');
    let isCheck = true
    for(var x = 0; x<chk.length; x++) {
      if(!chk[x].checked){
        chkAll.checked = false
        isCheck = false;
      }
    }
    if(isCheck) chkAll.checked = true;
  }

  brandNameOrder = (order) => {
    this.setState({
        brandNameOrder : order,
        modelGroupNameOrder: null,
        modelNameOrder: null,
        activeOrder: null,
        updatedOrder: null,
        createdOrder: null
    } , () => {
        this.handlePage();
        this.getModelList(1);
    })
  }

  modelGroupNameOrder = (order) => {
    this.setState({
        brandNameOrder: null,
        modelGroupNameOrder : order,
        modelNameOrder: null,
        activeOrder: null,
        updatedOrder: null,
        createdOrder: null
    } , () => {
        this.handlePage();
        this.getModelList(1);
    })
  }

  modelNameOrder = (order) => {
    this.setState({
        brandNameOrder: null,
        modelGroupNameOrder: null,
        modelNameOrder : order,
        activeOrder: null,
        updatedOrder: null,
        createdOrder: null
    } , () => {
        this.handlePage();
        this.getModelList(1)
    })
  }

  activeOrder = (order) => {
    this.setState({
        brandNameOrder: null,
        modelGroupNameOrder: null,
        modelNameOrder: null,
        activeOrder : order,
        updatedOrder: null,
        createdOrder: null
    } , () => {
        this.handlePage();
        this.getModelList(1);
    })
  }

  updatedOrder = order => {
    this.setState({
        brandNameOrder: null,
        modelGroupNameOrder: null,
        modelNameOrder: null,
        activeOrder: null,
        updatedOrder : order,
        createdOrder: null
    } , () => {
        this.handlePage();
        this.getModelList(1);
    })
  }

  createdOrder = order => {
    this.setState({
        brandNameOrder: null,
        modelGroupNameOrder: null,
        modelNameOrder: null,
        activeOrder: null,
        updatedOrder: null,
        createdOrder : order
    } , () => {
        this.handlePage();
        this.getModelList(1);
    })
  }

  handleKeyPress = () => {
    if (window.event.keyCode == 13) {
      this.onSearch()
    }
  }

    // 모델 페이지 내의 모든 페이지 라우팅 핸들링
    handlePage = (path) => {
        const {
            brandId: { label: brandName, value: brandId },
            modelGroupId: { label: modelGroupId },
            modelId: { label: modelId },
            isActive: { value: isActive },
            brandNameOrder,
            modelGroupNameOrder,
            activeOrder,
            updatedOrder,
            createdOrder,
            modelNameOrder,
            page
        } = this.state;
        let searchInfo = {
            brandId,
            brandName,
            modelGroupId,
            modelId,
            isActive,
            brandNameOrder,
            modelGroupNameOrder,
            activeOrder,
            updatedOrder,
            createdOrder,
            modelNameOrder,
            page
        };
        // 해당 키가 가진 값이 존재하지 않는다면 제거
        Object.keys(searchInfo).map((item) => {
            if (item === 'brandId') {
                (searchInfo[item] === 0 || searchInfo[item].toString().length === 0) && delete searchInfo[item];
            } else if (item === 'brandName') {
                (searchInfo[item] === '전체' || searchInfo[item].toString().length === 0) && delete searchInfo[item];
            } else if (item === 'modelGroupId') {
                (searchInfo[item] === '전체' || searchInfo[item].toString().length === 0) && delete searchInfo[item];
            } else if (item === 'modelId') {
                (searchInfo[item] === '전체' || searchInfo[item].toString().length === 0) && delete searchInfo[item];
            } else if (item === 'isActive') {
                (searchInfo[item] === 'all' || searchInfo[item].toString().length === 0) && delete searchInfo[item];
            } else {
                (searchInfo[item] === null || searchInfo[item].toString().length === 0) && delete searchInfo[item];
            }
        });
        let queryString = Object.keys(searchInfo).map((item, index) => searchInfo[item] !== null && `${item}=${searchInfo[item]}`).join('&');
        this.props.history.push({
            pathname: path ? path : '/vehicleDB/model',
            search: `?${queryString}`
        });
    };

    handleModelLineupsOrderModal = (event) => {
        const { authToken } = this.state;
        const brandName = event.currentTarget.getAttribute('data-brand-name');
        const carModelGroupName = event.currentTarget.getAttribute('data-car-model-group-name');
        const carModelName = event.currentTarget.getAttribute('data-car-model-name');
        const carModelId = event.currentTarget.getAttribute('data-car-model-id');

        Service.getModelLineups(authToken, carModelId).then((response) => {
            const onSaleLineups = response.data.data.filter((item) => item.is_onsale);
            const notSaleLineups = response.data.data.filter((item) => !item.is_onsale);

            this.setState({
                lineupElements: {
                    brandName,
                    carModelGroupName,
                    carModelName,
                    carModelId,
                    on_sales: onSaleLineups,
                    not_sales: notSaleLineups,
                    order_lineup_ids: [
                        ...onSaleLineups.map((item) => item.id),
                        ...notSaleLineups.map((item) => item.id)
                    ]
                },
                isModalOpen: true
            });
        }).catch((error) => {
           console.log(error);
        });
    };

    handleCloseLineupsOrderModal = () => this.setState({ isModalOpen: false });

    handleOnSaleLineupOnSortEnd = ({ oldIndex, newIndex }) => {
        const lineupSortableElements = document.getElementsByClassName('uk-custom-sortable');
        for (let i = 0; i < lineupSortableElements.length; i++) {
            lineupSortableElements[i].removeAttribute('style');
        }

        const { lineupElements } = this.state;
        const onSaleLineups = arrayMove(lineupElements.on_sales, oldIndex, newIndex);
        this.setState({
            lineupElements: {
                ...lineupElements,
                on_sales: onSaleLineups,
                order_lineup_ids: [
                    ...onSaleLineups.map((item) => item.id),
                    ...lineupElements.not_sales.map((item) => item.id)
                ]
            }
        });
    };

    handleNotSaleLineupOnSortEnd = ({ oldIndex, newIndex }) => {
        const lineupSortableElements = document.getElementsByClassName('uk-custom-sortable');
        for (let i = 0; i < lineupSortableElements.length; i++) {
            lineupSortableElements[i].removeAttribute('style');
        }

        const { lineupElements } = this.state;
        const notSaleLineups = arrayMove(lineupElements.not_sales, oldIndex, newIndex);
        this.setState({
            lineupElements: {
                ...lineupElements,
                not_sales: notSaleLineups,
                order_lineup_ids: [
                    ...lineupElements.on_sales.map((item) => item.id),
                    ...notSaleLineups.map((item) => item.id),
                ]
            }
        });
    };

    handleModelLineupsOrderSave = () => {
      const { authToken, lineupElements: { carModelId, order_lineup_ids } } = this.state;

      if (isEmpty(order_lineup_ids)) {
          UIkit.notification({
              message: `<span class="uk-icon-color-danger uk-margin-small-right" uk-icon=\'icon: warning\'></span> 라인업이 존재하지 않습니다.`,
              pos: 'bottom-center',
              timeout: 1500
          });
          return false;
      }

      Service.putModelLineupsOrder(authToken, carModelId, order_lineup_ids).then(() => {
        UIkit.notification({
            message: '<span class="uk-icon-color-success uk-margin-small-right" uk-icon=\'icon: check\'></span> 라인업의 노출 순서가 수정되었습니다.',
            pos: 'bottom-center',
            timeout: 1500
        });
      }).catch((error) => {
        console.log(error);
        UIkit.notification({
          message: `<span class="uk-icon-color-danger uk-margin-small-right" uk-icon=\'icon: ban\'></span> ${error.response.data.message}`,
          pos: 'bottom-center',
          timeout: 1500
        });
      });
    };

    render(){
        const { isModalOpen, lineupElements } = this.state;

        return(
            <Fragment>
               <div className="title"><p className="mb10"><span>홈</span> 〉 <span>차량DB</span> 〉 <span>모델</span></p>모델 </div>
                <div className="info_searchwrap">
                        <table>
                            <colgroup>
                                <col width="10%"></col>
                                <col width="*"></col>
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>브랜드명</th>
                                    <td>
                                    <Select className="reactSelect w-500"
                                    options={this.state.totalInfo}
                                    value={this.state.brandId}
                                    name="brandId"
                                    onChange={this.selectBrand}
                                    placeholder="전체"
                                    isSearchable={true}/>
                                    </td>
                                </tr>
                                <tr>
                                    <th>모델그룹명</th>
                                    <td>
                                    <Select className="reactSelect w-500"
                                      options={this.state.modelGroup}
                                      value={this.state.modelGroupId}
                                      name="brandId"
                                      onChange={this.selectModelGroup}
                                      placeholder="전체"
                                      isSearchable={true}/>
                                    </td>
                                </tr>
                                <tr>
                                    <th>모델명</th>
                                    <td>
                                    <Select className="reactSelect w-500"
                                      options={this.state.model}
                                      value={this.state.modelId}
                                      name="brandId"
                                      onChange={this.selectModel}
                                      placeholder="전체"
                                      isSearchable={true}/>
                                    </td>
                                </tr>
                                <tr>
                                    <th>활성화 상태</th>
                                    <td>
                                    <Select className="reactSelect w-500"
                                      options={this.state.is_active}
                                      value={this.state.isActive}
                                      name="brandId"
                                      onChange={this.activeSelect}
                                      placeholder="전체"
                                      isSearchable={true}/>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="centerT mt20">
                            <button type="submit" className="btnLine navyBg medium" onClick={()=>this.onSearch()}>검색</button>
                        </div>
                </div>
                {/* 모델그룹 검색영역 끝 */}
                <div className="info_resultwrap">
                    <div className="info_tabletop">
                        <div className="mb10">
                            <button className="btnLine navyBg" onClick={() => this.handlePage(`/vehicleDB/model/modelDetail`)}>추가</button>
                            <button className="btn navyBg centerT ml10" onClick={() => this.DELETE()}>삭제</button>
                        </div>
                    </div>
                    <table>
                        <colgroup>
                            <col width="5%"></col>
                            <col width=""></col>
                            <col width=""></col>
                            <col width=""></col>
                            <col width=""></col>
                            <col width=""></col>
                            <col width=""></col>
                            <col width=""></col>
                            <col width="15%"></col>
                        </colgroup>
                        <thead>
                             <tr>
                                <th className="ccc" colSpan="9">
                                    총 <span>{this.state.totalCnt}</span>개
                                </th>
                            </tr>
                            <tr>
                                <th><input type="checkbox" id="model_all" name="model_group" onClick={() => this.onClickCheckAll()}></input></th>
                                <th>번호</th>
                                <th>
                                    브랜드명
                                    <button className="set_btn" onClick={() => this.brandNameOrder("desc")}>▼</button>
                                    <button className="set_btn" onClick={() =>  this.brandNameOrder("asc")}>▲</button>
                                </th>
                                <th>
                                    모델그룹명
                                    <button className="set_btn" onClick={() => this.modelGroupNameOrder("desc")}>▼</button>
                                    <button className="set_btn" onClick={() => this.modelGroupNameOrder("asc") }>▲</button>
                                </th>
                                <th>
                                    모델명
                                    <button className="set_btn" onClick={() => this.modelNameOrder("desc")}>▼</button>
                                    <button className="set_btn" onClick={() => this.modelNameOrder("asc") }>▲</button>
                                </th>
                                <th>
                                    활성화 상태
                                    <button className="set_btn" onClick={() => this.activeOrder("desc")}>▼</button>
                                    <button className="set_btn" onClick={() => this.activeOrder("asc") }>▲</button>
                                </th>
                                <th>
                                    수정일
                                    <button className="set_btn" onClick={() => this.updatedOrder("desc")} >▼</button>
                                    <button className="set_btn" onClick={() => this.updatedOrder("asc") } >▲</button>
                                </th>
                                <th>
                                    등록일
                                    <button className="set_btn" onClick={() => this.createdOrder("desc")} >▼</button>
                                    <button className="set_btn" onClick={() => this.createdOrder("asc") } >▲</button>
                                </th>
                                <th>관리</th>
                            </tr>
                        </thead>
                        <tbody>
                          {this.state.models.map((item,index)=> {
                            return(
                              <tr key={`model-${item.id}`}>
                                <td><input type="checkbox" id={item.id} name="model" className="delete_checkBox" onClick={() => this.checkAll()}></input></td>
                                <td>{item.id}</td>
                                <td><Link to={"/brand/totalInfo/totalInfoDetail/"+item.brand_id}>{item.brand_name}</Link></td>
                                <td><Link to={"/vehicleDB/modelGroup/modelGroupDetail/"+item.car_model_group_id}>{item.car_model_group_name}</Link></td>
                                <td><Link to="#">{item.name}</Link></td>
                                <td>{item.is_active ? "활성화" : "비활성화"}</td>
                                <td>{`${new Date(item.updated_at).getFullYear() - 2000}.
                                  ${new Date(item.updated_at).getMonth()+1 < 10 ? `0${new Date(item.updated_at).getMonth()+1}` : new Date(item.updated_at).getMonth()+1}.
                                  ${new Date(item.updated_at).getDate() < 10 ? `0${new Date(item.updated_at).getDate()}` : new Date(item.updated_at).getDate()}`}</td>
                                <td>{
                                 `${new Date(item.created_at).getFullYear() - 2000}.
                                  ${new Date(item.created_at).getMonth()+1 < 10 ? `0${new Date(item.created_at).getMonth()+1}` : new Date(item.created_at).getMonth()+1}.
                                  ${new Date(item.created_at).getDate() < 10 ? `0${new Date(item.created_at).getDate()}` : new Date(item.created_at).getDate()}`}</td>
                                <td>
                                    <button className="btn navyBg" onClick={() => this.handlePage(`/vehicleDB/lineUp/lineUpAdd/${item.brand_id}&${item.car_model_group_id}&${item.id}`)}>
                                        라인업 추가
                                    </button>
                                    <button
                                        className="btn navyBg ml10"
                                        data-brand-name={item.brand_name}
                                        data-car-model-group-name={item.car_model_group_name}
                                        data-car-model-name={item.name}
                                        data-car-model-id={item.id}
                                        onClick={this.handleModelLineupsOrderModal}
                                    >
                                        라인업 순서
                                    </button>
                                    <button className="btn grayBg ml10" onClick={() => this.handlePage(`/vehicleDB/model/modelDetail/${item.id}`)}>수정</button>
                                </td>
                            </tr>
                            )
                          })}
                        </tbody>
                    </table>
                    <div className="pagination">
                        <Pagination
                          onClickPage={this.onClickPage}
                          paginationOption={this.state.pagination}
                        />
                    </div>
                    <LineupsOrderModal
                        isModalOpen={isModalOpen}
                        lineupElements={lineupElements}
                        handleCloseLineupsOrderModal={this.handleCloseLineupsOrderModal}
                        handleOnSaleLineupOnSortEnd={this.handleOnSaleLineupOnSortEnd}
                        handleNotSaleLineupOnSortEnd={this.handleNotSaleLineupOnSortEnd}
                        handleModelLineupsOrderSave={this.handleModelLineupsOrderSave}
                    />
                </div>
            </Fragment>
        );
    }
}

export default Model;