import React from 'react';
import '../style/error.scss';

const page404 = () => {
    return(
        <div className="errorBox">
            해당 페이지는 존재하지 않아요.<br/>404 에러 페이지
        </div>
    );
};

export default page404;