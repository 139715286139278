import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import _ from 'underscore';
import {
  SortableContainer,
  SortableElement,
  arrayMove
} from 'react-sortable-hoc';
import Pagination from '../../component/Pagination';
import Choice from '../../component/Choice';
import * as Common from '../../component/services/common/CommonService';
import * as Service from '../../component/services/content/PhotoNewsDetailService';
import '../../style/contents/photoNews.scss';

const SortableItem = SortableElement(
  ({ item, number, onDeleteChecked }) => (
    <li className="sortable_box">
      <div className="sor">
        <input
          type="checkbox"
          id={`c_delete,${item.id}`}
          name="delete_checkbox"
          onChange={onDeleteChecked}
          checked={item.checked}
        />
      </div>
      <div className="sor">
        <span>{number + 1}</span>
      </div>
      <div className="subject">
        <div className="top">콘텐츠 제목</div>
        <div>{item.title}</div>
      </div>
      <div className="image_count">
        <div className="top">이미지 수량</div>
        <div>{item.images.length}</div>
      </div>
      <div className="sor">
        <span>↕</span>
      </div>
    </li>
  )
);

const SortableList = SortableContainer(({ items, onDeleteChecked }) => (
  <ul className="sortable_wrap">
    {items.map((item, index) => (
      <SortableItem
        key={item.id}
        index={index}
        number={index}
        item={item}
        onDeleteChecked={onDeleteChecked}
      />
    ))}
  </ul>
));

class PhotoNewsDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authToken: Common.getToken.call(this),
      page_history: this.props.location.search.replace(/\?page=/g, '') ? this.props.location.search.replace(/\?page=/g, '') : 1,
      per: 4,
      page: 1,
      id: 0,
      contents: [],
      popup_contents: [],
      medias: [],
      provider_id: 0,
      title: '',
      category: 'ALL',
      pagination: [],
      category_name: '',
      category_description: '',
      tags: [],
      is_checked_content: [],
      is_checked_count: 0,
      is_all_checked: false,
      is_pop_on: false,
      is_search: false
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const { query } = location;
    if (query != null) {
      let tempArray = [];
      for (let i = 0; i < query[4].length; i++) {
        tempArray = tempArray.concat(
          (query[4][i] = {
            ...query[4][i],
            checked: false
          })
        );
      }
      this.setState({
        id: query[0],
        category_name: query[1],
        category_description: query[2],
        tags: query[3],
        contents: tempArray
      });
    }

    this.getPopUpContentList();
    this.getMediaList();
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { contents } = this.state;
    this.setState({
      contents: arrayMove(contents, oldIndex, newIndex)
    });
  };

  // 팝업 컨텐츠 리스트
  getPopUpContentList = () => {
    const {
      authToken, title, category, per, page,
      provider_id, is_search
    } = this.state;
    if (is_search) {
      const formData = new FormData();
      formData.append('provider_id', provider_id);
      formData.append('title', title);
      formData.append('category', category);

      const contentList = Service.getSearchContentList(
        authToken,
        formData,
        per,
        page
      );
      contentList
        .then((response) => {
          this.setState(
            {
              popup_contents: response.data.data,
              pagination: response.data.pagination
            }
          );
          this.setState({
            is_checked_count: 0,
            is_checked_content: []
          });
          response.data.data.map((item) => {
            let { is_checked_content } = this.state;
            this.setState(
              {
                is_all_checked: false,
                is_checked_content: is_checked_content.concat({
                  id: item.id,
                  checked: false
                })
              }
            );
            return null;
          });
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.message) {
              alert(error.response.data.message);
            } else {
              alert(`팝업 컨텐츠 리스트를 불러오는 중 오류가 발생하였습니다.\r\n${error}`);
            }
          } else {
            alert(`팝업 컨텐츠 리스트를 불러오는 중 오류가 발생하였습니다.\r\n${error}`);
          }
        });
    } else {
      const contentList = Service.getContentList(
        authToken,
        per,
        page
      );
      contentList
        .then((response) => {
          this.setState(
            {
              popup_contents: response.data.data,
              pagination: response.data.pagination
            }
          );
          this.setState({
            is_checked_count: 0,
            is_checked_content: []
          });
          response.data.data.map((item) => {
            let { is_checked_content } = this.state;
            this.setState(
              {
                is_all_checked: false,
                is_checked_content: is_checked_content.concat({
                  id: item.id,
                  checked: false
                })
              }
            );
            return null;
          });
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.message) {
              alert(error.response.data.message);
            } else {
              alert(`팝업 컨텐츠 리스트를 불러오는 중 오류가 발생하였습니다.\r\n${error}`);
            }
          } else {
            alert(`팝업 컨텐츠 리스트를 불러오는 중 오류가 발생하였습니다.\r\n${error}`);
          }
        });
    }
  };

  // 컨텐츠 리스트 검색 유효성 검사
  searchPopUpContent = () => {
    this.setState(
      {
        is_search: true
      },
      () => {
        this.getPopUpContentList();
      }
    );
  };

  // 매체 리스트
  getMediaList = () => {
    const { authToken } = this.state;
    const mediaList = Service.getMediaList(authToken);
    mediaList.then((response) => {
      this.setState(
        {
          medias: response.data.data
        }
      );
    });
  };

  // 컨텐츠 검색 팝업
  popUpOn = () => {
    const { is_pop_on } = this.state;
    this.setState({
      is_pop_on: !is_pop_on
    });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  onChangeTags = (e) => {
    this.setState({
      [e.target.name]: e.target.value.split(',')
    });
  };

  // 컨텐츠 검색 팝업 내 CheckBox
  onChecked = (e) => {
    const id = e.target.id.split(',')[1];
    const { is_checked_content, is_checked_count } = this.state;
    const index = is_checked_content.findIndex((item) => item.id === Number(id));

    const selectContent = is_checked_content[index];
    const tempContent = [...is_checked_content];
    tempContent[index] = {
      ...selectContent,
      checked: !selectContent.checked
    };
    this.setState(
      {
        is_checked_content: tempContent
      },
      () => {
        this.setState({
          is_checked_count: !selectContent.checked
            ? is_checked_count + 1
            : is_checked_count - 1
        });
      }
    );
  };

  // 컨텐츠 검색 팝업 내 CheckBox All
  photoNewsAllChecked = () => {
    const { is_checked_content, is_all_checked } = this.state;
    const temp = [...is_checked_content];
    this.setState(
      {
        is_all_checked: !is_all_checked
      },
      () => {
        for (let i = 0; i < is_checked_content.length; i++) {
          temp[i] = {
            ...temp[i],
            checked: !is_all_checked === true
          };
        }
        this.setState({
          is_checked_count: !is_all_checked ? is_checked_content.length : 0,
          is_checked_content: temp
        });
      }
    );
  };

  // 검색 팝업에서 선택한 컨텐츠 저장
  savePhotoNewsContent = () => {
    const { is_checked_content, contents, popup_contents } = this.state;
    let array = [];
    popup_contents.map((item, key) => {
      if (is_checked_content[key].id === item.id) {
        if (is_checked_content[key].checked) {
          array = array.concat((item = {
            ...item,
            checked: false
          }));
        }
      }
    });

    this.setState(
      {
        contents: _.uniq(contents.concat(array), 'id')
      },
      () => {
        this.setState(
          {
            is_all_checked: false,
            is_pop_on: false
          },
          () => {
            this.getPopUpContentList();
          }
        );
      }
    );
  };

  // 삭제 CheckBox
  deleteOnChecked = (e) => {
    const id = e.target.id.split(',')[1];
    const { contents } = this.state;
    const index = contents.findIndex((item) => item.id === Number(id));
    const selectContent = contents[index];
    const temp = [...contents];

    temp[index] = {
      ...selectContent,
      checked: !selectContent.checked
    };
    this.setState({
      contents: temp
    });
  };

  // 컨텐츠 삭제
  deleteSaveContent = () => {
    const { contents } = this.state;
    this.setState({
      contents: contents.filter((item) => item.checked === false)
    });
  };

  // 포토뉴스(컨텐츠 그룹) 최종 저장
  putPhotoNewsContent = (e) => {
    e.preventDefault();
    const {
      authToken,
      category_name,
      category_description,
      tags,
      contents,
      id
    } = this.state;
    const { history } = this.props;
    const formData = new FormData();
    let content_ids_ = [];
    if (category_name === null || category_name.length === 0) {
      alert('카테고리 명을 입력해주세요.');
      return false;
    }
    if (category_description === null || category_description.length === 0) {
      alert('카테고리 설명을 입력해주세요.');
      return false;
    }
    if (tags === null || tags.length === 0) {
      alert('태그를 입력해주세요.');
      return false;
    }
    if (contents.length === 0) {
      alert('콘텐츠를 선택해주세요.');
      return false;
    }
    contents.forEach((i) => {
      content_ids_ = content_ids_.concat(i.id);
    });
    content_ids_ = Array.from(new Set(content_ids_));
    this.setState(
      {
        content_ids: content_ids_
      },
      () => {
        if (id) {
          formData.append('id', id);
        }
        formData.append('category_name', category_name);
        formData.append('category_description', category_description);
        formData.append('is_active', true);

        tags.forEach((i) => {
          formData.append('tags[]', i);
        });
        content_ids_.forEach((i) => {
          formData.append('content_ids[]', i);
        });

        const putPhotoNews = Service.putPhotoNews(
          authToken,
          formData
        );
        putPhotoNews
          .then((response) => {
            if (response.status === 200 || (response.status === 201 && response.statusText === 'OK')) {
              alert('정상적으로 처리되었습니다.');
              history.push('/contents/photoNews');
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.message) {
                alert(error.response.data.message);
              } else {
                alert(`포토뉴스(컨텐츠 그룹)을 저장하는 중 오류가 발생하였습니다.\r\n${error}`);
              }
            } else {
              alert(`포토뉴스(컨텐츠 그룹)을 저장하는 중 오류가 발생하였습니다.\r\n${error}`);
            }
          });
      }
    );
  };

  // 페이지 이동
  onClickPage = (page) => {
    const { authToken, per } = this.state;
    this.setState(
      {
        page
      },
      () => {
        this.getPopUpContentList(
          authToken,
          per,
          page
        );
      }
    );
  };

  render() {
    const {
      is_pop_on, is_checked_count, popup_contents, is_all_checked, is_checked_content,
      category_name, category_description, tags, contents, medias, pagination, page_history
    } = this.state;
    return (
      <>
        <div className="title disi wd100">
          {' '}
          <p className="mb10">
            <span>홈</span>
            {' '}
            〉
            {' '}
            <span>콘텐츠</span>
            {' '}
            〉
            {' '}
            <span>포토뉴스등록/관리</span>
            {' '}
            〉
            {' '}
            <span>등록/수정</span>
          </p>
          포토뉴스등록/관리 등록/수정
          <div className="btnwrap floatR disi">
            <Link to={`/contents/photoNews?page=${page_history}`}>
              <button
                type="button"
                className="btnLine navyBg ml10"
                onClick={this.putPhotoNewsContent}
              >
                저장
              </button>
            </Link>
            <button type="button" className="btn navyBg ml10" onClick={() => this.props.history.goBack()}>
              목록
            </button>
          </div>
        </div>
        <form>

          <table className="mt20 mda_reset_table">
            <colgroup>
              <col width="10%" />
              <col width="" />
            </colgroup>
            <tbody>
              <tr>
                <th>
                  카테고리 명
                  <span className="redT"> *</span>
                </th>
                <td className="cont_input">
                   <input
                   className="wd100"
                    type="text"
                    name="category_name"
                    onChange={this.onChange}
                    value={category_name}
                  />
                </td>
              </tr>
              <tr>
                <th>카테고리 설명</th>
                <td className="cont_input">
                  <input
                    className="wd100"
                    type="text"
                    name="category_description"
                    onChange={this.onChange}
                    value={category_description}
                  />
                </td>
              </tr>
              <tr>
                <th>태그</th>
                <td className="cont_input">
                  <input
                    className="wd100"
                    type="text"
                    name="tags"
                    onChange={this.onChangeTags}
                    value={tags}
                    placeholder="콤마(,)로 구분해서 작성해주세요. ex)현대,제네시스,현대자동차"
                  />
                </td>
              </tr>
              <tr>
                <th>콘텐츠 검색</th>
                <td>
                  <button
                    className="btn navyBg"
                    type="button"
                    onClick={this.popUpOn}
                  >
                    검색
                  </button>
                  {' '}
                  <button
                    type="button"
                    className="btnLine navyBg"
                    onClick={this.deleteSaveContent}
                  >
                    삭제
                  </button>
                  <div>
                    <SortableList
                      items={contents}
                      onSortEnd={this.onSortEnd}
                      onDeleteChecked={this.deleteOnChecked}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  공개여부
                  <span className="redT"> *</span>
                </th>
                <td>
                  <input type="radio" name="selling2" id="sell2_ok"></input>
                  <label htmlFor="sell2_ok"> 공개</label>
                  <input type="radio" name="selling2" id="sell2_no" className="ml10"></input>
                  <label htmlFor="sell2_no"> 비공개</label>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="btnwrap rightT mt20">
            <Link to="/contents/photoNews">
              <button
                type="button"
                className="btnLine navyBg ml10"
                onClick={this.putPhotoNewsContent}
              >
                저장
              </button>
            </Link>
            <button type="button" className="btn navyBg ml10" onClick={() => this.props.history.goBack()}>
              목록
            </button>
          </div>
        </form>

        {/* // 콘텐츠리스트 팝업 시작 // */}
        <div
          className={
            is_pop_on === true ? 'popWrap contlist_pop on' : 'popWrap contlist_pop'
          }
        >
          <div className="popBg" onClick={this.popUpOn} role="presentation" />
          <div className="pop_cont">
            <div className="pop_title">
              <p>콘텐츠 리스트</p>
              <span onClick={this.popUpOn} role="presentation">X</span>
            </div>
            <div className="mt20 pd_wrap">
              <form>
                <table className="add_table">
                  <tbody>
                    <tr>
                      <th>매체명</th>
                      <td>
                        <Choice
                          medias={medias}
                          onChangeSelect={this.onChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>콘텐츠 제목</th>
                      <td>
                        <input
                          type="text"
                          name="title"
                          onChange={this.onChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>카테고리</th>
                      <td>
                        <select name="category" onChange={this.onChange}>
                          <option value="ALL">전체</option>
                          <option value="0">뉴스</option>
                          <option value="1">시승기</option>
                        </select>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="centerT mt20">
                  <button
                    type="button"
                    className="btn pointBg"
                    onClick={this.searchPopUpContent}
                  >
                    검색
                  </button>
                </div>
              </form>
              <div className="select_count">
                선택
                <span>{is_checked_count}</span>
                개
              </div>
              <div className="mt20">
                <table>
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          name="contpop"
                          id="contpop_all"
                          onChange={this.photoNewsAllChecked}
                          checked={is_all_checked}
                        />
                      </th>
                      <th>카테고리</th>
                      <th>매체명</th>
                      <th>콘텐츠 제목</th>
                      <th>등록일</th>
                    </tr>
                  </thead>
                  <tbody>
                    {popup_contents.length !== 0 && popup_contents.map((item, index) => (
                      <tr key={item.id}>
                        <td>
                          <input
                            type="checkbox"
                            name="contpop"
                            id={`check,${item.id}`}
                            onChange={this.onChecked}
                            checked={
                              is_checked_content[index] != null
                              && is_checked_content[index].checked
                            }
                          />
                        </td>
                        <td>{item.category}</td>
                        <td>{item.provider_name}</td>
                        <td className="cont_flex">
                          <span>{item.title}</span>
                          {item.images.map((image, key) => {
                            if (key <= 1) {
                              return (
                                <div key={image.id} className="contpop_img">
                                  <img className="ct_image" src={image.url} alt="프리뷰 이미지" />
                                </div>
                              );
                            }
                          })}
                        </td>
                        <td>{item.published_at}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="pagination">
                  <Pagination
                    onClickPage={this.onClickPage}
                    paginationOption={pagination}
                  />
                </div>
              </div>
            </div>
            <div className="btnwrap centerT mt20">
              <button
                type="button"
                className="btnLine navyBg ml10"
                onClick={this.savePhotoNewsContent}
              >
                저장
              </button>
              <button
                type="button"
                className="btn navyBg ml10"
                onClick={this.popUpOn}
              >
                닫기
              </button>
            </div>
          </div>
        </div>
        {/* // 콘텐츠리스트 팝업 끝 // */}
      </>
    );
  }
}

export default PhotoNewsDetail;
