import { devUrl, keyNumber } from '../../serviceComm/variable';
import Axios from 'axios'
import moment from 'moment'

export const getEstimates = (authToken , page , per , model , userName , startAt , endAt) => {
  let where = ""
  if(model !== 0){
    where += `&car_model_id=${model}`
  }
  if(userName !== ""){
    where += `&user_name=${userName}`
  }
  if(startAt !== ""){
    where += `&start_at=${moment(startAt).format('YYYY-MM-DD')}`
  }
  if(endAt !== ""){
    where += `&end_at=${moment(endAt).format('YYYY-MM-DD')}`
  }
  const config = {
    method: "get",
    url:
    devUrl+`/admin/estimates?per=${per}&page=${page}${where}`,
    headers: {
      "x-api-key": keyNumber,
      Authorization: "Bearer " + authToken
    }
  }

  return Axios(config)
}

export const getEstimateDetail = (id) => {
  const config = {
    method: 'get',
    url: `${devUrl}/estimates/${id}`,
    headers: {
      "x-api-key": keyNumber
    }
  };

  return Axios(config)
}