import React, {useState, useEffect} from 'react';
import '../../../UIKitMonkeyPatch.scss'
import './TrimForm.scss'
import UIKit from 'uikit'
import ReactSelect from 'react-select';
import {isEmpty, isNumber, isEqual, cloneDeep} from 'lodash'
import {getTrimDetail, getTrimListNew} from '../../../component/services/vehicleDB/TrimService'
import {useTrimState, useTrimDispatch, trimToTrimState, emptyTrim} from "./TrimProvider";
import moment from "moment";
import PriceOptionsModal from "./PriceOptionsModal";
import SalesConditionsModal from "./SalesConditionsModal";
import TrimItemModal from "./TrimItemModal";
import TrimSpecModal from "./TrimSpecModal";

const TrimForm = ({index, lineupList, isEdit, authToken}) => {
  const trim = useTrimState()[index];
  const trimSize = useTrimState().length;
  const trimDispatch = useTrimDispatch();
  const initCopyManager = {
    open: false,
    lineup_id: '',
    trimList: [],
    trim_id: '',
    targetTrim: {},
    actionBtnInfo: {
      copyAll: {available: false, message: ''},
      copyPriceOption: {available: false, message: ''},
      copySalesCondition: {available: false, message: ''},
      copyTrimItem: {available: false, message: ''},
      copyTrimSpec: {available: false, message: ''}
    }
  };
  const [copyManager, setCopyManager] = useState(initCopyManager);
  const [footerExpand, setFooterExpand] = useState(false);

  const lineupChangeHandler = (object) => {
    trimInputHandler(object.value, 'lineup_id');
    trimInputHandler(object, 'selected_lineup');
  };

  const trimInputHandler = (value, inputName) => trimDispatch({
    type: 'CHANGE_TRIM_INPUT',
    payload: {value, index, inputName}
  });

  const copyTrim = index => trimDispatch({
    type: 'COPY_TRIM',
    payload: {index}
  });

  const addTrim = index => trimDispatch({
    type: 'ADD_TRIM',
    payload: {index}
  });

  const deleteTrim = index => trimDispatch({
    type: 'DELETE_TRIM',
    payload: {index}
  });

  const showModal = modalId => UIKit.modal(`#${modalId}`).show();

  const copyHandler = (action, payload) => {
    switch (action) {
      case "COPY_TOGGLE":
        if (copyManager.open) {
          setCopyManager(initCopyManager);
        } else {
          setCopyManager({...copyManager, open: !copyManager.open});
        }
        break;
      case "SELECT_COPY_LINEUP":
        setCopyManager({...copyManager, lineup_id: payload.value});
        break;
      case "SELECT_COPY_TRIM":
        setCopyManager({...copyManager, trim_id: payload.value});
        break;
      case "COPY_ALL":
        const targetTrimDup = cloneDeep(copyManager.targetTrim);
        delete targetTrimDup.id;
        delete targetTrimDup.lineup_id;
        trimDispatch({type: "REPLACE_TRIM", payload: {trim: {...trim, ...targetTrimDup}, index}});
        break;
      case "COPY_PRICE_OPTIONS":
        const poDeep = cloneDeep(copyManager.targetTrim.price_options);
        trimDispatch({type: "REPLACE_TRIM", payload: {trim: {...trim, price_options: poDeep}, index}});
        break;
      case "COPY_SALES_CONDITIONS":
        const scDeep = cloneDeep(copyManager.targetTrim.sales_conditions);
        trimDispatch({type: "REPLACE_TRIM", payload: {trim: {...trim, sales_conditions: scDeep}, index}});
        break;
      case "COPY_TRIM_ITEM":
        const tiDeep = cloneDeep(copyManager.targetTrim.trim_item);
        trimDispatch({type: "REPLACE_TRIM", payload: {trim: {...trim, trim_item: {...tiDeep}}, index}});
        break;
      case "COPY_TRIM_SPEC":
        const tsDeep = cloneDeep(copyManager.targetTrim.trim_spec);
        trimDispatch({type: "REPLACE_TRIM", payload: {trim: {...trim, trim_spec: {...tsDeep}}, index}});
        break;
    }
  };

  useEffect(() => {
    if (trim.lineup_id) {
      trimInputHandler(lineupList.filter(obj => obj.value === parseInt(trim.lineup_id))[0], 'selected_lineup');
    }
  }, [trim.lineup_id]);

  useEffect(() => {
    if (isNumber(copyManager.lineup_id)) {
      getTrimListNew(authToken, {
        id_desc: true,
        lineup_id: copyManager.lineup_id,
        per: 5000
      }).then(response => {
        const trimList = isEmpty(response.data.data) ? [] : response.data.data.map(obj => ({
          value: obj.id,
          label: `${obj.name} [${obj.option}]`
        }));
        setCopyManager({...copyManager, trimList})
      })

    }
  }, [copyManager.lineup_id]);

  useEffect(() => {
    if (isNumber(copyManager.trim_id)) {
      getTrimDetail(authToken, copyManager.trim_id).then(response => {
        setCopyManager({...copyManager, targetTrim: trimToTrimState(response.data.data)[0]})
      })
    }
  }, [copyManager.trim_id]);

  useEffect(() => {
    if (!isEmpty(copyManager.targetTrim)) {
      const poAvb = !isEqual(copyManager.targetTrim.price_options, emptyTrim.price_options);
      const scAvb = !isEqual(copyManager.targetTrim.sales_conditions, emptyTrim.sales_conditions);
      const tiAvb = !isEqual(copyManager.targetTrim.trim_item, emptyTrim.trim_item);
      const tsAvb = !isEqual(copyManager.targetTrim.trim_spec, emptyTrim.trim_spec);

      const newAbi = {
        copyAll: {
          available: true,
          message: '전체복사'
        },
        copyPriceOption: {
          available: poAvb,
          message: poAvb ? `가격옵션 (${copyManager.targetTrim.price_options.length}개)` : '가격옵션 (없음)'
        },
        copySalesCondition: {
          available: scAvb,
          message: scAvb ? `판매조건 (${copyManager.targetTrim.sales_conditions.length}개)` : '판매조건 (없음)'
        },
        copyTrimItem: {
          available: tiAvb,
          message: tiAvb ? `트림사양 (${Object.values(copyManager.targetTrim.trim_item).filter(v => !isEmpty(v)).length}개)` : '트림사양 (없음)'
        },
        copyTrimSpec: {
          available: tsAvb,
          message: tsAvb ? `트림제원 (${Object.values(copyManager.targetTrim.trim_spec).filter(v => !isEmpty(v)).length}개)` : '트림제원 (없음)'
        }
      };
      setCopyManager({...copyManager, actionBtnInfo: newAbi});
    }
  }, [copyManager.targetTrim]);

  return (
    <>
      <div className={`uk-card uk-card-default uk-margin ${trim.save_helper.is_valid ? '' : 'uk-card-danger'}`}>
        {
          !trim.save_helper.is_valid &&
          <div className='uk-card-header'>
            <div className="uk-text-danger uk-text-center">
              {trim.save_helper.message}
            </div>
          </div>
        }
        <div className='uk-card-body'>
          <div className='uk-grid uk-grid-column-small uk-grid-row-small'>
            <div className="uk-width-1-2">
              <label>라인업명*</label>
              <ReactSelect options={lineupList} autoFocus={index === 0}
                           placeholder='브랜드, 자동차, 라인업명으로 검색' value={trim.selected_lineup}
                           isDisabled={isEdit} onChange={object => lineupChangeHandler(object)}/>
            </div>

            <div className="uk-width-1-2">
              <label>트림명*</label>
              <input className="uk-input" type="text" placeholder="트림명" value={trim.name || ''}
                     onChange={e => trimInputHandler(e.target.value, 'name')}/>
            </div>
          </div>

          <div className='uk-grid uk-grid-column-small uk-grid-row-small'>
            <div className="uk-width-1-3">
              <label>가격*</label>
              <input className="uk-input" type="number" placeholder="가격" value={trim.price || ''}
                     onChange={e => trimInputHandler(e.target.value, 'price')}/>
            </div>

            <div className="uk-width-1-3">
              <label>변속기</label>
              <select className="uk-select" value={trim.option || ''}
                      onChange={e => trimInputHandler(e.target.value, 'option')}>
                <option value='' hidden>-</option>
                <option>A/T</option>
                <option>M/T</option>
              </select>
            </div>

            <div className="uk-width-1-3">
              <label>활성화여부*</label>
              <select className="uk-select" value={trim.is_active || ''}
                      onChange={e => trimInputHandler(e.target.value, 'is_active')}>
                <option value='' hidden>-</option>
                <option value='1'>활성</option>
                <option value='0'>비활성</option>
              </select>
            </div>
          </div>

          <div className='uk-grid uk-grid-column-small uk-grid-row-small'>
            <div className="uk-width-1-4 uk-flex uk-flex-middle uk-flex-column">
              <p>가격옵션</p>
              <button className="uk-button uk-button-default" onClick={() => showModal(`modal-po-${index}`)}>
                가격옵션 입력
              </button>
            </div>

            <div className="uk-width-1-4 uk-flex uk-flex-middle uk-flex-column">
              <p>{moment().format('YYYY년 MM월')} 판매조건</p>
              <button className="uk-button uk-button-default" onClick={() => showModal(`modal-sc-${index}`)}>
                판매조건 입력
              </button>
            </div>

            <div className="uk-width-1-4 uk-flex uk-flex-middle uk-flex-column">
              <p>제원</p>
              <button className="uk-button uk-button-default" onClick={() => showModal(`modal-ts-${index}`)}>
                트림제원 입력
              </button>
            </div>

            <div className="uk-width-1-4 uk-flex uk-flex-middle uk-flex-column">
              <p>트림사양</p>
              <button className="uk-button uk-button-default" onClick={() => showModal(`modal-ti-${index}`)}>
                트림사양 입력
              </button>
            </div>
          </div>
        </div>
        <div className='uk-card-footer uk-padding-remove uk-flex uk-flex-between'>
          <div className="uk-button-group add-delete-button form-handler-button">
            <button className="uk-button " onClick={() => {
              copyHandler("COPY_TOGGLE")
            }}><span data-uk-icon="copy"/></button>
          </div>

          <div className="uk-button-group add-delete-button form-handler-button">
            <button className="uk-button uk-button-secondary" disabled={isEdit} onClick={() => copyTrim(index)}>
              ⇩
            </button>
            <button className="uk-button uk-button-primary" disabled={isEdit} onClick={() => addTrim(index)}>
              +
            </button>
            <button className="uk-button uk-button-default" disabled={isEdit || trimSize === 1}
                    onClick={() => deleteTrim(index)}>
              -
            </button>
          </div>
        </div>
        {
          copyManager.open &&
          <div className='uk-card-footer' style={footerExpand ? {height: "400px"} : {}}>
            <div className='uk-grid uk-grid-column-small uk-grid-row-small'>
              <div className="uk-width-1-2">
                <label>대상 라인업</label>
                <ReactSelect options={lineupList} placeholder='브랜드, 자동차, 라인업명으로 검색'
                             onMenuOpen={() => setFooterExpand(true)}
                             onMenuClose={() => setFooterExpand(false)}
                             onChange={object => copyHandler("SELECT_COPY_LINEUP", object)}/>
              </div>

              <div className="uk-width-1-2">
                <label>대상 트림</label>
                <ReactSelect options={copyManager.trimList}
                             placeholder={isNumber(copyManager.lineup_id) ? '트림명으로 검색' : '대상 라인업을 먼저 선택하세요'}
                             isDisabled={!isNumber(copyManager.lineup_id)}
                             onMenuOpen={() => setFooterExpand(true)}
                             onMenuClose={() => setFooterExpand(false)}
                             onChange={object => copyHandler("SELECT_COPY_TRIM", object)}/>
              </div>

              {!isEmpty(copyManager.targetTrim) && <div className="uk-width-1-1 uk-margin-top">복사옵션</div>}
              {
                !isEmpty(copyManager.targetTrim) &&
                <div className="uk-width-1-1">
                  <button className="uk-button uk-button-default"
                          disabled={!copyManager.actionBtnInfo.copyAll.available}
                          onClick={() => {
                            copyHandler("COPY_ALL")
                          }}>
                    {copyManager.actionBtnInfo.copyAll.message}
                  </button>
                  <button className="uk-button uk-button-default"
                          disabled={!copyManager.actionBtnInfo.copyPriceOption.available}
                          onClick={() => {
                            copyHandler("COPY_PRICE_OPTIONS")
                          }}>
                    {copyManager.actionBtnInfo.copyPriceOption.message}
                  </button>
                  <button className="uk-button uk-button-default"
                          disabled={!copyManager.actionBtnInfo.copySalesCondition.available}
                          onClick={() => {
                            copyHandler("COPY_SALES_CONDITIONS")
                          }}>
                    {copyManager.actionBtnInfo.copySalesCondition.message}
                  </button>
                  <button className="uk-button uk-button-default"
                          disabled={!copyManager.actionBtnInfo.copyTrimSpec.available}
                          onClick={() => {
                            copyHandler("COPY_TRIM_SPEC")
                          }}>
                    {copyManager.actionBtnInfo.copyTrimSpec.message}
                  </button>
                  <button className="uk-button uk-button-default"
                          disabled={!copyManager.actionBtnInfo.copyTrimItem.available}
                          onClick={() => {
                            copyHandler("COPY_TRIM_ITEM")
                          }}>
                    {copyManager.actionBtnInfo.copyTrimItem.message}
                  </button>
                </div>
              }
            </div>
          </div>
        }
      </div>

      <PriceOptionsModal title='가격옵션' index={index} key={`po-${index}`} isEdit={isEdit}/>
      <SalesConditionsModal title='판매조건' index={index} key={`sc-${index}`} isEdit={isEdit}/>
      <TrimSpecModal title='트림제원' index={index} key={`ts-${index}`}/>
      <TrimItemModal title='트림사양' index={index} key={`ti-${index}`}/>
    </>
  );
};

export default TrimForm;
