import React, { Component, Fragment } from 'react';
import * as Common from "../../component/services/common/CommonService";
import * as Service from "../../component/services/vehicleDB/TrimService";
import * as BrandService from "../../component/services/brand/BrandService";
import * as modelGroupService from "../../component/services/vehicleDB/ModelGroupService"
import * as modelService from "../../component/services/vehicleDB/ModelService"
import * as LineUpService from '../../component/services/vehicleDB/LineUpService'
import * as PriceOptionService from '../../component/services/brand/PriceOptionService';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import Select from 'react-select';
import {Link} from 'react-router-dom';
import arrayMove from 'array-move';
import DatePicker from 'react-datepicker'

class VehicleAdd extends Component {

    SortableItem = SortableElement(({value}) =>
      <div className="popUp del_file_btn btnLine navyBg car_img_btn img_data" data-id={value.id} style={{backgroundImage : 'url(' + value.image_url + ')' , backgroundSize : "cover" , backgroundPosition : 'center'}}>
        <button className="popUp img_delete" onClick={() => {this.deleteImage(value.id)}}>X</button>
      </div>
     );

    SortableList = SortableContainer(({items}) => {
      const item = []
      const data = this.state.model.imagesOrder
      for(let x = 0; x<data.length; x++){
        for(let y = 0; y<items.length; y++){
          if(data[x] == items[y].id){
            item.push(items[y])
          }
        }
      }
     return (
       <div className="popUp car_box_inner">
         {
           item?
         item.map((data , key) => {
           return (
           <this.SortableItem key={`item-${key}`} index={key} value={data}/>
           )
         }) : <></>}
         <button className="popUp del_file_btn btnLine navyBg car_img_btn" onClick={() => { document.querySelector('#images').click() }}>+</button>

       </div>
     );
    });

    deleteImage = (id) => {
      const confirm = window.confirm('삭제 시 복구할 수 없습니다. 정말로 삭제하시겠습니까?');
      if(confirm){
        const model = this.state.model
        const req = modelService.deleteDraftImage(this.state.authToken , id)
        req.then(res => {
          const req = modelService.getImages(this.state.authToken)
          req.then(res => {
          model.images = res.data.data
          model.imagesOrder = model.imagesOrder.filter(orderId => orderId!=id)
          this.setState({ model : model })
        })
        }
        )
      }
    }

    constructor(props){
      super(props)
      this.state = {
        authToken : Common.getToken.call(this),
        page : 1,
        brandId : {label : '브랜드명' , value : 0},
        totalInfo : [],
        modelGroupId : 0,
        modelGroup : {
          Active : [{label : '활성화' , value : true},{label : '비활성화' , value : false}],
          isActive : {label : '활성화' , value : true},
          carTypes : [{label : '경차' , value : '경차'},{label : '경승합' , value : '경승합'},{label : '경트럭' , value : '경트럭'},{label : '대형' , value : '대형'},{label : '대형MPV' , value : '대형MPV'},{label : '대형SUV' , value : '대형SUV'},{label : '소형MPV' , value : '소형MPV'},{label : '소형SUV' , value : '소형SUV'},{label : '소형버스' , value : '소형버스'},{label : '소형트럭' , value : '소형트럭'},{label : '스포츠카' , value : '스포츠카'},{label : '승합' , value : '승합'},{label : '준대형' , value : '준대형'},
          {label : '준중형' , value : '준중형'},{label : '중형' , value : '중형'},{label : '중형SUV' , value : '중형SUV'},{label : '중형트럭' , value : '중형트럭'},{label : '픽업/밴' , value : '픽업/밴'},],
          carType : {label : '경차' , value : '경차'},
          name : ""
        },
        model : {
          name : "",
          codeName : "",
          exteriorTypes : [{label : "세단" , value : "세단"},{label : "해치백" , value : "해치백"},{label : "컨버터블" , value : "컨버터블"},{label : "쿠페" , value : "쿠페"},{label : "왜건" , value : "왜건"},{label : "SUV" , value : "SUV"},{label : "RV" , value : "RV"},{label : "벤" , value : "벤"},{label : "기타" , value : "기타"},],
          exteriorType : {label : "세단" , value : "세단"},
          rivalBrands : [],
          rivalModelGroup : [{label : '모델그룹명' , value : 0}],
          rivalModels : [{label : '모델명' , value : 0}],
          popActive:false,             // 팝업창 상태값
          rivalBrandId : {label : '브랜드명' , value : 0},
          rivalModelGroupId : {label : '모델그룹명' , value : 0},
          rivalModelId : {label : '모델명' , value : 0},
          rivalList : [],
          colorCodes : [],
          colorNames : [],
          colorImages : [],
          images : [],
          imagesOrder : [],
          nonDriftImages : [],
          colorName : "",
          colorCode : '#000000',
          colorImage : null,
          will : false,
          new : false,
          active : false,
        },
        line : {
          name : "",
          fuelType : {label : "경유" , value : "경유"},
          fuelTypes : [ {label : "경유" , value : "경유"}, {label : "휘발유" , value : "휘발유"}, {label : "LPG" , value : "LPG"}, {label : "전기" , value : "전기"}, {label : "휘발유+전기" , value : "휘발유+전기"}, {label : "수소" , value : "수소"}, {label : "CNG" , value : "CNG"},],
          displacement : "",
          mileage : "",
          date : new Date(),
          year : new Date().getFullYear().toString(),
          sale : false,
          primary : false,
          active : false,
          conditions : [],
          condName : "",
          condPrice : "",
          condPercentage : "",
          condDescription : ""
        },
        trim : {
          priceOptionId : {label: '가격옵션' , value : 0},
          priceOptionList : [{label: '가격옵션' , value : 0}],
          optionId : {label : '변속기' , value : null},
          optionList : [{label : '변속기' , value : null} ,{label : 'A/T' , value : "A/T"} ,{label : 'M/T' , value : "M/T"} ],
          name : "",
          subname : "",
          efficiency : "",
          price : "",
          is_active : false,
          price_option_ids : [],
          popActiveResource : false,
          popActiveOption : false,
          trimSpec : {
            engine_type : "",
            fuel: "",
            displacement: "",
            engine_max_out: "",
            engine_max_torque: "",
            idle_stop_go: "",
            eco_friendly: "",
            battery_type: "",
            battery_voltage: "",
            battery_capacity: "",
            charging_capacity: "",
            motor_max_out: "",
            motor_max_torque: "",
            charging_type: "",
            charging_hour: "",
            quick_charging_type: "",
            quick_charging_min: "",
            wheel_drive_type: "",
            gearbox: "",
            suspension_front: "",
            suspension_rear: "",
            break_front: "",
            break_rear: "",
            tire_front: "",
            tire_rear: "",
            wheel_front: "",
            wheel_rear: "",
            efficiency_city: "",
            efficiency_high: "",
            co2_emission: "",
            energy_consumption: "",
            efficiency_e_city: "",
            efficiency_e_high: "",
            mileage: "",
            full_length: "",
            full_width: "",
            full_height: "",
            wheel_base: "",
            wheel_track_front: "",
            wheel_track_rear: "",
            overhang_front: "",
            overhang_rear: "",
            quota: "",
            empty_weight: "",
            tank_volume: "",
            cargo_width: "",
            cargo_area: "",
            cargo_height: "",
            zero_to_max: "",
            max_velocity: "",
          },
          trimItem : {
            sft_belt:"",
            ext_fog_lamp_front:"",
            ext_fog_lamp:"",
            ext_rear_lamp:"",
            ext_roof:"",
            ext_door_light:"",
            ext_out_mirror:"",
            ext_wiper:"",
            ext_uv_glass:"",
            ext_no_water_glass:"",
            ext_no_sound_glass:"",
            ext_muffler_tip:"",
            int_dashboard:"",
            int_power_window:"",
            int_safty_power_window:"",
            int_steering_wheel:"",
            int_gear_knob:"",
            int_room_mirror:"",
            int_door_light:"",
            int_rear_side_curtain:"",
            int_rear_rear_curtaion:"",
            sft_airbag:"",
            sft_drive:"",
            sft_pedestrian:"",
            sft_reduce_rear_impact:"",
            fac_change_mode:"",
            fac_fix_speed:"",
            fac_break:"",
            fac_engine_start:"",
            fac_trunk:"",
            fac_power_outlet:"",
            fac_parking:"",
            sht_sequence:"",
            sht_type:"",
            sht_driver:"",
            sht_passenger:"",
            sht_row2:"",
            sht_row3:"",
            acn_aircon:"",
            acn_temp_range:"",
            acn_rear:"",
            acn_air_clean:"",
            mul_monitor_size:"",
            mul_functions:"",
            mul_extra_functions:"",
            mul_interface:"",
            mul_speaker:"",
            mul_sound_system:""
          }
        }
      }
    }

    componentDidMount(){
      this.getTotalInfo()
      this.flushImages()
    }

    getPriceOptions = () => {
      const req = PriceOptionService.getPriceOptionListByTrim(this.state.authToken , this.state.brandId.value)
      req.then(res => {
        const data = res.data.data
        let options = []
        options.push({label: '가격옵션' , value : 0})
        for(var x = 0; x<data.length; x++){
          options.push({label: data[x].name , value : data[x].id})
        }
        const trim = this.state.trim
        trim.priceOptionList = options
        this.setState({
          trim : trim
        })
      })
    }

    flushImages = () => {
      const flush = modelService.flushImages(
        this.state.authToken
      )
      flush.then(res => {

      })
    }

    getTotalInfo = aysnc => {
      const brandList = BrandService.getTotalInfo(this.state.authToken);
      brandList.then(response => {
        const data = response.data.data
        let options = []
        options.push({label: '브랜드명' , value : 0})
        for(var x = 0; x<data.length; x++){
          options.push({label: data[x].name , value : data[x].id})
        }
        let model = this.state.model
        model.rivalBrands = options
        this.setState({
          totalInfo: options,
          model : model
        });
      });
    };

    selectBrand = selectOption => {
      this.setState({
        brandId : selectOption,
      })
    }
    selectCarType = selectOption => {
      const modelGroup = this.state.modelGroup
      modelGroup.carType= selectOption
      this.setState({
        modelGroup : modelGroup
      })
    }
    selectActive = selectOption => {
      const modelGroup = this.state.modelGroup
      modelGroup.isActive= selectOption
      this.setState({
        modelGroup : modelGroup
      })
    }

    selectExterior = selectOption => {
      const model = this.state.model
      model.exteriorType= selectOption
      this.setState({
        model : model
      })
    }

    selectRivalBrand = selectOption => {
      const model = this.state.model
      model.rivalBrandId= selectOption
      this.setState({
        model : model
      } ,() => {
        const req = modelGroupService.getModelG(this.state.authToken, selectOption.value)
        req.then(res => {
          const data = res.data.data
          let options = []
          options.push({label: '모델그룹명' , value : 0})
          for(var x = 0; x<data.length; x++){
            options.push({label: data[x].name , value : data[x].id})
          }
          let model = this.state.model
          model.rivalModelGroup = options
          model.rivalModelGroupId = {label: '모델그룹명' , value : 0}
          model.rivalModels = [{label: '모델명' , value : 0}]
          model.rivalModelId = {label: '모델명' , value : 0}
          this.setState({
            model : model
          });
        })
      })
    }

    selectRivalModelGroup = selectOption => {
      const model = this.state.model
      model.rivalModelGroupId= selectOption
      this.setState({
        model : model
      } ,() => {
        const req = modelService.getModelList(model.rivalBrandId.value , 1000,1,this.state.authToken,model.rivalModelGroupId.label,'','all','','','','','','')
        req.then(res => {
          const data = res.data.data

          let options = []
          options.push({label: '모델그룹명' , value : 0})
          for(var x = 0; x<data.length; x++){
            options.push({label: data[x].name , value : data[x].id})
          }
          let model = this.state.model
          model.rivalModels = options
          model.rivalModelId = {label: '모델명' , value : 0}
          this.setState({
            model : model
          });
        })
      })
    }

    selectRivalModel = selectOption => {
      const model = this.state.model
      model.rivalModelId= selectOption
      this.setState({
        model : model
      })
    }

    addRivalModel(){
      const data = this.state.model.rivalModelId
      if(data.value == 0) {
        alert('추가할 모델을 선택해주세요')
        return false;
      }
      const pushData = {
        "value" : data.value,
        "text" : data.label
      }
      const model = this.state.model
      model.rivalList = model.rivalList.concat(pushData)
      this.setState({
        model : model
      })
    }

    removeRivalModel = (id) => {
      const model = this.state.model
      let rivals = model.rivalList
      for(let x =0; x<rivals.length; x++){
        if(rivals[x].value == id) {
          rivals.splice(x,1)
        }
      }
      this.setState({
        model : model
      });
    }

    infoSettingModel = ( e ) => {
      const model = this.state.model
      if(e.target.name == 'colorImage'){
        model[e.target.name] = e.target.files[0]
      }else if(e.target.name == 'will' || e.target.name == 'new' || e.target.name == 'active'){
        model[e.target.name] = e.target.className == "ok" ? true : false
      }
      else{
        model[e.target.name] = e.target.value

      }
      this.setState({
        model : model
      })
    }

    onAddColor = () => {
      const model = this.state.model;

      if(!model.colorImage){
        alert('이미지는 필수 항목입니다.')
      }
      else {
        model.colorCodes.push(model.colorCode)
        model.colorNames.push(model.colorName)
        model.colorImages.push(model.colorImage)
        model.colorCode = '#000000'
        model.colorName = ""
        model.colorImage = null
        this.setState({
          model : model
        })
      }
    }

    onRemoveColor = (index) => {
      const model = this.state.model
      model.colorCodes.splice(index,1)
      model.colorNames.splice(index,1)
      model.colorImages.splice(index,1)
      this.setState({
        model : model
      })
    }

    onClosePopUp = () => {
      const model = this.state.model
      model.popActive = false
      this.setState({ model : model })
    }

    onUploadImages = e => {
      const model = this.state.model
      let images = []
      images = e.target.files
      const req = modelService.uploadImages(this.state.authToken, images)
      req.then(res => {
        const req = modelService.getImages(this.state.authToken)
        req.then(res => {
          const data = res.data.data
          for(let x = 0;x<data.length; x++){
            if(!model.imagesOrder[x]){
              model.imagesOrder = model.imagesOrder.concat(data[x].id)

            }
          }
          model.images = res.data.data
          this.setState({ model : model })
        })
      })
    }

    onSortEnd = ({oldIndex, newIndex}) => {
      const model = this.state.model
      model.images = arrayMove(model.images ,oldIndex, newIndex)
      this.setState({
        model : model
      } , () => {
        let imagesOrder = []
        for(let x =0; x<model.images.length; x++){
          imagesOrder.push(model.images[x].id)
        }
        model.imagesOrder = imagesOrder
        this.setState({
          model : model
        })
      })
    }

    pageMove = page => {

      const modelGroup = this.state.modelGroup;
      const model = this.state.model
      const line = this.state.line

      console.log(this.state.line)

      // 모델 그룹 -> 모델
      if(page == 2){
        if(this.state.brandId.value == 0){
          alert('브랜드를 선택해주세요.')
          return false
        }else if(modelGroup.name == ""){
          alert('모델 그룹명을 입력해주세요')
          return false
        }
      }
      //모델 -> 라인업
      else if(page == 3){
        if(model.name == ""){
          alert('모델명을 입력해주세요')
          return false
        }
      }
      //라인업 -> 트림
      else if(page == 4){
        if(line.name == ""){
          alert('라인업명을 입력해주세요')
          return false
        }else if (line.fuelType.value == "전기" && line.mileage == ""){
          alert('주행거리를 입력해주세요.')
          return false
        }else if (line.fuelType.value != "전기" && line.displacement == ""){
          alert('배기량를 입력해주세요.')
          return false
        }else if (line.year == ""){
          alert('연식을 입력해주세요.')
          return false
        }else if (line.year.length != 4){
          alert('올바른 연식을 입력해주세요  예)2020')
          return false
        }
        this.getPriceOptions()
      }


      this.setState({
        page : page
      })
    }

    onSaveAtModel = async () => {
      const model = this.state.model
      let modelGroupId  = 0
      if(model.name == ""){
        alert('모델명을 입력해주세요')
        return false
      }
      const modelGroupReq = modelGroupService.postModelGroup(this.state.authToken,this.state.brandId.value,this.state.modelGroup.name,this.state.modelGroup.carType.value,this.state.modelGroup.isActive.value)
      await modelGroupReq.then(res => {
        if(res.status !== 200){
          alert('모델 그룹 저장 요청 중 오류가 발생하였습니다.')
          return false
        }else {
          modelGroupId = res.data.data.id
        }
      })

      const modelReq = modelService.postModel(this.state.authToken,model.name,model.codeName,model.new,!model.will,model.will,this.state.brandId.value,modelGroupId,model.active,model.exteriorType.value,model.colorNames,model.colorCodes,model.colorImages,model.rivalList,model.imagesOrder)
      await modelReq.then(res => {
        if(res.status == 200){
          alert('저장이 완료되었습니다.')
          this.props.history.push('/vehicleDB/model');
        }else{
          alert('모델 저장 요청 오류가 발생하였습니다.')
        }
      })
    }

    onSaveAtTrim = async () => {
      if (window.confirm('차량추가를 완료하시겠습니까?')){
        const trim = this.state.trim
        const model = this.state.model
        let modelGroupId  = 0
        let modelId = 0
        let lineUpId = 0

        //트림 페이지 데이터 체크
        if(trim.name == ""){
          alert('트림명을 입력해주세요')
          return false
        }
        if(trim.price == ""){
          alert('가격을 입력해주세요')
          return false
        }

        // 모델 그룹 저장
        const modelGroupReq = modelGroupService.postModelGroup(this.state.authToken,this.state.brandId.value,this.state.modelGroup.name,this.state.modelGroup.carType.value,this.state.modelGroup.isActive.value)
        await modelGroupReq.then(res => {
          if(res.status !== 200){
            alert('모델 그룹 저장 요청 중 오류가 발생하였습니다.')
            return false
          }else {
            modelGroupId = res.data.data.id
          }
        })

        //모델 저장
        const modelReq = modelService.postModel(this.state.authToken,model.name,model.codeName,model.new,!model.will,model.will,this.state.brandId.value,modelGroupId,model.active,model.exteriorType.value,model.colorNames,model.colorCodes,model.colorImages,model.rivalList,model.imagesOrder)
        await modelReq.then(res => {
          if(res.status == 200){
            modelId = res.data.data.id
          }else{
            alert('모델 저장 요청 오류가 발생하였습니다.')
          }
        })

        //라인업 저장
        const lineUpReq = LineUpService.postLineUp(this.state.authToken , this.state.brandId.value , modelGroupId , {value : modelId} , this.state.line)
        await lineUpReq.then(res => {
          if(res.status == 200){
            lineUpId = res.data.data.id
          }else{
            alert('라인업 저장 요청 오류가 발생하였습니다.')
          }
        })

        //트림 저장
        const trimReq = Service.postTrim(this.state.authToken , trim.name , trim.subname , trim.optionId.value , trim.price , lineUpId , trim.is_active,trim.price_option_ids,trim.efficiency , trim.trimSpec , trim.trimItem)
        await trimReq.then(res => {
          if(res.status == 200){
            alert('저장이 완료되었습니다.')
            this.props.history.push('/vehicleDB/trim');
          }else{
            alert('라인업 저장 요청 오류가 발생하였습니다.')
          }
        })
      }
    }

    infoSettingLine = e => {
      const line = this.state.line

      if(e.target.type == "number"){
        if(isNaN(e.target.value)){
          line[e.target.className] = ""
        }
      }
      if((e.target.className == "condPrice" && line.condPercentage !== "") || (e.target.className == "condPercentage" && line.condPrice !== "")){
        alert('할인가격 또는 할인율 중 하나만 입력 가능합니다.')
        line[e.target.className] = ""
        return false
      }else if(e.target.type == "radio"){
        line[e.target.name] = e.target.id.indexOf('ok') !== -1 ? true : false
      }else{
        line[e.target.className] = e.target.value
      }
      this.setState({
        line : line
      })
    }

    selectFuelType = selectOption => {
      const line = this.state.line
      line.fuelType = selectOption
      this.setState({
        line : line
      })
    }

    handleChange = date => {
      const line = this.state.line
      line.date = date
      this.setState({
        line : line
      })
    }

    onAddConditions = () => {
      const line = this.state.line
      if(line.condName == "") {
        alert('판매조건 명을 입력해주세요.')
        return false;
      }
      if(line.condPrice == "" && line.condPercentage == "") {
        alert('할인가격 또는 할인율을 입력해주세요.')
        return false;
      }
      const pushData = {
        name : line.condName,
        price : line.condPrice,
        percentage : line.condPercentage,
        description : line.condDescription,
        type : "main",
        status : "done",
        year : document.querySelector('#condYear').value,
        month : document.querySelector('#condMonth').value,
        child : []
      }
      line.conditions.push(pushData)
      line.condName = ''
      line.condPrice = ''
      line.condPercentage = ''
      line.condDescription = ''
      this.setState({
        line : line,
      } , ()=> {
        document.querySelector('#condYear').value = new Date().getFullYear()
        document.querySelector('#condMonth').value = new Date().getMonth()+1
      })
    }

    toggleDoneToEdit = ( conditionIndex ) => {
      const line = this.state.line
      line.conditions[conditionIndex].status = "edit"
      this.setState({
        line : line
      })
    }

    toggleEditToDone = ( conditionIndex ) => {
      const line = this.state.line
      if(document.querySelector('#condName'+conditionIndex).value == ""){
        alert('판매조건을 입력해주세요.')
        return false
      }
      if(document.querySelector('#condPrice'+conditionIndex).value == "" && document.querySelector('#condPercentage'+conditionIndex).value){
        alert('할인가격 또는 할인율을 입력해주세요.')
        return false
      }
      line.conditions[conditionIndex].status = "done"
      line.conditions[conditionIndex].name = document.querySelector('#condName'+conditionIndex).value
      line.conditions[conditionIndex].price = document.querySelector('#condPrice'+conditionIndex).value
      line.conditions[conditionIndex].percentage = document.querySelector('#condPercentage'+conditionIndex).value
      line.conditions[conditionIndex].description = document.querySelector('#condDescription'+conditionIndex).value
      this.setState({
        line : line
      })
    }

    onRemoveCondition = i => {
      const line = this.state.line
      line.conditions.splice(i,1)
      this.setState({
        line : line
      })
    }

    pricePercentageCheck = (e , targetid) => {
      if(isNaN(e.target.value)){
        e.target.value = ""
        return false
      }
      if(document.querySelector(targetid).value !== ""){
        alert('할인가격 또는 할인율 중 하나만 입력 가능합니다.')
        e.target.value = ""
        return false
      }
    }

    addCategory = (index ) => {
      const line = this.state.line
      line.conditions[index].type = "parentChild"
      line.conditions[index].child.push({
        childName : "",
        childPrice : "",
        childPercentage : "",
        type : "ing"
      })
      this.setState({
        line : line
      })
    }

    saveCategory = (conditionIndex , itemIndex) => {
      const line = this.state.line
      if(document.querySelector('#childName'+itemIndex).value == ""){
        alert('판매조건을 입력해주세요.')
        return false
      }
      if(document.querySelector('#childPrice'+itemIndex).value == "" && document.querySelector('#childPercentage'+itemIndex).value == ""){
        alert('할인가격 또는 할인율을 입력해주세요.')
        return false
      }
      line.conditions[conditionIndex].child[itemIndex].type = "done"
      line.conditions[conditionIndex].child[itemIndex].childName = document.querySelector('#childName'+itemIndex).value
      line.conditions[conditionIndex].child[itemIndex].childPrice = document.querySelector('#childPrice'+itemIndex).value
      line.conditions[conditionIndex].child[itemIndex].childPercentage = document.querySelector('#childPercentage'+itemIndex).value
      this.setState({
        line : line
      })
    }

    editCategory = (conditionIndex , itemIndex) => {
      const line = this.state.line
      line.conditions[conditionIndex].child[itemIndex].type = "ing"
      this.setState({
        line : line
      })
    }

    removeCategory = (conditionIndex , itemIndex) => {
      const line = this.state.line
      line.conditions[conditionIndex].child.splice(itemIndex,1)
      if(!line.conditions[conditionIndex].child.length){
        line.conditions[conditionIndex].type = 'main'
      }
      this.setState({
        line : line
      })
    }

    infoSearchValue = (e) => {
      const trim = this.state.trim

      if(e.target.type == "radio"){
        if(e.target.id.indexOf('ok') !== -1){
          trim.is_active = true
        }else{
          trim.is_active = false
        }
      }
      else{
          trim[e.target.name] = e.target.value
      }
      this.setState({
        trim : trim
      });
    };

    infoSearchValueByTrimSpec = (e) => {
      const trim = this.state.trim
      if(e.target.name == "quota" && isNaN(e.target.value)){
        return false
      }else{
        trim.trimSpec[e.target.name] = e.target.value
      }
      this.setState({
        trim : trim
      })
    }

    infoSearchValueByTrimItem = (e)=> {
      const trim = this.state.trim
      trim.trimItem[e.target.name] = e.target.value
      this.setState({
        trim : trim
      })
    }

    selectOption = (selectOption) => {
      const trim = this.state.trim
      trim.optionId = selectOption
      this.setState({
        trim : trim
      })
    }

    selectPriceOption = (selectOption) => {

      const trim = this.state.trim
      if(selectOption.value == 0){
        trim.priceOptionId = selectOption
      }
      else{
        trim.priceOptionId = selectOption
        trim.price_option_ids = trim.price_option_ids.concat(selectOption)
      }

      this.setState({
        trim : trim
      })
    }

    removePriceOption = ( value ) =>{
      const trim = this.state.trim

      trim.price_option_ids = trim.price_option_ids.filter(priceOption => priceOption.value !== value)

      this.setState({
        trim : trim
      })
    }

    exposureResource = () =>{
      const trim = this.state.trim
      trim.popActiveResource = true
      this.setState({trim : trim});
    }
    exposureOption = () =>{
      const trim = this.state.trim
      trim.popActiveOption = true
      this.setState({trim : trim});
    }
    closePop = () => {
    const trim = this.state.trim
      trim.popActiveOption = false
      trim.popActiveResource = false
      this.setState({trim : trim});
    }

    render() {
      const modelGroup = this.state.modelGroup
      const model = this.state.model
      const line = this.state.line
      const trim = this.state.trim

      let year = []
      let month = []
      for(var x  = 2010; x<2026; x++){
        year.push(x)
      }
      for(var x = 1; x<=12; x++){
        month.push(x)
      }

        return (
            <Fragment>
                {/* 차량DB> 차량추가 > 모델그룹탭 영역 시작 */}
                {
                  this.state.page == 1 ?
                  <>
                {/* 모델 그룹  */}
                <div className="title disi wd100"><p className="mb10"><span>홈</span> 〉 <span>차량DB</span> 〉 <span>차량추가</span></p>차량추가
                    <div className="floatR disi">
                        <Link to="#"><button type="button" className="btnLine navyBg" onClick={() => this.pageMove(2)}>다음</button></Link>
                    </div>
                </div>
                <div className="car_addtap mt10">
                    <ul className="car_bd">
                        <li className="on bdccc car_bd_li"><Link to="#">01. 모델그룹</Link></li>
                        <li className="bdccc car_bd_li"><Link to="#">02. 모델</Link></li>
                        <li className="bdccc car_bd_li"><Link to="#">03. 라인업</Link></li>
                        <li className="bdccc car_bd_li"><Link to="#">04. 트림</Link></li>
                    </ul>
                </div>
                <div className="form_tablewrap">
                        <table className="mt20">
                            <colgroup>
                                <col width="10%"></col>
                                <col width="*"></col>
                                <col width="10%"></col>
                                <col width="*"></col>
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>브랜드명 <span className="redT">*</span></th>
                                    <td>
                                    <Select className="reactSelect"
                                    options={this.state.totalInfo}
                                    value={this.state.brandId}
                                    name="brandId"
                                    onChange={this.selectBrand}
                                    placeholder="전체"
                                    isSearchable={true}/>
                                    </td>
                                    <th>모델 그룹명 <span className="redT">*</span></th>
                                    <td><input type="text" value={modelGroup.name} onChange={e => {
                                      const modelGroup = this.state.modelGroup
                                      modelGroup.name = e.target.value
                                      this.setState({
                                        modelGroup : modelGroup
                                      })
                                    }}></input></td>
                                </tr>
                                <tr>
                                    <th>차종 <span className="redT">*</span></th>
                                    <td>
                                    <Select className="reactSelect"
                                    options={modelGroup.carTypes}
                                    value={modelGroup.carType}
                                    name="brandId"
                                    onChange={this.selectCarType}
                                    placeholder="전체"
                                    isSearchable={true}/>
                                    </td>
                                    <th>활성화 상태 <span className="redT">*</span></th>
                                    <td>
                                    <Select className="reactSelect"
                                    options={modelGroup.Active}
                                    value={modelGroup.isActive}
                                    name="brandId"
                                    onChange={this.selectActive}
                                    placeholder="전체"
                                    isSearchable={true}/>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="rightT mt20">
                            <Link to="#"><button type="button" className="btnLine navyBg" onClick={() => this.pageMove(2)}>다음</button></Link>
                        </div>
                </div>
                {/* 모델 그룹  */}
                  </> :
                  this.state.page == 2 ?
                  <>
                  {/* #모델  */}
                <div className="title disi wd100"><p className="mb10"><span>홈</span> 〉 <span>차량DB</span> 〉 <span>차량추가</span></p>차량추가
                    <div className="floatR disi">
                        <Link to="#"><button type="button" className="btnLine navyBg" onClick={() => this.pageMove(1)}>이전</button></Link>
                        <Link to="#"><button type="button" className="btnLine navyBg ml10" onClick={() => this.pageMove(3)}>다음</button></Link>
                        <Link to="#"><button type="button" className="btnLine navyBg ml10 b_btn" onClick={this.onSaveAtModel}>저장하고 끝내기</button></Link>
                    </div>
                </div>
                <div className="car_addtap mt10">
                    <ul className="car_bd">
                        <li className="bdccc car_bd_li"><Link to="#">01. 모델그룹</Link></li>
                        <li className="on bdccc car_bd_li"><Link to="#">02. 모델</Link></li>
                        <li className="bdccc car_bd_li"><Link to="#">03. 라인업</Link></li>
                        <li className="bdccc car_bd_li"><Link to="#">04. 트림</Link></li>
                    </ul>
                </div>
                <div className="form_tablewrap">
                <div className={model.popActive ? "popUp img_pop on" : "popUp img_pop"}>
                    <p className={`popUp$ mb10`}>
                      차량포토뷰어 이미지
                    </p>
                    <span onClick={this.onClosePopUp}>x</span>
                    <div className={`edit_desc on mb10`}>이미지 업로드/순서변경 또는 삭제 요청 시 변동사항이 자동으로 저장됩니다.</div>
                    <div className="popUp car_img_box">
                        <div className="popUp car_boxs">
                          <this.SortableList axis="xy" distance={10} items={model.images}  onSortEnd={this.onSortEnd} />
                        </div>
                        <input onChange={(e) => this.onUploadImages(e)} style={{display : "none"}} multiple type="file" accept="image/*" className="popUp" id="images" />
                    </div>
                    <div className="popUp brand_btn">
                        <button type="button" className={`btnLine btnSave navyBg popUp on`} onClick={this.onClosePopUp}>저장</button>
                        <button type="button" className="btn navyBg ml10" onClick={this.onClosePopUp}>닫기</button>
                    </div>
                </div>
                {/*차량포토뷰어 이미지 팝업*/}
                <div className="form_tablewrap">
                        <table className="mt20">
                            <colgroup>
                                <col width="14%"></col>
                                <col width="*"></col>
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>브랜드 <span className="redT">*</span> / 모델그룹 <span className="redT">*</span></th>
                                    <td  id="brandSelect">
                                    <Select className="reactSelect"
                                    options={this.state.totalInfo}
                                    value={this.state.brandId}
                                    isDisabled={true}/>
                                     <Select className="reactSelect"
                                    options={[{label : modelGroup.name , value : ""}]}
                                    value={{label : modelGroup.name , value : ""}}
                                    isDisabled={true}/>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="mt40">
                            <colgroup>
                                <col width="14%"></col>
                                <col width="26%"></col>
                                <col width="10%"></col>
                                <col width="50%"></col>
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>모델명 <span className="redT">*</span></th>
                                    <td><input type="text" name="name" value={model.name} onChange={this.infoSettingModel}></input></td>
                                    <th>전체컬러</th>
                                    <td>
                                        <table className="nonborder_table table_file">
                                            <colgroup>
                                                <col width="80px"></col>
                                                <col width="*"></col>
                                                <col width="270px"></col>
                                                <col width="100px"></col>
                                            </colgroup>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        #<input type="color" name="colorCode" value={model.colorCode} onChange={this.infoSettingModel}></input>
                                                    </td>
                                                    <td>
                                                        <input type="text" name="colorName" value={model.colorName} onChange={this.infoSettingModel} placeholder="컬러명"></input>
                                                    </td>
                                                    <td>
                                                        <input type="text" readOnly placeholder="이미지*" id="colorImage" value={model.colorImage ? model.colorImage.name : ""}
                                                        onClick={() => document.querySelector('#file_input').click()} ></input>
                                                        <input accept="image/*" type="file" id="file_input" name="colorImage" style={{display : 'none'}} files={model.colorImage} onChange={this.infoSettingModel}></input>
                                                    </td>
                                                    <td>
                                                        <button htmlFor="model_file" className="file_btn btnLine navyBg pl10 pr10" onClick={() => this.onAddColor()}>컬러추가</button>
                                                    </td>
                                                </tr>
                                                {
                                                  model.colorCodes ?
                                                  model.colorCodes.map((item,index) => {
                                                    return (
                                                    <Fragment key={index}>
                                                    <tr>
                                                        <td>
                                                            #<input type="color" readOnly value={item}></input>
                                                        </td>
                                                        <td>
                                                            <input type="text" value={model.colorNames[index]} readOnly></input>
                                                        </td>
                                                        <td >
                                                            <input type="text"  readOnly value={model.colorImages[index].name} className="file_input"></input>
                                                        </td>
                                                        <td>
                                                            <label htmlFor="model_file" className="file_btn del_file_btn btnLine navyBg pl10 pr10" onClick={() => this.onRemoveColor( index )}>삭제</label>
                                                        </td>
                                                    </tr>
                                                    </Fragment>
                                                    )
                                                  })
                                                  :
                                                   <></>
                                                }
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <th>개발명</th>
                                    <td><input type="text" name="codeName" value={model.codeName} onChange={this.infoSettingModel}></input></td>
                                    <th>외관</th>
                                    <td>
                                    <Select className="reactSelect"
                                    options={model.exteriorTypes}
                                    value={model.exteriorType}
                                    name="brandId"
                                    onChange={this.selectExterior}
                                    placeholder="전체"
                                    isSearchable={true}/>
                                    </td>
                                </tr>
                                <tr>
                                    <th>차량포토뷰어 이미지</th>
                                    <td><button className="file_btn pl10 pr10" onClick={() => {
                                      const model = this.state.model
                                      model.popActive = true
                                      this.setState({ model : model })
                                      }}>이미지등록</button></td>
                                    <th>예정/미정 여부</th>
                                    <td>
                                        <input type="radio" name="will" className="ok" id="will" checked={model.will ? true : false} onClick={this.infoSettingModel}></input><label htmlFor="will"> 예정</label>
                                        <input type="radio" name="will" className="no ml10" checked={!model.will ? true : false} onClick={this.infoSettingModel} id="undtr"></input><label htmlFor="undtr"> 미정</label>
                                    </td>
                                </tr>
                                <tr>
                                  <th>신차여부</th>
                                  <td>
                                    <input type="radio" name="new"  className="ok"id="new_true" checked={model.new ? true : false} onClick={this.infoSettingModel}></input><label htmlFor="new_true"> 예</label>
                                    <input type="radio" name="new" className="no ml10" checked={!model.new ? true : false} onClick={this.infoSettingModel} id="new_false"></input><label htmlFor="new_false"> 아니오</label>
                                  </td>
                                  <th>활성화 상태</th>
                                  <td>
                                    <input type="radio" name="active"  className="ok"id="active_true" checked={model.active ? true : false} onClick={this.infoSettingModel}></input><label htmlFor="active_true"> 예</label>
                                    <input type="radio" name="active" className="no ml10" id="active_false" checked={!model.active ? true : false} onClick={this.infoSettingModel}></input><label htmlFor="active_false"> 아니오</label>
                                  </td>
                                </tr>
                                <tr>
                                    <th>경쟁차 비교 선택</th>
                                    <td colSpan="3">
                                        <div className="brandSelect">
                                        <Select className="reactSelect"
                                        options={model.rivalBrands}
                                        value=  {model.rivalBrandId}
                                        name="brandId"
                                        onChange={this.selectRivalBrand}
                                        placeholder="전체"
                                        isSearchable={true}/>
                                        <Select className="reactSelect"
                                        options={model.rivalModelGroup}
                                        value=  {model.rivalModelGroupId}
                                        name="brandId"
                                        onChange={this.selectRivalModelGroup}
                                        placeholder="전체"
                                        isSearchable={true}/>
                                        <Select className="reactSelect"
                                        options={model.rivalModels}
                                        value=  {model.rivalModelId}
                                        name="brandId"
                                        onChange={this.selectRivalModel}
                                        placeholder="전체"
                                        isSearchable={true}/>
                                            <button className="file_btn" onClick={() => this.addRivalModel()}>추가</button>
                                        </div>
                                        <div className="sel_tagwrap">
                                          {model.rivalList.map((item,key) => {
                                            return (
                                              <Fragment key={key}>
                                                <span className="sel_tag"><Link to="#">{item.text} <i onClick={() => this.removeRivalModel(item.value)}>X</i></Link></span>
                                              </Fragment>
                                            )
                                          })}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="rightT mt20">
                  <Link to="#"><button type="button" className="btnLine navyBg" onClick={() => this.pageMove(1)}>이전</button></Link>
                  <Link to="#"><button type="button" className="btnLine navyBg ml10" onClick={() => this.pageMove(3)}>다음</button></Link>
                  <Link to="#"><button type="button" className="btnLine navyBg ml10 b_btn" onClick={this.onSaveAtModel}>저장하고 끝내기</button></Link>
                </div>
                {/* 모델  */}
                </> :
                  this.state.page == 3 ?
                  <>
                  <div className="title disi wd100"><p className="mb10"><span>홈</span> 〉 <span>차량DB</span> 〉 <span>차량추가</span></p>차량추가
                    <div className="floatR disi">
                        <Link to="#"><button type="button" className="btnLine navyBg" onClick={() => this.pageMove(2)}>이전</button></Link>
                        <Link to="#"><button type="button" className="btnLine navyBg ml10" onClick={() => this.pageMove(4)}>다음</button></Link>
                    </div>
                </div>
                <div className="car_addtap mt10">
                    <ul className="car_bd">
                        <li className="bdccc car_bd_li"><Link to="#">01. 모델그룹</Link></li>
                        <li className="bdccc car_bd_li"><Link to="#">02. 모델</Link></li>
                        <li className="on bdccc car_bd_li"><Link to="#">03. 라인업</Link></li>
                        <li className="bdccc car_bd_li"><Link to="#">04. 트림</Link></li>
                    </ul>
                </div>
                   <div className="form_tablewrap">
                        <table className="mt20">
                            <colgroup>
                                <col width="14%"></col>
                                <col width="*"></col>
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>브랜드 / 모델그룹 / 모델명</th>
                                    <td id="brandSelect">
                                    <Select className="reactSelect"
                                    options={this.state.totalInfo}
                                    value={this.state.brandId}
                                    isDisabled={true}/>
                                     <Select className="reactSelect"
                                    options={[{label : modelGroup.name , value : ""}]}
                                    value={{label : modelGroup.name , value : ""}}
                                    isDisabled={true}/>
                                    <Select className="reactSelect"
                                    options={[{label : model.name , value : ""}]}
                                    value={{label : model.name , value : ""}}
                                    isDisabled={true}/>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                          <table className="mt30">
                            <colgroup>
                                <col width="10%"></col>
                                <col width="30%"></col>
                                <col width="12%"></col>
                                <col width="50%"></col>
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>라인업명 <span className="redT">*</span></th>
                                    <td><input type="text" className="name" value={line.name} onChange={this.infoSettingLine}></input></td>
                                    <th>연료 <span className="redT">*</span></th>
                                    <td>
                                    <Select className="reactSelect"
                                    options={line.fuelTypes}
                                    value={line.fuelType}
                                    name="brandId"
                                    onChange={this.selectFuelType}
                                    placeholder="전체"
                                    isSearchable={true}/>
                                    </td>
                                </tr>
                                <tr>
                                  {
                                    line.fuelType.value == "전기"  ?
                                    <>
                                    <th>주행거리(km/kWh) <span className="redT">*</span></th>
                                    <td><input type="number" className="mileage" value={line.mileage} onChange={this.infoSettingLine}></input></td>
                                    </>
                                     :
                                    <>
                                    <th>배기량(cc) <span className="redT">*</span></th>
                                    <td><input type="number" className="displacement" value={line.displacement} onChange={this.infoSettingLine}></input></td>
                                    </>
                                  }
                                    <th>출시일</th>
                                    <td><DatePicker
                                          selected={line.date}
                                          onChange={this.handleChange}
                                          dateFormat="yyyy-MM-dd"
                                        /></td>
                                </tr>
                                <tr>
                                    <th>연식 <span className="redT">*</span></th>
                                    <td><input type="number" className="year" value={line.year} onChange={this.infoSettingLine}></input></td>
                                    <th>활성화 여부 <span className="redT" >*</span></th>
                                    <td>
                                        <input type="radio" name="active" id={"active"+"_ok"} checked={line.active ? true : false} onChange={this.infoSettingLine}></input>
                                        <label htmlFor={"active"+"_ok"}> 예</label>
                                        <input type="radio" name="active" id={"active"+"_no"} className="ml10" checked={!line.active ? true : false} onChange={this.infoSettingLine}></input>
                                        <label htmlFor={"active"+"_no"}> 아니오</label>
                                    </td>
                                </tr>
                                <tr>
                                    <th>시판 여부 <span className="redT">*</span></th>
                                    <td>
                                        <input type="radio" name="sale" id={"sale"+"_ok"} checked={line.sale ? true : false} onChange={this.infoSettingLine}></input>
                                        <label htmlFor={"sale"+"_ok"}> 예</label>
                                        <input type="radio" name="sale" id={"sale"+"_no"} className="ml10" checked={!line.sale ? true : false} onChange={this.infoSettingLine}></input>
                                        <label htmlFor={"sale"+"_no"} > 아니오</label>
                                    </td>
                                    <th>대표 라인업 선택 <span className="redT">*</span></th>
                                    <td>
                                        <input type="radio" name="primary" id={"rp"+"_ok"} checked={line.primary ? true : false} onChange={this.infoSettingLine}></input>
                                        <label htmlFor={"rp"+"_ok"}> 예</label>
                                        <input type="radio" name="primary" id={"rp"+"_no"} className="ml10" checked={!line.primary ? true : false} onChange={this.infoSettingLine}></input>
                                        <label htmlFor={"rp"+"_no"}> 아니오</label>
                                    </td>
                                </tr>
                                <tr>
                                    <th>판매조건</th>
                                    <td colSpan="3">
                                        <div>
                                            <select className="mr10" id="condYear" defaultValue={new Date().getFullYear()}>
                                              {
                                                year.map((y , i) => {
                                                    return(
                                                      <option value={y}>{y}</option>
                                                    )
                                                  })
                                                }
                                            </select>
                                            년 /
                                            <select className="ml10 mr10" id="condMonth" defaultValue={new Date().getMonth()+1}>
                                              {
                                                month.map((m , i) => {
                                                    return(
                                                      <option value={m}>{m}</option>
                                                    )
                                                  })
                                                }
                                            </select>
                                            월
                                        </div>
                                        <table className="nonborder_table">
                                            <colgroup>
                                                <col width="15%"></col>
                                                <col width="10%"></col>
                                                <col width="10%"></col>
                                                <col width=""></col>
                                                <col width="13%"></col>
                                            </colgroup>
                                            <tbody>
                                            {
                                              line.conditions.map((data , i) => {
                                                return (
                                                  <>
                                                  <Fragment key={i}>
                                                    {
                                                      data.status == 'done' ?
                                                      <tr>
                                                          <td>&nbsp;&nbsp;&nbsp;{data.name}</td>
                                                          <td>&nbsp;&nbsp;&nbsp;{data.type=="main" ? data.price : ""}</td>
                                                          <td>&nbsp;&nbsp;&nbsp;{data.type=="main" ? data.percentage+`${data.percentage == '' ? "" : "%"}` : ""}</td>
                                                          <td>&nbsp;&nbsp;&nbsp;{data.description}</td>
                                                          <td>
                                                              <Link to="#"><button type="button" className="btn navyBg" onClick={() => this.toggleDoneToEdit(i)}>수정</button></Link>
                                                              <button type="button" className="btnLine navyBg ml10" onClick={() => this.onRemoveCondition(i)}>삭제</button>
                                                          </td>
                                                      </tr>
                                                      :
                                                      <tr>
                                                          <td><input id={"condName"+i} type="text" defaultValue={data.name}></input></td>
                                                          <td><input id={"condPrice"+i} type="number"defaultValue={data.price} onChange={(e) => this.pricePercentageCheck(e , '#condPercentage'+i)}></input></td>
                                                          <td><input id={"condPercentage"+i} type="number"defaultValue={data.percentage} onChange={(e) => this.pricePercentageCheck(e , '#condPrice'+i)}></input></td>
                                                          <td><input id={"condDescription"+i} type="text"defaultValue={data.description}></input></td>
                                                          <td>
                                                              <Link to="#"><button type="button" className="btn navyBg" onClick={() => this.toggleEditToDone(i)}>저장</button></Link>
                                                              <button type="button" className="btnLine navyBg ml10" onClick={() => this.onRemoveCondition(i)}>삭제</button>
                                                          </td>
                                                      </tr>
                                                    }

                                                    {
                                                      data.child
                                                      ?
                                                      data.child.map((child , j) => {
                                                      return(
                                                      child.type=="ing"
                                                      ?
                                                      <tr>
                                                          <td><input type="text"   id={"childName"+j} defaultValue={child.childName} placeholder="판매조건 명"></input></td>
                                                          <td><input type="number" id={"childPrice"+j} defaultValue={child.childPrice} placeholder="할인가격" onChange={(e) => this.pricePercentageCheck(e , '#childPercentage'+j)}></input></td>
                                                          <td><input type="number" id={"childPercentage"+j} defaultValue={child.childPercentage} placeholder="할인율" onChange={(e) => this.pricePercentageCheck(e , '#childPrice'+j)}></input></td>
                                                          <td></td>
                                                          <td>
                                                              <button type="button" className="btn navyBg" onClick={() => this.saveCategory(i,j)}>저장</button>
                                                              <button type="button" className="btnLine navyBg ml10" onClick={() => this.removeCategory(i , j)}>삭제</button>
                                                          </td>
                                                      </tr>
                                                      :
                                                      <tr>
                                                          <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{child.childName}</td>
                                                          <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{child.childPrice}</td>
                                                          <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{child.childPercentage}</td>
                                                          <td></td>
                                                          <td>
                                                              <button type="button" className="btn navyBg"onClick={() => this.editCategory(i , j)}>수정</button>
                                                              <button type="button" className="btnLine navyBg ml10" onClick={() => this.removeCategory(i , j)}>삭제</button>
                                                          </td>
                                                      </tr>
                                                      )

                                                      })
                                                      :
                                                      <></>
                                                    }
                                                    <tr>
                                                        <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a className="categoryMaker" onClick={() => this.addCategory(i)}>└ 하위 카테고리 추가</a></td>
                                                    </tr>
                                                </Fragment>
                                                <tr>
                                                  <td colSpan="4">
                                                  <div className="division"/>
                                                  </td>
                                                </tr>
                                                </>
                                                )
                                              })
                                            }
                                                <tr>
                                                    <td><input type="text" className="condName"          onChange={this.infoSettingLine} value={line.condName}placeholder="판매조건 명" ></input></td>
                                                    <td><input type="number" className="condPrice"       onChange={this.infoSettingLine} value={line.condPrice}placeholder="할인가격" ></input></td>
                                                    <td><input type="number" className="condPercentage"  onChange={this.infoSettingLine} value={line.condPercentage}placeholder="할인율" ></input></td>
                                                    <td><input type="text" className="condDescription"   onChange={this.infoSettingLine} value={line.condDescription}placeholder="판매조건 설명" ></input></td>
                                                    <td>
                                                        <button type="submit" className="btn navyBg" onClick={this.onAddConditions}>추가</button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>

                        </table>
                        <div className="floatR disi mt30">
                          <Link to="#"><button type="button" className="btnLine navyBg" onClick={() => this.pageMove(2)}>이전</button></Link>
                          <Link to="#"><button type="button" className="btnLine navyBg ml10" onClick={() => this.pageMove(4)}>다음</button></Link>
                        </div>
                      </div>

                  </>
                  :
                  <>
                  <div className="title disi wd100"><p className="mb10"><span>홈</span> 〉 <span>차량DB</span> 〉 <span>차량추가</span></p>차량추가
                    <div className="floatR disi">
                        <Link to="#"><button type="button" className="btnLine navyBg" onClick={() => this.pageMove(3)}>이전</button></Link>
                        <Link to="#"><button type="button" className="btnLine navyBg ml10" onClick={this.onSaveAtTrim}>저장</button></Link>
                    </div>
                  </div>
                  <div className="car_addtap mt10">
                      <ul className="car_bd">
                          <li className="bdccc car_bd_li"><Link to="#">01. 모델그룹</Link></li>
                          <li className="bdccc car_bd_li"><Link to="#">02. 모델</Link></li>
                          <li className="bdccc car_bd_li"><Link to="#">03. 라인업</Link></li>
                          <li className="on bdccc car_bd_li"><Link to="#">04. 트림</Link></li>
                      </ul>
                  </div>
                  <div className="form_tablewrap">
                      <table className="mt20">
                          <colgroup>
                              <col width="10%"></col>
                              <col width="*"></col>
                          </colgroup>
                          <tbody>
                              <tr>
                                  <th>브랜드/모델그룹/모델명</th>
                                  <td id="brandSelect">
                                  <Select className="reactSelect"
                                  options={this.state.totalInfo}
                                  value={this.state.brandId}
                                  isDisabled={true}/>
                                  <Select className="reactSelect"
                                  options={[{label : modelGroup.name , value : ""}]}
                                  value={{label : modelGroup.name , value : ""}}
                                  isDisabled={true}/>
                                  <Select className="reactSelect"
                                  options={[{label : model.name , value : ""}]}
                                  value={{label : model.name , value : ""}}
                                  isDisabled={true}/>
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                      <table className="mt30">
                          <colgroup>
                              <col width="10%"></col>
                              <col width="30%"></col>
                              <col width="10%"></col>
                              <col width="50%"></col>
                          </colgroup>
                          <tbody>
                              <tr>
                                  <th>라인업명 <span className="redT">*</span></th>
                                  <td colSpan="3">
                                  <Select className="reactSelect"
                                  options={[{label : line.name , value : ""}]}
                                  value={{label : line.name , value : ""}}
                                  isDisabled={true}/>
                                  </td>
                              </tr>
                              <tr>
                                  <th>트림명 <span className="redT">*</span></th>
                                  <td><input type="text" name="name" onChange={e => this.infoSearchValue(e)} value={trim.name}></input></td>
                                  <th>서브트림명</th>
                                  <td><input type="text" name="subname" onChange={e => this.infoSearchValue(e)} value={trim.subname}></input></td>

                              </tr>
                              <tr>
                                  <th>가격 <span className="redT">*</span></th>
                                  <td><input type="number" name="price" onChange={e => this.infoSearchValue(e)} value={trim.price}></input></td>
                                  <th>연비</th>
                                  <td><input type="text" name="efficiency" onChange={e => this.infoSearchValue(e)} value={trim.efficiency}></input></td>
                              </tr>
                              <tr>
                                <th>변속기</th>
                                <td>
                                    <Select className="reactSelect"
                                      options={trim.optionList}
                                      value={trim.optionId}
                                      name="option"
                                      onChange={selectOption => this.selectOption(selectOption)}
                                      placeholder="전체"
                                      isSearchable={true}/>
                                </td>
                                <th>활성화 여부 <span className="redT">*</span></th>
                                <td>
                                  <input type="radio" name={"active"} id={"active"+"_ok"} checked={trim.is_active ? true : false} onChange={e => this.infoSearchValue(e)}></input>
                                  <label htmlFor={"active"+"_ok"}> 예  </label>
                                  <input type="radio" name={"active"} id={"active"+"_no"} checked={trim.is_active ? false : true} onChange={e => this.infoSearchValue(e)}></input>
                                  <label htmlFor={"active"+"_no"}> 아니오</label>
                                </td>
                              </tr>
                              <tr>
                                  <th>가격옵션</th>
                                  <td colSpan="3">
                                    <Select className="reactSelect"
                                      options={trim.priceOptionList}
                                      value={trim.priceOptionId}
                                      name="priceOption"
                                      onChange={selectOption => this.selectPriceOption(selectOption)}
                                      placeholder="전체"
                                      isSearchable={true}/>
                                      <div className="sel_tagwrap" style={{marginTop : trim.price_option_ids.length > 0 ? "8px" : "0px"}}>
                                        {
                                          trim.price_option_ids.map((priceOption , x) => {
                                            return(
                                              <span className="sel_tag"><Link to="#">{priceOption.label} <i onClick={() => this.removePriceOption(priceOption.value)}>X</i></Link></span>
                                            )
                                          })
                                        }
                                      </div>
                                  </td>
                              </tr>
                              <tr>
                                  <th>제원</th>
                                  <td colSpan="3" className="table_popbtn">
                                      <button className="file_btn pl10 pr10" onClick={() => this.exposureResource()}>제원 입력</button>
                                  </td>
                              </tr>
                              <tr>
                                  <th>사양/옵션</th>
                                  <td colSpan="3" className="table_popbtn">
                                      <button className="file_btn pl10 pr10" onClick={() => this.exposureOption()}>사양 입력</button>
                                  </td>
                              </tr>

                          </tbody>
                      </table>
                      {/* 재원 팝업 */}
                  <Fragment>
                  <div className={trim.popActiveResource ? "popBg onPop" : "popBg"} onClick={() => this.closePop()} />
                  <div className={trim.popActiveResource
                     ? "resources on" : "resources"}>
                    <p>재원</p><span onClick={() => this.closePop()}>x</span>
                      <div className="resources_table_box">
                      <table className="resources_table">
                          <colgroup>
                              <col width="50%"></col>
                              <col width="50%"></col>
                          </colgroup>
                          <tbody>
                              <tr>
                                  <th>엔진형식</th>
                                  <td><input type="text" name="engine_type" value={trim.trimSpec.engine_type} onChange={e => this.infoSearchValueByTrimSpec(e)}></input></td>
                              </tr>
                              <tr>
                                  <th>연료</th>
                                  <td><input type="text" name="fuel" value={trim.trimSpec.fuel} onChange={e => this.infoSearchValueByTrimSpec(e)}></input></td>
                              </tr>
                              <tr>
                                  <th>배기량 (cc)</th>
                                  <td><input type="text" name="displacement" value={trim.trimSpec.displacement} onChange={e => this.infoSearchValueByTrimSpec(e)}></input></td>
                              </tr>
                              <tr>
                                  <th>최고출력 (ps/rpm)</th>
                                  <td><input type="text" name="engine_max_out" value={trim.trimSpec.engine_max_out} onChange={e => this.infoSearchValueByTrimSpec(e)}></input></td>
                              </tr>
                              <tr>
                                  <th>최대토크 (kg.m/rpm)</th>
                                  <td><input type="text" name="engine_max_torque" value={trim.trimSpec.engine_max_torque} onChange={e => this.infoSearchValueByTrimSpec(e)}></input></td>
                              </tr>
                              <tr>
                                  <th>공회전제한장치</th>
                                  <td><input type="text" name="idle_stop_go" value={trim.trimSpec.idle_stop_go} onChange={e => this.infoSearchValueByTrimSpec(e)}></input></td>
                              </tr>
                              <tr>
                                  <th>친환경</th>
                                  <td><input type="text" name="eco_friendly" value={trim.trimSpec.eco_friendly} onChange={e => this.infoSearchValueByTrimSpec(e)}></input></td>
                              </tr>
                              <tr>
                                  <th>배터리종류</th>
                                  <td><input type="text" name="battery_type" value={trim.trimSpec.battery_type} onChange={e => this.infoSearchValueByTrimSpec(e)}></input></td>
                              </tr>
                              <tr>
                                  <th>배터리전압 (V)</th>
                                  <td><input type="text" name="battery_voltage" value={trim.trimSpec.battery_voltage} onChange={e => this.infoSearchValueByTrimSpec(e)}></input></td>
                              </tr>
                              <tr>
                                  <th>배터리용량 (Ah)</th>
                                  <td><input type="text" name="battery_capacity" value={trim.trimSpec.battery_capacity} onChange={e => this.infoSearchValueByTrimSpec(e)}></input></td>
                              </tr>
                              <tr>
                                  <th>충전용량 (kWh)</th>
                                  <td><input type="text" name="charging_capacity" value={trim.trimSpec.charging_capacity} onChange={e => this.infoSearchValueByTrimSpec(e)}></input></td>
                              </tr>
                              <tr>
                                  <th>모터최고출력 (ps)</th>
                                  <td><input type="text" name="motor_max_out" value={trim.trimSpec.motor_max_out} onChange={e => this.infoSearchValueByTrimSpec(e)}></input></td>
                              </tr>
                              <tr>
                                  <th>모터최대토크 (kg.m)</th>
                                  <td><input type="text" name="motor_max_torque" value={trim.trimSpec.motor_max_torque} onChange={e => this.infoSearchValueByTrimSpec(e)}></input></td>
                              </tr>
                              <tr>
                                  <th>충전방식 (완속)</th>
                                  <td><input type="text" name="charging_type" value={trim.trimSpec.charging_type} onChange={e => this.infoSearchValueByTrimSpec(e)}></input></td>
                              </tr>
                              <tr>
                                  <th>충전시간 (완속) (시간)</th>
                                  <td><input type="text" name="charging_hour" value={trim.trimSpec.charging_hour} onChange={e => this.infoSearchValueByTrimSpec(e)}></input></td>
                              </tr>
                              <tr>
                                  <th>충전방식 (급속)</th>
                                  <td><input type="text" name="quick_charging_type" value={trim.trimSpec.quick_charging_type} onChange={e => this.infoSearchValueByTrimSpec(e)}></input></td>
                              </tr>
                              <tr>
                                  <th>충전시간 (급속) (분)</th>
                                  <td><input type="text"  name="quick_charging_min" value={trim.trimSpec.quick_charging_min} onChange={e => this.infoSearchValueByTrimSpec(e)}></input></td>
                              </tr>
                              <tr>
                                  <th>굴림방식</th>
                                  <td><input type="text" name="wheel_drive_type" value={trim.trimSpec.wheel_drive_type} onChange={e => this.infoSearchValueByTrimSpec(e)}></input></td>
                              </tr>
                              <tr>
                                  <th>변속기</th>
                                  <td><input type="text" name="gearbox" value={trim.trimSpec.gearbox} onChange={e => this.infoSearchValueByTrimSpec(e)}></input></td>
                              </tr>
                              <tr>
                                  <th>서스펜션 (전)</th>
                                  <td><input type="text" name="suspension_front" value={trim.trimSpec.suspension_front} onChange={e => this.infoSearchValueByTrimSpec(e)}></input></td>
                              </tr>
                              <tr>
                                  <th>서스펜션 (후)</th>
                                  <td><input type="text" name="suspension_rear" value={trim.trimSpec.suspension_rear} onChange={e => this.infoSearchValueByTrimSpec(e)}></input></td>
                              </tr>
                              <tr>
                                  <th>브레이크 (전)</th>
                                  <td><input type="text" name="break_front" value={trim.trimSpec.break_front} onChange={e => this.infoSearchValueByTrimSpec(e)}></input></td>
                              </tr>
                              <tr>
                                  <th>브레이크 (후)</th>
                                  <td><input type="text" name="break_rear" value={trim.trimSpec.break_rear} onChange={e => this.infoSearchValueByTrimSpec(e)}></input></td>
                              </tr>
                              <tr>
                                  <th>타이어 (전)</th>
                                  <td><input type="text" name="tire_front" value={trim.trimSpec.tire_front} onChange={e => this.infoSearchValueByTrimSpec(e)}></input></td>
                              </tr>
                              <tr>
                                  <th>타이어 (후)</th>
                                  <td><input type="text" name="tire_rear" value={trim.trimSpec.tire_rear} onChange={e => this.infoSearchValueByTrimSpec(e)}></input></td>
                              </tr>
                          </tbody>
                      </table>

              <table className="resources_table">
                      <colgroup>
                          <col width="50%"></col>
                          <col width="50%"></col>
                      </colgroup>
                      <tbody>
                          <tr>
                              <th>휠 (전) (인치)</th>
                              <td><input type="text" name="wheel_front" value={trim.trimSpec.wheel_front} onChange={e => this.infoSearchValueByTrimSpec(e)}></input></td>
                          </tr>
                          <tr>
                              <th>휠 (후) (인치)</th>
                              <td><input type="text"  name="wheel_rear" value={trim.trimSpec.wheel_rear} onChange={e => this.infoSearchValueByTrimSpec(e)}></input></td>
                          </tr>
                          <tr>
                              <th>복합연비 (km/l)</th>
                              <td><input type="text" name="efficiency" value={trim.efficiency} onChange={e => this.infoSearchValue(e)}></input></td>
                          </tr>
                          <tr>
                              <th>도심연비 (km/l)</th>
                              <td><input type="text" name="efficiency_city" value={trim.trimSpec.efficiency_city} onChange={e => this.infoSearchValueByTrimSpec(e)}></input></td>
                          </tr>
                          <tr>
                              <th>고속연비 (km/l)</th>
                              <td><input type="text" name="efficiency_high" value={trim.trimSpec.efficiency_high} onChange={e => this.infoSearchValueByTrimSpec(e)}></input></td>
                          </tr>
                          <tr>
                              <th>CO2배출 (g/km)</th>
                              <td><input type="text" name="co2_emission" value={trim.trimSpec.co2_emission} onChange={e => this.infoSearchValueByTrimSpec(e)}></input></td>
                          </tr>
                          <tr>
                              <th>에너지소비효율 (등급)</th>
                              <td><input type="text" name="energy_consumption" value={trim.trimSpec.energy_consumption} onChange={e => this.infoSearchValueByTrimSpec(e)}></input></td>
                          </tr>
                          <tr>
                              <th>복합연비 (전기) (km/kWh)</th>
                              <td><input type="text" name="efficiency" value={trim.efficiency} onChange={e => this.infoSearchValue(e)}></input></td>
                          </tr>
                          <tr>
                              <th>도심연비 (전기) (km/kWh)</th>
                              <td><input type="text" name="efficiency_e_city" value={trim.trimSpec.efficiency_e_city} onChange={e => this.infoSearchValueByTrimSpec(e)}></input></td>
                          </tr>
                          <tr>
                              <th>고속연비 (전기) (km/kWh)</th>
                              <td><input type="text" name="efficiency_e_high" value={trim.trimSpec.efficiency_e_high} onChange={e => this.infoSearchValueByTrimSpec(e)}></input></td>
                          </tr>
                          <tr>
                              <th>항속거리 (km)</th>
                              <td><input type="text" name="mileage" value={trim.trimSpec.mileage} onChange={e => this.infoSearchValueByTrimSpec(e)}></input></td>
                          </tr>
                          <tr>
                              <th>전장 (mm)</th>
                              <td><input type="text" name="full_length" value={trim.trimSpec.full_length} onChange={e => this.infoSearchValueByTrimSpec(e)}></input></td>
                          </tr>
                          <tr>
                              <th>전폭 (mm)</th>
                              <td><input type="text" name="full_width" value={trim.trimSpec.full_width} onChange={e => this.infoSearchValueByTrimSpec(e)}></input></td>
                          </tr>
                          <tr>
                              <th>전고 (mm)</th>
                              <td><input type="text" name="full_height" value={trim.trimSpec.full_height} onChange={e => this.infoSearchValueByTrimSpec(e)}></input></td>
                          </tr>
                          <tr>
                              <th>축거 (mm)</th>
                              <td><input type="text" name="wheel_base" value={trim.trimSpec.wheel_track_frwheel_baseont} onChange={e => this.infoSearchValueByTrimSpec(e)}></input></td>
                          </tr>
                          <tr>
                              <th>윤거 (전) (mm)</th>
                              <td><input type="text" name="wheel_track_front" value={trim.trimSpec.wheel_track_front} onChange={e => this.infoSearchValueByTrimSpec(e)}></input></td>
                          </tr>
                          <tr>
                              <th>윤거 (후) (mm)</th>
                              <td><input type="text" name="wheel_track_rear" value={trim.trimSpec.wheel_track_rear} onChange={e => this.infoSearchValueByTrimSpec(e)}></input></td>
                          </tr>
                          <tr>
                              <th>오버행 (전) (mm)</th>
                              <td><input type="text" name="overhang_front" value={trim.trimSpec.overhang_front} onChange={e => this.infoSearchValueByTrimSpec(e)}></input></td>
                          </tr>
                          <tr>
                              <th>오버행 (후) (mm)</th>
                              <td><input type="text" name="overhang_rear" value={trim.trimSpec.overhang_rear} onChange={e => this.infoSearchValueByTrimSpec(e)}></input></td>
                          </tr>
                          <tr>
                              <th>승차정원</th>
                              <td><input type="text" name="quota" value={trim.trimSpec.quota} onChange={e => this.infoSearchValueByTrimSpec(e)}></input></td>
                          </tr>
                          <tr>
                              <th>공차중량 (kg)</th>
                              <td><input type="text" name="empty_weight" value={trim.trimSpec.empty_weight} onChange={e => this.infoSearchValueByTrimSpec(e)}></input></td>
                          </tr>
                          <tr>
                              <th>연료탱크 (l)</th>
                              <td><input type="text" name="tank_volume" value={trim.trimSpec.tank_volume} onChange={e => this.infoSearchValueByTrimSpec(e)}></input></td>
                          </tr>
                          <tr>
                              <th>적재함길이 (mm)</th>
                              <td><input type="text" name="cargo_width" value={trim.trimSpec.cargo_width} onChange={e => this.infoSearchValueByTrimSpec(e)}></input></td>
                          </tr>
                          <tr>
                              <th>적재함넓이 (mm)</th>
                              <td><input type="text" name="cargo_area" value={trim.trimSpec.cargo_area} onChange={e => this.infoSearchValueByTrimSpec(e)}></input></td>
                          </tr>
                          <tr>
                              <th>적재함높이 (mm)</th>
                              <td><input type="text" name="cargo_height" value={trim.trimSpec.cargo_height} onChange={e => this.infoSearchValueByTrimSpec(e)}></input></td>
                          </tr>
                          <tr>
                              <th>적재량 (kg)</th>
                              <td><input type="text" name="cargo_weight" value={trim.trimSpec.cargo_weight} onChange={e => this.infoSearchValueByTrimSpec(e)}></input></td>
                          </tr>
                          <tr>
                              <th>제로백 (초)</th>
                              <td><input type="text" name="zero_to_max" value={trim.trimSpec.zero_to_max} onChange={e => this.infoSearchValueByTrimSpec(e)}></input></td>
                          </tr>
                          <tr>
                              <th>최고속도 (km/h)</th>
                              <td><input type="text" name="max_velocity" value={trim.trimSpec.max_velocity} onChange={e => this.infoSearchValueByTrimSpec(e)}></input></td>
                          </tr>
                      </tbody>
                  </table>
              </div>
              <div className="centerT mt10 mb10 dpb">
                  <Link to="#"><button type="button" className="btnLine navyBg disb" onClick={() => this.closePop()}>저장</button></Link>
              </div>
          </div>
                <div className={trim.popActiveOption ? "popBg onPop" : "popBg"} onClick={() => this.closePop()}/>
                <div className={trim.popActiveOption ? "resources on" : "resources"}>
                  <p>사양/옵션</p><span onClick={() => this.closePop()}>x</span>
                      <div className="resources_table_box">
                      <table className="resources_table">
                          <colgroup>
                              <col width="50%"></col>
                              <col width="50%"></col>
                          </colgroup>
                          <tbody>
                              <tr>
                                  <th>헤드램프</th>
                                  <td><input type="text" name="ext_head_lamp" value={trim.trimItem.ext_head_lamp} onChange={e => this.infoSearchValueByTrimItem(e )}></input></td>
                              </tr>
                              <tr>
                                  <th>전방 안개등</th>
                                  <td><input type="text" name="ext_fog_lamp_front" value={trim.trimItem.ext_fog_lamp_front} onChange={e => this.infoSearchValueByTrimItem(e )}></input></td>
                              </tr>
                              <tr>
                                  <th>주간 안개들</th>
                                  <td><input type="text" name="ext_fog_lamp" value={trim.trimItem.ext_fog_lamp} onChange={e => this.infoSearchValueByTrimItem(e )}></input></td>
                              </tr>
                              <tr>
                                  <th>리어 램프</th>
                                  <td><input type="text" name="ext_rear_lamp" value={trim.trimItem.ext_rear_lamp} onChange={e => this.infoSearchValueByTrimItem(e )}></input></td>
                              </tr>
                              <tr>
                                  <th>루프</th>
                                  <td><input type="text" name="ext_roof" value={trim.trimItem.ext_roof} onChange={e => this.infoSearchValueByTrimItem(e )}></input></td>
                              </tr>
                              <tr>
                                  <th>도어포켓 라이트</th>
                                  <td><input type="text" name="ext_door_light" value={trim.trimItem.ext_door_light} onChange={e => this.infoSearchValueByTrimItem(e )}></input></td>
                              </tr>
                              <tr>
                                  <th>아웃 사이드미러</th>
                                  <td><input type="text" name="ext_out_mirror" value={trim.trimItem.ext_out_mirror} onChange={e => this.infoSearchValueByTrimItem(e )}></input></td>
                              </tr>
                              <tr>
                                  <th>와이퍼</th>
                                  <td><input type="text" name="ext_wiper" value={trim.trimItem.ext_wiper} onChange={e => this.infoSearchValueByTrimItem(e )}></input></td>
                              </tr>
                              <tr>
                                  <th>자외선 차단유리</th>
                                  <td><input type="text" name="ext_uv_glass" value={trim.trimItem.ext_uv_glass} onChange={e => this.infoSearchValueByTrimItem(e )}></input></td>
                              </tr>
                              <tr>
                                  <th>발수 유리</th>
                                  <td><input type="text" name="ext_no_water_glass" value={trim.trimItem.ext_no_water_glass} onChange={e => this.infoSearchValueByTrimItem(e )}></input></td>
                              </tr>
                              <tr>
                                  <th>차음 유리</th>
                                  <td><input type="text" name="ext_no_sound_glass" value={trim.trimItem.ext_no_sound_glass} onChange={e => this.infoSearchValueByTrimItem(e )}></input></td>
                              </tr>
                              <tr>
                                  <th>머플러팁 형태</th>
                                  <td><input type="text" name="ext_muffler_tip" value={trim.trimItem.ext_muffler_tip} onChange={e => this.infoSearchValueByTrimItem(e )}></input></td>
                              </tr>
                              <tr>
                                  <th>계기판</th>
                                  <td><input type="text" name="int_dashboard" value={trim.trimItem.int_dashboard} onChange={e => this.infoSearchValueByTrimItem(e )}></input></td>
                              </tr>
                              <tr>
                                  <th>파워원도우</th>
                                  <td><input type="text" name="int_power_window" value={trim.trimItem.int_power_window} onChange={e => this.infoSearchValueByTrimItem(e )}></input></td>
                              </tr>
                              <tr>
                                  <th>세이프티 파워윈도우</th>
                                  <td><input type="text" name="int_safty_power_window" value={trim.trimItem.int_safty_power_window} onChange={e => this.infoSearchValueByTrimItem(e )}></input></td>
                              </tr>
                              <tr>
                                  <th>스티어링 휠</th>
                                  <td><input type="text" name="int_steering_wheel" value={trim.trimItem.int_steering_wheel} onChange={e => this.infoSearchValueByTrimItem(e )}></input></td>
                              </tr>
                              <tr>
                                  <th>기어 노브</th>
                                  <td><input type="text" name="int_gear_knob" value={trim.trimItem.int_gear_knob} onChange={e => this.infoSearchValueByTrimItem(e )}></input></td>
                              </tr>
                              <tr>
                                  <th>룸미러</th>
                                  <td><input type="text" name="int_room_mirror" value={trim.trimItem.int_room_mirror} onChange={e => this.infoSearchValueByTrimItem(e )}></input></td>
                              </tr>
                              <tr>
                                  <th>도어포켓 라이트</th>
                                  <td><input type="text" name="int_door_light" value={trim.trimItem.int_door_light} onChange={e => this.infoSearchValueByTrimItem(e )}></input></td>
                              </tr>
                              <tr>
                                  <th>뒷좌석 측면커튼</th>
                                  <td><input type="text" name="int_rear_side_curtain" value={trim.trimItem.int_rear_side_curtain} onChange={e => this.infoSearchValueByTrimItem(e )}></input></td>
                              </tr>
                              <tr>
                                  <th>뒷좌석 후면커튼</th>
                                  <td><input type="text" name="int_rear_rear_curtaion" value={trim.trimItem.int_rear_rear_curtaion} onChange={e => this.infoSearchValueByTrimItem(e )}></input></td>
                              </tr>
                              <tr>
                                  <th>에어백</th>
                                  <td><input type="text" name="sft_airbag" value={trim.trimItem.sft_airbag} onChange={e => this.infoSearchValueByTrimItem(e )}></input></td>
                              </tr>
                              <tr>
                                  <th>주행안전</th>
                                  <td><input type="text" name="sft_drive" value={trim.trimItem.sft_drive} onChange={e => this.infoSearchValueByTrimItem(e )}></input></td>
                              </tr>
                              <tr>
                                  <th>안전벨트</th>
                                  <td><input type="text" name="sft_belt" value={trim.trimItem.sft_belt} onChange={e => this.infoSearchValueByTrimItem(e )}></input></td>
                              </tr>
                              <tr>
                                  <th>보행자 안전</th>
                                  <td><input type="text" name="sft_pedestrian" value={trim.trimItem.sft_pedestrian} onChange={e => this.infoSearchValueByTrimItem(e )}></input></td>
                              </tr>
                          </tbody>
                      </table>

                      <table className="resources_table">
                      <colgroup>
                          <col width="50%"></col>
                          <col width="50%"></col>
                      </colgroup>
                      <tbody>
                          <tr>
                              <th>후반충격 저감 시스템</th>
                              <td><input type="text" name="sft_reduce_rear_impact" value={trim.trimItem.sft_reduce_rear_impact} onChange={e => this.infoSearchValueByTrimItem(e )}></input></td>
                          </tr>
                          <tr>
                              <th>주행모드변경</th>
                              <td><input type="text" name="fac_change_mode" value={trim.trimItem.fac_change_mode} onChange={e => this.infoSearchValueByTrimItem(e )}></input></td>
                          </tr>
                          <tr>
                              <th>정속주행</th>
                              <td><input type="text" name="fac_fix_speed" value={trim.trimItem.fac_fix_speed} onChange={e => this.infoSearchValueByTrimItem(e )}></input></td>
                          </tr>
                          <tr>
                              <th>주차 브레이크</th>
                              <td><input type="text" name="fac_break" value={trim.trimItem.fac_break} onChange={e => this.infoSearchValueByTrimItem(e )}></input></td>
                          </tr>
                          <tr>
                              <th>엔진시동</th>
                              <td><input type="text" name="fac_engine_start" value={trim.trimItem.fac_engine_start} onChange={e => this.infoSearchValueByTrimItem(e )}></input></td>
                          </tr>
                          <tr>
                              <th>트렁크</th>
                              <td><input type="text" name="fac_trunk" value={trim.trimItem.fac_trunk} onChange={e => this.infoSearchValueByTrimItem(e )}></input></td>
                          </tr>
                          <tr>
                              <th>파워 아웃렛</th>
                              <td><input type="text" name="fac_power_outlet" value={trim.trimItem.fac_power_outlet} onChange={e => this.infoSearchValueByTrimItem(e )}></input></td>
                          </tr>
                          <tr>
                              <th>주차보조</th>
                              <td><input type="text" name="fac_parking" value={trim.trimItem.fac_parking} onChange={e => this.infoSearchValueByTrimItem(e )}></input></td>
                          </tr>
                          <tr>
                              <th>시트배열</th>
                              <td><input type="text" name="sht_sequence" value={trim.trimItem.sht_sequence} onChange={e => this.infoSearchValueByTrimItem(e )}></input></td>
                          </tr>
                          <tr>
                              <th>시트재질</th>
                              <td><input type="text" name="sht_type" value={trim.trimItem.sht_type} onChange={e => this.infoSearchValueByTrimItem(e )}></input></td>
                          </tr>
                          <tr>
                              <th>운전석</th>
                              <td><input type="text" name="sht_driver" value={trim.trimItem.sht_driver} onChange={e => this.infoSearchValueByTrimItem(e )}></input></td>
                          </tr>
                          <tr>
                              <th>동승석</th>
                              <td><input type="text" name="sht_passenger" value={trim.trimItem.sht_passenger} onChange={e => this.infoSearchValueByTrimItem(e )}></input></td>
                          </tr>
                          <tr>
                              <th>2열</th>
                              <td><input type="text" name="sht_row2" value={trim.trimItem.sht_row2} onChange={e => this.infoSearchValueByTrimItem(e )}></input></td>
                          </tr>
                          <tr>
                              <th>3열</th>
                              <td><input type="text" name="sht_row3" value={trim.trimItem.sht_row3} onChange={e => this.infoSearchValueByTrimItem(e )}></input></td>
                          </tr>
                          <tr>
                              <th>에어컨</th>
                              <td><input type="text" name="acn_aircon" value={trim.trimItem.acn_aircon} onChange={e => this.infoSearchValueByTrimItem(e )}></input></td>
                          </tr>
                          <tr>
                              <th>온도조절 범위</th>
                              <td><input type="text" name="acn_temp_range" value={trim.trimItem.acn_temp_range} onChange={e => this.infoSearchValueByTrimItem(e )}></input></td>
                          </tr>
                          <tr>
                              <th>뒷좌석 송풍구</th>
                              <td><input type="text" name="acn_rear" value={trim.trimItem.acn_rear} onChange={e => this.infoSearchValueByTrimItem(e )}></input></td>
                          </tr>
                          <tr>
                              <th>공기청정</th>
                              <td><input type="text" name="acn_air_clean" value={trim.trimItem.acn_air_clean} onChange={e => this.infoSearchValueByTrimItem(e )}></input></td>
                          </tr>
                          <tr>
                              <th>화면크기 (인치)</th>
                              <td><input type="text" name="mul_monitor_size" value={trim.trimItem.mul_monitor_size} onChange={e => this.infoSearchValueByTrimItem(e )}></input></td>
                          </tr>
                          <tr>
                              <th>주요기능</th>
                              <td><input type="text" name="mul_functions" value={trim.trimItem.mul_functions} onChange={e => this.infoSearchValueByTrimItem(e )}></input></td>
                          </tr>
                          <tr>
                              <th>부가기능</th>
                              <td><input type="text" name="mul_extra_functions" value={trim.trimItem.mul_extra_functions} onChange={e => this.infoSearchValueByTrimItem(e )}></input></td>
                          </tr>
                          <tr>
                              <th>인터페이스</th>
                              <td><input type="text" name="mul_interface" value={trim.trimItem.mul_interface} onChange={e => this.infoSearchValueByTrimItem(e )}></input></td>
                          </tr>
                          <tr>
                              <th>스피커</th>
                              <td><input type="text" name="mul_speaker" value={trim.trimItem.mul_speaker} onChange={e => this.infoSearchValueByTrimItem(e )}></input></td>
                          </tr>
                          <tr>
                              <th>사운드시스템</th>
                              <td><input type="text" name="mul_sound_system" value={trim.trimItem.mul_sound_system} onChange={e => this.infoSearchValueByTrimItem(e )}></input></td>
                          </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="centerT mt10 mb10 dpb">
                    <Link to="#"><button type="button" className="btnLine navyBg disb" onClick={() => this.closePop()}>저장</button></Link>
                  </div>
                </div>
                </Fragment>
                </div>

                <div className="floatR disi mt30">
                    <Link to="#"><button type="button" className="btnLine navyBg" onClick={() => this.pageMove(3)}>이전</button></Link>
                    <Link to="#"><button type="button" className="btnLine navyBg ml10" onClick={this.onSaveAtTrim}>저장</button></Link>
                </div>
                </>
                }

            </Fragment>
        );
    }
}

export default VehicleAdd;
