import React, {createContext, useContext, useReducer} from 'react';
import {cloneDeep, pick, keys, isNull, isNumber, isEmpty} from 'lodash';
import moment from "moment";

export const emptyTrim = {
  id: null,
  name: '',
  lineup_id: '',
  option: '',
  selected_lineup: '',
  price: '',
  is_active: '',
  price_options: [{name: '', price: '', description: ''}],
  sales_conditions: [
    {
      year: moment().format('YYYY'),
      month: moment().format('MM'),
      name: '',
      items: [{name: '', price: '', price_rate: ''}]
    }
  ],
  trim_spec: {
    engine_type: '',
    fuel: '',
    displacement: '',
    engine_max_out: '',
    engine_max_torque: '',
    idle_stop_go: '',
    eco_friendly: '',
    battery_type: '',
    battery_voltage: '',
    battery_capacity: '',
    charging_capacity: '',
    motor_max_out: '',
    motor_max_torque: '',
    charging_type: '',
    charging_hour: '',
    quick_charging_type: '',
    quick_charging_min: '',
    wheel_drive_type: '',
    gearbox: '',
    suspension_front: '',
    suspension_rear: '',
    break_front: '',
    break_rear: '',
    tire_front: '',
    tire_rear: '',
    wheel_front: '',
    weel_rear: '',
    efficiency: '',
    efficiency_city: '',
    efficiency_high: '',
    co2_emission: '',
    energy_consumption: '',
    efficiency_e: '',
    efficiency_e_city: '',
    efficiency_e_high: '',
    mileage: '',
    full_length: '',
    full_width: '',
    full_height: '',
    wheel_base: '',
    wheel_track_front: '',
    wheel_track_rear: '',
    overhang_front: '',
    overhang_rear: '',
    quota: '',
    empty_weight: '',
    tank_volume: '',
    cargo_width: '',
    cargo_area: '',
    cargo_height: '',
    zero_to_max: '',
    max_velocity: '',
  },
  trim_item: {
    ext_fog_lamp_front: '',
    ext_fog_lamp: '',
    ext_head_lamp: '',
    ext_rear_lamp: '',
    ext_roof: '',
    ext_door_light: '',
    ext_out_mirror: '',
    ext_wiper: '',
    ext_uv_glass: '',
    ext_no_water_glass: '',
    ext_no_sound_glass: '',
    ext_muffler_tip: '',
    int_dashboard: '',
    int_power_window: '',
    int_safty_power_window: '',
    int_steering_wheel: '',
    int_gear_knob: '',
    int_room_mirror: '',
    int_door_light: '',
    int_rear_side_curtain: '',
    int_rear_rear_curtaion: '',
    sft_airbag: '',
    sft_drive: '',
    sft_belt: '',
    sft_pedestrian: '',
    sft_reduce_rear_impact: '',
    fac_change_mode: '',
    fac_fix_speed: '',
    fac_break: '',
    fac_engine_start: '',
    fac_trunk: '',
    fac_power_outlet: '',
    fac_parking: '',
    sht_sequence: '',
    sht_type: '',
    sht_driver: '',
    sht_passenger: '',
    sht_row2: '',
    sht_row3: '',
    acn_aircon: '',
    acn_temp_range: '',
    acn_rear: '',
    acn_air_clean: '',
    mul_monitor_size: '',
    mul_functions: '',
    mul_extra_functions: '',
    mul_interface: '',
    mul_speaker: '',
    mul_sound_system: ''
  },
  save_helper: {
    is_valid: true,
    message: ''
  }
};

export const trimSpecMapper = {
  engine_type: '엔진형식',
  fuel: '연료',
  displacement: '배기량(cc)',
  engine_max_out: '최고출력(ps/rpm)',
  engine_max_torque: '최대토크(kg.m/rpm)',
  idle_stop_go: '공회전제한장치',
  eco_friendly: '친환경',
  battery_type: '배터리종류',
  battery_voltage: '배터리전압(V)',
  battery_capacity: '배터리용량(Ah)',
  charging_capacity: '충전용량(kWh)',
  motor_max_out: '모터최고출력(ps)',
  motor_max_torque: '모터최대토크(kg.m)',
  charging_type: '충전방식(완속)',
  charging_hour: '충전시간(완속)(시간)',
  quick_charging_type: '충전방식(급속)',
  quick_charging_min: '충전시간(급속)(분)',
  wheel_drive_type: '굴림방식',
  gearbox: '변속기',
  suspension_front: '서스펜션(전)',
  suspension_rear: '서스펜션(후)',
  break_front: '브레이크(전)',
  break_rear: '브레이크(후)',
  tire_front: '타이어(전)',
  tire_rear: '타이어(후)',
  wheel_front: '휠(전)(인치)',
  weel_rear: '휠(후)(인치)',
  efficiency: '복합연비(km/l)',
  efficiency_city: '도심연비(km/l)',
  efficiency_high: '고속연비(km/l)',
  co2_emission: 'CO2배출(g/km)',
  energy_consumption: '에너지소비효율(등급)',
  efficiency_e: '복합연비(전기)(km/kWh)',
  efficiency_e_city: '도심연비(전기)(km/kWh)',
  efficiency_e_high: '고속연비(전기)(km/kWh)',
  mileage: '항속거리(km)',
  full_length: '전장(mm)',
  full_width: '전폭(mm)',
  full_height: '전고(mm)',
  wheel_base: '축거(mm)',
  wheel_track_front: '윤거(전)(mm)',
  wheel_track_rear: '윤거(후)(mm)',
  overhang_front: '오버행(전)(mm)',
  overhang_rear: '오버행(후)(mm)',
  quota: '승차정원',
  empty_weight: '공차중량(kg)',
  tank_volume: '연료탱크(l)',
  cargo_width: '적재함길이(mm)',
  cargo_area: '적재함높이(mm)',
  cargo_height: '적재량(kg)',
  zero_to_max: '제로백(초)',
  max_velocity: '최고속도(km/h)',
};

export const trimItemMapper = {
  ext_fog_lamp_front: '(외관)전방 안개등',
  ext_fog_lamp: '(외관)주간 안개등',
  ext_head_lamp: '(외관)헤드 램프',
  ext_rear_lamp: '(외관)리어 램프',
  ext_roof: '(외관)루프',
  ext_door_light: '(외관)도어포켓 라이트 ',
  ext_out_mirror: '(외관)아웃 사이드미러',
  ext_wiper: '(외관)와이퍼',
  ext_uv_glass: '(외관)자외선 차단유리',
  ext_no_water_glass: '(외관)발수 유리',
  ext_no_sound_glass: '(외관)차음 유리',
  ext_muffler_tip: '(외관)머플러팁 형태',
  int_dashboard: '(내관)계기판',
  int_power_window: '(내관)파워윈도우',
  int_safty_power_window: '(내관)세이프티 파워윈도우',
  int_steering_wheel: '(내관)스티어링 휠',
  int_gear_knob: '(내관)기어 노브',
  int_room_mirror: '(내관)룸미러',
  int_door_light: '(내관)도어포켓 라이트',
  int_rear_side_curtain: '(내관)뒷좌석 측면커튼',
  int_rear_rear_curtaion: '(내관)뒷자석 후면커튼',
  sft_airbag: '(안전)에어백',
  sft_drive: '(안전)주행안전',
  sft_belt: '(안전)안전벨트',
  sft_pedestrian: '(안전)보행자 안전',
  sft_reduce_rear_impact: '(안전)후방충격 저감 시스템',
  fac_change_mode: '(편의)주행모드변경',
  fac_fix_speed: '(편의)정속주행',
  fac_break: '(편의)주차 브레이크',
  fac_engine_start: '(편의)엔진시동',
  fac_trunk: '(편의)트렁크',
  fac_power_outlet: '(편의)파워 아웃렛',
  fac_parking: '(편의)주차보조',
  sht_sequence: '(시트)시트배열',
  sht_type: '(시트)시트재질',
  sht_driver: '(시트)운전석',
  sht_passenger: '(시트)동승석',
  sht_row2: '(시트)2열',
  sht_row3: '(시트)3열',
  acn_aircon: '(에어컨)에어컨',
  acn_temp_range: '(에어컨)온도조절 범위',
  acn_rear: '(에어컨)뒷좌석 송풍구',
  acn_air_clean: '(에어컨)공기청정',
  mul_monitor_size: '(멀티미디어)화면크기(인치)',
  mul_functions: '(멀티미디어)멀티미디어 주요기능',
  mul_extra_functions: '(멀티미디어)멀티미디어 부가기능',
  mul_interface: '(멀티미디어)멀티미디어 인터페이스',
  mul_speaker: '(멀티미디어)스피커',
  mul_sound_system: '(멀티미디어)사운드시스템'
};

const initState = [emptyTrim];

const TrimStateContext = createContext(null);
const TrimDispatchContext = createContext(null);

function pushAfterIndex(originArr, index, item) {
  const arr = cloneDeep(originArr);
  const newItem = cloneDeep(item);
  return [...arr.slice(0, index + 1), newItem, ...arr.slice(index + 1)]
}

function replaceOnIndex(originArr, index, item) {
  const arr = cloneDeep(originArr);
  const newItem = cloneDeep(item);
  return [...arr.slice(0, index), newItem, ...arr.slice(index + 1)]
}

function popOnIndex(originArr, targetIndex) {
  const arr = cloneDeep(originArr);
  return arr.filter((item, index) => index !== targetIndex)
}

export function trimToTrimState(trim) {
  const pickedTrim = pick(trim, keys(emptyTrim));
  pickedTrim.is_active = pickedTrim.is_active ? "1" : "0";

  if (isNumber(pickedTrim.price)) {
    pickedTrim.price = String(pickedTrim.price)
  }

  if (isNull(pickedTrim.option)) {
    pickedTrim.option = emptyTrim.option
  }

  if (isNull(pickedTrim.sales_conditions)) {
    pickedTrim.sales_conditions = emptyTrim.sales_conditions
  } else {
    pickedTrim.sales_conditions.forEach((sc, i) => {
      delete pickedTrim.sales_conditions[i].id;
      delete pickedTrim.sales_conditions[i].trim_id;
      delete pickedTrim.sales_conditions[i].created_at;
      delete pickedTrim.sales_conditions[i].updated_at;
      pickedTrim.sales_conditions[i].description = isEmpty(sc.description) ? '' : sc.description;

      sc.items.forEach((item, j) => {
        pickedTrim.sales_conditions[i].items[j].price = isNumber(item.price) ? String(item.price) : '';
        pickedTrim.sales_conditions[i].items[j].price_rate = isNumber(item.price_rate) ? String(item.price_rate) : '';
        pickedTrim.sales_conditions[i].items[j].description = isEmpty(item.description) ? '' : item.description;

        delete pickedTrim.sales_conditions[i].items[j].id;
        delete pickedTrim.sales_conditions[i].items[j].trim_sales_condition_id;
        delete pickedTrim.sales_conditions[i].items[j].created_at;
        delete pickedTrim.sales_conditions[i].items[j].updated_at;
      })
    })
  }

  if (isNull(pickedTrim.price_options)) {
    pickedTrim.price_options = emptyTrim.price_options
  } else {
    pickedTrim.price_options.forEach((po, i) => {
      pickedTrim.price_options[i].price = isNumber(po.price) ? String(po.price) : '';
      delete pickedTrim.price_options[i].id;
      delete pickedTrim.price_options[i].brand_id;
      delete pickedTrim.price_options[i].trim_id;
      delete pickedTrim.price_options[i].created_at;
      delete pickedTrim.price_options[i].updated_at;
    })
  }

  if (isNull(pickedTrim.trim_item)) {
    pickedTrim.trim_item = emptyTrim.trim_item
  } else {
    for (let key in pickedTrim.trim_item) {
      pickedTrim.trim_item[key] = isEmpty(pickedTrim.trim_item[key]) ? '' : pickedTrim.trim_item[key];
    }

    delete pickedTrim.trim_item.id;
    delete pickedTrim.trim_item.trim_id;
    delete pickedTrim.trim_item.created_at;
    delete pickedTrim.trim_item.updated_at;
  }

  if (isNull(pickedTrim.trim_spec)) {
    pickedTrim.trim_spec = emptyTrim.trim_spec
  } else {
    for (let key in pickedTrim.trim_spec) {
      pickedTrim.trim_spec[key] = isEmpty(pickedTrim.trim_spec[key]) ? '' : pickedTrim.trim_spec[key];
    }

    delete pickedTrim.trim_spec.id;
    delete pickedTrim.trim_spec.trim_id;
    delete pickedTrim.trim_spec.created_at;
    delete pickedTrim.trim_spec.updated_at;
  }

  pickedTrim.save_helper = {is_valid: true, message: ''};

  return [pickedTrim];
}

function trimReducer(state, action) {
  const stateDup = cloneDeep(state);
  const pl = action.payload;
  let scItems;

  switch (action.type) {
    //TRIM
    case 'FLUSH':
      return [];
    case 'SET_TRIM':
      return trimToTrimState(pl.trim);
    case 'REPLACE_TRIM':
      return replaceOnIndex(state, pl.index, pl.trim);
    case 'ADD_TRIM':
      return pushAfterIndex(state, pl.index, emptyTrim);
    case 'COPY_TRIM':
      return pushAfterIndex(state, pl.index, stateDup[pl.index]);
    case 'DELETE_TRIM':
      return popOnIndex(state, pl.index);
    case 'CHANGE_TRIM_INPUT':
      stateDup[pl.index][pl.inputName] = pl.value;
      return stateDup;

    //PRICE_OPTIONS
    case 'ADD_PRICE_OPTION':
      stateDup[pl.trimIndex].price_options = pushAfterIndex(stateDup[pl.trimIndex].price_options, pl.priceOptionIndex, emptyTrim.price_options[0]);
      return stateDup;
    case 'DELETE_PRICE_OPTION':
      stateDup[pl.trimIndex].price_options = popOnIndex(stateDup[pl.trimIndex].price_options, pl.priceOptionIndex);
      return stateDup;
    case 'CHANGE_PRICE_OPTION_INPUT':
      stateDup[pl.trimIndex].price_options[pl.priceOptionIndex][pl.inputName] = pl.value;
      return stateDup;

    //SALES_CONDITIONS
    case 'ADD_SALES_CONDITION':
      stateDup[pl.trimIndex].sales_conditions = pushAfterIndex(stateDup[pl.trimIndex].sales_conditions, pl.salesConditionIndex, emptyTrim.sales_conditions[0]);
      return stateDup;
    case 'DELETE_SALES_CONDITION':
      stateDup[pl.trimIndex].sales_conditions = popOnIndex(stateDup[pl.trimIndex].sales_conditions, pl.salesConditionIndex);
      return stateDup;
    case 'CHANGE_SALES_CONDITION_INPUT':
      stateDup[pl.trimIndex].sales_conditions[pl.salesConditionIndex][pl.inputName] = pl.value;
      return stateDup;
    case 'ADD_SALES_CONDITION_ITEM':
      scItems = stateDup[pl.trimIndex].sales_conditions[pl.salesConditionIndex].items;
      stateDup[pl.trimIndex].sales_conditions[pl.salesConditionIndex].items = pushAfterIndex(scItems, pl.scItemIndex, emptyTrim.sales_conditions[0]);
      return stateDup;
    case 'DELETE_SALES_CONDITION_ITEM':
      scItems = stateDup[pl.trimIndex].sales_conditions[pl.salesConditionIndex].items;
      if (scItems.length > 1) {
        stateDup[pl.trimIndex].sales_conditions[pl.salesConditionIndex].items = popOnIndex(scItems, pl.scItemIndex);
      } else {
        alert('더 이상 지울 수 없습니다.');
        return state;
      }
      return stateDup;
    case 'CHANGE_SALES_CONDITION_ITEM_INPUT':
      scItems = stateDup[pl.trimIndex].sales_conditions[pl.salesConditionIndex].items;
      scItems[pl.scItemIndex][pl.inputName] = pl.value;

      if (pl.inputName === 'price') {
        scItems[pl.scItemIndex]['price_rate'] = '';
      }

      if (pl.inputName === 'price_rate') {
        scItems[pl.scItemIndex]['price'] = '';
      }

      return stateDup;

    //TRIM_ITEM
    case 'CHANGE_TRIM_ITEM_INPUT':
      stateDup[pl.trimIndex].trim_item[pl.inputName] = pl.value;
      return stateDup;

    //TRIM_SPEC
    case 'CHANGE_TRIM_SPEC_INPUT':
      stateDup[pl.trimIndex].trim_spec[pl.inputName] = pl.value;
      return stateDup;

    //VALIDATION
    case 'SET_SAVE_HELPER':
      stateDup[pl.index].save_helper = pl.save_helper;
      return stateDup;

    //UNDEFINED ACTION -> Error
    default:
      throw new Error(`'${action.type}' is not defined`);
  }
}

export const TrimProvider = ({children}) => {
  const [state, dispatch] = useReducer(trimReducer, initState);

  return (
    <TrimStateContext.Provider value={state}>
      <TrimDispatchContext.Provider value={dispatch}>
        {children}
      </TrimDispatchContext.Provider>
    </TrimStateContext.Provider>
  )
};

export function useTrimState() {
  const state = useContext(TrimStateContext);
  return state
}

export function useTrimDispatch() {
  const dispatch = useContext(TrimDispatchContext);
  return dispatch
}
