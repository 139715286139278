import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import Choices from 'choices.js';

import * as Common from '../../component/services/common/CommonService';
import * as Service from '../../component/services/content/MovieDetailService';
import * as mediaService from '../../component/services/content/MediaService';

class MovieDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authToken: Common.getToken.call(this),
      page_history: this.props.location.search.replace(/\?page=/g, '') ? this.props.location.search.replace(/\?page=/g, '') : 1,
      data: {
        uploader: '',
        title: '',
        url: '',
        play_time: '0:0',
        thumbnail: '',
        is_active: true,
        published_at: new Date(),
        revised_at: '',
        brand_id: 0,
        car_model_group_id: 0,
        car_model_id: 0
      },
      brandList: [],
      carModelGroupList: [],
      carModelList: [],
      mediaOption: false,
      thumbnailUrl: ''
    };
    this.mediaRef = React.createRef();
    this.fileRef = React.createRef();
  }

  componentDidMount() {
    Common.getToken.call(this);
    this.choices = new Choices(this.mediaRef.current, {
      searchFields: ['label', 'value'],
      delimiter: ',',
      editItems: true,
      maxItemCount: 2,
      removeItemButton: true,
      duplicateItemsAllowed: false,
      searchEnabled: true,
      searchChoices: true,
      searchFloor: 1,
      shouldSort: false
    });
    // this.getMovieDetail();
    this.getMediaList();
    // console.log(this.mediaRef.current, 'A');

    // 임시, 동영상 상세 api 완성되면 getVideoDetail 만들어서 이동시킬 것.
  }

  componentDidUpdate() {
    // console.log(this.state.data);
  }

    postMovieDetail = () => {
      const { history } = this.props;
      const { authToken, data } = this.state;
      if (!(data.uploader || data.published_at || data.revised_at || data.title || data.url)) {
        alert('필수란을 작성해주세요');
        return;
      }
      const movieDetail = Service.postMovieDetail(authToken, data);
      movieDetail.then((res) => {
        if (!(res.status >= 200 && res.status < 400)) return;
        alert('저장되었습니다!');
        history.push('/contents/movie');
      }).catch((error) => {
        alert(error.response.data.message);
        if (error.response.status === 401) {
          alert('로그인을 해주세요');
          history.push('/login');
        }
      });
    };

    putMovieDetail = () => {
      const { authToken, data } = this.state;
      const { match: { params: { id } }, history } = this.props;
      if (!(data.uploader || data.published_at || data.revised_at || data.title || data.url)) {
        alert('필수란을 작성해주세요');
        return;
      }
      Service.deleteMovieThumnail(authToken, id).then((res) => {
        if (!(res.status >= 200 && res.status < 400)) return;
        const movieDetail = Service.putMovieDetail(authToken, data);
        movieDetail.then((res) => {
          if (!(res.status >= 200 && res.status < 400)) return;
          alert('수정되었습니다!');
        }).catch((error) => {
          alert(error.response.data.message);
          if (error.response.status === 401) {
            alert('로그인을 해주세요');
            history.push('/login');
          }
        });
      });
    }

    getMovieDetail = () => {
      const { match: { params, params: { id } }, history } = this.props;
      const { authToken } = this.state;
      if (params.id) {
        const request = Service.getMovieDetail(authToken, id);
        request.then((res) => {
          if (!(res.status >= 200 && res.status < 400)) return;
          this.setState({
            data: {
              ...res.data.data,
              thumbnail: '',
              published_at: new Date(res.data.data.published_at),
              revised_at: new Date(res.data.data.revised_at)
            },
            thumbnailUrl: res.data.data.thumbnail
          });
          if (params.id) {
            this.getCarModelGroupList(res.data.data.brand_id);
            this.getCarModelSimpleList(res.data.data.brand_id, res.data.data.car_model_group_id);
          }
        }).catch((error) => {
          alert(error.response.data.message);
          if (error.response.status === 401) {
            alert('로그인을 해주세요');
            history.push('/login');
          }
        });
      }
    }

    getBrandSimpleList = () => {
      const { match: { params } } = this.props;
      const { authToken } = this.state;
      const request = Service.getBrandSimpleList(authToken,
        {
          is_active: true
        });
      request.then((res) => {
        this.setState({
          brandList: res.data.data
        }, () => {
          if (params.id) return;
          this.getCarModelGroupList(res.data.data[0].id);
          this.getCarModelSimpleList(res.data.data[0].id, 0);
        });
      });
    }

    getCarModelGroupList = (brandId) => {
      const { authToken, data } = this.state;
      const request = Service.getCarModelGroupList(authToken,
        {
          is_active: true,
          brand_id: brandId
        });
      request.then((res) => {
        // if (!brandId) return;
        if (res.data.data.length === 1) {
          this.setState({
            data: {
              ...data,
              brand_id: brandId,
              car_model_group_id: res.data.data[0].id
            },
            carModelList: res.data.data
          }, () => {
            this.getCarModelSimpleList(brandId, res.data.data[0].id);
          });
        }
        this.setState({
          // data: {
          //   ...data,
          //   brand_id: brandId
          // },
          carModelGroupList: res.data.data
        });
      });
    }

    getCarModelSimpleList = (brandId, carModelGroupId) => {
      const { authToken, data } = this.state;
      const request = Service.getCarModelSimpleList(authToken,
        {
          is_active: true,
          brand_id: brandId,
          car_model_group_id: carModelGroupId
        });
      request.then((res) => {
        if (res.data.data.length === 1) {
          this.setState({
            data: {
              ...data,
              brand_id: brandId,
              car_model_group_id: carModelGroupId,
              car_model_id: res.data.data[0].id
            },
            carModelList: res.data.data
          });
        } else {
          this.setState({
            carModelList: res.data.data
          });
        }
      });
    }

    getMediaList = () => {
      const { authToken } = this.state;
      const { match: { params }, history } = this.props;
      const mediaList = mediaService.getMediaList(authToken);
      mediaList.then((res) => {
        if (res.data.data.filter((mediadata) => mediadata.category === '동영상').length === 0 || !params.id) {
          this.setState({
            mediaOption: true
          });
          this.choices.setValue([
            ...res.data.data.map((item) => (item.category === '동영상' ? { id: item.id, value: item.name } : {}
            )), { value: '직접입력', label: '직접입력' }
          ]);
        }
        if (res.data.data.filter((mediadata) => mediadata.category === '동영상').length === 0 || params.id) {
          this.choices.setValue([{ value: '직접입력', label: '직접입력' },
            ...res.data.data.map((item) => (item.category === '동영상' ? { id: item.id, value: item.name } : {}
            ))
          ]);
        }
        // this.choices.setValue(
        //   params.id ? { id: data.id, value: data.uploader, selected: !!params.id } : {}
        // );
        this.getMovieDetail();
        this.getBrandSimpleList();
      }).catch((error) => {
        alert(error.response.data.message);
        if (error.response.status === 401) {
          alert('로그인을 해주세요');
          history.push('/login');
        }
      });
    };

    formatSecond = (second) => {
      let str = '';
      str = `0${second}`.slice(-2);
      return str;
    }

    getYoutubeData = (e) => {
      const { data } = this.state;
      e.preventDefault();
      if (!data.url) {
        // 임시
        alert('url을 입력해주세요');
        return;
      }
      const id = this.extractYoutubeID(data.url);
      Service.getYoutubeData(id).then((res) => {
        const value = res.data.items[0].contentDetails.duration.match(/^P(\d+M)?(\d+W)?(\d+D)?(T(\d+H)?(\d+M)?(\d+S)?)?$/);
        const hour = (value[5] || '').replace('H', '');
        const minute = (value[6] || '00').replace('M', '');
        const second = (value[7] || '00').replace('S', '');
        this.setState({
          thumbnailUrl: res.data.items[0].snippet.thumbnails.maxres.url
        });
        this.setState({
          data: {
            ...data,
            play_time: `${hour || ''}${hour ? ':' : ''}${minute}${minute ? ':' : ''}${this.formatSecond(second)}`,
            thumbnail: ''
          }
        });
        this.fileRef.current.value = '';
      }).catch((err) => alert(err));
    }

    // getPlayTime = (pt) => {
    //   const { data } = this.state;
    //   const value = pt.match(/^P(\d+M)?(\d+W)?(\d+D)?(T(\d+H)?(\d+M)?(\d+S)?)?$/);
    //   // const month = value[1].replace('M', '');
    //   // const week = value[2].replace('W', '');
    //   // const day = value[3].replace('D', '');
    //   const hour = (value[5] || '00').replace('H', '');
    //   const minute = (value[6] || '00').replace('M', '');
    //   const second = (value[7] || '00').replace('S', '');

    //   this.setState({
    //     data: {
    //       ...data,
    //       play_time: `${hour || ''}:${minute}:${second}`
    //     }
    //   });
    // }


    handleChange = (e) => {
      const { data } = this.state;
      this.setState({
        data: {
          ...data,
          [e.target.name]: e.target.value
        }
      });
    };

    handleStartDateChange = (date) => {
      const { data } = this.state;
      this.setState({
        data: {
          ...data,
          published_at: date || ''
        }
      });
    }

    handleEndDateChange = (date) => {
      const { data } = this.state;
      this.setState({
        data: {
          ...data,
          revised_at: date || ''
        }
      });
    }

    uploadImage = (e) => {
      const { data } = this.state;
      this.setState({
        data: {
          ...data,
          thumbnail: e.target.files[0]
        }
      });
      if (!e.target.files[0]) return;
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = () => {
        this.setState({
          thumbnailUrl: reader.result
        });
      };
    };

    handleMediaOptionChange = (e) => {
      const { data } = this.state;
      if (e.target.value === '직접입력') {
        this.setState({
          mediaOption: true,
          data: {
            ...data,
            uploader: ''
          }
        });
      } else {
        this.setState({
          mediaOption: false,
          data: {
            ...data,
            uploader: e.target.value
          }
        });
      }
    };

    handleActiveChange = (e) => {
      const { data } = this.state;
      return e.target.id === 'veiw_vdo_ok' ? this.setState({
        data: {
          ...data,
          is_active: true
        }
      }) : this.setState({
        data: {
          ...data,
          is_active: false
        }
      });
    }

    handleModelChange = (e) => {
      const { data } = this.state;
      switch (e.target.name) {
        case 'brand_id':
          this.getCarModelGroupList(+e.target.value);
          this.getCarModelSimpleList(+e.target.value);
          break;
        case 'car_model_group_id':
          // this.getCarModelGroupList(data.brand_id);
          this.getCarModelSimpleList(data.brand_id, +e.target.value);
          break;
        case 'car_model_id':
          // this.getCarModelGroupList(data.brand_id);
          // this.getCarModelSimpleList(data.brand_id, data.car_model_group_id);
          break;

        default: return null;
      }
      this.setState({
        data: {
          ...data,
          [e.target.name]: +e.target.value
        }
      });
      return null;
    }

    inputMediaOption = (e) => {
      const { data } = this.state;
      this.setState({
        data: {
          ...data,
          uploader: e.target.value
        }
      });
    }

    extractYoutubeID = (url) => {
      const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      const match = url.match(regExp);
      let result = '';
      if (match && match[7].length === 11) {
        // eslint-disable-next-line prefer-destructuring
        result = match[7];
      }
      return result;
    }
    // componentWillUnmount() {
    //     this.choices.destroy();
    // }

    render() {
      const { match: { params } } = this.props;
      const {
        data,
        mediaOption,
        thumbnailUrl,
        brandList,
        carModelGroupList,
        carModelList,
        page_history
      } = this.state;
      return (
        <>

          <div className="title disi wd100">
            <p className="mb10">
              <span>홈</span>
              {' '}
                〉
              {' '}
              <span>콘텐츠</span>
              {' '}
                〉
              {' '}
              <span>동영상등록/관리</span>
              {' '}
                〉
              {' '}
              <span>등록/수정</span>
            </p>
            동영상등록/관리 등록/수정
            <div className="floatR disi">
              <button
                type="button" className="btnLine navyBg"
                onClick={params.id ? this.putMovieDetail : this.postMovieDetail}
              >
                  저장
              </button>
              <button type="button" className="btn navyBg ml10" onClick={() => this.props.history.goBack()}>목록</button>
          </div>
          </div>
          <form>

            <table className="mt40 mda_reset_table">
              <colgroup>
                <col width="12%" />
                <col width="" />
                <col width="12%" />
                <col width="" />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    매체명
                    {' '}
                    <span className="redT">*</span>
                  </th>
                  <td colSpan="3">
                    <select onChange={this.handleMediaOptionChange} name="uploader" ref={this.mediaRef}>
                      {/* <option>0</option> */}
                    </select>
                    {mediaOption ? <input type="text" onChange={this.inputMediaOption} value={data.uploader} /> : ''}

                  </td>
                </tr>
                <tr>
                  <th>
                    등록일
                    {' '}
                    <span className="redT">*</span>
                  </th>
                  <td>
                    <DatePicker
                      onChange={this.handleStartDateChange}
                      selected={data.published_at}
                      disabled={params.id}
                    />
                  </td>
                  <th>
                    수정일
                    {' '}
                    <span className="redT">*</span>
                  </th>
                  <td>
                    <DatePicker
                      onChange={this.handleEndDateChange}
                      selected={data.revised_at}
                    />

                  </td>
                </tr>
                <tr>
                  <th>브랜드/모델그룹/모델검색</th>
                  <td colSpan="3">
                    <select name="brand_id" onChange={this.handleModelChange} value={0 || data.brand_id}>
                      <option key={'brand-0'} value={0}>전체</option>
                      {brandList.map((brand) => (
                        <option key={brand.id} value={brand.id}>{brand.name}</option>
                      ))}
                    </select>
                    <select className="ml10" name="car_model_group_id" onChange={this.handleModelChange} value={0 || data.car_model_group_id}>
                      <option key={'modelGroup-0'} value={0}>전체</option>
                      {carModelGroupList.map((modelGroup) => (
                        <option key={modelGroup.id} value={modelGroup.id}>{modelGroup.name}</option>
                      ))}
                    </select>
                    <select className="ml10" name="car_model_id" onChange={this.handleModelChange} value={0 || data.car_model_id}>
                      <option key={'model-0'} value={0}>전체</option>
                      {carModelList.map((model) => (
                        <option key={model.id} value={model.id}>{model.name}</option>
                      ))}
                    </select>
                  </td>
                </tr>
                <tr>
                  <th>
                    콘텐츠 제목
                    {' '}
                    <span className="redT">*</span>
                  </th>
                  <td colSpan="3"><input type="text" name="title" onChange={this.handleChange} value={data.title} /></td>
                </tr>
                <tr>
                  <th>
                    동영상 링크
                    {' '}
                    <span className="redT">*</span>
                  </th>
                  <td colSpan="3">
                    <input type="text" name="url" onChange={this.handleChange} value={data.url} />
                    <button type="button" className="btnLine navyBg ml10 b_btn" onClick={this.getYoutubeData}>썸네일 가져오기</button>
                  </td>
                </tr>
                <tr>
                  <th>썸네일 이미지 지정</th>
                  <td colSpan="3">
                  <div className="file_wrap">
                    <input type="file" id="line_img"></input>
                    <input type="text" readOnly className="file_input"></input>
                    <label htmlFor="line_img" className="file_btn">업로드</label>
                  </div>
                  <div className="up_thumb disi">
                    {thumbnailUrl || data.thumbnail ? <img src={thumbnailUrl || data.thumbnail} alt="thumbnail" width="210px" height="118px" /> : ''}
                  </div>
                  </td>
                </tr>
                <tr>
                  <th>
                  공개여부
                    {' '}
                    <span className="redT">*</span>
                  </th>
                  <td colSpan="3">
                    <input
                      type="radio"
                      name="veiw_vdo"
                      id="veiw_vdo_ok"
                      onChange={this.handleActiveChange}
                      checked={data.is_active}
                    />
                    <label htmlFor="veiw_vdo_ok"> 공개</label>
                    <input
                      type="radio"
                      name="veiw_vdo"
                      id="veiw_vdo_no"
                      className="ml10"
                      onChange={this.handleActiveChange}
                      checked={!data.is_active}
                    />
                    <label htmlFor="veiw_vdo_no"> 비공개</label>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
          <div className="floatR mt10">
              <button
                type="button" className="btnLine navyBg"
                onClick={params.id ? this.putMovieDetail : this.postMovieDetail}
              >
                  저장
              </button>
              <button type="button" className="btn navyBg ml10" onClick={() => this.props.history.goBack()}>목록</button>
          </div>          
        </>
      );
    }
}

export default MovieDetail;
