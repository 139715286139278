import React from 'react';
import {isEmpty} from 'lodash';
import moment from 'moment';

const PriorityTable = ({data, isForCarModel, friendlyName}) => {
    const tableHeaderText = (isEmpty(data.data)) ? '등록된 우선순위가 없습니다.' : `최신 순위 : ${moment(data.created_at).format('YYYY-MM-DD HH시 mm분 ss초').toString()}`;

    return (
        <>
            <div className='uk-alert uk-margin-remove-bottom'>
                {tableHeaderText}
            </div>
            <table className='uk-table uk-table-hover uk-table-middle uk-margin-remove-top'>
                <thead>
                <tr>
                    <th className='uk-text-center'>순위</th>
                    {isForCarModel && <th className='uk-text-center'>국가</th>}
                    <th className='uk-text-center'>{friendlyName}명</th>
                </tr>
                </thead>

                <tbody>
                {
                    data.data && data.data.map((object, index) => {
                        return (
                            <tr key={index}>
                                <td className='uk-text-center'>{index + 1}위</td>
                                {isForCarModel &&
                                <td className='uk-text-center'>{object.is_imported ? '수입' : '국내'}</td>}
                                <td className='uk-text-center'>[{isForCarModel ? object.car_model_id : object.id}] {isForCarModel ? object.car_model_name : object.title }</td>

                            </tr>
                        )
                    })
                }
                </tbody>
            </table>
        </>
    );
};

export default PriorityTable;
