import React, { memo } from "react";

function ListTableNavigator() {
    return (
        <div className="uk-flex uk-flex-middle uk-flex-nowrap uk-text-nowrap">
            <span>
                <span className="uk-margin-small-right uk-icon-color-success" data-uk-icon="icon: check" />
                발행완료
            </span>
            <span className="uk-margin-small-left">
                <span className="uk-margin-small-right uk-icon-color-danger" data-uk-icon="icon: clock" />
                오늘 발행
            </span>
            <span className="uk-margin-small-left">
                <span className="uk-margin-small-right uk-icon-color-primary" data-uk-icon="icon: future" />
                발행예약
            </span>
        </div>
    );
}

export default memo(ListTableNavigator);
