import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import * as Common from "../../component/services/common/CommonService";
import * as Service from "../../component/services/brand/BrandService";
import * as MGService from '../../component/services/vehicleDB/ModelGroupService'
import * as MService from '../../component/services/vehicleDB/ModelService'
// import $ from 'jquery';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import DatePicker from 'react-datepicker';

class ModelDetail extends Component{

  constructor(props) {
        super(props);
        this.state = {
          authToken: Common.getToken.call(this),
          brandId: 0,
          RivalBrandId : 0,
          isEnabled: "전체",
          per: 8,
          page: this.props.location.search.replace(/\?page=/g, '') ? this.props.location.search.replace(/\?page=/g, '') : 1,
          fetching: false,
          totalInfo: [],
          brands: [],
          modelGroupList : [],
          rivalBrands : [],
          rivalModelGroup : [],
          rivalModels : [],
          popActive:false,             // 팝업창 상태값
          rivalModelGroupId : 0,
          rivalList : [],
          colorCodes : [],
          colorNames : [],
          colorImages : [],
          images : [],
          imagesId : [],
          isSave: false,  // 업로드한 차량포토뷰어 이미지 저장 여부 확인 Flag State
          forceUpdate : true,
          isEdit : false,
          editId : 0,
          nonDriftImages : [],
          colorEdit : [],
          last_at: new Date(),
          synonyms: []
        };

        this.exposure = this.exposure.bind(this);
  }

  SortableItem = SortableElement(({value}) =>
  <div className="popUp del_file_btn btnLine navyBg car_img_btn img_data" data-id={value.id} style={{backgroundImage : 'url(' + value.image_url + ')' , backgroundSize : "cover" , backgroundPosition : 'center'}}>
    <button className="popUp img_delete" onClick={() => {this.deleteImage(value.id)}}>X</button>
  </div>
 );

SortableList = SortableContainer(({items}) => {
 return (
   <div className="popUp car_box_inner">
     {items.map((data , key) => {
       return (
       <this.SortableItem key={`item-${key}`} index={key} value={data}/>
       )
     })}
     <button className="popUp del_file_btn btnLine navyBg car_img_btn" onClick={() => { document.querySelector('#images').click() }}>+</button>

   </div>
 );
});

  deleteImage = (id) => {
    const confirm = window.confirm('삭제 시 복구할 수 없습니다. 정말로 삭제하시겠습니까?');
    if(confirm) {
      let isNonDrift = false;
      const pushData = this.state.images
      for(let x = 0; x<this.state.nonDriftImages.length; x++){
        if(this.state.nonDriftImages[x] == id)
          isNonDrift = true
      }
      for(let x = 0; x<pushData.length; x++){
        if(pushData[x].id == id){
          pushData.splice(x,1)
        }
      }
      this.setState({
        iamges : pushData
      }, () => {
        if(isNonDrift){
          const deleteImage = MService.deleteImage(
              this.state.authToken,
              this.state.editId,
              id
          )
          deleteImage.then(res => {})
        }
        else{
          const deleteDraftImage = MService.deleteDraftImage(
              this.state.authToken,
              id
          )
          deleteDraftImage.then(res => {})
        }
      });
    }
  }

  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState(({images}) => ({
      images: arrayMove(images, oldIndex, newIndex),
    }), () => {
      const { authToken, editId, isEdit, images } = this.state;
      if(isEdit) { // 모델 수정일 경우
        let imageIds = [];
        const formData = new FormData();
        images.forEach((image) => {
          imageIds.push(image.id);
          formData.append('image_order[]', image.id);
        });
        this.setState({
          imageIds
        }, () => {
          MService.putImageOrder(authToken, editId, formData).then((response) => {
          })
        });
      } else {
        this.setState({
          isSave: false
        });
      }
    });
  }

  componentDidMount() {
    // this.getToken();
    this.getTotalInfo();
    this.flushImages();
    //url로 id값이 들어오면 수정모드로 변경
    const id = this.props.location.pathname.split('/')
    if(!isNaN(parseInt(id[id.length-1]))){
      // url 파라미터가 붙었을 때 &가 포함되면 모델그룹에서 모델 추가버튼을 통해 들어옴
      if(id[id.length-1].indexOf('&') !== -1){
        this.setState({
          brandId : id[id.length-1].split('&')[0],
        },() => {
          this.getModelGroup('this',id[id.length-1].split('&')[1])
        })
      }
      // url 파라미터에 &가 없다면 수정모드로 전환
      else{
        this.setState({
          isEdit : true,
          editId : parseInt(id[id.length-1]),
          isSave: true
        }, () => this.getDetail())
      }
    }else{
      document.querySelector('#new_true').checked = true
      document.querySelector('#active_true').checked = true
    }
  }

  componentWillUnmount(){
    this.flushImages();
  }

  infoSearchValue = e => {
    if(e.target.name == 'brandId'){
      if(e.target.value == 0){
        this.setState({
          [e.target.name]: e.target.value,
          modelGroupList : []
        });
      }
      else {
        this.setState({
          [e.target.name]: e.target.value,
        },() => {
          this.getModelGroup('this')
        });
      }
        document.querySelector('#modelGroup').value = 0
    }

    else if(e.target.name == 'RivalBrandId'){
      if(e.target.value == 0){
        this.setState({
          [e.target.name]: e.target.value,
          rivalModelGroup : [],
          rivalModels : []
        });
      }
      else {
        this.setState({
          [e.target.name]: e.target.value,
        },() => {
          this.getModelGroup('rival')
        });
      }
      document.querySelector('#rivalModelGroup').value = 0
      document.querySelector('#rivalModel').value=0
    }

    else {
      if(e.target.value == 0){
        this.setState({
          [e.target.name]: e.target.value,
          rivalModels : []
        });
      }
      else {
        this.setState({
          [e.target.name]: e.target.value,
        },() => {
          this.getModels()
        });
      }
      document.querySelector('#rivalModel').value=0
    }
  };

  getDetail = () => {
    const state = this.state
    const detail = MService.getModelDetail(state.authToken , state.editId)
    detail.then(res => {
      if(res.status >= 200 && res.status < 400){
        const data = res.data.data
        let codes = []
        let names = []
        let images = []
        let colorEdit = []
        let rivalList = []
        //이미지 컬러 리스트 초기화
        for(let x = 0; x<data.car_model_colors.length; x++){
          codes.push('#'+data.car_model_colors[x].color_code)
          names.push(data.car_model_colors[x].name)
          images.push(data.car_model_colors[x].image_url.split('/')[3])
          colorEdit.push(data.car_model_colors[x].id)
        }

        //라이벌 리스트 초기화
        for(let x= 0; x<data.rivals.length; x++){
          const pushData = {
            "value" : data.rivals[x].id,
            "text" : data.rivals[x].name
          }
          rivalList.push(pushData)
        }

        //이미지 컬러 리스트 , 라이벌 리스트 state에 등록
        this.setState({
          brandId : data.brand_id,
          colorCodes : codes,
          colorNames : names,
          colorImages : images,
          rivalList : rivalList,
          colorEdit : colorEdit,
          last_at: data.last_at ? new Date(data.last_at) : new Date(),
          synonyms: data.synonyms || []
        } , () => {
          //수정사항 반영
          document.querySelector('select[name=brandId]').value = data.brand_id
          document.querySelector('#name').value = data.name
          document.querySelector('#codeName').value = data.code_name != 'null' ? data.code_name : ""
          document.querySelector('#new_true').checked = data.is_new_car
          document.querySelector('#new_false').checked = !data.is_new_car
          document.querySelector('#undtr').checked = data.is_preproduct
          document.querySelector('#will').checked = data.is_coming
          data.is_active ? document.querySelector('#active_true').checked = true : document.querySelector('#active_false').checked = true
          document.querySelector('#type').value = data.exterior_type
          this.getModelGroup('this',data.car_model_group_id)
          document.querySelector('select[name=brandId]').disabled = true
          document.querySelector('#modelGroup').disabled = true
        })

        const getCarModelImages = MService.getCarModelImages(
          this.state.authToken,
          data.id
        )

        getCarModelImages.then(res => {
          if(res.status >= 200 && res.status < 400){
            const imgData = res.data.data

            let imgId = []
            for(var x = 0; x<imgData.length; x++){
              imgId.push(imgData[x].id)
            }
            this.setState({
              nonDriftImages : imgId,
              images : imgData
            })
          }
        }).catch(err => console.log('이미지를 불러오는 도중에 문제가 발생하였습니다.'))
      }
    }).catch(err => console.log('요청을 처리하는 도중에 문제가 발생하였습니다.'))
  }

  getModels = () => {
    const data = MService.getModel(this.state.authToken , this.state.rivalModelGroupId , 'list')
    data.then(res => {
      this.setState({
        rivalModels : res.data.data
      })
    })
  }

  getTotalInfo = aysnc => {
    const brandList = Service.getTotalInfo(this.state.authToken);
    brandList.then(response => {
      this.setState({
        totalInfo: response.data.data,
        rivalBrands : response.data.data
      }, () => {
        document.querySelector('select[name=brandId]').value = this.state.brandId
      });
    });
  };

  getModelGroup = (target , editId) => {
    const data = target == 'this' ? MGService.getModelG(this.state.authToken , this.state.brandId) : MGService.getModelG(this.state.authToken , this.state.RivalBrandId)

    data.then(res => {
      if(target == 'this'){
        this.setState({
          modelGroupList : res.data.data
        } , () => {
          document.querySelector('#modelGroup').value = editId
        })
      }else{
        this.setState({
          rivalModelGroup : res.data.data
          ,rivalModels : []
        })
      }

    })
  }

  postModel = () => {
    const state = this.state
    if(document.querySelector('#name').value == ""){
      alert('모델명을 입력해주세요.')
      return false;
    }
    if(state.brandId == 0){
      alert('브랜드를 선택해주세요.')
      return false;
    }
    if(document.querySelector('#modelGroup').value == 0){
      alert('모델그룹을 선택해주세요.')
      return false;
    }

    const POST = MService.postModel(
        state.authToken,                                //인증 토큰
        document.querySelector('#name').value,          //모델명        REQUIRED
        document.querySelector('#codeName').value,      //개발명        ⚠
        document.querySelector('#new_true').checked,    //신차 여부     REQUIRED
        document.querySelector('#undtr').checked,       //미정 여부     REQUIRED
        document.querySelector('#will').checked,        //예정 여부     REQUIRED
        state.brandId,                                  //브랜드ID      REQUIRED
        document.querySelector('#modelGroup').value,    //모델그룹ID    REQUIRED
        document.querySelector('#active_true').checked, //활성화 여부   REQUIRED
        document.querySelector('#type').value,          //외관
        state.colorNames,                               //컬러명 리스트
        state.colorCodes,                               //컬러코드 리스트
        state.colorImages,                              //컬러 이미지 정보 리스트
        state.rivalList,                                //경쟁모델 아이디 리스트
        state.imagesId,                                 //차량포토뷰어 이미지 리스트
        state.last_at,                                 //자동차 출시일,
        state.synonyms                                  //동의어 목록
    );

    POST.then(res => {
      if (res.status >= 200 && res.status < 400) {
        alert('저장되었습니다.');
        this.props.history.push('/vehicleDB/model');
      }
    }).catch(err => alert('요청을 처리하는 도중에 문제가 발생하였습니다.'))
  }

  editModel = () => {
    const state = this.state

    if(document.querySelector('#name').value == ""){
      alert('모델명을 입력해주세요.')
      return false;
    }

    const EDIT = MService.editModel(
      state.authToken,
      state.editId,                                //인증 토큰
      document.querySelector('#name').value,          //모델명        REQUIRED
      document.querySelector('#codeName').value,      //개발명        ⚠
      document.querySelector('#new_true').checked,    //신차 여부     REQUIRED
      document.querySelector('#undtr').checked,       //미정 여부     REQUIRED
      document.querySelector('#will').checked,        //예정 여부     REQUIRED
      state.brandId,                                  //브랜드ID      REQUIRED
      document.querySelector('#modelGroup').value,    //모델그룹ID    REQUIRED
      document.querySelector('#active_true').checked, //활성화 여부   REQUIRED
      document.querySelector('#type').value,          //외관
      state.rivalList,                                //경쟁모델 아이디 리스트
      state.imagesId,                                 //차량포토뷰어 이미지 리스트
        state.last_at,                                 //자동차 출시일
        state.synonyms                                 //동의어 목록
      )

    EDIT.then(res => {
      if (res.status >= 200 && res.status < 400) {
        alert('저장되었습니다.');
        this.props.history.push('/vehicleDB/model');
      }
    }).catch(err => console.log(err))
  }

  exposure = (e) =>{
    (!this.state.popActive) && this.setState({popActive:true});
  }

  addRivalModel(){
    const data = document.querySelector('#rivalModel')
    if(data.value == 0) {
      alert('추가할 모델을 선택해주세요')
      return false;
    }
    const pushData = {
      "value" : data.value,
      "text" : data.options[data.selectedIndex].text
    }
    this.setState({
      rivalList : this.state.rivalList.concat(pushData)
    })
  }

  removeRivalModel = (id) => {
    let rivals = this.state.rivalList
    for(let x =0; x<rivals.length; x++){
      if(rivals[x].value == id) {
        rivals.splice(x,1)
      }
    }
    this.setState({
      rivalList : rivals
    });
  }

  onUploadImages = () => {
    let images = []
    const files = document.querySelector('#images').files
    for(var x = 0; x<files.length; x++){
      images.push(files[x])
    }

    if(this.state.isEdit){
      const req = MService.uploadImagesEdit(
        this.state.authToken,
        this.state.editId,
        images
      )

      req.then(res => {
        const data = MService.getCarModelImages(
          this.state.authToken,
          this.state.editId
        )

        data.then(res => {
          this.setState({
            images : res.data.data,
            isSave: false // 차량포토뷰어 이미지의 변동이 있을 때마다 저장 여부를 확인하기 위한 Flag State
          })
        })
      })
    }
    else{
      const req = MService.uploadImages(
        this.state.authToken,
        images
      )

      req.then(res => {
        const data = MService.getImages(
          this.state.authToken
        )
        data.then(res => {
          let nonDritf = []
          for(let x = 0; x<this.state.nonDriftImages.length; x++){
            for(let y= 0; y<this.state.images.length;y++){
              if(this.state.nonDriftImages[x]  == this.state.images[y].id)
                nonDritf.push(this.state.images[y])
            }
          }
          this.setState({
            images : nonDritf.concat(res.data.data),
            isSave: false // 차량포토뷰어 이미지의 변동이 있을 때마다 저장 여부를 확인하기 위한 Flag State
          });
        })
      })
    }
  }

  onSave = () => {
    let pushIds = []
    for(var x= 0; x<document.querySelectorAll('.img_data').length; x++){
      pushIds.push(document.querySelectorAll('.img_data')[x].dataset.id)
    }
    this.setState({
      popActive : false,
      imagesId : pushIds,
      isSave: true
    })
  }

  flushImages = () => {
    const flush = MService.flushImages(
      this.state.authToken
    )
    flush.then(res => {

    })
  }

  onUploadColorImage = () =>{
    if(document.querySelector('#file_input').files.length != 0)
    document.querySelector('#colorImage').value = document.querySelector('#file_input').files[0].name
    else {
      document.querySelector('#colorImage').value = ""
    }
  }

  onAddColor = () => {
    const colorCode = document.querySelector('#colorCode').value
    const colorName = document.querySelector('#colorName').value
    const colorImage = document.querySelector('#colorImage').value

    if(colorImage == ""){
      alert('이미지는 필수 항목입니다.')
    }
    else {
      const pushCodes = this.state.colorCodes
      pushCodes.push(colorCode)
      const pushNames = this.state.colorNames
      pushNames.push(colorName)
      const pushImages = this.state.colorImages
      pushImages.push(document.querySelector('#file_input').files[0])
      this.setState({
        colorCodes : pushCodes,
        colorNames : pushNames,
        colorImages : pushImages
      } , () => {
        document.querySelector('#colorCode').value = "#000000"
        document.querySelector('#colorName').value = ""
        document.querySelector('#colorImage').value = ""
      })
      if(this.state.isEdit){
        const post = MService.postCarModelColor(
          this.state.authToken,
          this.state.editId,
          colorName,
          colorCode.replace('#',''),
          document.querySelector('#file_input').files[0]
        )
        post.then(res => {
          this.setState({
            colorEdit : this.state.colorEdit.concat([res.data.data.id])
          })
        })
      }
    }
  }

  onRemoveColor = (index) => {
    if(this.state.isEdit){
      const deleteData = MService.deleteCarModelColor(
        this.state.authToken,
        this.state.editId,
        this.state.colorEdit[index]
      )
    }
    let pushCodes = this.state.colorCodes
    pushCodes.splice(index,1)
    let pushNames = this.state.colorNames
    pushNames.splice(index,1)
    let pushImages = this.state.colorImages
    pushImages.splice(index,1)
    let pushIds = this.state.colorEdit
    pushIds.splice(index,1)
    this.setState({
      colorCodes : pushCodes,
      colorNames : pushNames,
      colorImages : pushImages,
      colorEdit : pushIds
    })
  }

  onClosePopUp = () => {
    const { isEdit, isSave, images } = this.state;
    if(!isEdit && !isSave && images.length !== 0) {
      const confirm = window.confirm('업로드된 이미지의 변동사항이 저장되지 않았습니다. 정말로 닫으시겠습니까?');
      if(confirm) {
        this.setState({
          popActive: false
        });
      }
    } else {
      this.setState({
        popActive: false
      });
    }
  };

  handleLastAt = (date) => {
    this.setState({
      last_at: date
    });
  };

  handleSynonyms = (event) => {
    const key = event.key;

    if (key === 'Enter') {
      this.setState({
        synonyms: [
          ...new Set([
              ...this.state.synonyms,
              event.currentTarget.value
          ])
        ]
      })
    }
  };

  removeSynonyms = (event) => {
    const value = event.currentTarget.getAttribute('data-synonym');

    this.setState({
      synonyms: this.state.synonyms.filter((item) => item !== value)
    });
  };

  render(){
        const { popActive, isEdit, last_at } = this.state;
        const colors = this.state.colorCodes.map((item,index) => {
          return (
          <Fragment key={index}>
          <tr>
              <td>
                  #<input type="color" readOnly value={item}></input>
              </td>
              <td>
                  <input type="text" value={this.state.colorNames[index]} readOnly></input>
              </td>
              <td >
                  <input type="text"  readOnly value={this.state.colorImages[index].name ? this.state.colorImages[index].name : this.state.colorImages[index]} className="file_input"></input>
              </td>
              <td>
                  <label htmlFor="model_file" className="file_btn del_file_btn btnLine navyBg pl10 pr10" onClick={() => this.onRemoveColor( index )}>삭제</label>
              </td>
          </tr>
          </Fragment>
          )
        })
        return(
            <Fragment>
                {/*차량포토뷰어 이미지 팝업*/}
                <div className={popActive ? "popUp img_pop on" : "popUp img_pop"}>
                    <p className={`popUp${isEdit ? '':' mb10'}`}>
                      차량포토뷰어 이미지
                    </p>
                    <span onClick={this.onClosePopUp}>x</span>
                    <div className={`edit_desc${isEdit ? ' on mb10':''}`}>이미지 업로드/순서변경 또는 삭제 요청 시 변동사항이 자동으로 저장됩니다.</div>
                    <div className="popUp car_img_box">
                        <div className="popUp car_boxs">
                          <this.SortableList axis="xy" distance={10} items={this.state.images} onSortEnd={this.onSortEnd} />
                        </div>
                        <input onChange={() => this.onUploadImages()} style={{display : "none"}} multiple type="file" accept="image/*" className="popUp" id="images" />
                    </div>
                    <div className="popUp brand_btn">
                        <button type="button" className={`btnLine btnSave navyBg popUp${!isEdit ? ' on':''}`} onClick={() => this.onSave()}>저장</button>
                        <button type="button" className="btn navyBg ml10" onClick={this.onClosePopUp}>닫기</button>
                    </div>
                </div>
                {/*차량포토뷰어 이미지 팝업*/}
               <div className="title disi wd100"><p className="mb10"><span>홈</span> 〉 <span>차량DB</span> 〉 <span>모델</span> 〉 <span>추가/수정</span></p>모델 추가/수정
                    <div className="floatR disi">
                        <button type="button" className="btnLine navyBg ml10" onClick={() => !this.state.isEdit ? this.postModel() : this.editModel()}>저장</button>
                        <button type="button" className="btn navyBg ml10" onClick={() => this.props.history.goBack()}>목록</button>
                    </div>
               </div>
                <div className="form_tablewrap">
                        <table className="mt20">
                            <colgroup>
                                <col width="14%"></col>
                                <col width="*"></col>
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>브랜드 <span className="redT">*</span> / 모델그룹 <span className="redT">*</span></th>
                                    <td>
                                        <select
                                          name="brandId"
                                          onChange={this.infoSearchValue}
                                          defaultValue={this.state.brandId}
                                        >
                                          <option value="0">전체</option>
                                          {this.state.totalInfo.map((item, key) => {
                                            return (
                                              <Fragment key={key}>
                                                <option value={item["id"]}>{item["name"]}</option>
                                              </Fragment>
                                            );
                                          })}
                                        </select>
                                        <select className="ml10" id="modelGroup">
                                            <option value="0">전체</option>
                                          {
                                            this.state.modelGroupList.map((item,key) => {
                                              return (
                                                <Fragment key={key}>
                                                  <option value={item["id"]}>{item["name"]}</option>
                                                </Fragment>
                                              )
                                            })
                                          }
                                        </select>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="mt40">
                            <colgroup>
                                <col width="14%"></col>
                                <col width="26%"></col>
                                <col width="10%"></col>
                                <col width="50%"></col>
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>모델명 <span className="redT">*</span></th>
                                    <td><input type="text" defaultValue="" id="name"></input></td>
                                    <th>전체컬러</th>
                                    <td>
                                        <table className="nonborder_table table_file">
                                            <colgroup>
                                                <col width="80px"></col>
                                                <col width="*"></col>
                                                <col width="270px"></col>
                                                <col width="100px"></col>
                                            </colgroup>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        #<input type="color" id="colorCode"></input>
                                                    </td>
                                                    <td>
                                                        <input type="text" id="colorName" placeholder="컬러명"></input>
                                                    </td>
                                                    <td>
                                                        <input type="text" readOnly placeholder="이미지*" id="colorImage"
                                                        onClick={() => document.querySelector('#file_input').click()} ></input>
                                                        <input accept="image/*" type="file" id="file_input" style={{display : 'none'}} onChange={() => this.onUploadColorImage()}></input>
                                                    </td>
                                                    <td>
                                                        <button htmlFor="model_file" className="file_btn btnLine navyBg pl10 pr10" onClick={() => this.onAddColor()}>컬러추가</button>
                                                    </td>
                                                </tr>
                                                {colors}
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                  <th>동의어</th>
                                  <td colSpan="3">
                                    <div>
                                      <input
                                          type="text"
                                          defaultValue=""
                                          onKeyUp={this.handleSynonyms}
                                          placeholder="동의어 입력 후 엔터를 눌러주세요."
                                      ></input>
                                    </div>
                                    {this.state.synonyms.length !== 0 && (
                                        <div className="sel_tagwrap">
                                          {this.state.synonyms.map((item, index) => (
                                              <span key={`synonyms-${item}`} className="sel_tag">
                                            <Link to="#">
                                              {item} <i data-synonym={item} onClick={this.removeSynonyms}>X</i>
                                            </Link>
                                          </span>
                                          ))}
                                        </div>
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                    <th>개발명</th>
                                    <td><input type="text" defaultValue="" id="codeName"></input></td>
                                    <th>외관</th>
                                    <td>
                                     <select className="ml10" id="type">
                                            <option>세단</option>
                                            <option>해치백</option>
                                            <option>컨버터블</option>
                                            <option>쿠페</option>
                                            <option>왜건</option>
                                            <option>SUV</option>
                                            <option>RV</option>
                                            <option>벤</option>
                                            <option>기타</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th>차량포토뷰어 이미지</th>
                                    <td><button className="file_btn pl10 pr10" onClick={(e) => this.exposure(e)}>이미지등록</button></td>
                                    <th>출시일 <span className="redT">*</span></th>
                                    <td>
                                      <DatePicker
                                          selected={last_at}
                                          onChange={this.handleLastAt}
                                          dateFormat="yyyy-MM-dd"
                                      />
                                    </td>
                                </tr>
                                <tr>
                                  <th>예정 여부</th>
                                  <td>
                                    <input type="checkbox" name="conf" id="will"></input><label htmlFor="will"> 예정</label>
                                  </td>
                                  <th>미정 여부</th>
                                  <td>
                                    <input type="checkbox" name="conf" id="undtr"></input><label htmlFor="undtr"> 미정</label>
                                  </td>
                                </tr>
                                <tr>
                                  <th>신차 여부</th>
                                  <td>
                                    <input type="radio" name="new_car" id="new_true"></input><label htmlFor="new_true"> 예</label>
                                    <input type="radio" name="new_car" className="ml10" id="new_false"></input><label htmlFor="new_false"> 아니오</label>
                                  </td>
                                  <th>활성화 상태</th>
                                  <td>
                                    <input type="radio" name="is_active" id="active_true" ></input><label htmlFor="active_true"> 예</label>
                                    <input type="radio" name="is_active" className="ml10" id="active_false"></input><label htmlFor="active_false"> 아니오</label>
                                  </td>
                                </tr>
                                <tr>
                                    <th>경쟁차 비교 선택</th>
                                    <td colSpan="3">
                                        <div>
                                            <select
                                              name="RivalBrandId"
                                              onChange={this.infoSearchValue}
                                              defaultValue={this.state.RivalBrandId}
                                            >
                                              <option value="0">브랜드명</option>
                                              {this.state.rivalBrands.map((item, key) => {
                                                return (
                                                  <Fragment key={key}>
                                                    <option value={item["id"]}>{item["name"]}</option>
                                                  </Fragment>
                                                );
                                              })}
                                            </select>
                                            <select className="ml10"
                                              name="rivalModelGroupId"
                                              onChange={this.infoSearchValue}
                                              defaultValue={this.state.rivalModelGroupId}
                                              id="rivalModelGroup">
                                            <option value="0">모델그룹명</option>
                                              {
                                                this.state.rivalModelGroup.map((item,key) => {
                                                  return (
                                                    <Fragment key={key}>
                                                      <option value={item["name"]}>{item["name"]}</option>
                                                    </Fragment>
                                                  )
                                                })
                                              }
                                            </select>
                                            <select className="ml10 mr10" id="rivalModel">
                                                <option value={0}>모델명</option>
                                                { this.state.rivalModels.map((item,key) => {
                                                  return (
                                                    <Fragment key={key} >
                                                      <option value={item.id}>{item.name}</option>
                                                    </Fragment>
                                                  )
                                                })}
                                            </select>
                                            <button className="file_btn" onClick={() => this.addRivalModel()}>추가</button>
                                        </div>
                                        <div className="sel_tagwrap">
                                          {this.state.rivalList.map((item,key) => {
                                            return (
                                              <Fragment key={key}>
                                                <span className="sel_tag"><Link to="#">{item.text} <i onClick={() => this.removeRivalModel(item.value)}>X</i></Link></span>
                                              </Fragment>
                                            )
                                          })}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="rightT mt20">
                            <button type="button" className="btnLine navyBg ml10" onClick={() => !this.state.isEdit ? this.postModel() : this.editModel()}>저장</button>
                            <button type="button" className="btn navyBg ml10" onClick={() => this.props.history.goBack()}>목록</button>
                        </div>
                </div>
            </Fragment>
        );
  }
}

export default ModelDetail;
