import React, {useEffect, useState} from 'react';
import {isEmpty, isNull} from 'lodash';
import ReactSelect from 'react-select';

const PriorityRow = ({autoCompleteData, isForCarModel, inputDefault, inputOnChangeHandler, placeholderText, selectTagName, index, selectedValue, onChangeHandler, plusBtnHandler, minusBtnHandler}) => {
    const [value, setValue] = useState(null);
    const [nationOriginText, setNationOriginText] = useState(null);

    useEffect(() => {
        setValue(autoCompleteData.filter(({value}) => value === selectedValue.value)[0] || null);
    }, [selectedValue]);

    useEffect(() => {
        if (isEmpty(value)) {
            setNationOriginText(null);
        } else {
            setNationOriginText(value.isImported ? "수입" : "국내");
        }
    }, [value]);

    const getUrlParameter = (url, name) => {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        const results = regex.exec(url);
        return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };

    const urlInputHandler = (event) => {
        if (event.key === 'Enter') {
            const target_id = getUrlParameter(event.target.value, 'id');
            if (!isNull(target_id)) {
                const targetData = autoCompleteData.filter(({value}) => value === parseInt(target_id))[0];
                if (isEmpty(targetData)) {
                    alert('해당 URL로 값을 찾을 수 없습니다');
                } else {
                    onChangeHandler(targetData, index);
                }
             } else {
                alert('입력한 값으로 데이터를 찾을 수 없습니다');
            }
            event.target.value = null;
        }
    };

    return (
        <tr>
            <td className='uk-text-center'>{index + 1}위</td>
            {isForCarModel && <td className='uk-text-center'>{nationOriginText}</td>}
            <td>
                <div className='uk-grid'>
                    <div className='uk-width-expand@m'>
                        <ReactSelect
                            value={value} options={autoCompleteData} name={selectTagName} autoFocus={index === 0}
                            onChange={(object) => onChangeHandler(object, index)} placeholder={placeholderText}
                            isSearchable={true}/>
                        <input className='uk-input uk-margin-small-top' placeholder='URL을 넣고 엔터를 누르면 값이 선택됩니다'
                               type='text' value={inputDefault} onChange={inputOnChangeHandler}
                               onKeyDown={urlInputHandler}/>
                    </div>


                    <div className='uk-width-auto@m'>
                        <div className="uk-button-group">
                            <button className="uk-button uk-button-small uk-button-primary"
                                    onClick={() => plusBtnHandler(index, {})}>+
                            </button>
                            <button className="uk-button uk-button-small uk-button-default"
                                    onClick={() => minusBtnHandler(index)}>-
                            </button>
                        </div>
                    </div>

                </div>

            </td>
        </tr>
    );
};

export default PriorityRow;
