// 액션
const SET_PAGE_HISTORY = 'SET_PAGE_HISTORY';
const SET_PAGINATION_HISTORY = 'SET_PAGINATION_HISTORY';
const RESET_PAGE_HISTORY = 'RESET_PAGE_HISTORY';
const RESET_PAGINATION_HISTORY = 'RESET_PAGINATION_HISTORY';

// 액션 함수
export const setPageHistory = (page) => ({ type: SET_PAGE_HISTORY, page });
export const setPaginationArrayHistory = (pagination) => ({ type: SET_PAGINATION_HISTORY, pagination });
export const resetPageHistory = () => ({ type: RESET_PAGE_HISTORY });
export const resetPaginationHistory = () => ({ type: RESET_PAGINATION_HISTORY });

// State
const initialState = {
    page_history: 1,
    paginationArray_history: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
};

// Reducer
export default function common(state = initialState, action) {
    switch (action.type) {
        case SET_PAGE_HISTORY:
            return {
                ...state,
                page_history: action.page
            };
        case SET_PAGINATION_HISTORY:
            return {
                ...state,
                paginationArray_history: [ ...action.pagination ]
            };
        case RESET_PAGE_HISTORY:
            return {
                ...state,
                page_history: 1
            };
        case RESET_PAGINATION_HISTORY:
            return {
                ...state,
                paginationArray_history: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
            };
        default:
            return state;
    }
}
