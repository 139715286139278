import React, { Component, Fragment } from 'react';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import * as Service from '../../component/services/vehicleDB/LineUpService'
import * as Common from "../../component/services/common/CommonService";
import * as BrandService from "../../component/services/brand/BrandService";
import * as modelGroupService from "../../component/services/vehicleDB/ModelGroupService"
import * as modelService from "../../component/services/vehicleDB/ModelService"
import Pagination from '../../component/Pagination'
import Select from 'react-select';
import QueryString from 'query-string';
import { arrayMove } from "react-sortable-hoc";
import UIkit from "uikit";

// Components
import TrimsOrderModal from "../../component/lineup/TrimsOrderModal";

class LineUp extends Component {

  constructor(props){
    super(props)
    const queryStringHistory = QueryString.parse(this.props.location.search);
    this.state = {
        authToken : Common.getToken.call(this),
        brandId : {label : queryStringHistory.brandName ? queryStringHistory.brandName : '전체', value : queryStringHistory.brandId ? queryStringHistory.brandId : 0},
        modelGroupId : {label : queryStringHistory.modelGroupName ? queryStringHistory.modelGroupName : '전체' , value : queryStringHistory.modelGroupId ? queryStringHistory.modelGroupId : 0},
        modelId : {label: queryStringHistory.modelName ? queryStringHistory.modelName : '전체' , value : queryStringHistory.modelId ? queryStringHistory.modelId : 0},
        isActive : {label: queryStringHistory.isActive ? (queryStringHistory.isActive === 'true' ? '활성화' : '비활성화') : '전체', value : queryStringHistory.isActive ? (queryStringHistory.isActive === 'true' ? true : false) : 'all'},
        lineUpId : {label: queryStringHistory.lineUpName ? queryStringHistory.lineUpName : '전체' , value : queryStringHistory.lineUpId ? queryStringHistory.lineUpId : 0},
        per : 8,
        page : queryStringHistory.page ? queryStringHistory.page : 1,
        LineUps : [],
        pagination : [],
        totalInfo : [],
        modelGroup : [{label: '전체' , value : 0}],
        model : [{label: '전체' , value : 0}],
        is_active : [{label: '전체' , value : 'all'} , {label: '활성화' , value : true} ,{label: '비활성화' , value : false}],
        lineUpList : [{label: '전체' , value : 0}],
        totalCnt : 0,
        brandOrder : queryStringHistory.brandOrder ? queryStringHistory.brandOrder : null,
        modelGroupOrder : queryStringHistory.modelGroupOrder ? queryStringHistory.modelGroupOrder : null,
        activeOrder : queryStringHistory.activeOrder ? queryStringHistory.activeOrder : null,
        updatedOrder : queryStringHistory.updatedOrder ? queryStringHistory.updatedOrder : null,
        createdOrder : queryStringHistory.createdOrder ? queryStringHistory.createdOrder : null,
        modelOrder : queryStringHistory.modelOrder ? queryStringHistory.modelOrder : null,
        nameOrder : queryStringHistory.nameOrder ? queryStringHistory.nameOrder : null,
        isModalOpen: false,
        trimElements: {
            brandName: null,
            carModelGroupName: null,
            carModelName: null,
            lineupName: null,
            lineupId: null,
            trims: [],
            order_trim_ids: []
        }
    }
  }

  componentDidMount(){
    this.getLineUpList()
    this.getTotalInfo()
    this.getHistoryData();
  }

    getHistoryData = () => {
        // 브랜드를 선택한 history가 존재한다면 해당 브랜드의 모델그룹 목록 렌더링
        const { brandId: { value } } = this.state;
        if(value !== 0) {
            const req = modelGroupService.getModelG(this.state.authToken , this.state.brandId.value);
            req.then(response => {
                const data = response.data.data
                let options = []
                options.push({label: '전체' , value : 0})
                for(var x = 0; x<data.length; x++){
                    options.push({label: data[x].name , value : data[x].id})
                }
                this.setState({
                    modelGroup : options
                }, () => {
                    // 모델그룹을 선택한 history가 존재한다면 해당 브랜드의 모델 목록 렌더링
                    const { modelGroupId: { label } } = this.state;
                    if(label && label !== '전체') {
                        const req = modelService.getModelList(
                            this.state.brandId.value,
                            10000,
                            1,
                            this.state.authToken,
                            this.state.modelGroupId.label,
                            "","all","","","","","",""
                        )
                        req.then(response => {
                            const data = response.data.data
                            let options = []
                            options.push({label: '전체' , value : 0})
                            for(var x = 0; x<data.length; x++){
                                options.push({label: data[x].name , value : data[x].id})
                            }
                            this.setState({
                                model : options
                            }, () => {
                                const { modelId: { value } } = this.state;
                                if (value && value !== 0) {
                                    const req = Service.getLineUpList(
                                        this.state.authToken,10000,1,"","","","","","","",this.state.brandId.value,this.state.modelGroupId.value,this.state.modelId.value,0,'all'
                                    )
                                    req.then(response => {
                                        const data = response.data.data
                                        let options = []
                                        options.push({label: '전체' , value : 0})
                                        for(var x = 0; x<data.length; x++){
                                            options.push({label: `${data[x].name} : [${data[x].id}]`, value : data[x].id})
                                        }
                                        this.setState({
                                            lineUpList : options
                                        })
                                    });
                                }
                            })
                        })
                    }
                });
            })
        }
    };

  getTotalInfo = aysnc => {
    const brandList = BrandService.getTotalInfo(this.state.authToken);
    brandList.then(response => {
      const data = response.data.data
      let options = []
      options.push({label: '전체' , value : 0})
      for(var x = 0; x<data.length; x++){
        options.push({label: data[x].name , value : data[x].id})
      }
      this.setState({
        totalInfo: options
      });
    });
  };

  getLineUpList = is_render => {
    const Data = Service.getLineUpList(this.state.authToken , this.state.per , this.state.page,
      this.state.brandOrder     ,
      this.state.modelGroupOrder,
      this.state.modelOrder     ,
      this.state.nameOrder      ,
      this.state.activeOrder    ,
      this.state.createdOrder   ,
      this.state.updatedOrder   ,
      this.state.brandId.value,
      this.state.modelGroupId.value,
      this.state.modelId.value,
      this.state.lineUpId.value,
      this.state.isActive.value
      )

    Data.then(res => {
      this.setState({
        LineUps : res.data.data,
        pagination : res.data.pagination,
        totalCnt : res.data.total_entries
      } , () => {
        if(is_render){
          if(document.querySelector('#first_btn'))
          document.querySelector('#first_btn').click()
        }
      })
    })
  }

  onClickPage = (page) => {
    this.setState(
      {
        page
      },
      () => {
        this.handlePage();
        this.getLineUpList();
      }
    );
  };

  selectBrand = selectOption => {
    this.setState({
      brandId : selectOption,
      modelGroup : [{label: '전체' , value : 0}],
      model : [{label: '전체' , value : 0}],
      lineUpList : [{label: '전체' , value : 0}],
      modelGroupId : {label : '전체' , value : 0},
      modelId : {label: '전체' , value : 0},
      lineUpId : {label: '전체' , value : 0},
    } , () => {
      const req = modelGroupService.getModelG(this.state.authToken , this.state.brandId.value)
      req.then(response => {
        const data = response.data.data
        let options = []
        options.push({label: '전체' , value : 0})
        for(var x = 0; x<data.length; x++){
          options.push({label: data[x].name , value : data[x].id})
        }
        this.setState({
          modelGroup : options
        })
      })
    });
  };

  selectModelGroup = selectOption => {
    this.setState({
      modelGroupId : selectOption,
      model : [{label: '전체' , value : 0}],
      lineUpList : [{label: '전체' , value : 0}],
      modelId : {label: '전체' , value : 0},
      lineUpId : {label: '전체' , value : 0},
    } , () => {
      const req = modelService.getModelList(
        this.state.brandId.value,
      10000,
      1,
      this.state.authToken,
      this.state.modelGroupId.label,
      "","all","","","","","",""
      )
      req.then(response => {
        const data = response.data.data
        let options = []
        options.push({label: '전체' , value : 0})
        for(var x = 0; x<data.length; x++){
          options.push({label: data[x].name , value : data[x].id})
        }
        this.setState({
          model : options
        })
      })
    });
  };

  selectModel = selectOption => {
    this.setState({
      modelId : selectOption
    } , () => {
      const req = Service.getLineUpList(
        this.state.authToken,10000,1,"","","","","","","",this.state.brandId.value,this.state.modelGroupId.value,this.state.modelId.value,0,'all'
      )
      req.then(response => {
        const data = response.data.data
        let options = []
        options.push({label: '전체' , value : 0})
        for(var x = 0; x<data.length; x++){
            options.push({label: `${data[x].name} : [${data[x].id}]`, value : data[x].id})
        }
        this.setState({
          lineUpList : options
        })
      })
    })
  }

  selectLineUp = selectOption => {
    this.setState({
      lineUpId : selectOption
    })
  }

  activeSelect = selectOption => {
    this.setState({
      isActive : selectOption
    })
  }

  onSearch = () => {
    this.setState({
        page: 1
    }, () => {
        this.handlePage();
        this.getLineUpList(1);
    });
  }

  setBrandOrder = order => {
    this.setState({
        brandOrder : order,
        modelGroupOrder: null,
        modelOrder: null,
        nameOrder: null,
        activeOrder: null,
        createdOrder: null,
        updatedOrder: null
    }, () => {
        this.handlePage();
        this.getLineUpList(1);
    })
  }

  setmodelGroupOrder = (order) => {
    this.setState({
        brandOrder: null,
        modelGroupOrder : order,
        modelOrder: null,
        nameOrder: null,
        activeOrder: null,
        createdOrder: null,
        updatedOrder: null
    }, () => {
        this.handlePage();
        this.getLineUpList(1);
    })
  }
  setmodelOrder      = (order) => {
    this.setState({
        brandOrder: null,
        modelGroupOrder: null,
        modelOrder : order,
        nameOrder: null,
        activeOrder: null,
        createdOrder: null,
        updatedOrder: null
    }, () => {
        this.handlePage();
        this.getLineUpList(1);
    })
  }
  setnameOrder       = (order) => {
    this.setState({
        brandOrder: null,
        modelGroupOrder: null,
        modelOrder: null,
        nameOrder : order,
        activeOrder: null,
        createdOrder: null,
        updatedOrder: null
    }, () => {
        this.handlePage();
        this.getLineUpList(1);
    })
  }
  setactiveOrder = (order) => {
    this.setState({
        brandOrder: null,
        modelGroupOrder: null,
        modelOrder: null,
        nameOrder : null,
        activeOrder : order,
        createdOrder: null,
        updatedOrder: null
    }, () => {
        this.handlePage();
        this.getLineUpList(1);
    })
  }
  setcreatedOrder = (order) => {
    this.setState({
        brandOrder: null,
        modelGroupOrder: null,
        modelOrder: null,
        nameOrder : null,
        activeOrder : null,
        createdOrder : order,
        updatedOrder: null
    }, () => {
        this.handlePage();
        this.getLineUpList(1);
    })
  }
  setupdatedOrder = (order) => {
    this.setState({
        brandOrder: null,
        modelGroupOrder: null,
        modelOrder: null,
        nameOrder : null,
        activeOrder : null,
        createdOrder : null,
        updatedOrder : order
    }, () => {
        this.handlePage();
        this.getLineUpList(1);
    })
  }
  checkBoxCheck = e => {
    const data = document.querySelectorAll('.checkBox')
    let check = 0;
    for(let x = 0; x<data.length; x++){
      if(data[x].checked){
        check+=1
      }
    }
    if(check == 1){
      document.querySelector('#bulkEditButton').disabled = false
      document.querySelector('#bulkEditButton').classList.add('bulkEditButtonOn')
    }else{
      document.querySelector('#bulkEditButton').disabled = true
      document.querySelector('#bulkEditButton').classList.remove('bulkEditButtonOn')
    }
  }

  goEdit = () => {
        if(Array.from(document.querySelector('#bulkEditButton').classList).indexOf('bulkEditButtonOn') !== -1){
            const data = document.querySelectorAll('.checkBox')
            let check = 0;
            for(let x = 0; x<data.length; x++){
                if(data[x].checked){
                    check = data[x].id.replace('line_','')
                }
            }
            const req = Service.getLineUpDetail(this.state.authToken , check)
            req.then(res => {
                const data = res.data.data;
                this.handlePage(`/vehicleDB/lineUp/lineUpUpdate/${data.brand_id}&${data.car_model_group_id}&${data.car_model_id}&bulk&${data.brand_name}&${data.car_model_group_name}&${data.car_model_name}`);
            })
        }
    }

    // 선택한 라인업들을 삭제합니다.
    deleteLienUp = () => {
        const data = document.querySelectorAll('.checkBox');
        // let check = 0;
        let ids = [];
        for(let x = 0; x<data.length; x++){
            if(data[x].checked){
                // check = data[x].id.replace('line_','');
                ids = [ ...ids, parseInt(data[x].id.replace('line_','')) ];
            }
        }

        if(ids.length !== 0) {
            const { authToken } = this.state;
            Service.deleteLineUp(authToken, ids).then(() => {
                alert('삭제되었습니다.');
                this.getLineUpList();
            }).catch((error) => {
               if(error) {
                   if(error.response.data.message) {
                       alert(error.response.data.message);
                   }
               }
            });
        }
    };

    // 라인업 페이지 내의 모든 페이지 라우팅 핸들링
    handlePage = (path) => {
        const {
            brandId: { label: brandName, value: brandId },
            modelGroupId: { label: modelGroupName, value: modelGroupId },
            modelId: { label: modelName, value: modelId },
            lineUpId: { label: lineUpName, value: lineUpId },
            isActive: { value: isActive },
            brandOrder,
            modelGroupOrder,
            activeOrder,
            updatedOrder,
            createdOrder,
            modelOrder,
            nameOrder,
            page
        } = this.state;
        let searchInfo = {
            brandId,
            brandName,
            modelGroupId,
            modelGroupName,
            modelId,
            modelName,
            lineUpId,
            lineUpName,
            isActive,
            brandOrder,
            modelGroupOrder,
            activeOrder,
            updatedOrder,
            createdOrder,
            modelOrder,
            nameOrder,
            page
        };
        // 해당 키가 가진 값이 존재하지 않는다면 제거
        Object.keys(searchInfo).map((item) => {
            if (item === 'brandId') {
                (searchInfo[item] === 0 || searchInfo[item].toString().length === 0) && delete searchInfo[item];
            } else if (item === 'brandName') {
                (searchInfo[item] === '전체' || searchInfo[item].toString().length === 0) && delete searchInfo[item];
            } else if (item === 'modelGroupId') {
                (searchInfo[item] === 0 || searchInfo[item].toString().length === 0) && delete searchInfo[item];
            } else if (item === 'modelGroupName') {
                (searchInfo[item] === '전체' || searchInfo[item].toString().length === 0) && delete searchInfo[item];
            } else if (item === 'modelId') {
                (searchInfo[item] === 0 || searchInfo[item].toString().length === 0) && delete searchInfo[item];
            } else if (item === 'modelName') {
                (searchInfo[item] === '전체' || searchInfo[item].toString().length === 0) && delete searchInfo[item];
            } else if (item === 'lineUpId') {
                (searchInfo[item] === 0 || searchInfo[item].toString().length === 0) && delete searchInfo[item];
            } else if (item === 'lineUpName') {
                (searchInfo[item] === '전체' || searchInfo[item].toString().length === 0) && delete searchInfo[item];
            } else if (item === 'isActive') {
                (searchInfo[item] === 'all' || searchInfo[item].toString().length === 0) && delete searchInfo[item];
            } else {
                (searchInfo[item] === null || searchInfo[item].toString().length === 0) && delete searchInfo[item];
            }
        });
        let queryString = Object.keys(searchInfo).map((item, index) => searchInfo[item] !== null && `${item}=${searchInfo[item]}`).join('&');
        this.props.history.push({
            pathname: path ? path : '/vehicleDB/lineUp',
            search: `?${queryString}`
        });
    };

    handleLineupTrimsOrderModal = (event) => {
        const brandName = event.currentTarget.getAttribute('data-brand-name');
        const carModelGroupName = event.currentTarget.getAttribute('data-car-model-group-name');
        const carModelName = event.currentTarget.getAttribute('data-car-model-name');
        const lineupName = event.currentTarget.getAttribute('data-lineup-name');
        const lineupId = event.currentTarget.getAttribute('data-lineup-id');

        const { authToken } = this.state;
        Service.getLineupTrims(authToken, lineupId).then((response) => {
            this.setState({
                trimElements: {
                    brandName,
                    carModelGroupName,
                    carModelName,
                    lineupName,
                    lineupId,
                    trims: response.data.data,
                    order_trim_ids: [ ...response.data.data.map((item) => item.id) ]
                },
                isModalOpen: true
            });
        }).catch((error) => {
            console.log(error);
        });
    };

    handleCloseTrimsOrderModal = () => this.setState({ isModalOpen: false });

    handleLineupTrimOnSortEnd = ({ oldIndex, newIndex }) => {
        const trimSortableElements = document.getElementsByClassName('uk-custom-sortable');
        for (let i = 0; i < trimSortableElements.length; i++) {
            trimSortableElements[i].removeAttribute('style');
        }

        const { trimElements } = this.state;
        const trims = arrayMove(trimElements.trims, oldIndex, newIndex);
        this.setState({
            trimElements: {
                ...trimElements,
                trims,
                order_trim_ids: [ ...trims.map((item) => item.id) ]
            }
        });
    };

    handleLineupTrimsOrderSave = () => {
      const { authToken, trimElements: { lineupId, order_trim_ids } } = this.state;

      if (isEmpty(order_trim_ids)) {
          UIkit.notification({
              message: `<span class="uk-icon-color-danger uk-margin-small-right" uk-icon=\'icon: warning\'></span> 트림이 존재하지 않습니다.`,
              pos: 'bottom-center',
              timeout: 1500
          });
          return false;
      }

      Service.putLineupTrimsOrder(authToken, lineupId, order_trim_ids).then((response) => {
        UIkit.notification({
          message: '<span class="uk-icon-color-success uk-margin-small-right" uk-icon=\'icon: check\'></span> 트림의 노출 순서가 수정되었습니다.',
          pos: 'bottom-center',
          timeout: 1500
        });
      }).catch((error) => {
        console.log(error);
        UIkit.notification({
          message: `<span class="uk-icon-color-danger uk-margin-small-right" uk-icon=\'icon: ban\'></span> ${error.response.data.message}`,
          pos: 'bottom-center',
          timeout: 1500
        });
      });
    };

    render() {
        const { isModalOpen, trimElements } = this.state;

        return (
            <Fragment>
                {/* 차량DB> 라인업 영역 시작 */}
                <div className="title"><p className="mb10"><span>홈</span> 〉 <span>차량DB</span> 〉 <span>라인업</span></p>라인업 </div>
                <div className="info_searchwrap">
                        <table>
                            <colgroup>
                                <col width="10%"></col>
                                <col width="*"></col>
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>브랜드명</th>
                                    <td>
                                    <Select className="reactSelect w-500"
                                    options={this.state.totalInfo}
                                    value={this.state.brandId}
                                    name="brandId"
                                    onChange={this.selectBrand}
                                    placeholder="전체"
                                    isSearchable={true}/>
                                    </td>
                                </tr>
                                <tr>
                                    <th>모델그룹명</th>
                                    <td>
                                      <Select className="reactSelect w-500"
                                      options={this.state.modelGroup}
                                      value={this.state.modelGroupId}
                                      name="brandId"
                                      onChange={this.selectModelGroup}
                                      placeholder="전체"
                                      isSearchable={true}/>
                                    </td>
                                </tr>
                                <tr>
                                    <th>모델명</th>
                                    <td>
                                    <Select className="reactSelect w-500"
                                      options={this.state.model}
                                      value={this.state.modelId}
                                      name="brandId"
                                      onChange={this.selectModel}
                                      placeholder="전체"
                                      isSearchable={true}/>
                                    </td>
                                </tr>
                                <tr>
                                    <th>라인업명</th>
                                    <td>
                                    <Select className="reactSelect w-500"
                                      options={this.state.lineUpList}
                                      value={this.state.lineUpId}
                                      name="brandId"
                                      onChange={this.selectLineUp}
                                      placeholder="전체"
                                      isSearchable={true}/>
                                    </td>
                                </tr>
                                <tr>
                                    <th>활성화 상태</th>
                                    <td>
                                    <Select className="reactSelect w-500"
                                      options={this.state.is_active}
                                      value={this.state.isActive}
                                      name="brandId"
                                      onChange={this.activeSelect}
                                      placeholder="전체"
                                      isSearchable={true}/>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="centerT mt20">
                            <button type="submit" className="btnLine navyBg medium" onClick={() => this.onSearch()}>검색</button>
                        </div>
                </div>
                {/* 모델그룹 검색영역 끝 */}
                <div className="info_resultwrap">
                    <div className="info_tabletop">
                       <button id="bulkEditButton" className="btn grayBg b_btn floatL" onClick={() => this.goEdit()}>라인업 일괄수정</button>
                        <div className="mb10">
                            <button className="btnLine navyBg ml10" onClick={() => this.handlePage(`/vehicleDB/lineUp/lineUpAdd`)}>추가</button>
                            <button className="btn navyBg centerT ml10" onClick={this.deleteLienUp}>삭제</button>
                        </div>
                    </div>
                    <table>
                        <colgroup>
                            <col width="5%"></col>
                            <col width=""></col>
                            <col width=""></col>
                            <col width=""></col>
                            <col width=""></col>
                            <col width=""></col>
                            <col width=""></col>
                            <col width=""></col>
                            <col width=""></col>
                            <col width="15%"></col>
                        </colgroup>
                        <thead>
                            <tr>
                                <th colSpan="10" className="ccc">
                                  총 <span>{this.state.totalCnt}</span>개
                                </th>
                            </tr>
                            <tr>
                                <th><input type="checkbox" id="line_all" name="line-up"></input></th>
                                <th>번호</th>
                                <th>
                                    브랜드명
                                    <button className="set_btn" onClick={() => this.setBrandOrder('desc')}>▼</button>
                                    <button className="set_btn" onClick={() => this.setBrandOrder('asc')} >▲</button>
                                </th>
                                <th>
                                    모델그룹명
                                    <button className="set_btn" onClick={() => this.setmodelGroupOrder('desc')}>▼</button>
                                    <button className="set_btn" onClick={() => this.setmodelGroupOrder('asc')} >▲</button>
                                </th>
                                <th>
                                    모델명
                                    <button className="set_btn" onClick={() => this.setmodelOrder('desc')}>▼</button>
                                    <button className="set_btn" onClick={() => this.setmodelOrder('asc')} >▲</button>
                                </th>
                                <th>
                                    라인업명
                                    <button className="set_btn" onClick={() => this.setnameOrder('desc')}>▼</button>
                                    <button className="set_btn" onClick={() => this.setnameOrder('asc')} >▲</button>
                                </th>
                                <th>
                                    활성화 상태
                                    <button className="set_btn" onClick={() => this.setactiveOrder('desc')}>▼</button>
                                    <button className="set_btn" onClick={() => this.setactiveOrder('asc')} >▲</button>
                                </th>
                                <th>
                                    수정일
                                    <button className="set_btn" onClick={() => this.setupdatedOrder('desc')}>▼</button>
                                    <button className="set_btn" onClick={() => this.setupdatedOrder('asc')} >▲</button>
                                </th>
                                <th>
                                    등록일
                                    <button className="set_btn" onClick={() => this.setcreatedOrder('desc')}>▼</button>
                                    <button className="set_btn" onClick={() => this.setcreatedOrder('asc')} >▲</button>
                                </th>
                                <th>관리</th>
                            </tr>
                        </thead>
                        <tbody>
                          {this.state.LineUps.map((value , index) => {
                            return(
                              <tr key={`lineup-${value.id}`}>
                                <td><input type="checkbox" className="checkBox" id={"line_"+value.id} name="line-up" onChange={this.checkBoxCheck}></input></td>
                                <td>{value.id}</td>
                                <td><Link to={"/brand/totalInfo/totalInfoDetail/"+value.brand_id}>{value.brand_name}</Link></td>
                                <td><Link to={"/vehicleDB/modelGroup/modelGroupDetail/"+value.car_model_group_id}>{value.car_model_group_name}</Link></td>
                                <td><Link to={"/vehicleDB/model/modelDetail/"+value.car_model_id}>{value.car_model_name}</Link></td>
                                <td><Link to={"/vehicleDB/lineUp/lineUpUpdate/"+value.brand_id+"&"+value.car_model_group_id+"&"+value.id}>{value.name}</Link></td>
                                <td>{value.is_active ? "활성화" : "비활성화"}</td>
                                <td>{`${new Date(value.updated_at).getFullYear() - 2000}.
                                  ${new Date(value.updated_at).getMonth()+1 < 10 ? `0${new Date(value.updated_at).getMonth()+1}` : new Date(value.updated_at).getMonth()+1}.
                                  ${new Date(value.updated_at).getDate() < 10 ? `0${new Date(value.updated_at).getDate()}` : new Date(value.updated_at).getDate()}`}</td>
                                <td>{
                                 `${new Date(value.created_at).getFullYear() - 2000}.
                                  ${new Date(value.created_at).getMonth()+1 < 10 ? `0${new Date(value.created_at).getMonth()+1}` : new Date(value.created_at).getMonth()+1}.
                                  ${new Date(value.created_at).getDate() < 10 ? `0${new Date(value.created_at).getDate()}` : new Date(value.created_at).getDate()}`}</td>
                                <td>
                                    <button
                                        className="btn navyBg"
                                        data-brand-name={value.brand_name}
                                        data-car-model-group-name={value.car_model_group_name}
                                        data-car-model-name={value.car_model_name}
                                        data-lineup-name={value.name}
                                        data-lineup-id={value.id}
                                        onClick={this.handleLineupTrimsOrderModal}
                                    >
                                        트림 순서
                                    </button>
                                    <button className="btn grayBg ml10" onClick={() => this.handlePage(`/vehicleDB/lineUp/lineUpUpdate/${value.brand_id}&${+value.car_model_group_id}&${value.id}`)}>수정</button>
                                </td>
                            </tr>
                            )
                          })}
                        </tbody>
                    </table>
                    <div className="pagination">
                    <Pagination
                      onClickPage={this.onClickPage}
                      paginationOption={this.state.pagination}
                    />
                    </div>
                </div>
                <TrimsOrderModal
                    isModalOpen={isModalOpen}
                    trimElements={trimElements}
                    handleCloseTrimsOrderModal={this.handleCloseTrimsOrderModal}
                    handleLineupTrimOnSortEnd={this.handleLineupTrimOnSortEnd}
                    handleLineupTrimsOrderSave={this.handleLineupTrimsOrderSave}
                />
                {/* 차량DB> 라인업 영역 끝 */}
                </Fragment>
        );
    }

}
export default LineUp;
