import React, { useEffect, useState, useCallback } from "react";
import { isEmpty } from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import "moment/locale/ko";
import QueryString from "query-string";

// Common Component
import DatePickerGroup from "../../component/common/DatePickerGroup";

// Components
import ListTable from "../../component/featuredNews/ListTable";
import ListTableNavigator from "../../component/featuredNews/ListTableNavigator";
import ContentsModal from "../../component/featuredNews/ContentsModal";

// UIKits
import '../../UIKitMonkeyPatch.scss'

// Services
import {
    getFeaturedNewsList,
    deleteFeaturedNews,
    postPublishFeaturedNews
} from '../../component/services/content/FeaturedNewsService';
import { getToken } from '../../component/services/common/CommonService';

moment.locale("ko");

function getInitializeParameters(search) {
    const { start_at, end_at } = search;

    if (!isEmpty(start_at) && !isEmpty(end_at)) {
        return {
            start_at,
            end_at
        }
    }

    return {
        start_at: moment().add(-7, "days").format("YYYY-MM-DD"),
        end_at: moment().format("YYYY-MM-DD")
    }
}

function FeaturedNews() {
    const history = useHistory();
    const { search } = useLocation();

    const [authToken] = useState(getToken());
    const [featuredNews, setFeaturedNews] = useState([]);
    const [fetchParams, setFetchParams] = useState({
        ...getInitializeParameters(QueryString.parse(search)),
        with_contents: true
    });
    const [isOpenModal, setIsOpenModal] = useState(false);

    const handleDatePicker = useCallback((dataType, value) => {
        setFetchParams({
            ...fetchParams,
            [dataType]: moment(value).format("YYYY-MM-DD")
        });

        const date = moment(value).format("YYYY-MM-DD");
        const start_at = dataType === "start_at" ? date : fetchParams.start_at;
        const end_at = dataType === "end_at" ? date : fetchParams.end_at;

        history.replace(`/contents/featuredNews?start_at=${start_at}&end_at=${end_at}`);
    }, [history, fetchParams.start_at, fetchParams.end_at]);

    const handleContentModal = useCallback((event) => {
        setIsOpenModal(true);
        const id = event.currentTarget.getAttribute('data-id');

        setFeaturedNews(featuredNews.map((item) => ({
            ...item,
            is_selected: item.id === Number(id)
        })));
    }, [featuredNews]);

    const handleManageButton = useCallback((event) => {
        const type = event.currentTarget.getAttribute("data-type");
        const id = Number(event.currentTarget.getAttribute("data-id") || 0);

        if (type === "delete") {
            deleteFeaturedNews({ authToken, id }).then((response) => {
                if (response.status === 200) {
                    alert("삭제되었습니다.");
                    // setFeaturedNews(featuredNews.filter((item) => item.id !== id));
                    setFeaturedNews(featuredNews.map((item) => ({
                        ...item,
                        is_exist: (item.id !== id) && item.is_exist
                    })))
                }
            }).catch((error) => {
                alert(error.response.data.message);
                console.log(error.response.data.message);
            });
        } else if (type === "publish") {
            const selectedFeaturedNews = featuredNews.filter((item) => item.id === id);
            const date = !isEmpty(selectedFeaturedNews) && selectedFeaturedNews[0].date;

            if (date) {
                postPublishFeaturedNews({ authToken, date }).then((response) => {
                    if (response.status === 200) {
                        alert("수정사항이 반영되었습니다.");
                    } else {
                        alert("알 수 없는 오류로 인해 수정사항 반영에 실패하였습니다.");
                    }
                }).catch((error) => {
                    alert(error.response.data.message);
                    console.log(error.response.data.message);
                })
            }
        }
    }, [authToken, featuredNews]);

    const closeContentModal = useCallback(() => setIsOpenModal(false), []);

    useEffect(() => {
        getFeaturedNewsList({ authToken, ...fetchParams })
            .then((response) => {
                const { start_at, end_at } = fetchParams;
                const startAtToDate = moment(start_at);
                const endAtToDate = moment(end_at);

                const diffDay = endAtToDate.diff(startAtToDate, 'days');
                let featuredNewsArray = [];
                for (let i = 0; i <= diffDay; i++) {
                    featuredNewsArray.push({
                        date: moment(start_at).add(i, "days").format("YYYY-MM-DD")
                    });
                }

                const isCreateExpired = (data, date) => {
                    const currentDate = moment();
                    const featuredNewsDate = moment(date);

                    const isFuture = currentDate > featuredNewsDate;
                    const isToday =
                        currentDate.format("YYYY-MM-DD") === featuredNewsDate.format("YYYY-MM-DD");

                    if (!!!data.filter((data) => data.date === date)[0] && !isToday && isFuture) {
                        return true;
                    }
                };

                setFeaturedNews(featuredNewsArray.map((item) => ({
                    ...item,
                    ...response.data.data.filter((data) => data.date === item.date)[0],
                    is_exist: !!response.data.data.filter((data) => data.date === item.date)[0],
                    is_create_expired: isCreateExpired(response.data.data, item.date),
                    is_selected: false,
                })));
    })}, [authToken, fetchParams]);

    return (
        <>
            <div className="title">
                <p className="mb10">
                    <span>홈</span> 〉 <span>콘텐츠</span> 〉 <span>추천뉴스 관리</span>
                </p>
                추천뉴스 관리
            </div>
            <div className="uk-flex uk-flex-between uk-flex-middle uk-margin-small-top uk-margin-small-bottom">
                <DatePickerGroup
                    start_at={fetchParams.start_at}
                    end_at={fetchParams.end_at}
                    handleDatePicker={handleDatePicker}
                />
                <ListTableNavigator />
            </div>
            <ListTable
                featuredNews={featuredNews}
                handleManageButton={handleManageButton}
                handleContentModal={handleContentModal}
            />
            <ContentsModal
                featuredNews={featuredNews.filter((item) => item.is_selected)}
                isOpenModal={isOpenModal}
                closeContentModal={closeContentModal}
            />
        </>
    );
}

export default FeaturedNews;
