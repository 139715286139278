import React, { Component } from 'react';
import { NavLink, Switch, Route } from 'react-router-dom';

class SideMenu extends Component {

  render() {
    return (
      <div style={{ background: "#fff" }} className="sideMenu floatL">
        <Switch>
          <Route path="(/|/brand)">
            <NavLink to="/brand/totalInfo" className="item" activeClassName="active">종합정보</NavLink>
            <NavLink to="/brand/salesRecord" className="item" activeClassName="active">판매실적관리</NavLink>
            <NavLink to="/brand/salesCondition" className="item" activeClassName="active">판매조건관리</NavLink>
          </Route>
          <Route path="/vehicleDB">
            <NavLink to="/vehicleDB/modelGroup" className="item" activeClassName="active">모델그룹</NavLink>
            <NavLink to="/vehicleDB/model" className="item" activeClassName="active">모델</NavLink>
            <NavLink to="/vehicleDB/lineUp" className="item" activeClassName="active">라인업</NavLink>
            <NavLink to="/vehicleDB/trim" className="item" activeClassName="active">트림</NavLink>
          </Route>
          <Route path="/contents">
            <NavLink to="/contents/media" className="item" activeClassName="active">매체</NavLink>
            <NavLink to="/contents/content" className="item" activeClassName="active">콘텐츠</NavLink>
            <NavLink to="/contents/photoNews" className="item" activeClassName="active">포토뉴스</NavLink>
            <NavLink to="/contents/featuredNews" className="item" activeClassName="active">추천뉴스</NavLink>
          </Route>
          <Route path="/operation">
            <NavLink to="/operation/commonAdv" className="item" activeClassName="active">공통날개광고</NavLink>
            <NavLink to="/operation/ecommersAdv" className="item" activeClassName="active">E커머스광고</NavLink>
            <NavLink to="/operation/photoGallery" className="item" activeClassName="active">포토갤러리</NavLink>
            <NavLink to="/operation/homePhoto" className="item" activeClassName="active">홈 인기포토</NavLink>
            <NavLink to="/operation/priority/carModel" className="item" activeClassName="active">자동차 우선순위</NavLink>
            <NavLink to="/operation/priority/content" className="item" activeClassName="active">컨텐츠 우선순위</NavLink>
            <NavLink to="/operation/priority/video" className="item" activeClassName="active">동영상 우선순위</NavLink>
          </Route>
        </Switch>
      </div>
    );
  }
}

export default SideMenu
