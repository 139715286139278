import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import moment from 'moment'
import * as Common from "../../component/services/common/CommonService";
import * as Service from "../../component/services/operation/estimateService";
class TotalInfo extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      authToken: Common.getToken.call(this),
      data : {},
      loading : true
    };
  }

  getDetail = async () => {
    const id = window.location.href.split('/')[window.location.href.split('/').length - 1]
    await Service.getEstimateDetail(id).then(res => {
      this.setState({
        data : res.data.data,
        loading : false
      })
    })
  }

  setComma = ( value ) => {
    if (value == null || value == ""){
        return value;
    }
    var valueArr = value.toString().split(".");
    var str = valueArr[0].toString();
        str = str.replace ( /,/g ,'' );
    var retValue = "";
    for( var i = 1; i <= str.length; i++ ) {
        if ( i > 1 && ( i % 3 ) == 1 )
            retValue = str.charAt ( str.length - i ) + "," + retValue;
        else
            retValue = str.charAt ( str.length - i ) + retValue;
    }
    return retValue + (valueArr.length > 1 ? "." + valueArr[1] : "");
  }

  getArrayTotal = array => {
    let total = 0
    for(let item of array){
      total+=item.price
    }
    return total
  }

  componentDidMount() {
    this.getDetail()
  }

  render() {
    const {data} = this.state
    return (
      <Fragment>
        {/* <BigText>종합정보 <p className="floatR"><span>홈</span> 〉 <span>브랜드</span> 〉 <span>종합정보</span></p></BigText> */}
        <div className="title"><p className="mb10">
            <span>홈</span> 〉 <span>운영</span> 〉 <span>견적요청현황관리</span> 〉 <span>답변관리</span>
          </p>
          견적요청현황 답변관리{" "}
          <div className="floatR disi">
            <Link to="/operation/Estimate"><button type="button" className="btn navyBg ml10">목록</button></Link>
          </div>
        </div>
        <div className="info_searchwrap">
          <form>
            <table>
              <colgroup>
                <col width="20%"></col>
                <col width="30%"></col>
                <col width="20%"></col>
                <col width="30%"></col>                
              </colgroup>
              <tbody>
              {
                this.state.loading ? <></> :
                <>
                <tr>
                  <th>접수번호</th>
                  <td>
                    {data.id}
                  </td>
                  <th>견적요청모델명</th>
                    <td>{data.car_model.brand.name}&nbsp;&nbsp;〉<Link target="_blank" to={`/vehicleDB/model/modelDetail/${data.car_model.id}`}><span>{data.car_model.name}</span></Link></td>
                </tr>
                <tr>
                </tr>
                <tr>
                  <th>작성자</th>
                  <td>
                    {data.masked_user_name}
                  </td>
                  <th>등록일</th>
                    <td>
                      {moment(data.created_at).format('YY.MM.DD hh:mm:ss')}
                    </td>
                </tr>
                </>
              }
              </tbody>
            </table>
          </form>
          {
            this.state.loading ? <></>
            :
            <div className="detailWrapper">
                <div className="leftWing">
                  <img className="logo" src={data.car_model.brand.logo}/>
                  <img src={data.car_model.primary_image} alt="" className="primaryImage"/>
                  <span className="modelName">{data.car_model.name}</span>
                  <span className="lineUpName">{data.lineup.name}</span>
                  <span className="trimName">{data.trim.name}</span>
                  <span className="conditionTitle">판매조건</span>
                  {
                    data.selected_sales_conditions.length ?
                    data.selected_sales_conditions.map((item , index) => {
                      return (
                      <div className="priceOptions" key={index}>
                        <span>{item.name}</span>
                        <span>{this.setComma(item.price)}원</span>
                      </div>
                      )
                    })
                    :
                    <div className="priceOptions">
                      <span>판매조건이 없습니다</span>
                    </div>
                  }
                  <span className="optionTitle">옵션선택</span>
                  {
                    data.selected_price_options.length ?
                    data.selected_price_options.map((item , index) => {
                      return (
                      <div className="priceOptions" key={index}>
                        <span>{item.name}</span>
                        <span>{this.setComma(item.price)}원</span>
                      </div>
                      )
                    })
                    :
                    <div className="priceOptions">
                      <span>선택한 옵션이 없습니다</span>
                    </div>
                  }
                </div>
                <div className="rightWing">
                  {
                    data.payment_type === "일시불"
                    ?
                    <>
                    <h1 className="title">일시불 결제</h1>
                    <div className="priceIndicator">
                      <span className="label">기본가격</span>
                      <span className="price">{this.setComma(data.trim.price)}원</span>
                    </div>
                    <div className="priceIndicator">
                      <span className="label">옵션가격</span>
                      <span className="price">{this.setComma(this.getArrayTotal(data.selected_price_options))}원</span>
                    </div>
                    <div className="priceIndicator">
                      <span className="label">판매조건</span>
                      <span className="price">{this.setComma(this.getArrayTotal(data.selected_sales_conditions))}원</span>
                    </div>
                    <div className="priceIndicator">
                      <span className="label">취득새/공채</span>
                      <span className="price">{this.setComma(data.extra_options.tax.price + data.extra_options.location.price)}원</span>
                    </div>
                    <div className="priceIndicator">
                      <span className="label">예상 탁송료</span>
                      <span className="price">{this.setComma(data.extra_options.pickup.price)}원</span>
                    </div>
                    <div className="priceIndicator">
                      <span className="label">예상 부대비용</span>
                      <span className="price">{this.setComma(data.extra_options.incidental_price)}원</span>
                    </div>
                    <div className="totalPrice">
                      <span className="label">구입비용</span>
                      <span className="price">{this.setComma(data.trim.price + data.extra_options.tax.price + this.getArrayTotal(data.selected_sales_conditions) + this.getArrayTotal(data.selected_price_options) + data.extra_options.location.price + data.extra_options.pickup.price + data.extra_options.incidental_price)}원</span>
                    </div>
                    <span className="guide">
                      카드사별 결제프로그램 , 탁송료 및 부대비용에 따라 예상견적과 다를 수 있습니다. 보다 정확한 금액은 견적 요청을 통해 받아보실 수 있습니다.
                    </span>
                    </>
                    :
                    <>
                    <h1 className="title">{data.installment_options.duration} 할부 결제</h1>
                    <div className="priceIndicator">
                      <span className="label">기본가격</span>
                      <span className="price">{this.setComma(data.trim.price)}원</span>
                    </div>
                    <div className="priceIndicator">
                      <span className="label">옵션가격</span>
                      <span className="price">{this.setComma(this.getArrayTotal(data.selected_price_options))}원</span>
                    </div>
                    <div className="priceIndicator">
                      <span className="label">판매조건</span>
                      <span className="price">{this.setComma(this.getArrayTotal(data.selected_sales_conditions))}원</span>
                    </div>
                    <div className="priceIndicator">
                      <span className="label">취득새/공채</span>
                      <span className="price">{this.setComma(data.extra_options.tax.price + data.extra_options.location.price)}원</span>
                    </div>
                    <div className="priceIndicator">
                      <span className="label">예상 탁송료</span>
                      <span className="price">{this.setComma(data.extra_options.pickup.price)}원</span>
                    </div>
                    <div className="priceIndicator">
                      <span className="label">예상 부대비용</span>
                      <span className="price">{this.setComma(data.extra_options.incidental_price)}원</span>
                    </div>
                    <div className="monthlyPriceIndicator firstIndicator">
                      <span className="label">선납금액</span>
                      <span className="price">{this.setComma(data.installment_options.prepayment)}원</span>
                    </div>
                    <div className="monthlyPriceIndicator">
                      <span className="label">할부개월</span>
                      <span className="price">{data.installment_options.duration}</span>
                    </div>
                    <div className="monthlyPriceIndicator">
                      <span className="label">금리</span>
                      <span className="price">{data.installment_options.interest_rate}</span>
                    </div>
                    <div className="totalMonthlyPrice">
                      <span className="label"></span>
                      <span className="priceMonthly">월 {this.setComma(data.installment_options.monthly_billing)}원</span>
                    </div>
                    </>
                  }
                </div>
              </div>
          }

          <div className="floatR disi">
            <Link to="/operation/Estimate"><button type="button" className="btn navyBg ml10">목록</button></Link>
          </div>

        </div>

        {/* 종합정보 검색영역 끝 */}

  
        {/*///// 종합정보 끝 //////*/}
      </Fragment>
    );
  }
}

export default TotalInfo;
