import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import * as Common from "../../component/services/common/CommonService";
import * as Service from '../../component/services/brand/PriceOptionService';
import * as BrandService from "../../component/services/brand/BrandService";
let id = 0;

class PriceOptionDetail extends Component{
  constructor(props){
    super(props)
    this.state = {
      authToken : Common.getToken.call(this),
      isEdit : false,
      totalInfo : [],
      brandId : 0,
      inputData : [],
      nameInput : "",
      priceInput : "",
      descriptionInput : "",
        page_history: this.props.location.search.replace(/\?page=/g, '') ? this.props.location.search.replace(/\?page=/g, '') : 1
    }
  }
  getTotalInfo = aysnc => {
    const brandList = BrandService.getTotalInfo(this.state.authToken);
    brandList.then(response => {
      this.setState({
        totalInfo: response.data.data,
      });
    });
  };

  getDetail = id => {
    const req = Service.getPriceOptionDetail(this.state.authToken , id)
    req.then(res => {
      let data = {
        name : res.data.data[0].name,
        price : res.data.data[0].price,
        description : res.data.data[0].description,
        type : 'call_done',
        id : id++,
        editId : res.data.data[0].id
      }
      this.setState({
        brandId : res.data.data[0].brand_id,
        inputData : this.state.inputData.concat(data)
      } , () => document.querySelector('#brandSelect').disabled = true)
    })
  }

  totalInfoValue = e => {
    if(e.target.name == 'brandId'){
      this.setState({
        brandId : e.target.value
      })
    }
  }

  inputValue = e => {
    if(e.target.name == 'priceInput'){
      if(isNaN(e.target.value)){
        return false
      }
    }
    this.setState({
      [e.target.name] : e.target.value
    })
  }

  onAddPriceOption = () => {
    let data = {
      id : id++,
      type : 'done',
      name : this.state.nameInput,
      price : this.state.priceInput,
      description : this.state.descriptionInput
    }
    this.setState({
      inputData : this.state.inputData.concat(data),
      nameInput : "",
      priceInput : "",
      descriptionInput : ""
    })
  }

  toggleDoneToEdit = (id) => {
    let data = this.state.inputData
    for(var x = 0; x<data.length; x++){
      if(data[x].id == id){
        if(data[x].type == 'call_done'){
          data[x].type = "call_edit"
        }
        else{
          data[x].type = "edit"
        }
      }
    }
    this.setState({
      inputData : data
    })
  }

  toggleEditToDone = (id) => {
    let data = this.state.inputData
    for(var x = 0; x<data.length; x++){
      if(data[x].id !== id){
        continue
      }
      if(data[x].type == 'call_edit'){
        data[x].type = "call_done"
        data[x].name = document.querySelector('#name'+id).value
        data[x].price = document.querySelector('#prcie'+id).value
        data[x].description = document.querySelector('#description'+id).value
      }
      else{
        data[x].type = "done"
        data[x].name = document.querySelector('#name'+id).value
        data[x].price = document.querySelector('#prcie'+id).value
        data[x].description = document.querySelector('#description'+id).value
      }
    }
    this.setState({
      inputData : data
    })
  }

  removeInputData = (id) => {
    let data = this.state.inputData
    for(var x = 0; x<data.length; x++){
      if(data[x].id == id){
        if(data[x].type != 'call_done' && data[x].type != 'call_edit'){
          data.splice(x,1)
        }
        else{
          data[x].type = 'call_delete'
        }
      }
    }
    this.setState({
      inputData : data
    })
  }

  postPriceOption = () => {
    if(this.state.brandId == 0){
      alert('브랜드를 선택해주세요.')
      return false
    }
    if (window.confirm('저장된 가격옵션만 서버에 반영됩니다. 저장하시겠습니까?')){
      const req = Service.postPriceOptions(
        this.state.authToken,
        this.state.brandId,
        this.state.inputData
      )

      let ids = []
      for(let item of this.state.inputData){
        if(item.type === "call_delete"){
          ids.push(item.editId)
        }
      }
      
  
      req.then(res => {
        if(res.status == 200){
          Service.deletePriceOptions(this.state.authToken , ids).then(res => {
            if(res.status === 200){
              alert('수정이 완료 되었습니다.')
              this.props.history.push('/brand/priceOption')
            }else {
              alert('가격 옵션을 삭제하는 중 오류가 발생하였습니다.')

            }
          })
        }
        else {
          alert('가격 옵션을 등록하는 중 오류가 발생하였습니다.')
        }
      })
      return false
    }
  }

  goList = () => {
      const { page_history } = this.state;
    if(this.state.isEdit){
      if(this.state.inputData.length != 1){
        if (window.confirm('현재 수정된 값이 반영되지 않습니다. 그래도 이동하시겠습니까?')){
            this.props.history.goBack();
          // this.props.history.push({
          //     pathname: '/brand/priceOption',
          //     search: `?page=${page_history}`
          // });
          return false
        }
      } else {
          this.props.history.goBack();
      }
    }
    else{
      if(this.state.inputData.length > 0){
        if (window.confirm('현재 수정된 값이 반영되지 않습니다. 그래도 이동하시겠습니까?')){
            this.props.history.goBack();
          return false
        }
      }else{
          this.props.history.goBack();
      }
    }
  }

  componentDidMount(){
    this.getTotalInfo()
    const id = this.props.location.pathname.split('/')
    if(this.props.location.pathname.indexOf('brand_id=') !== -1){
      this.setState({
        isEdit : true
      } , () => {
        const id = this.props.location.pathname.split('brand_id=')
        Service.getPriceOptionListByEdit(id[1],10000,1,this.state.authToken).then(res => {
          let pushData = []
          for(let item of res.data.data){
            pushData.push({
              name : item.name,
              price : item.price,
              description : item.description,
              type : 'call_done',
              id : item.id,
              editId : item.id
            })
          }
          this.setState({
            brandId : id[1],
            inputData : this.state.inputData.concat(pushData)
          } , () => document.querySelector('#brandSelect').disabled = true)
        })
      })
    }
    else if(!isNaN(parseInt(id[id.length-1]))){
      this.setState({
        isEdit : true,
      },() => this.getDetail(parseInt(id[id.length-1])))
    }
  }

    render(){
        return(
            <Fragment>
                {/* //가격옵션 추가.수정페이지 시작 // */}
                <div className="title disi wd100"><p className="mb10"><span>홈</span> 〉 <span>브랜드</span> 〉 <span>가격옵션</span> 〉 <span>추가/수정</span></p>가격옵션 추가/수정 
                        <div className="floatR disi">
                            <button type="button" className="btnLine navyBg" onClick={() => this.postPriceOption()}>저장</button>
                            <button type="button" className="btn navyBg ml10" onClick={() => this.goList()}>목록</button>
                        </div>
                </div>
                <div className="form_tablewrap">
                        <table className="mt20">
                            <colgroup>
                                <col width="10%"></col>
                                <col width="*"></col>
                                <col width="10%"></col>
                                <col width="*"></col>
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>브랜드명 <span className="redT">*</span></th>
                                    <td>
                                        <select className="ml10" id="brandSelect" name="brandId" value={this.state.brandId} onChange={this.totalInfoValue}>
                                          <option value="0">브랜드명</option>
                                          {this.state.totalInfo.map((item, key) => {
                                            return (
                                              <Fragment key={key}>
                                                <option value={item["id"]}>{item["name"]}</option>
                                              </Fragment>
                                            );
                                          })}
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th>가격옵션</th>
                                    <td>
                                        <table className="nonborder_table">
                                            <colgroup>
                                                <col width="15%"></col>
                                                <col width="10%"></col>
                                                <col width=""></col>
                                                <col width="13%"></col>
                                            </colgroup>
                                            <tbody>
                                                {
                                                  this.state.inputData.length > 0 ?
                                                  this.state.inputData.map((item , key) => {
                                                    return(
                                                      item.type == "done" || item.type == "call_done" ?
                                                      <Fragment key={key}>
                                                        <tr>
                                                          <td>&nbsp;&nbsp;&nbsp;{item.name}</td>
                                                          <td>&nbsp;&nbsp;&nbsp;{item.price}</td>
                                                          <td>&nbsp;&nbsp;&nbsp;{item.description}</td>
                                                          <td>
                                                              <button type="submit" className="btn navyBg price_btn" onClick={() => this.toggleDoneToEdit(item.id)}>수정</button>
                                                              <button type="button" className="btnLine navyBg ml10 " onClick={() => this.removeInputData(item.id)}>삭제</button>
                                                          </td>
                                                        </tr>  
                                                      </Fragment> : item.type == "call_delete" ? <></> :
                                                      <Fragment key={key}>
                                                        <tr>
                                                          <td><input type="text" id={"name"+item.id} defaultValue={item.name}></input></td>
                                                          <td><input type="text" id={"prcie"+item.id} defaultValue={item.price}></input></td>
                                                          <td><input type="text" id={"description"+item.id} defaultValue={item.description}></input></td>
                                                          <td>
                                                            <button type="submit" className="btn navyBg price_btn" onClick={() => this.toggleEditToDone(item.id)}>저장</button>
                                                            <button type="button" className="btnLine navyBg ml10" onClick={() => this.removeInputData(item.id)}>삭제</button>
                                                          </td>
                                                        </tr>  
                                                      </Fragment>
                                                    )
                                                  }) : <></>
                                                }                                            
                                                <tr>
                                                    <td><input type="text" placeholder="옵션명" name="nameInput" onChange={this.inputValue} value={this.state.nameInput}></input></td>
                                                    <td><input type="text" placeholder="가격" name="priceInput" onChange={this.inputValue} value={this.state.priceInput}></input></td>
                                                    <td><input type="text" placeholder="옵션상세내용" name="descriptionInput" onChange={this.inputValue} value={this.state.descriptionInput}></input></td>
                                                    <td>
                                                        <button type="button" className="btn navyBg" onClick={() => this.onAddPriceOption()}>추가</button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>    
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="rightT mt20">
                            <button type="button" className="btnLine navyBg" onClick={() => this.postPriceOption()}>저장</button>
                            <button type="button" className="btn navyBg ml10" onClick={() => this.goList()}>목록</button>
                        </div>
                </div>
                {/* // 가격옵션 추가/수정 영역 끝 // */}
            </Fragment>
        );
    }
}

export default PriceOptionDetail;