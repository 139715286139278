import { devUrl } from '../../serviceComm/variable';

const axios = require('axios');

export function getMediaList(authToken) {
  const config = {
    method: 'get',
    url:
        `${devUrl}/admin/cms/providers`,
    headers: {
      //   "x-api-key": "b2cb6699e9c4e10cf36a5f5451309069",
      Authorization: `Bearer  ${authToken}`
    }
  };

  return axios(config);
}

export function deleteMediaItem(authToken, id) {
  const config = {
    method: 'delete',
    url:
        `${devUrl}/admin/cms/providers`,
    headers: {
      //   "x-api-key": "b2cb6699e9c4e10cf36a5f5451309069",
      Authorization: `Bearer  ${authToken}`
    },
    data: {
      provider_ids: id
    }
  };

  return axios(config);
}
