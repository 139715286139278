import { devUrl, keyNumber } from '../../serviceComm/variable';

export function getModelG(authToken , brandId) {
  const axios = require("axios");
  const config = {
    method: "get",
    url:
    devUrl+"/admin/car_db/car_model_groups" +
    `${brandId != 0 ? `?brand_id=${brandId}` : "" }` +
    "&per=" + 100 +
    "&page=" + 1 
    ,
    headers: {
      "x-api-key": keyNumber,
      Authorization: "Bearer " + authToken
    }
  };

  return axios(config);
}

export function getModelGList(brandId, per, page, authToken , name ,is_active , brandName,modelGroup,active,updated,created) {
  const axios = require("axios");
  const config = {
    method: "get",
    url:
      devUrl+"/admin/car_db/car_model_groups" +
      "?per=" + per +
      "&page=" + page +
      "&name=" + name+ "&id_desc=true"+
      `${brandId != 0 ? `&brand_id=${brandId}` : "" }` +
      `${is_active !== "all" ? "&is_active=" + is_active : ""}`+
      `${brandName == "desc" ? "&brand_desc=true" : brandName == "asc" ? "&brand_asc=true" : ""}`+
      `${modelGroup == "desc" ? "&name_desc=true" : modelGroup == "asc" ? "&name_asc=true" : ""}` +
      `${active == "desc" ? "&active_desc=true" : active == "asc" ? "&active_asc=true" : ""}` +
      `${updated == "desc" ? "&updated_desc=true" : updated == "asc" ? "&updated_asc=true" : ""}` +
      `${created == "desc" ? "&created_desc=true" : created == "asc" ? "&created_asc=true" : ""}`
      ,
    headers: {
      "x-api-key": keyNumber,
      Authorization: "Bearer " + authToken
    }
  };
  return axios(config);
}

export const postModelGroup = (authToken , brandId , modelGroupName , vehicleType , isActive) => {
  const axios = require('axios')
  let formData = new FormData()
  formData.set("brand_id" , brandId)
  formData.append("vehicle_type" , vehicleType)
  formData.append("is_active",isActive)
  formData.append("name",modelGroupName)
  const config = {
    method : "post",
    url : devUrl + "/admin/car_db/car_model_groups",
    headers : {
      "x-api-key": keyNumber,
      Authorization: "Bearer " + authToken
    },
    data : formData
  }
  return axios(config)
}

export const editModelGroup = (authToken , brandId , modelGroupName , vehicleType , isActive , id ) => {
  const axios = require('axios')
  let formData = new FormData()
  formData.set("brand_id" , brandId)
  formData.append("vehicle_type" , vehicleType)
  formData.append("is_active",isActive)
  formData.append("name",modelGroupName)
  const config = {
    method : "put",
    url : devUrl + "/admin/car_db/car_model_groups/"+id,
    headers : {
      "x-api-key": keyNumber,
      Authorization: "Bearer " + authToken
    },
    data : formData
  }
  return axios(config)
}

export const deleteModelGroups = (authToken , ids) => {
  const axios = require('axios')
  const config = {
    url : devUrl + '/admin/car_db/car_model_groups',
    headers : {
      "x-api-key": keyNumber,
      Authorization: "Bearer " + authToken
    },
    data : {
      "ids" : ids
    },
    method : "delete"
  }

  return axios(config)
}

export const getDetail = (authToken , id) => {
  const axios = require('axios')
  const config = {
    url : devUrl + '/admin/car_db/car_model_groups/'+id,
    headers : {
      "x-api-key": keyNumber,
      Authorization: "Bearer " + authToken
    },
    method : "get"
  }

  return axios(config)
}