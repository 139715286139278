import React, { memo } from "react";
import { FixedSizeList as List } from "react-window";

const ContentSelectHeight = 35;

function MenuList(props) {
    const { options, children, maxHeight, getValue } = props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * ContentSelectHeight;

    if (!children.length) {
        return (
            <div className="uk-padding-small">
                <span className="uk-icon-color-primary" data-uk-icon="icon: warning" />
                <span className="uk-text-meta uk-margin-small-left">콘텐츠가 존재하지 않습니다.</span>
            </div>
        );
    }

    return (
        <List
            height={maxHeight}
            itemCount={children.length}
            itemSize={ContentSelectHeight}
            initialScrollOffset={initialOffset}
        >
            {({ index, style }) => <div style={style}>{children[index]}</div>}
        </List>
    )
}

export default memo(MenuList);
