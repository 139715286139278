import { devUrl } from '../../serviceComm/variable';

// 포토뉴스(컨텐츠 그룹) 리스트 요청
export function getPhotoNewsList(authToken, per, page) {
  const axios = require('axios');
  const config = {
    method: 'get',
    url: `${devUrl}/admin/cms/content_groups`,
    headers: {
      Authorization: `Bearer ${authToken}`
    },
    params: {
      with_contents: true,
      per: per,
      page: page
    }
  };

  return axios(config);
}

// 포토뉴스(컨텐츠 그룹) 리스트 카운트를 위한 요청
export function getPhotoNewsListCount(authToken, per, total_pages) {
  const axios = require('axios');
  const config = {
    method: 'get',
    url: `${devUrl}/admin/cms/content_groups`,
    headers: {
      Authorization: `Bearer ${authToken}`
    },
    params: {
      per: ((per * total_pages) < 0) ? 1 : (per * total_pages)
    }
  };

  return axios(config);
}

// 검색된 포토뉴스(컨텐츠 그룹) 리스트 카운트를 위한 요청
export function getSearchPhotoNewsListCount(authToken, per, total_pages, formData) {
  const axios = require('axios');
  const config = {
    method: 'get',
    url: `${devUrl}/admin/cms/content_groups`,
    headers: {
      Authorization: `Bearer ${authToken}`
    },
    params: {
      per: ((per * total_pages) < 0) ? 1 : (per * total_pages),
      with_contents: true,
      is_active:
        String(formData.get('is_active')) === 'ALL'
          ? true
          : formData.get('is_active'),
      category_name: formData.get('category_name'),
      start_of_created_at: formData.get('start_of_created_at'),
      end_of_created_at: formData.get('end_of_created_at')
    }
  };

  return axios(config);
}

// 포토뉴스(컨텐츠 그룹) 리스트 검색 요청
export function getSearchPhotoNewsList(authToken, formData, per, page) {
  const axios = require('axios');
  const config = {
    method: 'get',
    url: `${devUrl}/admin/cms/content_groups`,
    headers: {
      Authorization: `Bearer ${authToken}`
    },
    params: {
      with_contents: true,
      is_active:
        String(formData.get('is_active')) === 'ALL'
          ? true
          : formData.get('is_active'),
      category_name: formData.get('category_name'),
      start_of_created_at: formData.get('start_of_created_at'),
      end_of_created_at: formData.get('end_of_created_at'),
      per: per,
      page: page
    }
  };

  return axios(config);
}

// 포토뉴스(컨텐츠 그룹) 삭제 요청
export function deletePhotoNews(authToken, formData) {
  const axios = require('axios');
  const config = {
    method: 'delete',
    url: `${devUrl}/admin/cms/content_groups`,
    headers: {
      Authorization: `Bearer ${authToken}`
    },
    data: formData
  };

  return axios(config);
}
