import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { Editor } from '@tinymce/tinymce-react';

import 'tui-editor/dist/tui-editor.css'; // editor's ui
import 'tui-editor/dist/tui-editor-contents.css'; // editor's content
import 'codemirror/lib/codemirror.css'; // codemirror
import 'highlight.js/styles/github.css'; // code block highlight
import '../../style/contents/contentDetail.scss';

import tEditor from 'tui-editor';
import SunEditor  from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File

import * as Common from '../../component/services/common/CommonService';
import * as Service from '../../component/services/content/ContentDetailService';

let toastEditor = null;
let uploadData = []

class ContentDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authToken: Common.getToken.call(this),
      page_history: this.props.location.search.replace(/\?page=/g, '') ? this.props.location.search.replace(/\?page=/g, '') : 1,
      categorys: [{
        id: 0,
        name: '뉴스',
        selected: true
      },
        {
          id: 1,
          name: '시승기',
          selected: false
        }
      ],
      medias: [],
      featured_news: [],
      provider_id: 0,
      content_id: 0,
      editors: [],
      editor: '',
      published_at: new Date(),
      revised_at: new Date(),
      is_revised: false,
      category: 0,
      title: '',
      thumbnail_id: 0,
      is_active: 'true',
      is_draft: 'false',
      brand_id: 0,
      car_model_group_id: 0,
      car_model_id: null,
      content_tags: [],
      brandGroup: {
        brands: [],
        model_groups: [],
        models: []
      },
      is_on_pop: 'false',
      thumbnail_src: [],
      is_featured_news: 'false',
      checked_featured_news: false,
      html : "",
      tinymce: {
        html: null
      },
      is_new_editor: true,
      editor_loading: true,
      loading: true
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.getContentInfo();
    if (id == null) {
      // 새 컨텐츠 등록
      this.initDrafts();
    } else {
      // 컨텐츠 수정
      this.setState(
        {
          content_id: id,
          is_revised: true
        },
        () => this.getContent()
      );
    }
    this.getMediaList();
    toastEditor = new tEditor({
      el: document.querySelector('#editSection'),
      initialEditType: 'wysiwyg', // 'markdown'
      previewStyle: 'tab',
      width : '704px',
      height: '300px',
      hooks: {
        addImageBlobHook: (blob, callback) => {
          this.putContentImage(uploadData, callback);
        }
      }
    });

    document.querySelector('.te-image-file-input').multiple = true
    document.querySelector('.te-image-file-input').onchange = () => {

      var inputData = []
      for(var x = 0; x<document.querySelector('.te-image-file-input').files.length; x++){
        inputData.push(document.querySelector('.te-image-file-input').files[x])
      }
        uploadData = inputData
    }

    this.getBrandSimples();
  }

  // 컨텐츠 정보(카테고리)를 불러옴
  getContentInfo = () => {
    const { authToken } = this.state;
    Service.getContentInfo(authToken).then((response) => {
      this.setState({
        categorys: Object.entries(response.data.content.category).map((item) => (
          item = {
            id: item[0],
            name: item[1],
            selected: false
          }
        ))
      });
    });
  }

  // 초안 추가
  initDrafts = () => {
    const { authToken } = this.state;
    const initDrafts = Service.initDrafts(authToken);
    initDrafts
      .then((response) => {
        this.setState({
          content_id: response.data.data.id,
          loading: false
        });
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.message) {
            alert(error.response.data.message);
          } else {
            alert(`초안 등록 중 오류가 발생하였습니다.\r\n${error}`);
          }
        } else {
          alert(`초안 등록 중 오류가 발생하였습니다.\r\n${error}`);
        }
      });
  };

  // 컨텐츠 수정 시 해당 컨텐츠 요청
  getContent = () => {
    const { authToken, content_id } = this.state;
    const content = Service.getContent(
      authToken,
      content_id
    );
    content.then((response) => {
      this.setState(
        {
          ...this.state,
          provider_id: response.data.data.provider.id,
          editor: response.data.data.author,
          title: response.data.data.title,
          thumbnail_id: response.data.data.thumbnail ? response.data.data.thumbnail.id : 0,
          published_at: new Date(response.data.data.published_at),
          revised_at: new Date(response.data.data.revised_at),
          content_tags: response.data.data.tags,
          is_draft: response.data.data.is_draft === true ? 'true' : 'false',
          is_active: response.data.data.is_active === true ? 'true' : 'false',
          is_featured_news: response.data.data.is_featured_news === true ? 'true' : 'false',
          checked_featured_news: response.data.data.is_featured_news,
          brand_id: response.data.data.brand_id,
          car_model_group_id: response.data.data.car_model_group_id,
          car_model_id: response.data.data.car_model_id,
          html : response.data.data.html,
          thumbnail_src: response.data.data.images,
          is_new_editor: new Date(response.data.data.published_at) >= new Date('2020-07-29'),
          tinymce: {
            html: response.data.data.html
          },
          loading: false
        },
        () => {
          const { provider_id } = this.state;
          this.getEditorList(provider_id);
          if (response.data.data.brand_id && response.data.data.car_model_group_id && response.data.data.car_model_id) {
            this.handleContentCarModelId();
          }
          this.setContentCategory(response.data.data.category);
        }
      );
      document.querySelector('.se-wrapper-wysiwyg').innerHTML = response.data.data.html
    });
  };

  // 불러온 컨텐츠 카테고리 설정
  setContentCategory = (category) => {
    const { categorys } = this.state;
    const index = categorys.findIndex((item) => item.name === String(category));
    const selected = categorys.filter((item) => item.name === String(category))[0];
    const temp = categorys;
    temp[index] = {
      ...selected,
      selected: !selected.selected
    }
    this.setState({
      categorys: temp,
      category: selected.id
    });
  }

  // 매체 목록 요청
  getMediaList = async () => {
    const { authToken } = this.state;
    const mediaList = Service.getMediaList(authToken);
    mediaList
      .then((response) => {
        this.setState({
          medias: response.data.data.filter((item) => item.category === '컨텐츠')
        });
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.message) {
            alert(error.response.data.message);
          } else {
            alert(`매체 목록을 불러오는 중 오류가 발생하였습니다.\r\n${error}`);
          }
        } else {
          alert(`매체 목록을 불러오는 중 오류가 발생하였습니다.\r\n${error}`);
        }
      });
  };

  // 해당 매체에 속한 편집자 목록 요청
  getEditorList = (id) => {
    const { authToken } = this.state;
    if (Number(id) !== 0) {
      const editorList = Service.getEditorList(authToken, id);
      editorList
        .then((response) => {
          this.setState({
            editors:
              response.data.data.editors != null
                ? response.data.data.editors
                : []
          });
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.message) {
              alert(error.response.data.message);
            } else {
              alert(`편집자 목록을 불러오는 중 오류가 발생하였습니다.\r\n${error}`);
            }
          } else {
            alert(`편집자 목록을 불러오는 중 오류가 발생하였습니다.\r\n${error}`);
          }
        });
    } else {
      this.setState({
        editors: [],
        editor: ''
      });
    }
  };

  // 매체 및 편집자 Select Box onChange
  setOnChange = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    this.setState({
      [name]: value
    });
    if (String(name) === 'provider_id') {
      this.getEditorList(value);
    }
  };

  // 추천뉴스 리스트 렌더링
  getFeaturedNewsList = () => {
    const { authToken } = this.state;
    const feturedNewsList = Service.getFeaturedNewsList(authToken);
    feturedNewsList.then((response) => {
      this.setState({
        featured_news: response.data.data
      });
    });
  };

  // 등록일시 onChange
  setDateOnChange = (date) => {
    this.setState({
      published_at: date
    });
  };

  // 수정일시 onChange
  setRevisedDateOnChange = (date) => {
    this.setState({
      revised_at: date
    });
  };

  // Input onChange
  setInputOnChange = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    if (String(name) === 'content_tags') {
      this.setState({
        [name]: value.split(',')
      });
    } else {
      this.setState({
        [name]: value
      });
    }
  };

  // 추천뉴스 목록 PopUp Open
  openRecommendNewsPopUp = () => {
    const { is_on_pop } = this.state;
    if (is_on_pop === 'true') {
      this.setState({
        is_on_pop: 'false'
      });
    } else {
      this.setState(
        {
          is_on_pop: 'true'
        },
        () => {
          this.getFeaturedNewsList();
        }
      );
    }
  };

  // 추천뉴스 Wrap Inner 클릭 시 Wrap로 이벤트가 전파되지 않도록
  popUpStopPropagation = (e) => {
    e.stopPropagation();
  };

  // Content 이미지 업로드
  putContentImage = (image, callback) => {
    const { authToken, content_id } = this.state;
    const start = this.state.thumbnail_src.length;

    this.setState({
      thumbnail_src: []
    }, () => {
      const { thumbnail_src } = this.state;
      // 다중 업로드
      // for (let i = 0; i < e.target.files.length; i++) {
      //   formData.append('images[]', e.target.files[i]);
      //   console.log(e.target.files[i]);
      // }

      const putContentImage = Service.putContentImage(
        authToken,
        content_id,
        image
      );

      putContentImage
        .then((response) => {
          for(var x = start; x<response.data.data.images.length; x++){
            callback(response.data.data.images[x].url);
            document.querySelector('.se-wrapper-inner.se-wrapper-wysiwyg.sun-editor-editable').innerHTML =
            document.querySelector('.se-wrapper-inner.se-wrapper-wysiwyg.sun-editor-editable').innerHTML +
            `<img src="${response.data.data.images[x].url}" alt="content_img"></img>`
          }
          this.setState({
            thumbnail_src: thumbnail_src.concat(
              response.data.data.images
            )
          });
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.message) {
              alert(error.response.data.message);
            } else {
              alert(`이미지 업로드 중 오류가 발생하였습니다.\r\n${error}`);
            }
          } else {
            alert(`이미지 업로드 중 오류가 발생하였습니다.\r\n${error}`);
          }
        });
    });
  };

  returnNatural = (url) =>{
    return new Promise(res => {
      const img = new Image();
      img.addEventListener('load', () => {
        res(img.naturalHeight / img.naturalWidth)
      })
      img.src = url
    })
  }

  componentDidUpdate(){
    for(var x  = 0; x<this.state.thumbnail_src.length; x++){
      let img = document.querySelectorAll('.thumb_list li img')[x]
      this.returnNatural(this.state.thumbnail_src[x].url)
      .then(ratio => {
        if(ratio >= 0.5506 && ratio <= 0.5690) {
          img.style.display = "block"
        }else{
          img.style.display = "none"
        }
      })
    }
  }

  // 콘텐츠 썸네일 이미지 선택
  handleThumbnail = (id) => {
    this.setState({
      thumbnail_id: id
    });
  }

  // 유효성 검사 후 최종 수정
  submitContentVaild = (e) => {
    e.preventDefault();
    const format = require('date-format');
    let { published_at } = this.state;
    published_at = format('yyyy-MM-dd', published_at);
    const now = format('yyyy-MM-dd', new Date());
    if (published_at > now) {
      // 발행일이 미래라면 비공개로 설정
      this.setState(
        {
          is_active: false
        },
        () => this.submitContent()
      );
    } else {
      this.submitContent();
    }
  };

  // 컨텐츠 최종 등록
  submitContent = () => {
    const format = require('date-format');
    const {
      authToken,
      editor,
      title,
      category,
      provider_id, content_id, thumbnail_id, content_tags,
      is_active, is_draft, is_featured_news, car_model_id,
      checked_featured_news, brandGroup, is_revised,
      is_new_editor, tinymce: { html: tinymceHTML }
    } = this.state;
    let { published_at, revised_at } = this.state;
    const formData = new FormData();
    let html = document.querySelector('.se-wrapper-wysiwyg').innerHTML;

    // <p> 사이에 <br>이 안들어가서 Enter가 먹지 않는 버그로 해당 코드 추가
    html = html.replace(/\<\/p\>/gi, '</p><br>');
    let sanitized_html = html.replace(/(<([^>]+)>)/ig, '').trim();

    if (is_new_editor) {
     sanitized_html = window.tinyMCE.get('new-editor-tinymce').getContent({format: 'text'});
    }

    published_at = format('yyyy-MM-dd', published_at);
    revised_at = format('yyyy-MM-dd', revised_at);

    if (provider_id === 0) {
      alert('매체를 선택해주세요.');
      return false;
    }
    if (editor === null || editor.length === 0) {
      alert('편집자를 선택해주세요.');
      return false;
    }
    if (content_tags === null || content_tags.length === 0 || content_tags[0].length === 0) {
      alert('태그를 입력해주세요.');
      return false;
    }
    if (title === null || title.length === 0) {
      alert('제목을 입력해주세요.');
      return false;
    }
    if (html === null || html.length === 0) {
      alert('내용을 입력해주세요.');
      return false;
    }
    if (thumbnail_id === 0) {
      alert('썸네일 이미지를 등록해주세요.');
      return false;
    }
    formData.append('id', content_id);
    formData.append('provider_id', provider_id);
    formData.append('author', editor);
    formData.append('title', title);
    formData.append('html', is_new_editor ? tinymceHTML : html);
    formData.append('sanitized_html', sanitized_html);
    formData.append('published_at', published_at);
    formData.append('revised_at', revised_at);
    formData.append('category', category);
    formData.append('thumbnail_id', thumbnail_id);
    formData.append('is_active', is_active);
    formData.append('is_draft', is_draft);
    formData.append('is_featured_news', is_featured_news);
    if(is_revised) {
      formData.append('car_model_id', car_model_id ? car_model_id : null);
    } else if(car_model_id && car_model_id !== 0) {
      formData.append('car_model_id', car_model_id);
    }
    content_tags.forEach((i) => {
      formData.append('content_tags[][name]', i);
    });
    const putContents = Service.putContents(authToken, formData);
    const { history } = this.props;
    let selectCount = 0;
    selectCount += brandGroup.brands.filter((item) => item.selected === true).length;
    selectCount += brandGroup.model_groups.filter((item) => item.selected === true).length;
    selectCount += brandGroup.models.filter((item) => item.selected === true).length;
    let confirm = true;
    if (selectCount >= 1 && selectCount <= 2) {
      confirm = window.confirm('자동차 모델을 선택하지 않으셨습니다. 만약 기존에 자동차 모델이 매핑되어 있다면 삭제됩니다. 계속하시겠습니까?');
    }
    if (confirm) {
      putContents
        .then((response) => {
          if (response.status === 200 || (response.status === 201 && response.statusText === 'OK')) {
            if (is_featured_news === 'true' && !checked_featured_news) {
              const fnFormData = new FormData();
              fnFormData.append('content_id', content_id);
              const feturedNews = Service.putFeaturedNewsList(
                authToken,
                fnFormData
              );
              feturedNews
                .then((fnNews) => {
                  if (fnNews.status === 200 || (fnNews.status === 201 && fnNews.statusText === 'OK')) {
                    alert('정상적으로 처리되었습니다.');
                    history.push('/contents/content');
                  }
                })
                .catch((error) => {
                  if (error.response) {
                    if (error.response.data.message) {
                      alert(error.response.data.message);
                    } else {
                      alert(`추천뉴스 등록 중 오류가 발생하였습니다.\r\n${error}`);
                    }
                  } else {
                    alert(`추천뉴스 등록 중 오류가 발생하였습니다.\r\n${error}`);
                  }
                });
            } else {
              alert('정상적으로 처리되었습니다.');
              history.push('/contents/content');
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.message) {
              alert(error.response.data.message);
            } else {
              alert(`컨텐츠 등록 중 오류가 발생하였습니다.\r\n${error}`);
            }
          } else {
            alert(`컨텐츠 등록 중 오류가 발생하였습니다.\r\n${error}`);
          }
        });
    }
  };

  // 컨텐츠 수정일 경우에만 DatePicker 렌더링
  setRevisedAtDatePicker = () => {
    const { is_revised, revised_at } = this.state;
    if (is_revised) {
      return (
        <td>
          <DatePicker
            onChange={this.setRevisedDateOnChange}
            selected={revised_at}
          />
        </td>
      );
    } else {
      return (<td>-</td>);
    }
  }

  // 브랜드 심플 리스트 렌더링
  getBrandSimples = () => {
    const { authToken } = this.state;
    let { brandGroup } = this.state;
    const brandSimples = Service.getBrandSimples(authToken);
    brandSimples.then((response) => {
      this.setState({
        brandGroup: brandGroup = {
          ...brandGroup,
          brands: response.data.data.map((item) => ({ ...item, selected: false }))
        }
      });
    });
  }

  // 브랜드에 속한 모델 그룹 심플 리스트 렌더링
  getModelGroupSimples = (type) => {
    const { authToken } = this.state;
    let { brandGroup } = this.state;
    const selected = brandGroup.brands.filter((item) => item.selected === true);
    const modelGroupSimples = Service.getModelGroupSimples(authToken, selected[0].id);
    modelGroupSimples.then((response) => {
      this.setState({
        brandGroup: brandGroup = {
          ...brandGroup,
          model_groups: response.data.data.map((item) => ({ ...item, selected: false })),
          models: []
        }
      }, () => {
        if (type === 'modify') {
          const { car_model_group_id } = this.state;
          const temp = this.state.brandGroup;
          const index = this.state.brandGroup.model_groups.findIndex(
            (item) => item.id === Number(car_model_group_id)
          );
          const selected2 = this.state.brandGroup.model_groups[index];
          if(selected2) {
            temp.model_groups[index] = {
              ...selected2,
              selected: !selected2.selected
            }
            this.setState({
              brandGroup: temp
            }, () => this.getCarModelSimples(type))
          }
        }
      });
    })
  }

  // 모델 그룹에 속한 자동차 모델 심플 리스트 렌더링
  getCarModelSimples = (type) => {
    const { authToken } = this.state;
    let { brandGroup } = this.state;
    const selectedBrand = brandGroup.brands.filter((item) => item.selected === true);
    const selectedModelGroup = brandGroup.model_groups.filter((item) => item.selected === true);
    const carModelSimples = Service.getCarModelSimples(
      authToken, selectedBrand[0].id, selectedModelGroup[0].id
    );
    carModelSimples.then((response) => {
      this.setState({
        brandGroup: brandGroup = {
          ...brandGroup,
          models: response.data.data.map((item) => ({ ...item, selected: false }))
        }
      }, () => {
        if (type === 'modify') {
          const { car_model_id } = this.state;
          const temp = this.state.brandGroup;
          const index = this.state.brandGroup.models.findIndex((item) => item.id === Number(car_model_id));
          const selected = this.state.brandGroup.models[index];
          temp.models[index] = {
            ...selected,
            selected: !selected.selected
          }
          this.setState({
            brandGroup: temp
          }, () => this.setCarModelId())
        }
      });
    })
  }

  // 자동차 모델 ID 설정
  setCarModelId = () => {
    const { brandGroup } = this.state;
    const selected = brandGroup.models.filter((item) => item.selected === true);
    this.setState({
      car_model_id: selected[0].id
    })
  }

  // 자동차 모델 ID Select
  handleCarModelId = (e) => {
    const { target } = e;
    let { brandGroup } = this.state;

    if (target.name === 'brands') {
      if (!(Number(target.value) === 0)) {
        this.setState({
          brandGroup: brandGroup = {
            ...brandGroup,
            brands: brandGroup.brands.map((item) => ({ ...item, selected: false }))
          }
        }, () => {
          const temp = brandGroup;
          const index = brandGroup.brands.findIndex((item) => item.id === Number(target.value));
          const selected = brandGroup.brands[index];
          temp.brands[index] = {
            ...selected,
            selected: !selected.selected
          }
          this.setState({
            brandGroup: temp,
            car_model_id: null
          }, () => this.getModelGroupSimples('normal'))
        })
      } else {
        this.setState({
          brandGroup: brandGroup = {
            brands: brandGroup.brands.map((item) => ({ ...item, selected: false })),
            model_groups: [],
            models: []
          },
          car_model_id: null
        })
      }
    } else if (target.name === 'model_groups') {
      if (!(Number(target.value) === 0)) {
        this.setState({
          brandGroup: brandGroup = {
            ...brandGroup,
            model_groups: brandGroup.model_groups.map((item) => ({ ...item, selected: false }))
          }
        }, () => {
          const temp = brandGroup;
          const index = brandGroup.model_groups.findIndex(
            (item) => item.id === Number(target.value)
          );
          const selected = brandGroup.model_groups[index];
          temp.model_groups[index] = {
            ...selected,
            selected: !selected.selected
          }
          this.setState({
            brandGroup: temp,
            car_model_id: null
          }, () => this.getCarModelSimples('normal'))
        });
      } else {
        this.setState({
          brandGroup: brandGroup = {
            ...brandGroup,
            model_groups: brandGroup.model_groups.map((item) => ({ ...item, selected: false })),
            models: []
          },
          car_model_id: null
        })
      }
    } else if (target.name === 'models') {
      if (!(Number(target.value) === 0)) {
        this.setState({
          brandGroup: brandGroup = {
            ...brandGroup,
            models: brandGroup.models.map((item) => ({ ...item, selected: false }))
          }
        }, () => {
          const temp = brandGroup;
          const index = brandGroup.models.findIndex((item) => item.id === Number(target.value));
          const selected = brandGroup.models[index];
          temp.models[index] = {
            ...selected,
            selected: !selected.selected
          }
          this.setState({
            brandGroup: temp
          }, () => this.setCarModelId())
        });
      } else {
        this.setState({
          brandGroup: brandGroup = {
            ...brandGroup,
            models: brandGroup.models.map((item) => ({ ...item, selected: false }))
          },
          car_model_id: null
        })
      }
    }
  }

  // 컨텐츠 수정 시 자동차 모델 ID Select
  handleContentCarModelId = () => {
    const { brandGroup, brand_id } = this.state;
    const temp = brandGroup;
    const index = brandGroup.brands.findIndex((item) => item.id === Number(brand_id));
    const selected = brandGroup.brands[index];
    temp.brands[index] = {
      ...selected,
      selected: !selected.selected
    }
    this.setState({
      brandGroup: temp
    }, () => {
      this.getModelGroupSimples('modify');
    });
  }

  // 컨텐츠 카테고리 설정
  handleCategory = (e) => {
    const { target } = e;
    const { categorys } = this.state;
    this.setState({
      categorys: categorys.map((item) => ({
        ...item,
        selected: false
      }))
    }, () => {
      const index = this.state.categorys.findIndex((item) => Number(item.id) === Number(target.value));
      const selected = this.state.categorys.filter((item) => Number(item.id) === Number(target.value))[0];
      const temp = this.state.categorys;
      temp[index] = {
        ...selected,
        selected: !selected.selected
      }
      this.setState({
        categorys: temp,
        category: selected.id
      }, () => {
        console.log(this.state.categorys);
      });
    })
  }

  handleEditorLoading = () => {
    this.setState({
      editor_loading: false
    });
  };

  handleEditor = (content, editor) => {
    this.setState({
      tinymce: {
        html: content
      }
    });
  };

  handleEditorImage = (blobInfo, success, failure) => {
      const { authToken, content_id } = this.state;
      const image = new File([blobInfo.blob()], blobInfo.filename());
      Service.putContentImageNew(authToken, content_id, image).then((response) => {
        const { data: { images } } = response.data;

        this.setState({
          thumbnail_src: images
        }, () => success(images[images.length - 1].url));
      }).catch((error) => {
        failure(error);
      });
  };

  render() {
    const thumbParentStyle = {
      display: 'flex',
      marginBottom: '5px'
    };
    const {
      page_history, categorys, medias, editor, editors, title, brandGroup,
      provider_id, thumbnail_id, published_at,
      is_featured_news, content_tags, thumbnail_src, is_active, is_on_pop, featured_news,
      checked_featured_news , editorState , content_id , authToken,
      tinymce: { html }, is_new_editor, editor_loading, loading
    } = this.state;

    let thumbnailImg = new Image()

    return (
      <>
        <div className="title disi wd100">
          <p className="mb10">
            <span>홈</span>
            {' '}
            〉
            {' '}
            <span>콘텐츠</span>
            {' '}
            〉
            {' '}
            <span>콘텐츠등록/관리</span>
            {' '}
            〉
            {' '}
            <span>등록/수정</span>
          </p>
          콘텐츠등록/관리 등록/수정
          <div className="floatR disi">
              <Link to="/contents/content">
                <button
                  type="button"
                  className="btnLine navyBg"
                  onClick={this.submitContentVaild}
                >
                  저장
                </button>
              </Link>
              <button type="button" className="btn navyBg ml10" onClick={() => this.props.history.goBack()}>
                목록
              </button>
            </div>
        </div>
        <div className="form_tablewrap">
          <form>
            <table className="mt20 content_reset">
              <colgroup>
                <col width="200px" />
                <col width="" />
                <col width="200px" />
                <col width="" />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    매체명
                    {' '}
                    <span className="redT">*</span>
                  </th>
                  <td>
                    <select
                      name="provider_id"
                      value={provider_id}
                      onChange={this.setOnChange}
                    >
                      <option value="0">매체선택</option>
                      {medias.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </td>
                  <th>
                    등록일
                    <span className="redT" />
                  </th>
                  <td>
                    <DatePicker
                      onChange={this.setDateOnChange}
                      selected={published_at}
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    편집자
                    {' '}
                    <span className="redT">*</span>
                  </th>
                  <td>
                    <select
                      name="editor"
                      value={editor}
                      onChange={this.setOnChange}
                    >
                      <option value="None">편집자선택</option>
                      {editors !== null && editors.map((item, index) => (
                        <option key={index} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </td>
                  <th>수정일</th>
                  {this.setRevisedAtDatePicker()}
                </tr>
                <tr>
                  <th>
                    카테고리
                    {' '}
                    <span className="redT">*</span>
                  </th>
                  <td colSpan="3">
                    <select name="category" onChange={this.handleCategory}>
                      {categorys.map((item, index) => (
                        <option key={index} name="category" value={item.id} selected={item.selected}>{item.name}</option>
                      ))}
                    </select>
                  </td>
                </tr>
                <tr>
                  <th>
                    브랜드 / 모델그룹 / 모델검색
                  </th>
                  <td colSpan="3">
                    <select name="brands" onChange={this.handleCarModelId}>
                      <option value={0}>브랜드명</option>
                      {brandGroup.brands.map((item) => (
                        <option key={item.id} value={item.id} selected={item.selected}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    <select name="model_groups" className="ml10" onChange={this.handleCarModelId}>
                      <option value={0}>모델그룹명</option>
                      {brandGroup.model_groups.map((item) => (
                        <option key={item.id} value={item.id} selected={item.selected}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    <select name="models" className="ml10" onChange={this.handleCarModelId}>
                      <option value={0}>모델명</option>
                      {brandGroup.models.map((item) => (
                        <option key={item.id} value={item.id} selected={item.selected}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
                <tr>
                  <th>
                    태그
                    {' '}
                    <span className="redT">*</span>
                  </th>
                  <td colSpan="3" className="cont_input">
                    <input
                      type="text"
                      placeholder="콤마(,)로 구분해서 작성해주세요. ex)현대,제네시스,현대자동차"
                      name="content_tags"
                      value={content_tags}
                      onChange={this.setInputOnChange}
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    콘텐츠 제목
                    {' '}
                    <span className="redT">*</span>
                  </th>
                  <td colSpan="3" className="cont_input">
                    <input
                      type="text"
                      name="title"
                      value={title}
                      onChange={this.setInputOnChange}
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    콘텐츠 내용
                    {' '}
                    <span className="redT">*</span>
                  </th>
                  <td colSpan="3" className="cont_input">
                    <div className={`content-editor${(loading || editor_loading) ? ' on-editor' : ''}`}>
                      <div className="uk-flex-column uk-flex uk-flex-middle uk-flex-center uk-margin-large-top uk-margin-large-bottom">
                        <div className='uk-spinner' data-uk-spinner="ratio: 2" />
                        <h5>Loading Editor</h5>
                      </div>
                    </div>
                    <div className={`content-editor${(!loading && !editor_loading && is_new_editor) ? ' on-editor' : ''}`}>
                      <Editor
                          apiKey="fad476mpbnjhsegfut6le11xdm32xj2smrqxa9cs14qpjczi"
                          id="new-editor-tinymce"
                          init={{
                            width: 820,
                            height: 600,
                            menubar: true,
                            plugins: [
                              'print preview paste importcss searchreplace',
                              'autolink autosave directionality code',
                              'visualblocks visualchars fullscreen image link',
                              'media template codesample table charmap hr pagebreak',
                              'nonbreaking anchor toc insertdatetime advlist lists',
                              'wordcount textpattern noneditable help',
                              'charmap quickbars'
                            ],
                            toolbar: "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample ltr rtl | code",
                            images_upload_handler: (blobInfo, success, failure) => this.handleEditorImage(blobInfo, success, failure),
                            paste_data_images: true,
                            contextmenu: "link image imagetools table",
                            toolbar_sticky: true,
                            autosave_ask_before_unload: true,
                            autosave_interval: "30s",
                            autosave_prefix: "{path}{query}-{id}-",
                            autosave_restore_when_empty: false,
                            autosave_retention: "2m",
                            image_advtab: true,
                            image_caption: true,
                            quickbars_selection_toolbar: "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
                            noneditable_noneditable_class: "mceNonEditable",
                            toolbar_mode: "sliding",
                            language: "ko_KR",
                            content_style: "img { max-width: 100%; }"
                          }}
                          value={html}
                          onInit={this.handleEditorLoading}
                          onEditorChange={this.handleEditor}
                      />
                    </div>
                    <div className={`content-editor${!loading && !editor_loading && !is_new_editor ? ' on-editor' : ''}`}>
                      <SunEditor
                          lang="ko"
                          setOptions={{
                            maxWidth :'720px',
                            height : 500,
                            buttonList : [['undo', 'redo',
                              'font', 'fontSize', 'formatBlock',
                              'paragraphStyle',
                              'bold', 'underline', 'italic', 'strike', 'subscript', 'superscript',
                              'fontColor', 'hiliteColor', 'textStyle',
                              'removeFormat',
                              'outdent', 'indent',
                              'align', 'horizontalRule', 'list', 'lineHeight',
                              'table', 'link', 'video',
                              'fullScreen', 'showBlocks', 'codeView',
                              'preview', 'print', 'save', 'template']],
                          }}
                      />
                      <div id="editSection" className="taw" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>
                    썸네일 이미지 지정
                    {' '}
                    <span className="redT">*</span>
                  </th>
                  <td colSpan="3">
                    <ul className="thumb_list" style={thumbParentStyle}>
                      {
                        thumbnail_src !== null && thumbnail_src.map((item) =>
                        (
                          <li key={item.id} onClick={() => this.handleThumbnail(item.id)}>
                            <img
                              className={thumbnail_id === item.id ? 'active thumb_img ' : 'thumb_img '}
                              src={item.url}
                              alt="썸네일 이미지"
                            />
                          </li>
                        ))
                      }
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th>
                    공개여부
                    {' '}
                    <span className="redT">*</span>
                  </th>
                  <td colSpan="3">
                    <input
                      type="radio"
                      name="is_active"
                      id="veiw_qu_ok"
                      checked={is_active === 'true'}
                      onChange={this.setOnChange}
                      value="true"
                    />
                    <label htmlFor="veiw_qu_ok">공개</label>
                    <input
                      type="radio"
                      name="is_active"
                      id="veiw_qu_no"
                      className="ml10"
                      checked={is_active === 'false'}
                      onChange={this.setOnChange}
                      value="false"
                    />
                    <label htmlFor="veiw_qu_no">비공개</label>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="rightT mt20">
              <Link>
                <button
                  type="button"
                  className="btnLine navyBg"
                  onClick={this.submitContentVaild}>
                  저장
                </button>
              </Link>
              <button type="button" className="btn navyBg ml10" onClick={() => this.props.history.goBack()}>
                목록
              </button>
            </div>
          </form>
        </div>

        {/* // 추천뉴스 리스트 팝업 시작 // */}
        <div className={is_on_pop === 'true' ? 'popWrap recomend_news_pop on ' : 'popWrap recomend_news_pop'} onClick={this.openRecommendNewsPopUp} role="presentation">
{/*
          <div className="popBg" />
*/}
          <div className="pop_cont" onClick={this.popUpStopPropagation} role="presentation">
            <div className="pop_title">
              <p>추천뉴스 리스트</p>
              <span onClick={this.openRecommendNewsPopUp} role="presentation">×</span>
            </div>
            <div className="pop_contwrap">
              <table>
                <thead>
                  <tr>
                    <th>매체명</th>
                    <th>콘텐츠 제목</th>
                    <th>등록일</th>
                  </tr>
                </thead>
                <tbody>
                  {featured_news.length !== 0 && featured_news.map((item) => (
                    <tr key={item.id}>
                      <td className="leftT">{item.provider_name}</td>
                      <td>
                        <span>{item.title}</span>
                      </td>
                      <td>{item.published_at}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="btnwrap centerT mt20 mb10">
              <button
                type="button"
                className="btn navyBg ml10"
                onClick={this.openRecommendNewsPopUp}
              >
                닫기
              </button>
            </div>
          </div>
        </div>
        {/* // 추천뉴스 리스트 팝업 끝 // */}
      </>
    );
  }
}

export default ContentDetail;
