import { devUrl, keyNumber } from '../../serviceComm/variable';

export function getPriceOption(authToken) {
  const axios = require("axios");
  const config = {
    method: "get",
    url:
    devUrl+"/admin/brands/price_options",
    headers: {
      "x-api-key": keyNumber,
      Authorization: "Bearer " + authToken
    }
  };

  return axios(config);
}

export function getPriceOptionDetail(authToken , id) {
  const axios = require("axios");
  const config = {
    method: "get",
    url:
    devUrl+"/admin/brands/price_options?ids[]="+id,
    headers: {
      "x-api-key": keyNumber,
      Authorization: "Bearer " + authToken
    }
  };

  return axios(config);
}

export function getPriceOptionList(brandId, per, page, authToken , brand_order ,priceOptionName) {
  const axios = require("axios");
  const config = {
    method: "get",
    url:
      devUrl+"/admin/brands/price_options" +
      "?per=" +
      per +
      "&page=" +
      page +
      "&name=" + priceOptionName +
      `${brandId != 0 ? `&brand_id=${brandId}` : "" }` +
      `${brand_order=="desc" ? "&name_desc=true" : brand_order=="asc" ? "&name_asc=true" : ""}` +
      "&id_desc=" + true 
      ,
    headers: {
      "x-api-key": keyNumber,
      Authorization: "Bearer " + authToken
    }
  };

  return axios(config);
}

export function getPriceOptionListByEdit(brandId, per, page, authToken) {
  const axios = require("axios");
  const config = {
    method: "get",
    url:
      devUrl+"/admin/brands/price_options" +
      "?per=" +
      per +
      "&page=" +
      page +
      `${brandId != 0 ? `&brand_id=${brandId}` : "" }` +
      "&id_desc=" + true 
      ,
    headers: {
      "x-api-key": keyNumber,
      Authorization: "Bearer " + authToken
    }
  };

  return axios(config);
}

export function getPriceOptionListByTrim(authToken , brandId) {
  const axios = require("axios");
  const config = {
    method: "get",
    url:
      devUrl+`/admin/brands/price_options?brand_id=${brandId}` ,
    headers: {
      "x-api-key": keyNumber,
      Authorization: "Bearer " + authToken
    }
  };

  return axios(config);
}

export const postPriceOptions = (authToken , brandId , data) => {
  const axios = require('axios')
  let jsonData = {
    price_options : []
  }
  for(let x = 0; x<data.length; x++){
    if(data[x].type == 'done'){
      jsonData.price_options.push({
        brand_id : brandId,
        name : data[x].name,
        price : data[x].price,
        description : data[x].description
      })
    }else if(data[x].type == 'call_done'){
      jsonData.price_options.push({
        id : data[x].editId,
        brand_id : brandId,
        name : data[x].name,
        price : data[x].price,
        description : data[x].description
      })
    }
  }
  const config = {
    method : "POST",
    headers: {
      "x-api-key": keyNumber,
      Authorization: "Bearer " + authToken
    },
    url : devUrl + '/admin/brands/price_options',
    data : jsonData
  }

  return axios(config)
}

export const deletePriceOptions = (authToken , ids ) => {
  const axios = require('axios')
  const config = {
    method : "DELETE",
    headers: {
      "x-api-key": keyNumber,
      Authorization: "Bearer " + authToken
    },
    url : devUrl + '/admin/brands/price_options',
    data : {ids : ids}
  }

  return axios(config)
}