import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';

import Pagination from '../../component/Pagination';

import * as Common from '../../component/services/common/CommonService';
import * as Service from '../../component/services/content/MovieService';
import { clientUrl } from '../../component/serviceComm/variable';
import QueryString from 'query-string';


class Movie extends Component {q
  constructor(props) {
    super(props);
    const queryStringHistory = QueryString.parse(this.props.location.search);
    this.state = {
      authToken: Common.getToken.call(this),
      dataList: [
        {
          // default
          id: 2448,
          uploader: 'fastview videos',
          title: 'this is title',
          url: 'https://www.youtube.com/watch?v=kR77WlHRZrs',
          car_model_id: 5,
          created_at: '2019-10-20T12:47:13.371+09:00',
          updated_at: '2019-10-20T12:47:13.406+09:00',
          view_count: 0,
          play_time: '6:29',
          is_active: true,
          checked: false,
          published_at: '2019-01-01',
          revised_at: '2019-01-01',
          thumbnail: 'http://127.0.0.1:3000/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaWdDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--2838d0dbc8764820fd5e18beb4cfd5c03d19b203/KakaoTalk_Photo_2019-05-31-11-51-52.png'
        }
      ],
      paginationOption: {
        total_pages: 0,
        current_page: 0,
        prev_page: null,
        next_page: 0,
        per_page: 0,
        is_last_page: false
      },
      searchOption: {
        uploader: queryStringHistory.uploader ? queryStringHistory.uploader : '',
        title: queryStringHistory.title ? queryStringHistory.title : '',
        is_active: queryStringHistory.is_active ? queryStringHistory.is_active : '',
        start_of_published_at: queryStringHistory.start_of_published_at ? new Date(queryStringHistory.start_of_published_at) : '',
        end_of_published_at: queryStringHistory.end_of_published_at ? new Date(queryStringHistory.end_of_published_at) : '',
        order_by: queryStringHistory.order_by ? queryStringHistory.order_by : '',
        per: ''
      },
      movieCount: 0,
      checkAll: false,
      page: queryStringHistory.page ? queryStringHistory.page : 1
    };
  }

  componentDidMount() {
    const { page, searchOption } = this.state;
    Common.getToken.call(this);
    this.requestMovieList(page, searchOption);
    this.calculateMovieCount();
  }

  componentDidUpdate() {
    // console.log(this.state.paginationArray);
  }

  calculateMovieCount = () => {
    const { authToken, searchOption } = this.state;
    Service.getMovieList(authToken, null, { ...searchOption, per: 1 }).then((res) => {
      this.setState({
        movieCount: res.data.pagination.total_pages * 1
      });
    });
  }

  requestMovieList = (page, searchOption) => {
    const { history } = this.props;
    const { authToken } = this.state;
    const movieList = Service.getMovieList(authToken, page, searchOption);
    return movieList.then((response) => {
      this.setState({
        page,
        dataList: response.data.data.map((res) => ({ ...res, checked: false })),
        paginationOption: response.data.pagination
      }, () => {
        this.handlePage();
      });
    }).catch((error) => {
      alert(error.response.data.message);
      if (error.response.status === 401) {
        alert('로그인을 해주세요');
        history.push('/login');
      }
    });
  }

  // getPageMovieList = (page) => {
  //   const { searchOption } = this.state;
  //   this.requestMovieList(page, searchOption).then(() => {
  //     const { paginationArray, paginationOption } = this.state;
  //     if (paginationArray.indexOf(paginationOption.total_pages) !== -1) {
  //       // 페이지네이션이 마지막 부분일 경우
  //       this.setState({
  //         paginationArray: paginationArray.filter((pageNum) => (
  //           pageNum <= paginationOption.total_pages
  //         ))
  //       });
  //     }
  //   });
  // }

  deleteMovie = () => {
    const { dataList, authToken } = this.state;
    const { history } = this.props;
    const checkedId = [];
    dataList.forEach((data) => {
      if (data.checked && checkedId.indexOf(data.id) === -1) {
        checkedId.push(data.id);
      }
    });
    const request = Service.deleteMovieItem(authToken, checkedId);
    request.then((res) => {
      if (!(res.status >= 200 && res.status < 400)) return;
      Common.getToken.call(this);
      this.requestMovieList();
      this.calculateMovieCount();
      alert('삭제되었습니다');
    }).catch((error) => {
      alert(error.response.data.message);
      if (error.response.status === 401) {
        alert('로그인을 해주세요');
        history.push('/login');
      }
    });
  }

  searchMovieList = (e) => {
    const { authToken, searchOption } = this.state;
    const { history } = this.props;
    e.preventDefault();
    Service.getMovieList(authToken, null, searchOption).then((response) => {
      this.setState({
        dataList: response.data.data.map((res) => ({ ...res, checked: false })),
        paginationOption: response.data.pagination
      });
    }).then(() => {
      this.calculateMovieCount();
    }).catch((error) => {
      alert(error.response.data.message);
      if (error.response.status === 401) {
        alert('로그인을 해주세요');
        history.push('/login');
      }
    });
  }

  onSearch = (e) => {
    this.setState({
      page: 1
    }, () => {
      this.handlePage();
      this.searchMovieList(e);
    });
  };

  // paginate = (page) => {
  //   const { searchOption } = this.state;
  //   this.requestMovieList(page, searchOption).then(() => {
  //     const { paginationArray, paginationOption } = this.state;
  //     // 페이지 리스트가 넘어갈때 알고리즘
  //     if (paginationOption.current_page > paginationArray[paginationArray.length - 1]) {
  //       // 넘어가려는 페이지가 현재 페이지네이션의 최대 페이지보다 클 경우
  //       this.setState({
  //         paginationArray: paginationArray.map((pageNum) => pageNum + 10)
  //       });
  //     } else if (paginationOption.current_page < paginationArray[0]) {
  //       // 넘어가려는 페이지가 현재 페이지네이션의 최소 페이지보다 작을 경우
  //       this.setState({
  //         paginationArray: paginationArray.map((pageNum) => pageNum - 10)
  //       });

  //       if (!(paginationArray.length < 10)) return;
  //       // 마지막 페이지리스트 -> 이전 페이지 이동
  //       let arr = [];
  //       for (
  //         let i = paginationOption.current_page - 9;
  //         i <= paginationOption.current_page;
  //         i += 1
  //       ) {
  //         arr = [...arr, i];
  //       }
  //       this.setState({
  //         paginationArray: arr
  //       });
  //     }
  //     if (paginationArray.indexOf(paginationOption.total_pages) !== -1) {
  //       // 페이지네이션이 마지막 부분일 경우
  //       this.setState({
  //         paginationArray: paginationArray.filter((pageNum) => (
  //           pageNum <= paginationOption.total_pages
  //         ))
  //       });
  //     }
  //   });
  // }

  handleChange = (e) => {
    const { searchOption } = this.state;
    this.setState({
      searchOption: {
        ...searchOption,
        [e.target.name]: e.target.value
      }
    });
  };

  handleStartDateChange = (date) => {
    const { searchOption } = this.state;
    this.setState({
      searchOption: {
        ...searchOption,
        start_of_published_at: date || ''
      }
    });
  }

  handleEndDateChange = (date) => {
    const { searchOption } = this.state;

    this.setState({
      searchOption: {
        ...searchOption,
        end_of_published_at: date || ''
      }
    });
  }

  checkItem = (e) => {
    const { dataList } = this.state;
    this.setState({
      dataList: [
        ...dataList.map((data) => (
          data.id === +e.target.id ? { ...data, checked: !data.checked } : data))
      ]
    });
  }

  checkAll = (e) => {
    const { dataList } = this.state;
    if (e.target.checked) {
      this.setState({
        dataList: [
          ...dataList.map((data) => (
            { ...data, checked: true }))
        ]
      });
    } else {
      this.setState({
        dataList: [
          ...dataList.map((data) => ({ ...data, checked: false }))
        ]
      });
      this.checkedId = [];
    }
  }

  onClickPage = (page) => {
    const { authToken, searchOption } = this.state;
    const { history } = this.props;
    const movieList = Service.getMovieList({ authToken, page, searchOption });
    this.handlePage();
    return movieList.then((response) => {
      this.setState({
        dataList: response.data.data.map((res) => ({ ...res, checked: false })),
        paginationOption: response.data.pagination
      }).catch((error) => {
        alert(error.response.data.message);
        if (error.response.status === 401) {
          alert('로그인을 해주세요');
          history.push('/login');
        }
      });
    });
  }

  // 동영상 페이지 내의 모든 페이지 라우팅 핸들링
  handlePage = (path, query) => {
    const format = require('date-format');
    const {
      searchOption: {
        uploader,
        title,
        is_active,
        start_of_published_at,
        end_of_published_at,
        order_by,
      },
      page
    } = this.state;
    let searchInfo = {
      uploader,
      title,
      is_active,
      start_of_published_at,
      end_of_published_at,
      order_by,
      page
    };
    Object.keys(searchInfo).map((item) => {
      if (item === 'start_of_published_at' || item === 'end_of_published_at') {
        if (searchInfo[item]) {
          searchInfo[item] = format('yyyy-MM-dd', searchInfo[item]);
        } else {
          delete searchInfo[item];
        }
      } else {
        (searchInfo[item] === null || searchInfo[item].toString().length === 0) && delete searchInfo[item];
      }
    });
    let queryString = Object.keys(searchInfo).map((item, index) => searchInfo[item] !== null && `${item}=${searchInfo[item]}`).join('&');
    this.props.history.push({
      pathname: path ? path : '/contents/movie',
      search: `?${queryString}`,
      query: query && query
    });
  }

  render() {
    const {
      dataList, paginationOption, searchOption, checkAll, movieCount, page
    } = this.state;
    return (
      <>
        <div className="title">
          <p className="mb10">
            <span>홈</span>
            {' '}
            〉
            {' '}
            <span>콘텐츠</span>
            {' '}
            〉
            {' '}
            <span>동영상등록/관리</span>
          </p>
          동영상등록/관리
        </div>
        <form>
          <table className="mt40 mda_reset_table">
            <colgroup>
              <col width="10%" />
              <col width="" />
            </colgroup>
            <tbody>
              <tr>
                <th>업로더 명</th>
                <td>
                  <input
                    type="text"
                    name="uploader"
                    onChange={this.handleChange}
                    value={searchOption.uploader}
                  />
                </td>
              </tr>
              <tr>
                <th>제목</th>
                <td>
                  <input
                    type="text"
                    name="title"
                    onChange={this.handleChange}
                    value={searchOption.title}
                  />
                </td>
              </tr>
              <tr>
                <th>공개여부</th>
                <td>
                  <select name="is_active" onChange={this.handleChange} value={searchOption.is_active}>
                    <option value="">전체</option>
                    <option value>예</option>
                    <option value={false}>아니오</option>
                  </select>
                </td>
              </tr>
              <tr>
                <th>등록일</th>
                <td>
                  <DatePicker
                    onChange={this.handleStartDateChange}
                    selected={searchOption.start_of_published_at}
                  />

                  -

                  <DatePicker
                    onChange={this.handleEndDateChange}
                    selected={searchOption.end_of_published_at}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div className="centerT mt20">
            <button type="button" className="btnLine navyBg medium" onClick={this.onSearch}>검색</button>
          </div>
        </form>
        <div className="">
          <div className="info_tabletop">
            <div className="mb10">
              <button type="button" className="btnLine navyBg ml10" onClick={() => this.handlePage('/contents/movie/movieDetail')}>등록</button>
              <button type="button" className="btn navyBg ml10" onClick={this.deleteMovie}>삭제</button>
            </div>
          </div>
          <table className="">
            <colgroup>
              <col width="3%" />
              <col width="" />
              <col width="" />
              <col width="30%" />
              <col width="" />
              <col width="" />
              <col width="" />
              <col width="" />
              <col width="10%" />
            </colgroup>
            <thead>
              <tr>
                <th className="ccc" colsPan="9">총 <span>{movieCount}</span>개</th>
              </tr>
              <tr>
                <th>
                  <input type="checkbox" name="vedio_manage" onChange={this.checkAll} checked={checkAll} />
                </th>
                <th>번호</th>
                <th>콘텐츠 제목</th>
                <th>매체명</th>
                <th>공개여부</th>
                <th>조회수</th>
                <th>수정일</th>
                <th>등록일</th>
                <th>관리</th>
              </tr>
            </thead>
            <tbody>
              {dataList.map((data, idx) => (
                <tr key={data.id}>
                  <td>
                    <input type="checkbox" name="vedio_manage" id={data.id} onChange={this.checkItem} checked={data.checked} />
                  </td>
                  <td>{data.id}</td>
                  <td>
                    <a href={`${clientUrl}/video/videoView?id=${data.id}`} target={'_blank'}>{data.title}</a>
                  </td>
                  <td>{data.uploader}</td>
                  <td>{data.is_active ? '공개' : '비공개'}</td>
                  <td>{data.view_count}</td>
                  <td>{data.revised_at}</td>
                  <td>{data.published_at}</td>
                  <td>
                    <button
                      type="button"
                      className="btn grayBg ml10"
                      onClick={() => this.handlePage(`/contents/movie/movieDetail/${data.id}`, dataList.filter((item) => item.id === data.id))}
                    >
                      수정
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* <div className="pagination">
            {(
              paginationOption.total_pages ? (
                <ul className="clearfix">
                  {paginationOption.prev_page ? (
                    <li>
                      <Link
                        to="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.paginate(paginationOption.prev_page);
                        }}
                      >
                        <img src={ArrowLeft} alt="전 페이지로" />
                      </Link>
                    </li>
                  )
                    : (
                      <li>
                        <Link to="#" style={{ cursor: 'default', border: 'none' }} />
                      </li>
                    )}
                  {paginationArray.map((page) => (
                    <Pagination
                      key={page}
                      currentPage={paginationOption.current_page}
                      page={page}
                      movePage={(e) => {
                        e.preventDefault();
                        this.getPageMovieList(page);
                      }}
                    />
                  ))}
                  {paginationOption.next_page ? (
                    <li>
                      <Link
                        to="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.paginate(paginationOption.next_page);
                        }}
                      >
                        <img src={ArrowRight} alt="다음 페이지로" />
                      </Link>
                    </li>
                  ) : (
                      <li>
                        <Link to="#" style={{ cursor: 'default', border: 'none' }} />
                      </li>
                    )}
                </ul>
              ) : '')}
          </div> */}
          <div className="pagination">
            <Pagination
              onClickPage={(page) => this.requestMovieList(page, searchOption)}
              paginationOption={paginationOption}
            />
          </div>
        </div>
      </>
    );
  }
}

export default Movie;
