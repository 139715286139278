import React, { Component } from 'react';
import Datepicker from 'react-datepicker';
// import { Link } from 'react-router-dom';

import * as AdsService from '../../component/services/operation/adsService';
import * as Common from '../../component/services/common/CommonService';

// let imageInputState = true;

class CommonAdv extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authToken: Common.getToken.call(this),
      commonAds: {},
      oneAds: {},
      // newAds: {},
      isDisabled: false,
      isAdd: false,
      isEdit: false,
      adsItemNumber: 0,
      imageUrl: '',
      imageName: '',
      imageInputState: true
      // param: {
      //   position: 0,
      //   name: '',
      //   owner: '',
      //   description: '',
      //   url: '',
      //   is_active: true,
      //   image: '',
      //   start_at: '',
      //   end_at: ''
      // }
    };
  }

  componentDidMount() {
    this.getOneFourAds();
  }

  formatNumber = (num) => {
    if (num === 0 || num === '0') return 0;

    const reg = /(^[+-]?\d+)(\d{3})/;
    let n = (`${num}`);

    while (reg.test(n)) n = n.replace(reg, '$1,$2');

    return n;
  }

  getOneFourAds = () => {
    const { authToken } = this.state;
    const request = AdsService.getOneFourAds(authToken);
    request.then((res) => {
      this.setState({
        commonAds: res.data.data,
        isDisabled: false,
        isAdd: false,
        isEdit: false,
        adsItemNumber: 0
      });
    }).catch((err) => console.log(err));
  }

  postOneAds = () => {
    const { authToken, oneAds } = this.state;
    if (!oneAds.position || !oneAds.name || !oneAds.owner || !oneAds.description || !oneAds.url || !oneAds.image) {
      alert('필수 값을 입력해 주세요');
      return null;
    }
    if (!!oneAds.end_at !== !!oneAds.start_at) {
      alert('시작일이 있을 경우, 종료일도 입력해줘야 합니다.');
      return null;
    }
    if (window.confirm('해당 내용으로 광고를 설정하시겠습니까?')) {
      const request = AdsService.postOneFourAds(authToken, {
        ...oneAds,
        start_at: this.dateISOformat(oneAds.start_at),
        end_at: this.dateISOformat(oneAds.end_at)
      });
      request.then((res) => {
        if (res.status >= 200 && res.status < 400) {
          alert('등록되었습니다.');
          this.getOneFourAds();
        }
      }).catch((err) => alert('요청을 처리하는 도중에 문제가 발생하였습니다.'));
    }
    return undefined;
  }

  putOneAds = () => {
    const { authToken, oneAds } = this.state;
    if (!!oneAds.end_at !== !!oneAds.start_at) {
      alert('시작일이 있을 경우, 종료일도 입력해줘야 합니다.');
      return null;
    }
    if (window.confirm('해당 내용으로 광고를 설정하시겠습니까?')) {
      const request = AdsService.putOneFourAds(authToken, {
        ...oneAds,
        start_at: this.dateISOformat(oneAds.start_at),
        end_at: this.dateISOformat(oneAds.end_at)
      });
      request.then((res) => {
        if (res.status >= 200 && res.status < 400) {
          alert('등록되었습니다.');
          this.getOneFourAds();
        }
      }).catch((err) => alert('요청을 처리하는 도중에 문제가 발생하였습니다.'));
    }
    return undefined;
  }

  setOneAds = (ad, num) => {
    const { adsItemNumber } = this.state;
    if (adsItemNumber !== num) {
      this.setState({
        imageUrl: '',
        imageName: '',
        isDisabled: false
      });
    }
    this.setState({
      adsItemNumber: num,
      oneAds: {
        ...ad,
        image: null
      }
    });
  }

  handleChange = (e) => {
    const { oneAds } = this.state;
    this.setState({
      oneAds: {
        ...oneAds,
        [e.target.name]: e.target.value
      }
    });
  }

  handleDateChange = (e, name) => {
    const { oneAds } = this.state;
    if (name === 'start_at') {
      this.setState({
        oneAds: {
          ...oneAds,
          start_at: new Date(e)
        }
      });
    }
    if (name === 'end_at') {
      this.setState({
        oneAds: {
          ...oneAds,
          end_at: new Date(new Date(e).setHours(23, 59, 59))
        }
      });
    }
  }

  handleEdit = () => {
    this.setState({
      isDisabled: true
    });
  }

  dateISOformat = (date) => {
    if (typeof date !== 'object' || !date) return null;
    return date.toISOString();
  }

  uploadImage = (e) => {
    const { oneAds } = this.state;
    this.setState({
      oneAds: {
        ...oneAds,
        image: e.target.files[0]
      },
      imageName: e.target.files[0].name
    });
    if (!e.target.files[0]) return;
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      this.setState({
        imageUrl: reader.result
      });
    };
  };

  render() {
    const {
      commonAds, oneAds, isDisabled, adsItemNumber, imageUrl, imageInputState, isAdd, isEdit, imageName
    } = this.state;
    return (
      <>
        <div className="title">
          {' '}
          <p className="mb10">
            <span>홈</span>
            {' '}
            〉
            {' '}
            <span>운영</span>
            {' '}
            〉
            {' '}
            <span>공통날개광고</span>
          </p>
          공통날개광고
        </div>
        <div className="info_searchwrap">
          <ul className="img_view">
            {/* 키 값이 숫자일 때 */}
            {/* {Object.entries(commonAds).map((adsArr, adsIdx) => {
              if (!adsArr[1].id) return <li key={`${adsIdx}`}><a>1</a></li>;
              return <li key={adsArr[1].position}><a><img src={adsArr[1].image} alt="공통날개광고" /></a></li>;
            })} */}
            {commonAds.first ? (
              <li
                style={adsItemNumber === 1 ? { border: '1px solid red' } : {}}
                onClick={() => {
                  this.setOneAds({ ...commonAds.first }, 1);
                  this.setState({
                    isAdd: false,
                    isEdit: true
                  });
                }}
              >
                <img src={(adsItemNumber === 1 && imageUrl) || commonAds.first.image} style={{ width: '148px', height: '148px' }} alt="공통날개광고" />
              </li>
            ) : (
              <li
                style={adsItemNumber === 1 ? { border: '1px solid red' } : {}}
                onClick={() => {
                  this.setOneAds({
                    position: 1,
                    name: '',
                    owner: '',
                    description: '',
                    url: '',
                    is_active: true,
                    image: '',
                    start_at: '',
                    end_at: ''
                  }, 1);
                  this.setState({
                    isAdd: true,
                    isEdit: false
                  });
                }}
              >
                {!(adsItemNumber === 1 && imageUrl) ? '1' : <img src={imageUrl} style={{ width: '148px', height: '148px' }} alt="공통날개광고" />}
              </li>
            )}
            {commonAds.second ? (
              <li
                style={adsItemNumber === 2 ? { border: '1px solid red' } : {}}
                onClick={() => {
                  this.setOneAds({ ...commonAds.second }, 2);
                  this.setState({
                    isAdd: false,
                    isEdit: true
                  });
                }}
              >
                <img src={(adsItemNumber === 2 && imageUrl) || commonAds.second.image} style={{ width: '148px', height: '148px' }} alt="공통날개광고" />
              </li>
            ) : (
              <li
                style={adsItemNumber === 2 ? { border: '1px solid red' } : {}}
                onClick={() => {
                  this.setOneAds({
                    position: 2,
                    name: '',
                    owner: '',
                    description: '',
                    url: '',
                    is_active: true,
                    image: '',
                    start_at: '',
                    end_at: ''
                  }, 2);
                  this.setState({
                    isAdd: true,
                    isEdit: false
                  });
                }}
              >
                {!(adsItemNumber === 2 && imageUrl) ? '2' : <img src={imageUrl} style={{ width: '148px', height: '148px' }} alt="공통날개광고" />}
              </li>
            )}
            {commonAds.third ? (
              <li
                style={adsItemNumber === 3 ? { border: '1px solid red' } : {}}
                onClick={() => {
                  this.setOneAds({ ...commonAds.third }, 3);
                  this.setState({
                    isAdd: false,
                    isEdit: true
                  });
                }}
              >
                <img src={(adsItemNumber === 3 && imageUrl) || commonAds.third.image} style={{ width: '148px', height: '148px' }} alt="공통날개광고" />
              </li>
            ) : (
              <li
                style={adsItemNumber === 3 ? { border: '1px solid red' } : {}}
                onClick={() => {
                  this.setOneAds({
                    position: 3,
                    name: '',
                    owner: '',
                    description: '',
                    url: '',
                    is_active: true,
                    image: '',
                    start_at: '',
                    end_at: ''
                  }, 3);
                  this.setState({
                    isAdd: true,
                    isEdit: false
                  });
                }}
              >
                {!(adsItemNumber === 3 && imageUrl) ? '3' : <img src={imageUrl} style={{ width: '148px', height: '148px' }} alt="공통날개광고" />}
              </li>
            )}
            {commonAds.fourth ? (
              <li
                style={adsItemNumber === 4 ? { border: '1px solid red' } : {}}
                onClick={() => {
                  this.setOneAds({ ...commonAds.fourth }, 4);
                  this.setState({
                    isAdd: false,
                    isEdit: true
                  });
                }}
              >
                <img src={(adsItemNumber === 4 && imageUrl) || commonAds.fourth.image} style={{ width: '148px', height: '148px' }} alt="공통날개광고" />
              </li>
            ) : (
              <li
                style={adsItemNumber === 4 ? { border: '1px solid red' } : {}}
                onClick={() => {
                  this.setOneAds({
                    position: 4,
                    name: '',
                    owner: '',
                    description: '',
                    url: '',
                    is_active: true,
                    image: '',
                    start_at: '',
                    end_at: ''
                  }, 4);
                  this.setState({
                    isAdd: true,
                    isEdit: false
                  });
                }}
              >
                {!(adsItemNumber === 4 && imageUrl) ? '4' : <img src={imageUrl} style={{ width: '148px', height: '148px' }} alt="공통날개광고" />}
              </li>
            )}
          </ul>
        </div>
        <div className="clearwrap mt40">
          <table className="mini_table">
            <colgroup>
              <col width="20%" />
              <col width="" />
            </colgroup>
            <tbody>
              <tr>
                <th>조회수</th>
                <td>
                  <span>{oneAds.view_count === 0 || oneAds.view_count ? `${this.formatNumber(oneAds.view_count)} 회` : ''}</span>
                  {' '}
                </td>
              </tr>

            </tbody>
          </table>
          <div>
            {isAdd && !isDisabled ? <button type="button" className="btn grayBg ml10" onClick={this.handleEdit}>추가</button> : ''}
            {isEdit && !isDisabled ? <button type="button" className="btnLine navyBg ml10" onClick={this.handleEdit}>수정</button> : ''}
            {isDisabled ? (
              <button
                type="button"
                className="btn navyBg ml10"
                onClick={() => {
                  if (isAdd) this.postOneAds();
                  if (isEdit) this.putOneAds();
                }}
              >
              저장
              </button>
            ) : ''}
          </div>
        </div>
        <table className="add_table mt40">
          <tbody>
            <tr>
              <th>
                이미지 업로드
                {' '}
                <span className="redT">*</span>
              </th>
              <td colSpan="3" className="file_wrap adv_wrap">
                {imageInputState ? <input className="adv_file" type="file" id="add_img" onChange={this.uploadImage} disabled={isDisabled ? '' : 'disabled'} /> : ''}
                <input type="text" readOnly className="file_input ml10 adv_text" value={imageName || ''} disabled={isDisabled ? '' : 'disabled'} />
                <label
                  htmlFor="add_img"
                  className="file_btn adv_btn ml10"
                  onClick={() => {
                    this.setState({
                      imageInputState: false
                    }, () => {
                      this.setState({
                        imageInputState: true
                      });
                    });
                  }}
                >
                업로드
                </label>
              </td>
            </tr>
            <tr>
              <th>
                광고 명
                {' '}
                <span className="redT">*</span>
              </th>
              <td><input type="text" onChange={this.handleChange} name="name" value={oneAds.name ? oneAds.name : ''} disabled={isDisabled ? '' : 'disabled'} /></td>
              <th>
                광고 주체
                {' '}
                <span className="redT">*</span>
              </th>
              <td><input type="text" onChange={this.handleChange} name="owner" value={oneAds.owner ? oneAds.owner : ''} disabled={isDisabled ? '' : 'disabled'} /></td>
            </tr>
            <tr>
              <th>
                광고 설명
                {' '}
                <span className="redT">*</span>
              </th>
              <td colSpan="3"><textarea onChange={this.handleChange} name="description" value={oneAds.description ? oneAds.description : ''} disabled={isDisabled ? '' : 'disabled'} /></td>
            </tr>
            <tr>
              <th>광고 시작일</th>
              <td>
                {/* <input type="text" onChange={() => console.log()} value={oneAds.start_at ? oneAds.start_at : ''} /> */}
                <Datepicker
                  onChange={(e) => this.handleDateChange(e, 'start_at')}
                  selected={oneAds.start_at ? new Date(oneAds.start_at) : ''}
                  dateFormat="yyyy-MM-dd"
                  disabled={isDisabled ? '' : 'disabled'}
                />
              </td>
              <th>광고 종료일</th>
              <td>
                {/* <input type="text" onChange={() => console.log()} value={oneAds.updated_at ? oneAds.updated_at : ''} /> */}
                <Datepicker
                  onChange={(e) => this.handleDateChange(e, 'end_at')}
                  selected={oneAds.end_at ? new Date(oneAds.end_at) : ''}
                  dateFormat="yyyy-MM-dd"
                  disabled={isDisabled ? '' : 'disabled'}
                />
              </td>
            </tr>
            <tr>
              <th>
                랜딩 URL
                {' '}
                <span className="redT">*</span>
              </th>
              <td colSpan="3"><input type="text" onChange={this.handleChange} name="url" value={oneAds.url ? oneAds.url : ''} disabled={isDisabled ? '' : 'disabled'} /></td>
            </tr>
            <tr>
              <th>
                활성화 상태
                {' '}
                <span className="redT">*</span>
              </th>
              <td colSpan="3">
                <select onChange={this.handleChange} name="is_active" value={oneAds.is_active} disabled={isDisabled ? '' : 'disabled'}>
                  <option value={true}>활성화</option>
                  <option value={false}>비활성화</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
  }
}

export default CommonAdv;
