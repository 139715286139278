import {devUrl, keyNumber} from '../../serviceComm/variable';
import axios from 'axios';

export const getSalesRecords = (authToken) => {
    return axios({
        method: 'get',
        url: `${devUrl}/admin/brands/sales_records`,
        headers: {"x-api-key": keyNumber, Authorization: `Bearer ${authToken}`}
    })
};

export const postSalesRecords = (authToken, file, year, month) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('year', year);
    formData.append('month', month);

    return axios({
        method: 'post',
        url: `${devUrl}/admin/brands/sales_records`,
        headers: {"x-api-key": keyNumber, Authorization: `Bearer ${authToken}`},
        data: formData
    })
};
