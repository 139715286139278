import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import '../../../UIKitMonkeyPatch.scss'
import TrimForm from "./TrimForm";
import {isNull, isEmpty, cloneDeep, isEqual} from "lodash";
import {TrimProvider, useTrimState, useTrimDispatch, emptyTrim} from "./TrimProvider";
import {getSimpleActiveLineupsWithVerbose} from '../../../component/services/simpleList/SimpleList'
import {getToken} from "../../../component/services/common/CommonService";
import {getTrimDetail, postTrim, editTrim} from "../../../component/services/vehicleDB/TrimService";

const TrimLayout = ({paramTrimId}) => {
  const trimState = useTrimState();
  const trimDispatch = useTrimDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [saved, setSaved] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [lineupList, setLineupList] = useState(null);
  const [authToken] = useState(getToken.call(this));
  const history = useHistory();

  const fetchAndSetTrim = () => {
    if (/^\d+$/.test(paramTrimId)) {
      getTrimDetail(authToken, paramTrimId)
        .then(response => trimDispatch({type: 'SET_TRIM', payload: {trim: response.data.data}}))
        .then(() => setIsEdit(true))
        .then(() => setIsLoading(false))
    } else {
      setIsLoading(false);
    }
  };

  const handleSave = () => {
    let readyToSave = true;
    setDisabledBtn(true);

    trimState.forEach((trim, index) => {
      if (!validateForm(manipulateTrim(isEdit, trim), index)) {
        readyToSave = false;
      }
    });

    if (!readyToSave) {
      setDisabledBtn(false);
      return false;
    }

    //New Page ==> synchronously request
    if (isEdit) {
      (async () => {
        await asyncForEach(trimState, async (trim, index) => {
          await editTrim(authToken, manipulateTrim(isEdit, trim));
          if (trimState.length === index + 1) {
            setSaved(true);
          }
        })
      })();
    } else {
      (async () => {
        await asyncForEach(trimState, async (trim, index) => {
          await postTrim(authToken, manipulateTrim(isEdit, trim));
          if (trimState.length === index + 1) {
            setSaved(true);
          }
        })
      })();
    }
  };

  const asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index += 1) {
      await callback(array[index], index, array);
    }
  };

  const manipulateTrim = (isEdit, trim) => {
    const trimDup = cloneDeep(trim);
    delete trimDup.save_helper;
    delete trimDup.selected_lineup;

    if (!isEdit) {
      delete trimDup.id;
    }
    if (isEmpty(trimDup.option)) {
      delete trimDup.option;
    }
    if (isEqual(trimDup.trim_spec, emptyTrim.trim_spec)) {
      delete trimDup.trim_spec;
    }
    if (isEqual(trimDup.trim_item, emptyTrim.trim_item)) {
      delete trimDup.trim_item;
    }
    if (isEqual(trimDup.price_options, emptyTrim.price_options)) { //초기상태와 동일하면 지운다
      delete trimDup.price_options;
    }

    if (!isEmpty(trimDup.price_options)) { //비어있지 않으면 내부를 검사한다

      trimDup.price_options  = trimDup.price_options.filter(po => !isEqual(po, emptyTrim.price_options[0]));
    }

    if (isEqual(trimDup.sales_conditions, emptyTrim.sales_conditions)) { //초기상태와 동일하면 지운다
      delete trimDup.sales_conditions;
    }

    if (!isEmpty(trimDup.sales_conditions)) {
      trimDup.sales_conditions = trimDup.sales_conditions.filter(sc => !isEqual(sc, emptyTrim.sales_conditions[0]));
    }

    return trimDup;
  };

  const validateForm = (trim, index) => {
    const validLineupId = (trim.lineup_id > 0);
    const validName = !isEmpty(trim.name);
    const validPrice = !isEmpty(trim.price);
    const validActive = !isEmpty(trim.is_active);
    let validPriceOptions = true;
    let validSalesConditions = true;

    if (!isEmpty(trim.price_options)) {
      trim.price_options.forEach(priceOption => {
        if (!isEqual(priceOption, emptyTrim.price_options[0])) {
          if (isEmpty(priceOption.name) || isEmpty(priceOption.price)) {
            validPriceOptions = false
          }
        }
      });
    }

    if (!isEmpty(trim.sales_conditions)) {
      trim.sales_conditions.forEach(salesCondition => {
        if (!isEqual(salesCondition, emptyTrim.sales_conditions[0])) {
          if (isEmpty(salesCondition.name)) {
            validSalesConditions = false
          }

          salesCondition.items.forEach(item => {
            if (isEmpty(item.name) || (isEmpty(item.price) && isEmpty(item.price_rate))) {
              validSalesConditions = false
            }
          })
        }
      });
    }

    const isValid = validName && validPrice && validLineupId && validActive && validPriceOptions && validSalesConditions;

    if (isValid) {
      trimDispatch({
        type: "SET_SAVE_HELPER",
        payload: {index, save_helper: emptyTrim.save_helper}

      })
    } else {
      let message = '다음의 항목이 누락되었습니다 :';

      if (!validLineupId) {
        message += ' 라인업명,'
      }

      if (!validName) {
        message += ' 트림명,'
      }

      if (!validPrice) {
        message += ' 가격,'
      }

      if (!validActive) {
        message += ' 활성화여부,'
      }

      if (!validPriceOptions) {
        message += ' 가격옵션 필드,'
      }

      if (!validSalesConditions) {
        message += ' 판매조건 필드,'
      }

      trimDispatch({
        type: "SET_SAVE_HELPER",
        payload: {index, save_helper: {message: message.substring(0, message.length - 1), is_valid: isValid}}
      });
    }

    return isValid
  };

  useEffect(() => {
    if (isNull(lineupList)) {
      getSimpleActiveLineupsWithVerbose(authToken)
        .then(response => {
          setLineupList(response.data.data.map(obj => ({
            value: obj.id,
            label: `[${obj.brand_name} ${obj.car_model_name}] ${obj.name} [${obj.id}]`
          })).reverse())
        })
        .then(() => fetchAndSetTrim())
    }
  }, [paramTrimId]);

  useEffect(() => {
    if (saved) {
      setTimeout(() => history.goBack(), 1000);
    }
  }, [saved]);

  if (isLoading) {
    return <Spinner/>
  }

  if (saved) {
    return <Done isEdit={isEdit}/>
  }

  return (
    <div className='uk-container'>
      <ul className="uk-breadcrumb">
        <li><span>차량DB</span></li>
        <li><span>트림</span></li>
        <li><span>트림 {isEdit ? '수정' :'추가'}</span></li>
      </ul>
      {trimState.map((trim, index) => <TrimForm trim={trim} index={index} key={index} lineupList={lineupList} authToken={authToken}
                                                isEdit={isEdit}/>)}
      <div
        className="uk-flex uk-flex-column uk-position-large uk-position-bottom-right uk-margin-bottom uk-position-fixed">
        <button className="uk-button uk-button-primary" disabled={disabledBtn}
                onClick={handleSave}>{saved ? '저장됨' : '저장'}</button>
        <button className="uk-button uk-button-default" onClick={() => history.goBack()}>돌아가기</button>
      </div>
    </div>
  )
};

const TrimManager = ({match}) => {
  return (
    <TrimProvider>
      <TrimLayout paramTrimId={match.params.trimId}/>
    </TrimProvider>
  );
};

const Spinner = () => (
  <div className="uk-flex-column uk-flex uk-flex-middle uk-flex-center" data-uk-height-viewport="">
    <div className='uk-spinner' data-uk-spinner="ratio: 3">
    </div>
    <h5>Loading Data...</h5>
  </div>
);

const Done = isEdit => (
  <div className="uk-flex-column uk-flex uk-flex-middle uk-flex-center" data-uk-height-viewport="">
    <span className='uk-margin-small-bottom' data-uk-icon="icon: info; ratio: 1.5"/>
    <h4>{isEdit ? '수정' : '저장'}이 완료되었습니다.</h4>
  </div>
);

export default TrimManager;
