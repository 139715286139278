import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { isEmpty } from "lodash";
import moment from "moment";
import "moment/locale/ko";
import QueryString from "query-string";

// Components
import DetailManageTable from "../../component/featuredNews/DetailManageTable";

// DatePicker Locale
import ko from 'date-fns/locale/ko';

// Services
import { getSimpleContents } from '../../component/services/simpleList/SimpleList';
import {
    getFeaturedNews,
    postFeaturedNews,
    putFeaturedNews
} from "../../component/services/content/FeaturedNewsService";
import { getToken } from '../../component/services/common/CommonService';

// UIKits
import '../../UIKitMonkeyPatch.scss';
import DatePicker from "react-datepicker";
import DatePickerButton from "../../component/common/DatePickerButton";

moment.locale("ko");

function getInitializeContentRows(contents) {
    let step = 0;
    let initializeContentRows = [];

    if (isEmpty(contents)) {
        while (step < 17) {
            initializeContentRows.push({
                id: (step + 1),
                selected: null,
                url: ""
            });
            step += 1;
        }
    } else {
        initializeContentRows = contents.map((item, index) => ({
            id: (index + 1),
            selected: {
                label: `[${item.id}] ${item.title}`,
                value: item.id
            },
            url: ""
        }))
    }

    return initializeContentRows;
}

function FeaturedNewsDetail() {
    const history = useHistory();
    const { id } = useParams();
    const { search } = useLocation();

    const [authToken] = useState(getToken());
    const [date, setDate] = useState(moment());
    const [contentRows, setContentRows] = useState(getInitializeContentRows());
    const [options, setOptions] = useState([]);
    const [isEditable, setIsEditable] = useState(false);
    const [isDateChangeDisabled, setIsDateChangeDisabled] = useState(false);

    const contentIds = useMemo(() =>
        contentRows.map((item) =>
            !isEmpty(item.selected) ? item.selected.value : 0), [contentRows]);

    const handleHistoryGoBack = useCallback(() => {
        history.goBack();
    }, [history]);

    const handleDatePicker = useCallback((date) => {
        setDate(moment(date).format("YYYY-MM-DD"));
    }, [date]);

    const handleSelect = useCallback((content, id) =>
        setContentRows(contentRows.map((item) => ({
            ...item,
            selected: item.id === id ? content : item.selected
        }))), [contentRows]);

    const handleURL = useCallback((event) => {
        const value = event.currentTarget.value;
        const id = Number(event.currentTarget.getAttribute("data-id") || 0);

        setContentRows(contentRows.map((item) => ({
            ...item,
            url: item.id === id ? value : item.url
        })));
    }, [contentRows]);

    const handleURLEnter = useCallback((event) => {
        if (event.keyCode === 13) {
            const urlRegex = /(http(s)?:\/\/)auto.zum.com\/news\/main_view\?id=+[0-9]/;
            const value = event.currentTarget.value;
            const contentRowId = Number(event.currentTarget.getAttribute("data-id") || 0);

            if (!urlRegex.test(value)) {
                alert("자동차줌 뉴스 상세 페이지 URL 형식과 일치하지 않습니다.\n예시) https://auto.zum.com/news/main_view?id=7");
            } else {
                const id = Number(value.replace(/\D/g, "") || 0);

                const selectedOptions = options.filter((item) => item.value === id);

                if (isEmpty(selectedOptions)) {
                    alert('존재하지 않는 뉴스입니다.');
                } else {
                    setContentRows(contentRows.map((item) => ({
                        ...item,
                        selected: item.id === contentRowId ? selectedOptions[0] : item.selected,
                        url: item.id === contentRowId ? "" : item.url
                    })));
                }
            }
        }
    }, [options, contentRows]);

    const handleContentRowsSequence = useCallback((event) => {
        const id = Number(event.currentTarget.getAttribute("data-id"));
        const type = event.currentTarget.getAttribute("data-type");

        const currentSequence = id;
        let nextSequence = id;

        if (type === "down") {
            nextSequence = (id + 1)
            nextSequence = nextSequence > 17 ? 1 : nextSequence;
        } else if (type === "up") {
            nextSequence = (id - 1)
            nextSequence = nextSequence < 1 ? 17 : nextSequence;
        }

        const prevContentRow = contentRows.filter((item) => item.id === nextSequence);
        const nextContentRow = contentRows.filter((item) => item.id === currentSequence);

        setContentRows(contentRows.map((item) => {
           if (item.id === nextSequence) {
               return {
                   ...nextContentRow[0],
                   id: nextSequence
               }
           } else if (item.id === currentSequence) {
               return {
                   ...prevContentRow[0],
                   id: currentSequence
               }
           }
           return item;
        }));
    }, [contentRows]);

    const validationFeaturedNews = useCallback(() => {
        const hasOver = contentIds.filter((item, index) => contentIds.indexOf(item) !== index);

        if (contentIds.includes(0)) {
            alert("선택되지 않은 콘텐츠가 존재합니다.");
            return false;
        }

        if (!isEmpty(hasOver)) {
            alert("중복으로 선택된 콘텐츠가 존재합니다.");
            return false;
        }

        return true;
    }, [date, contentIds]);

    const saveFeaturedNews = useCallback(() => {
        if (validationFeaturedNews()) {
            if (!isEditable) {
                postFeaturedNews({ authToken, data: { date, content_ids: contentIds } })
                    .then((response) => {
                        if (response.status === 200) {
                            alert("발행을 예약하였습니다.");
                            history.goBack();
                        } else {
                            alert("알 수 없는 오류로 발행 예약에 실패하였습니다.");
                        }
                    }).catch((error) => {
                        alert(error.response.data.message);
                        console.log(error.response);
                    });
            } else {
                putFeaturedNews({ authToken, id, data: { date, content_ids: contentIds } })
                    .then((response) => {
                        if (response.status === 200) {
                            alert("저장되었습니다.");
                            history.goBack();
                        } else {
                            alert("알 수 없는 오류로 인해 저장에 실패하였습니다.");
                        }
                    }).catch((error) => {
                        alert(error.response.data.message);
                        console.log(error.response);
                    });
            }
        }
    }, [validationFeaturedNews, authToken, id, isEditable]);

    useEffect(() => {
        getSimpleContents(authToken).then((response) =>
            setOptions(response.data.data.map((item) => ({
                label: `[${item.id}] ${item.title}`,
                value: item.id
            })))
        );
    }, [authToken]);

    useEffect(() => {
        const parseSearch = QueryString.parse(search);

        if (!isEmpty(parseSearch)) {
            const { publish_date } = parseSearch;
            setDate(publish_date);
        }

        if (!isEmpty(id)) {
            getFeaturedNews({ authToken, id }).then((response) => {
                const { data: { date, contents } } = response;

                setIsEditable(true);
                setIsDateChangeDisabled(true);
                setDate(date);
                setContentRows(getInitializeContentRows(contents));
            }).catch((error) => {
                alert(error.response.data.message);
                console.log(error);
            })
        }
    }, [authToken, id, search]);

    return (
        <>
            <div className="title">
                <p className="mb10">
                    <span>홈</span> 〉 <span>콘텐츠</span> 〉 <span>추천뉴스 관리</span> 〉 <span>등록/수정</span>
                </p>
                추천뉴스 관리 - 등록/수정
            </div>
            <div className="uk-flex uk-flex-between uk-flex-middle uk-margin-small-top uk-margin-small-bottom">
                <div className="uk-grid-small uk-flex-middle uk-flex-nowrap" data-uk-grid>
                    <div>
                        <DatePicker
                            locale="ko"
                            dateFormat="yyyy. MM. dd"
                            minDate={new Date()}
                            selected={new Date(date)}
                            onChange={handleDatePicker}
                            customInput={<DatePickerButton />}
                            disabled={isDateChangeDisabled}
                        />
                    </div>
                </div>
                <div className="uk-flex uk-flex-middle uk-flex-nowrap uk-text-nowrap">
                    <div className="uk-button-group uk-text-nowrap">
                        <button
                            className="uk-button uk-button-primary"
                            onClick={saveFeaturedNews}
                        >{isEditable ? "저장" : "발행 예약"}</button>
                        <button className="uk-button uk-button-default" onClick={handleHistoryGoBack}>이전</button>
                    </div>
                </div>
            </div>
            <DetailManageTable
                options={options}
                contentRows={contentRows}
                handleSelect={handleSelect}
                handleURL={handleURL}
                handleURLEnter={handleURLEnter}
                handleContentRowsSequence={handleContentRowsSequence}
            />
            <div className="uk-flex uk-flex-right uk-flex-middle uk-margin-small-top uk-margin-small-bottom">
                <div className="uk-flex uk-flex-middle uk-flex-nowrap uk-text-nowrap">
                    <div className="uk-button-group uk-text-nowrap">
                        <button
                            className="uk-button uk-button-primary"
                            onClick={saveFeaturedNews}
                        >{isEditable ? "저장" : "발행 예약"}</button>
                        <button className="uk-button uk-button-default" onClick={handleHistoryGoBack}>이전</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FeaturedNewsDetail;
