import React, {useEffect, useState} from 'react';
import {getSimpleActiveCarModels, getSimpleContents, getSimpleVideos} from '../../component/services/simpleList/SimpleList';
import {find} from 'lodash';
import {getLatestPriority, createPriority} from '../../component/services/operation/priorityService';
import moment from 'moment';

import * as Common from "../../component/services/common/CommonService";
import PriorityRow from "./PriorityRow";
import PriorityFormValidator from "./PriorityFormValidator";
import PriorityTable from "./PriorityTable";

const NameMap = {
    carModel: {
        camelCase: 'CarModel',
        snakeCase: 'car_model'
    },
    content: {
        camelCase: 'Content',
        snakeCase: 'content'
    },
    video: {
        camelCase: 'Video',
        snakeCase: 'video'
    }
};

const MinimumQuantity = {
    carModel: {
        total: 20,
        domestic: 10,
        imported: 10
    },
    content: {total: 10},
    video: {total: 10}
};

const textMap = {
    carModel: {
        placeholder: "ID, 자동차명으로 검색",
        friendlyName: "자동차"
    },
    content: {
        placeholder: "ID, 컨텐츠 제목으로 검색",
        friendlyName: "컨텐츠"
    },
    video: {
        placeholder: "ID, 영상 제목으로 검색",
        friendlyName: "동영상"
    }
};

const PriorityForm = (props) => {
    const [authToken] = useState(Common.getToken.call(this));
    const [autoCompleteData, setAutoCompleteData] = useState(null);
    const [latestPriority, setLatestPriority] = useState([]);
    const [valueList, setValueList] = useState([]);
    const [flashMessage, setFlashMessage] = useState(null);
    const [readyToSave, setReadyToSave] = useState(false);
    const [isForCarModel, setIsForCarModel] = useState(false);

    useEffect(() => {
        setValueList(Array(MinimumQuantity[props.targetModel].total).fill({}));
        fetchAndSetLatestPriority(props.targetModel);
        fetchAndSetAutoCompleteData(props.targetModel);
        setIsForCarModel(props.targetModel === 'carModel');
    }, [props.targetModel]);

    const fetchAndSetAutoCompleteData = (targetModel) => {
        switch (targetModel) {
            case 'carModel':
                getSimpleActiveCarModels(authToken).then(response => {
                    setAutoCompleteData(response.data.data.map(obj => {
                        return {value: obj.id, label: `[${obj.id}] ${obj.name}`, isImported: obj.brand.is_imported}
                    }));
                });
                break;
            case 'content':
                getSimpleContents(authToken).then(response => {
                    setAutoCompleteData(response.data.data.map(obj => {
                        return {value: obj.id, label: `[${obj.id}] [${obj.author}] ${obj.title}`}
                    }));
                });
                break;
            case 'video':
                getSimpleVideos(authToken).then(response => {
                    setAutoCompleteData(response.data.data.map(obj => {
                        return {value: obj.id, label: `[${obj.id}] ${obj.title}`}
                    }));
                });
                break;

        }

    };

    const fetchAndSetLatestPriority = (targetModel) => {
        getLatestPriority(authToken, NameMap[targetModel].snakeCase).then(response => {
            setLatestPriority(response.data);
        })
    };

    const onChangeHandler = (object, targetIndex) => {
        if (find(valueList, object)) {
            alert("이미 등록된 아이템입니다.");
            setValueList(valueList.map((value, index) => {
                return index === targetIndex ? {} : value
            }));
        } else {
            setValueList(valueList.map((value, index) => {
                return index === targetIndex ? object : value
            }));
        }
    };

    const plusBtnHandler = (index, newItem) => {
        setValueList(pushAfterIndex(valueList, index, newItem));
    };

    const minusBtnHandler = (targetIndex) => {
        if (valueList.length === 1) {
            alert('더 이상 삭제할 수 없습니다.');
        } else {
            setValueList(valueList.filter((value, index) => index !== targetIndex));
        }
    };

    const readyToSaveHandler = (readyToSave) => {
        setReadyToSave(readyToSave);
    };

    const savePriority = () => {
        createPriority(authToken, {
            record_type: NameMap[props.targetModel].camelCase,
            ids: valueList.map(value => value.value)
        }).then(response => {
            const timestamp = moment(response.data.data.created_at).format('YYYY-MM-DD HH시 mm분 ss초').toString();
            fetchAndSetLatestPriority(props.targetModel);
            setFlashMessage(`정상적으로 저장되었습니다. ${timestamp} 부터 순위에 반영됩니다.`);
        });
    };

    const pushAfterIndex = (arr, index, newItem) => [...arr.slice(0, index + 1), newItem, ...arr.slice(index + 1)];

    return (
        <div className='uk-container uk-container-xlarge uk-margin-small-top'>
            {
                flashMessage &&
                <div className='uk-alert'>
                    <p>{flashMessage}</p>
                </div>
            }

            <div className='uk-grid uk-margin-small-top'>
                <div className='uk-width-2-3'>
                    <div className="uk-flex" style={{justifyContent: 'space-between'}}>
                        <PriorityFormValidator valueList={valueList} readyToSaveHandler={readyToSaveHandler}
                                               isForCarModel={isForCarModel}
                                               minimumQuantity={MinimumQuantity[props.targetModel]}/>
                        <button className='uk-button uk-button-danger' disabled={!readyToSave}
                                onClick={readyToSave ? savePriority : undefined}>저장
                        </button>
                    </div>
                    <table className='uk-table uk-table-hover uk-table-middle'>
                        <thead>
                        <tr>
                            <th className='uk-text-center'>순위</th>
                            {isForCarModel && <th className='uk-text-center'>국가</th>}
                            <th className='uk-text-center'>{textMap[props.targetModel].friendlyName}명</th>
                        </tr>
                        </thead>
                        <tbody>
                        {autoCompleteData && valueList.map((object, index) => {
                            return (<PriorityRow key={index} autoCompleteData={autoCompleteData}
                                                 isForCarModel={isForCarModel}
                                                 placeholderText={textMap[props.targetModel].placeholder}
                                                 selectTagName={`${props.targetModel}${index}`}
                                                 selectedValue={object} index={index}
                                                 onChangeHandler={onChangeHandler}
                                                 plusBtnHandler={plusBtnHandler} minusBtnHandler={minusBtnHandler}/>)
                        })}
                        </tbody>
                    </table>
                    <div className="uk-flex" style={{justifyContent: 'space-between'}}>
                        <PriorityFormValidator valueList={valueList} readyToSaveHandler={readyToSaveHandler}
                                               isForCarModel={isForCarModel}
                                               minimumQuantity={MinimumQuantity[props.targetModel]}/>
                        <button className='uk-button uk-button-danger' disabled={!readyToSave}
                                onClick={readyToSave ? savePriority : undefined}>저장
                        </button>
                    </div>
                </div>
                <div className='uk-width-1-3'>
                    <PriorityTable data={latestPriority} isForCarModel={isForCarModel} friendlyName={textMap[props.targetModel].friendlyName}/>
                </div>

            </div>
        </div>
    );
};

export default PriorityForm;
