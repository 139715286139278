import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import * as Common from "../../component/services/common/CommonService";
import * as Service from "../../component/services/brand/BrandService";
import $ from 'jquery';
import Pagination from '../../component/Pagination'
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import QueryString from 'query-string';

const SortableItem = SortableElement(({value}) => <div className="gridItem popUp" data-id={value.id}><a href="#">{value.name}</a></div>);

const SortableList = SortableContainer(({items}) => {
  const popUpData = []
  let index = -1;
  for(var x = 0; x<items.length; x++){
    if(x%13===0){
      popUpData[++index] = new Array()
      popUpData[index].push(items[x])
    }
    else{
      popUpData[index].push(items[x])
    }
  }
  return (
    <div className="grid popUp">
      {items.map((data , key) => {
        return (<SortableItem key={`item-${key}`} index={key} value={data}/>)
      })}
      </div>
  );
});

class TotalInfo extends Component {

  constructor(props) {
    super(props);
    const queryStringHistory = QueryString.parse(this.props.location.search);
    this.state = {
      authToken: Common.getToken.call(this),
      brandId : queryStringHistory.brandId ? queryStringHistory.brandId : 0,
      brandName : queryStringHistory.brandName ? queryStringHistory.brandName : '전체',
      isActive: queryStringHistory.isActive ? queryStringHistory.isActive : '전체',
      isEnabled: "전체",
      per: 8,
      page: queryStringHistory.page ? queryStringHistory.page : 1,
      fetching: false,
      totalInfo: [],
      brands: [],
      popActive:false,             // 팝업창 상태값
      pagination:[],
      brandNameOrder : queryStringHistory.brandNameOrder ? queryStringHistory.brandNameOrder : null,
      activeOrder : queryStringHistory.activeOrder ? queryStringHistory.activeOrder : null,
      idOrder : queryStringHistory.idOrder ? queryStringHistory.idOrder : null,
      popData : [],
    };

    this.exposure = this.exposure.bind(this);
  }

  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState(({popData}) => ({
      popData: arrayMove(popData, oldIndex, newIndex),
    }));
  };

  onSave = () => {
    let ids = { "ids" : [] }
    for(var x = 0; x<document.querySelectorAll('.gridItem').length; x++){
      ids.ids.push(document.querySelectorAll('.gridItem')[x].dataset.id)
    }
    const setSave = Service.setOrder(
      this.state.authToken,
      ids
    )
    setSave.then(res => {
      if(res.status == 200){
        this.setState({popActive:false}, () => {
          if(document.querySelector('#first_btn'))
            document.querySelector('#first_btn').click()
          this.getPopData()
        })
      }
    }).catch(error => {
      alert('노출 순서 수정에 실패하였습니다.')
    })
  }

  onClose = () => {
    this.setState({popActive:false},() => {
      this.getPopData()
    })
  }

  infoSearchValue = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  getTotalInfo = aysnc => {
    const brandList = Service.getTotalInfo(this.state.authToken);
    brandList.then(response => {
      this.setState({
        totalInfo: response.data.data
      });
    });
  };

  getPopData = async => {
    const brandList = Service.getPopData(this.state.authToken);
    brandList.then(response => {
      this.setState({
        popData: response.data.data
      });
    });
  }

  getBrandList = is_render => {
    const brandName = this.state.totalInfo.length !== 0 &&
    this.state.totalInfo.filter((item) => item.id === parseInt(this.state.brandId))[0] ? this.state.totalInfo.filter((item) => item.id === parseInt(this.state.brandId))[0].name : '전체';
    const brandList = Service.getBrandList(
      this.state.brandId,
      is_render ? 10 : this.state.per,
      is_render ? 1 : this.state.page,
      this.state.authToken,
      this.state.brandNameOrder,
      this.state.activeOrder,
      this.state.idOrder,
      this.state.isActive,
        (this.state.brandId !== 0 && this.state.brandName === '전체') ? brandName : this.state.brandName
    );
    console.log(this.state.brandId);
    brandList.then(response => {
      this.setState({
        brands: response.data.data,
        pagination : response.data.pagination
      });
    });
  };

  onSearch = () => {
    this.setState({
      page: 1
    }, () => {
      this.handlePage();
      this.getBrandList(true);
    })
  };

  exposure = (e) =>{
    (!this.state.popActive) && this.setState({popActive:true});
  }

  componentDidMount() {
    // this.getToken();
    this.getTotalInfo();
    this.getBrandList();
    this.getPopData();

    $(document).ready(()=>{

      // 팝업창 비활성 이벤트 Listener
      $('body').on("click",(e)=>{
        let targetClass = e.target.className;
        if(targetClass !== ""){
          ((targetClass.indexOf('popUp') === -1) && (this.state.popActive)) && this.onClose()
        }
      });
    });
  };

  exposure = (e) =>{
    (!this.state.popActive) && this.setState({popActive:true});
  }

  onClickPage = (page) => {
    const {brandId, authToken, per } = this.state;
    this.setState(
      {
        page
      },
      () => {
        this.handlePage();
        this.getBrandList(
          brandId,
          per,
          page,
          authToken
        );
      }
    );
  };

  brandNameOrder = (order) => {
    this.setState({
      brandNameOrder : order,
      activeOrder : null,
      idOrder : null
    }, () => {
      this.handlePage();
      this.getBrandList(true);
    })
  }

  activeOrder = (order) => {
    this.setState({
      brandNameOrder: null,
      activeOrder : order,
      idOrder: null
    }, () => {
      this.handlePage();
      this.getBrandList(true);
    });
  }

  idOrder = (order) => {
    this.setState({
      idOrder : order,
      brandNameOrder : null,
      activeOrder: null
    }, () => {
      this.handlePage();
      this.getBrandList(true)
    });
  }

  // 종합정보 페이지 내의 모든 페이지 라우팅 핸들링
  handlePage = (path) => {
    const {
      brandId,
      isActive,
      brandNameOrder,
      activeOrder,
      idOrder,
      totalInfo,
      page
    } = this.state;
    let searchInfo = {
      brandId,
      brandName: totalInfo.filter((item) => item.id === parseInt(brandId))[0] ? totalInfo.filter((item) => item.id === parseInt(brandId))[0].name : '전체',
      isActive,
      brandNameOrder,
      activeOrder,
      idOrder,
      page
    };
    Object.keys(searchInfo).map((item) => {
      if (item === 'brandId') {
        (searchInfo[item] === 0 || searchInfo[item].toString().length === 0) && delete searchInfo[item];
      } else if (item === 'brandName') {
        (searchInfo[item] === '전체' || searchInfo[item].toString().length === 0) && delete searchInfo[item];
      } else if (item === 'isActive') {
        (searchInfo[item] === '전체' || searchInfo[item].toString().length === 0) && delete searchInfo[item];
      } else {
        (searchInfo[item] === null || searchInfo[item].toString().length === 0) && delete searchInfo[item];
      }
    });
    let queryString = Object.keys(searchInfo).map((item, index) => searchInfo[item] !== null && `${item}=${searchInfo[item]}`).join('&');
    this.props.history.push({
      pathname: path ? path : '/brand/totalInfo',
      search: `?${queryString}`
    });
  }

  render() {
    const { popActive, page } = this.state;

    return (
      <Fragment>
        {/* <BigText>종합정보 <p className="floatR"><span>홈</span> 〉 <span>브랜드</span> 〉 <span>종합정보</span></p></BigText> */}
        <div className="title"><p className="mb10"><span>홈</span> 〉 <span>브랜드</span> 〉 <span>종합정보</span>
          </p>
          종합정보{" "}

        </div>
        <div className="info_searchwrap">
          <form>
            <table>
              <colgroup>
                <col width="10%"></col>
                <col width="*"></col>
              </colgroup>
              <tbody>
                <tr>
                  <th>브랜드명</th>
                  <td>
                    <select
                      name="brandId"
                      id="brandId"
                      onChange={this.infoSearchValue}
                      value={this.state.brandId}
                    >
                      <option value="0">전체</option>
                      {this.state.totalInfo.map((item, key) => {
                        return (
                          <Fragment key={key}>
                            <option value={item["id"]}>{item["name"]}</option>
                          </Fragment>
                        );
                      })}
                    </select>
                  </td>
                </tr>
                <tr>
                  <th>활성화 상태</th>
                  <td>
                    <select
                      id="isActive"
                      name={"isActive"}
                      onChange={this.infoSearchValue}
                      value={this.state.isActive}
                    >
                      <option>전체</option>
                      <option value={true}>활성화</option>
                      <option value={false}>비활성화</option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="centerT mt20">
              <button
                type="button"
                className="btnLine navyBg medium"
                onClick={this.onSearch}
              >
                검색
              </button>
            </div>
          </form>
        </div>
        {/* 종합정보 검색영역 끝 */}
        <div className="info_resultwrap">
          <div className="info_tabletop">
            <div className="mb10">
              <button className="btnLine navyBg" onClick={(e) => this.exposure(e)}>노출순서</button>
              <button className="btn navyBg centerT ml10" onClick={() => this.handlePage(`/brand/totalInfo/totalInfoDetail`)}>추가</button>
            </div>
          </div>
          <table>
            <colgroup>
              <col width="5%"></col>
              <col width="30%"></col>
              <col width=""></col>
              <col width=""></col>
              <col width=""></col>
              <col width="10%"></col>
            </colgroup>
            <thead>
              <tr><th className="ccc" colSpan="6">총 <span>{this.state.totalInfo.length}</span>개</th></tr>
              <tr>
                <th>번호</th>
                <th>
                  브랜드명
                  <button className="set_btn" onClick={() => this.brandNameOrder("desc")}>▼</button>
                  <button className="set_btn" onClick={() =>  this.brandNameOrder("asc")}>▲</button>
                </th>
                <th>
                  활성화 상태
                  <button className="set_btn" onClick={() => this.activeOrder("desc")}>▼</button>
                  <button className="set_btn" onClick={() => this.activeOrder("asc") }>▲</button>
                </th>
                <th>가격옵션 갯수</th>
                <th>
                  노출순서
                  <button className="set_btn" onClick={() => this.idOrder("desc")}>▼</button>
                  <button className="set_btn" onClick={() => this.idOrder("asc") }>▲</button>
                </th>
                <th>관리</th>
              </tr>
            </thead>
            <tbody>
              {
                this.state.brands ? 
                this.state.brands.map((item, key) => {
                  return (
                    <React.Fragment key={key}>
                      <tr>
                        <td>{item["id"]}</td>
                        <td>{item["name"]}</td>
                        <td>
                          {item["is_active"] === true ? "활성화" : "비활성화"}
                        </td>
                        <td><Link to={"/brand/priceOption/"+item.id}>{item.price_option_size}</Link></td>
                        <td>{item.order_id}</td>
                        <td>
                        <button className="btn grayBg" onClick={() => this.handlePage(`/brand/totalInfo/totalInfoDetail/${item.id}`)}>수정</button>
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                }
                )
                : 
                <></>}
            </tbody>
          </table>
          <div className="pagination">
            <Pagination
                onClickPage={this.onClickPage}
                paginationOption={this.state.pagination}
              />
          </div>
                  
        </div>

        {/*브랜드 노출 팝업 시작*/}
  
        <div className={popActive ? "order on popUp" : "order popUp"}>
          <p className="mb10 popUp">브랜드 노출 순서</p>
          <span className="floatR mb10 popUp" onClick={() => this.onClose()}>x</span>
          <div>
                <SortableList axis="xy" items={this.state.popData} onSortEnd={this.onSortEnd} />
          </div>
            <div className="brand_btn">
              <Link to="/brand/totalInfo"><button type="button" className="btnLine navyBg popUp" onClick={() => this.onSave()}>저장</button></Link>
              <Link to="/brand/totalInfo"><button type="button" className="btn navyBg ml10 popUp" onClick={() => this.onClose()}>닫기</button></Link>
            </div>          
        </div>
        {/*브랜드노출 팝업 끝 */}
        {/* 종합정보 검색결과 영역 끝 */}
        {/*///// 종합정보 끝 //////*/}
      </Fragment>
    );
  }
}

export default TotalInfo;
