import { devUrl, keyNumber } from '../../serviceComm/variable';

export function getTotalInfo(authToken) {
  const axios = require("axios");
  const config = {
    method: "get",
    url:
    devUrl+"/admin/brands?per=100",
    headers: {
      "x-api-key": keyNumber,
      Authorization: "Bearer " + authToken
    }
  };

  return axios(config);
}

export function getPopData(authToken) {
  const axios = require("axios");
  const config = {
    method: "get",
    url:
    devUrl+"/brands?order_asc=true",
    headers: {
      "x-api-key": keyNumber,
      Authorization: "Bearer " + authToken
    }
  };
  return axios(config);
}

export function getBrandList(brandId, per, page, authToken , brandName , active , idOrder, isActive , name) {
  const searchName = name == "전체" ? "" : name 
  const axios = require("axios");
  const config = {
    method: "get",
    url:
      devUrl+"/admin/brands" +
      "?per=" +
      per +
      "&page=" +
      page+"&name="+searchName+
      `${active == "desc" ? "&active_desc=true" : active == "asc" ? "&active_asc=true" : ""}` +
      `${brandName == "desc" ? "&name_desc=true" : brandName == "asc" ? "&name_asc=true" : "&id_desc=true"}` + 
      `${idOrder == "desc" ? "&order_id_desc=true" : idOrder == "asc" ? "&order_id_asc=true" : "&id_desc=true"}` +
      `${isActive != "전체" ? "&is_active="+isActive : ""}`,
    headers: {
      "x-api-key": keyNumber,
      Authorization: "Bearer " + authToken
    }
  };

  return axios(config);
}

export const postBrand = (authToken , nation , name , address , parentName , seller , startAt , founder , slogan , businessType , description , orderBy , isActive , isImported , officialUrl , showroomUrl , carcenterUrl , warrantyUrl , logo) => {
  const axios = require("axios");
  let formData = new FormData();
  formData.set('nation',nation)
  formData.append('name',name)
  formData.append('address',address)
  formData.append('parent_name',parentName)
  formData.append('seller',seller)
  formData.append('start_at',startAt)
  formData.append('founder',founder)
  formData.append('slogan',slogan)
  formData.append('business_type',businessType)
  formData.append('description',description)
  formData.append('order_id',orderBy)
  formData.append('is_active',isActive)
  formData.append('is_imported',isImported)
  formData.append('official_site_url',officialUrl)
  formData.append('showroom_url',showroomUrl)
  formData.append('car_center_url',carcenterUrl)
  formData.append('warranty_url',warrantyUrl)
  formData.append('logo',logo)
  const config = {
    url : devUrl + '/admin/brands',
    method : "post",
    headers : { 
      "x-api-key": keyNumber,
      Authorization: "Bearer " + authToken
    },
    data : formData
  }

  return axios(config)
}

export const editBrand = (id ,authToken , nation , name , address , parentName , seller , startAt , founder , slogan , businessType , description , orderBy , isActive , isImported , officialUrl , showroomUrl , carcenterUrl , warrantyUrl , logo) => {
  const axios = require("axios");
  let formData = new FormData();
  formData.set('nation',nation)
  formData.append('name',name)
  formData.append('address',address)
  formData.append('parent_name',parentName)
  formData.append('seller',seller)
  formData.append('start_at',startAt)
  formData.append('founder',founder)
  formData.append('slogan',slogan)
  formData.append('business_type',businessType)
  formData.append('description',description)
  formData.append('order_id',orderBy)
  formData.append('is_active',isActive)
  formData.append('is_imported',isImported)
  formData.append('official_site_url',officialUrl)
  formData.append('showroom_url',showroomUrl)
  formData.append('car_center_url',carcenterUrl)
  formData.append('warranty_url',warrantyUrl)
  if(logo !== 'none') formData.append('logo',logo)
  const config = {
    url : devUrl + '/admin/brands/'+id,
    method : "put",
    headers : { 
      "x-api-key": keyNumber,
      Authorization: "Bearer " + authToken
    },
    data : formData
  }

  return axios(config)
}

export const getBrandDetail = (authToken , id) => {
  const axios = require("axios");
  const config = {
    url : devUrl + '/admin/brands/'+id,
    method : "get",
    headers : { 
      "x-api-key": keyNumber,
      Authorization: "Bearer " + authToken
    },
  }

  return axios(config)
}

export const setOrder = (authToken , ids) => {
  const axios = require("axios");
  const config = {
    url : devUrl + '/admin/brands/order',
    method : "put",
    headers : { 
      "x-api-key": keyNumber,
      Authorization: "Bearer " + authToken
    },
    data : ids
  }

  return axios(config)
}