import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import DatePicker from 'react-datepicker';
import Pagination from '../../component/Pagination';
import * as Common from '../../component/services/common/CommonService';
import * as Service from '../../component/services/content/PhotoNewsService';
import QueryString from 'query-string';

class PhotoNews extends Component {
  constructor(props) {
    super(props);
    const queryStringHistory = QueryString.parse(this.props.location.search);
    this.state = {
      authToken: Common.getToken.call(this),
      per: 10,
      page: queryStringHistory.page ? queryStringHistory.page : 1,
      photonews: [],
      photonews_count: 0,
      pagination: [],
      category_name: queryStringHistory.category_name ? queryStringHistory.category_name : '',
      is_active: queryStringHistory.is_active ? queryStringHistory.is_active : 'ALL',
      start_of_created_at: queryStringHistory.start_of_created_at ? new Date(queryStringHistory.start_of_created_at) : new Date(),
      end_of_created_at: queryStringHistory.end_of_created_at ? new Date(queryStringHistory.end_of_created_at) : new Date(),
      is_all_checked: false,
      is_search: queryStringHistory.is_search ? queryStringHistory.is_search : false
    };
  }

  componentDidMount() {
    this.getPhotoNewsList();
  }

  // 포토뉴스(컨텐츠 그룹) 요청
  getPhotoNewsList = () => {
    const {
      authToken,
      category_name, start_of_created_at, end_of_created_at, is_active, is_search, per, page
    } = this.state;
    if (is_search === 'true') {
      const formData = new FormData();
      const format = require('date-format');
      formData.append('category_name', category_name);
      formData.append('is_active', is_active);
      formData.append(
        'start_of_created_at',
        format('yyyy-MM-dd', start_of_created_at)
      );
      formData.append(
        'end_of_created_at',
        format('yyyy-MM-dd', end_of_created_at)
      );
      const photoNewsList = Service.getSearchPhotoNewsList(
        authToken,
        formData,
        per,
        page
      );

      photoNewsList
        .then((response) => {
          this.setState(
            {
              photonews: response.data.data.map((item) => ({ ...item, checked: false })),
              pagination: response.data.pagination
            }); // , () => this.getPhotoNewsListCount()
          console.log(response);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.message) {
              alert(error.response.data.message);
            } else {
              alert(`포토뉴스(컨텐츠 그룹)을 불러오는 중 오류가 발생하였습니다.\r\n${error}`);
            }
          } else {
            alert(`포토뉴스(컨텐츠 그룹)을 불러오는 중 오류가 발생하였습니다.\r\n${error}`);
          }
        });
    } else {
      const photoNewsList = Service.getPhotoNewsList(
        authToken,
        per,
        page
      );

      photoNewsList
        .then((response) => {
          this.setState(
            {
              photonews: response.data.data.map((item) => ({ ...item, checked: false })),
              pagination: response.data.pagination
            }); //, () => this.getPhotoNewsListCount()
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.message) {
              alert(error.response.data.message);
            } else {
              alert(`포토뉴스(컨텐츠 그룹)을 불러오는 중 오류가 발생하였습니다.\r\n${error}`);
            }
          } else {
            alert(`포토뉴스(컨텐츠 그룹)을 불러오는 중 오류가 발생하였습니다.\r\n${error}`);
          }
        });
    }
  };

  // 렌더링된 포토뉴스(컨텐츠 그룹) 카운트
  getPhotoNewsListCount = () => {
    const {
      authToken,
      category_name, start_of_created_at, end_of_created_at, is_active, is_search, per, pagination
    } = this.state;
    if (is_search) {
      const formData = new FormData();
      const format = require('date-format');
      formData.append('category_name', category_name);
      formData.append('is_active', is_active);
      formData.append(
        'start_of_created_at',
        format('yyyy-MM-dd', start_of_created_at)
      );
      formData.append(
        'end_of_created_at',
        format('yyyy-MM-dd', end_of_created_at)
      );

      const count = Service.getSearchPhotoNewsListCount(
        authToken,
        per,
        pagination.total_pages,
        formData
      );
      count.then((response) => {
        this.setState({
          photonews_count: response.data.data.length
        });
      }).catch((error) => {
        if (error.response) {
          if (error.response.data.message) {
            alert(error.response.data.message);
          } else {
            alert(`포토뉴스(컨텐츠 그룹) 목록 카운트 중 오류가 발생하였습니다.\r\n${error}`);
          }
        } else {
          alert(`포토뉴스(컨텐츠 그룹) 목록 카운트 중 오류가 발생하였습니다.\r\n${error}`);
        }
      });
    } else {
      const count = Service.getPhotoNewsListCount(
        authToken,
        per,
        pagination.total_pages
      );
      count.then((response) => {
        this.setState({
          photonews_count: response.data.data.length
        });
      }).catch((error) => {
        if (error.response) {
          if (error.response.data.message) {
            alert(error.response.data.message);
          } else {
            alert(`포토뉴스(컨텐츠 그룹) 목록 카운트 중 오류가 발생하였습니다.\r\n${error}`);
          }
        } else {
          alert(`포토뉴스(컨텐츠 그룹) 목록 카운트 중 오류가 발생하였습니다.\r\n${error}`);
        }
      });
    }
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  // 검색 옵션 Date Set
  setStartDateOnChange = (date) => {
    this.setState({
      start_of_created_at: date
    });
  };

  // 검색 옵션 Date Set
  setEndDateOnChange = (date) => {
    this.setState({
      end_of_created_at: date
    });
  };

  // 포토뉴스(컨텐츠 그룹) CheckBox All
  photoNewsAllChecked = () => {
    const { photonews, is_all_checked } = this.state;
    const temp = [...photonews];
    this.setState(
      {
        is_all_checked: !is_all_checked
      },
      () => {
        for (let i = 0; i < temp.length; i++) {
          temp[i] = {
            ...temp[i],
            checked: !is_all_checked === true
          };
        }
        this.setState({
          photonews: temp
        });
      }
    );
  };

  // 포토뉴스(컨텐츠 그룹) 삭제 CheckBox
  deleteOnChecked = (e) => {
    const id = e.target.id.split(',')[1];
    const { photonews } = this.state;
    const index = photonews.findIndex((item) => item.id === Number(id));
    const selectPhotoNews = photonews[index];
    const temp = [...photonews];

    temp[index] = {
      ...selectPhotoNews,
      checked: !selectPhotoNews.checked
    };

    this.setState({
      photonews: temp
    });
  };

  // 포토뉴스(컨텐츠 그룹) 삭제
  deletePhotoNews = () => {
    const { authToken, photonews } = this.state;
    const deletePhotoNews = photonews.filter((item) => item.checked === true);

    if (deletePhotoNews.length < 1) {
      alert('삭제할 포토뉴스를 선택해주세요.');
      return false;
    } else {
      const confirm = window.confirm('정말로 삭제하시겠습니까?');
      if (confirm) {
        const formData = new FormData();
        deletePhotoNews.forEach((item) => {
          formData.append('content_group_ids[]', item.id);
        });
        const deletes = Service.deletePhotoNews(authToken, formData);
        deletes
          .then((response) => {
            if (response.status === 200 || (response.status === 201 && response.statusText === 'OK')) {
              alert('삭제되었습니다.');
              this.getPhotoNewsList();
            }
            console.log(response);
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.message) {
                alert(error.response.data.message);
              } else {
                alert(`포토뉴스(컨텐츠 그룹)을 삭제하는 중 오류가 발생하였습니다.\r\n${error}`);
              }
            } else {
              alert(`포토뉴스(컨텐츠 그룹)을 삭제하는 중 오류가 발생하였습니다.\r\n${error}`);
            }
          });
      }
    }
  };

  // 페이지 이동
  onClickPage = (page) => {
    const { authToken, per } = this.state;
    this.setState(
      {
        page
      },
      () => {
        this.handlePage();
        this.getPhotoNewsList(authToken, per, page);
      }
    );
  };

  // 포토뉴스(컨텐츠 그룹) 검색
  searchPhotoNews = () => {
    this.setState(
      {
        is_search: 'true'
      },
      () => {
        this.getPhotoNewsList();
      }
    );
  };

  onSearch = () => {
    this.setState({
      page: 1,
      is_search: 'true'
    }, () => {
      this.handlePage();
      this.getPhotoNewsList();
    });
  };

  // 포토뉴스 페이지 내의 모든 페이지 라우팅 핸들링
  handlePage = (path, query) => {
    const format = require('date-format');
    const {
      category_name,
      is_active,
      start_of_created_at,
      end_of_created_at,
      is_search,
      page
    } = this.state;
    let searchInfo = {
      category_name,
      is_active,
      start_of_created_at,
      end_of_created_at,
      is_search,
      page
    };
    Object.keys(searchInfo).map((item) => {
      if (item === 'is_active') {
        (searchInfo[item] === 'ALL' || searchInfo[item].toString().length === 0) && delete searchInfo[item];
      } else if (item === 'start_of_created_at' || item === 'end_of_created_at') {
        if (searchInfo[item] && is_search === 'true') {
          searchInfo[item] = format('yyyy-MM-dd', searchInfo[item]);
        } else {
          delete searchInfo[item];
        }
      } else {
        (searchInfo[item] === null || searchInfo[item].toString().length === 0) && delete searchInfo[item];
      }
    });
    let queryString = Object.keys(searchInfo).map((item, index) => searchInfo[item] !== null && `${item}=${searchInfo[item]}`).join('&');
    this.props.history.push({
      pathname: path ? path : '/contents/photoNews',
      search: `?${queryString}`,
      query: query && query
    });
  }

  render() {
    const {
      photonews, pagination, per, page, is_active,
      category_name, start_of_created_at, end_of_created_at, photonews_count
    } = this.state;
    return (
      <>
        <div className="title">
          {' '}
          <p className="mb10">
            <span>홈</span>
            {' '}
            〉
            {' '}
            <span>콘텐츠</span>
            {' '}
            〉
            {' '}
            <span>포토뉴스등록/관리</span>
          </p>
          포토뉴스등록/관리
        </div>
        <form>
          <table className="mt40 mda_reset_table">
            <colgroup>
              <col width="10%" />
              <col width="" />
            </colgroup>
            <tbody>
              <tr>
                <th>카테고리 명</th>
                <td>
                  <input
                    type="text"
                    name="category_name"
                    onChange={this.onChange}
                    value={category_name}
                  />
                </td>
              </tr>
              <tr>
                <th>공개여부</th>
                <td>
                  <select name="is_active" value={is_active} onChange={this.onChange}>
                    <option value="ALL">전체</option>
                    <option value="true">예</option>
                    <option value="false">아니오</option>
                  </select>
                </td>
              </tr>
              <tr>
                <th>등록일</th>
                <td>
                  <DatePicker
                    onChange={this.setStartDateOnChange}
                    selected={start_of_created_at}
                  />
                  {' '}
                  -
                  {' '}
                  <DatePicker
                    onChange={this.setEndDateOnChange}
                    selected={end_of_created_at}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div className="centerT mt20">
            <button
              type="button"
              className="btnLine navyBg medium"
              onClick={this.onSearch}
            >
              검색
            </button>
          </div>
        </form>
        <div className="">
          <div className="info_tabletop">
            <div className="mb10">
              <button type="button" className="btnLine navyBg ml10" onClick={() => this.handlePage('/contents/photoNews/photoNewsDetail')}>
                등록
              </button>
              <button
                type="button"
                className="btn navyBg ml10"
                onClick={this.deletePhotoNews}
              >
                삭제
              </button>
            </div>
          </div>
          <table className="">
            <colgroup>
              <col width="3%" />
              <col width="" />
              <col width="30%" />
              <col width="" />
              <col width="" />
              <col width="" />
              <col width="" />
              <col width="" />
              <col width="10%" />
            </colgroup>
            <thead>
              <tr>
                <th colsPan="9">
                  총 <span>94</span>개
                </th>
              </tr>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    name="pt_news"
                    id="pt_news_all"
                    onChange={this.photoNewsAllChecked}
                  />
                </th>
                <th>번호</th>
                <th>카테고리 명</th>
                <th>콘텐츠 수</th>
                <th>공개여부</th>
                <th>조회수</th>
                <th>수정일</th>
                <th>등록일</th>
                <th>관리</th>
              </tr>
            </thead>
            <tbody>
              {photonews != null && photonews.map((item, index) => (
                <tr key={item.id}>
                  <td>
                    <input
                      type="checkbox"
                      name="pt_news"
                      id={`ptn,${item.id}`}
                      onChange={this.deleteOnChecked}
                      checked={item.checked}
                    />
                  </td>
                  <td>{(per * pagination.current_page) - (per - (index + 1))}</td>
                  <td>
                    <Link to="#">{item.category_name}</Link>
                  </td>
                  <td>{item.contents.length}</td>
                  <td>{item.is_active === true ? '공개' : '비공개'}</td>
                  <td>{item.view_count}</td>
                  <td>
                    <Moment format="YYYY-MM-DD HH:mm:ss">
                      {item.updated_at}
                    </Moment>
                  </td>
                  <td>
                    <Moment format="YYYY-MM-DD HH:mm:ss">
                      {item.created_at}
                    </Moment>
                  </td>
                  <td>
                    <button type="button" className="btn grayBg ml10" onClick={() => this.handlePage('/contents/photoNews/photoNewsDetail', [
                      item.id,
                      item.category_name,
                      item.category_description,
                      item.tags,
                      item.contents
                    ])}>수정</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination">
            <Pagination
              onClickPage={this.onClickPage}
              paginationOption={pagination}
            />
          </div>
        </div>
      </>
    );
  }
}

export default PhotoNews;
