import React, { Component } from 'react';
import IconLogo from '../image/logo.png';
import IconLogo2 from '../image/logo2.png';

class Logo extends Component {
  render() {
    return (
      <div className="logoBox">
        <img src={IconLogo} alt="logo" /><img src={IconLogo2} alt="logo2" />
      </div>
    );
  }
}

export default Logo