import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { completeLogin, completeLogout } from '../store/modules/login';

import * as CommonService from '../component/services/common/CommonService';


import '../style/login.scss';


const LoginContainer = (props) => {
  const [loginData, setLoginData] = useState(
    {
      email: '',
      password: ''
    }
  );
  const handleChange = (e) => {
    setLoginData({
      ...loginData,
      [e.target.name]: e.target.value
    });
  };

  useEffect(() => {
    const { history } = props;
    if (window.localStorage.getItem('token')) {
      window.localStorage.setItem('tkDate',new Date())
        if (history.action === 'POP') {
          history.push('/contents/media');
        } else if (history.action === 'PUSH') {
          history.goForward();
        }


    }
  }, [props]);

  const login = () => {
    const { history, completeLogin } = props;
    // const completeLogin = this.props;
    CommonService.login(loginData).then((res) => {
      if (!(res.status >= 200 && res.status < 400)) return;
      console.log(res.data)
      window.localStorage.setItem('token', res.data.authentication_token);
      window.localStorage.setItem('username', res.data.username ? res.data.username : '');
      window.localStorage.setItem('tkDate',new Date());

      completeLogin();
      history.push('/contents/media');
      // history.goBack();
    }).catch((err) => alert('정보가 올바르지 않습니다'));
  };

  // const logout = () => {
  //   const { history, completeLogout } = props;
  //   CommonService.logout(window.localStorage.getItem('token')).then(() => {
  //     window.localStorage.removeItem('token');
  //     alert('로그아웃 되었습니다.');
  //     completeLogout();
  //     history.push('/login');
  //   }).catch((err) => alert(err));
  // };

  return (
    <>
      <div className="login">
        <div className="login-screen">
          <div className="app-title">
            <h1>관리자 로그인</h1>
          </div>
          <div className="login-form">
            <div className="control-group">
              <input
                type="text"
                className="login-field"
                name="email"
                onChange={handleChange}
                value={loginData.email}
                placeholder="username"
                id="login-name"
                required
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    login();
                  }
                }}
              />
            </div>
            <div className="control-group">
              <input
                type="password"
                className="login-field"
                name="password"
                onChange={handleChange}
                value={loginData.password}
                placeholder="password"
                id="login-pass"
                required
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    login();
                  }
                }}
              />
            </div>
            <button
              type="button"
              id="loginEventID"
              className="btn btn-primary btn-large btn-block"
              onClick={() => {
                login();
              }}
            >
                login
            </button>
          </div>
        </div>
        {/* <button onClick={logout}>로그아웃</button> */}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  loginState: state.login.loginState
});

const mapDispatchToProps = (dispatch) => ({
  completeLogin: () => dispatch(completeLogin()),
  completeLogout: () => dispatch(completeLogout())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginContainer);
