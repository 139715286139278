import {devUrl, keyNumber} from '../../serviceComm/variable';
import axios from 'axios';

export const getTrimList = (authToken, per, page,
                            /* 정렬 목록 */
                            bo, mgo, mo, lo, no, ao, co, uo,
                            /* 정렬 목록 */

                            /* 검색 목록*/
                            id, cmg, cm, lu, name, isActive
                            /* 검색 목록 */
) => {
  const axios = require('axios')
  const url = devUrl + '/admin/car_db/trims?id_desc=true' +
    "&per=" + per +
    "&page=" + page +
    `${bo == 'desc' ? "&brand_desc=true" : bo == 'asc' ? "&brand_asc=true" : ""}` +
    `${mgo == 'desc' ? "&car_model_group_desc=true" : mgo == 'asc' ? "&car_model_group_asc=true" : ""}` +
    `${mo == 'desc' ? "&car_model_desc=true" : mo == 'asc' ? "&car_model_asc=true" : ""}` +
    `${lo == 'desc' ? "&lineup_desc=true" : lo == 'asc' ? "&lineup_asc=true" : ""}` +
    `${no == 'desc' ? "&name_desc=true" : no == 'asc' ? "&name_asc=true" : ""}` +
    `${ao == 'desc' ? "&active_desc=true" : ao == 'asc' ? "&active_asc=true" : ""}` +
    `${co == 'desc' ? "&created_desc=true" : co == 'asc' ? "&created_asc=true" : ""}` +
    `${uo == 'desc' ? "&updated_desc=true" : uo == 'asc' ? "&updated_asc=true" : ""}` +
    `${id == 0 ? "" : `&brand_id=${id}`}` +
    `${cmg == 0 ? "" : "&car_model_group_id=" + cmg}` +
    `${cm == 0 ? "" : "&car_model_id=" + cm}` +
    `${lu == 0 ? "" : "&lineup_id=" + lu}` +
    `${name == 0 ? "" : "&id=" + name}` +
    `${isActive == 'all' ? "" : "&is_active=" + isActive}`
  const config = {
    url: url.replace(',', ''),
    method: 'get',
    headers: {
      "x-api-key": keyNumber,
      Authorization: "Bearer " + authToken
    }
  };

  return axios(config)
};

export const getTrimListNew = (authToken, params) => {
  return axios({
    url: devUrl + '/admin/car_db/trims',
    method: 'get',
    headers: {Authorization: `Bearer ${authToken}`},
    params
  })

};

export const postTrim = (authToken, trim) => {
  const axios = require('axios');

  const config = {
    url: `${devUrl}/admin/car_db/trims`,
    method: 'post',
    headers: {
      Authorization: `Bearer ${authToken}`
    },
    data: trim
  };

  return axios(config)
};

export const editTrim = (authToken, trim) => {
  const axios = require('axios');

  const config = {
    url: `${devUrl}/admin/car_db/trims/${trim.id}`,
    method: 'put',
    headers: {
      Authorization: `Bearer ${authToken}`
    },
    data: trim
  };

  return axios(config)
};

export const deleteTrim = (authToken, ids) => {
  const axios = require('axios')
  let data = {
    ids: ids
  }
  const config = {
    url: devUrl + '/admin/car_db/trims',
    method: 'DELETE',
    headers: {
      "x-api-key": keyNumber,
      Authorization: "Bearer " + authToken
    },
    data: data
  }

  return axios(config)
}

export const getTrimDetail = (authToken, id) => {
  const axios = require('axios')
  const config = {
    url: devUrl + '/admin/car_db/trims/' + id,
    method: 'get',
    headers: {
      "x-api-key": keyNumber,
      Authorization: "Bearer " + authToken
    }
  }

  return axios(config)
}

export const getTrimListByModel = (authToken, id) => {
  const axios = require('axios')
  const config = {
    url: devUrl + '/admin/car_db/trims?per=1000&page=1&car_model_id=' + id,
    method: 'get',
    headers: {
      "x-api-key": keyNumber,
      Authorization: "Bearer " + authToken
    }
  }

  return axios(config)
}

export const getSimpleTrims = (authToken, lineup_id) => {
  const axios = require('axios');
  const config = {
    url: `${devUrl}/admin/car_info/trims`,
    method: 'get',
    headers: {
      "x-api-key": keyNumber,
      Authorization: `Bearer ${authToken}`
    },
    params: {
      lineup_id
    }
  };

  return axios(config);
};

export const getAdditionalInfoOfTrim = (authToken, trim_id, type) => {
  const axios = require('axios');
  const config = {
    url: `${devUrl}/admin/car_db/trims/${trim_id}/${type}`,
    method: 'get',
    headers: {
      "x-api-key": keyNumber,
      Authorization: `Bearer ${authToken}`
    }
  };

  return axios(config);
};
