import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as Common from '../../component/services/common/CommonService';
import * as Service from '../../component/services/content/MediaService';

class Media extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authToken: Common.getToken.call(this),
      dataList: []
    };
  }

  componentDidMount() {
    this.getMediaList();
  }

  getMediaList = () => {
    const { authToken } = this.state;
    const { history } = this.props;
    const mediaList = Service.getMediaList(authToken);
    mediaList.then((response) => {
      if (!(response.status >= 200 && response.status < 400)) return;

      this.setState({
        dataList: response.data.data.map((res) => ({ ...res, checked: false }))
      });
    }).catch((error) => {
      alert(error.response.data.message);
      if (error.response.status === 401) {
        alert('로그인을 해주세요');
        history.push('/login');
      }
    });
  };

  deleteMedia = () => {
    const { dataList, authToken } = this.state;
    const { history } = this.props;
    const checkedId = [];
    dataList.forEach((data) => {
      if (data.checked && checkedId.indexOf(data.id) === -1) {
        checkedId.push(data.id);
      }
    });
    const request = Service.deleteMediaItem(authToken, checkedId);
    request.then((res) => {
      if (!(res.status >= 200 && res.status < 400)) return;
      this.getMediaList();
      alert('삭제되었습니다');
    }).catch((error) => {
      alert(error.response.data.message);
      if (error.response.status === 401) {
        alert('로그인을 해주세요');
        history.push('/login');
      }
    });
  }

  checkItem = (e) => {
    const { dataList } = this.state;
    this.setState({
      dataList: [
        ...dataList.map((data) => (
          data.id === +e.target.id ? { ...data, checked: !data.checked } : data))
      ]
    });
  }

  checkAll = (e) => {
    const { dataList } = this.state;
    if (e.target.checked) {
      this.setState({
        dataList: [
          ...dataList.map((data) => ({ ...data, checked: true }))
        ]
      });
    } else {
      this.setState({
        dataList: [
          ...dataList.map((data) => ({ ...data, checked: false }))
        ]
      });
    }
  }

  checkURL = (...url) => {
    const RegEx = /^((http(s?)):\/\/)([0-9a-zA-Z-]+\.)+[a-zA-Z]{2,6}(:[0-9]+)?(\/\S*)?$/;
    const arr = url.filter((str) => str);
    let str = '';
    if (arr.length !== 0 && RegEx.test(arr[0])) {
      const [distStr] = arr;
      str = distStr;
    }
    if (arr.length !== 0 && !RegEx.test(arr[0])) {
      str = `https://${arr[0]}`;
    }
    return str;
  };

  terneryEditor = (data) => (data.editors[0] ? data.editors[0].name : '');

  render() {
    const { dataList } = this.state;
    return (
      <>
        <div className="title disi wd100">
          {' '}
          <p className="mb10">
            <span>홈</span>
            {' '}
            〉
            {' '}
            <span>콘텐츠</span>
            {' '}
            〉
            {' '}
            <span>매체등록/관리</span>
          </p>
          매체등록/관리
          <div className="floatR disi">
          <Link to="/contents/media/mediaDetail">
            <button type="submit" className="btnLine navyBg ml10">
              매체등록
            </button>
          </Link>
          <button type="button" className="btn navyBg ml10" onClick={this.deleteMedia}>
            삭제
          </button>
        </div>
        </div>

        <div className="media_wrapper">
          <div className="info_tabletop">
          </div>
          <table className="media_table">
            <colgroup>
              <col width="3%" />
              <col width="5%" />
              <col width="10%" />
              <col width="" />
              <col width="18%" />
              <col width="24%" />
              <col width="10%" />
              <col width="10%" />
              <col width="8%" />
            </colgroup>
            <thead>
              <tr>
                <th className="ccc" colSpan="9">
                  총 <span>94</span>개
                </th>
              </tr>
              <tr>
                <th>
                  <input type="checkbox" name="media" id="media_all" onClick={this.checkAll} />
                </th>
                <th>번호</th>
                <th>구분</th>
                <th>매체로고</th>
                <th>매체명</th>
                <th>홈페이지 링크</th>
                <th>연락처</th>
                <th>편집자</th>
                <th>관리</th>
              </tr>
            </thead>
            <tbody>
              {dataList.map((data, idx) => (
                <tr key={data.id}>
                  <td>
                    <input type="checkbox" name="media" id={data.id} onChange={this.checkItem} checked={data.checked} />
                  </td>
                  <td>{idx + 1}</td>
                  <td>{data.category}</td>
                  <td>
                    <div className="media_logo">
                      {data.logo ? <img src={data.logo} alt="logo" /> : ''}
                    </div>
                  </td>
                  <td>{data.name}</td>
                  <td className="leftT">
                    <Link
                      to="#"
                      onClick={() => {
                        window.open(this.checkURL(data.homepage_url, data.naver_post_url, data.naver_tv_url, data.youtube_url, data.etc_url), '_blank');
                      }}
                    >
                      {data.homepage_url || data.naver_post_url
                    || data.naver_tv_url || data.youtube_url || data.etc_url}
                    </Link>
                  </td>
                  <td>{data.contact}</td>
                  <td>{data.editors ? this.terneryEditor(data) : ''}</td>
                  <td>
                    <Link to={{ pathname: `/contents/media/mediaDetail/${data.id}` }}>
                      <button type="button" className="btn grayBg ml10">수정</button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

export default Media;
